import React, { useContext } from "react";
import { LanguageContext } from "../../context/Language";
import Modal from "../Modal";
import Button from "../Buttons/GeneralButton";

const ModalConfirm = ({ id, visible, message, onCancel, onAction }) => {
  const { dictionary } = useContext(LanguageContext);

  return (
    <Modal
      idModal={id || "modal-confirm"}
      addclassName="confirmModal"
      handleCancel={onCancel}
      title={null}
      isModalVisible={visible}
      body={<div className="forms">{message}</div>}
      footer={
        <div className="modalAdd-btns">
          <Button
            key="cancel"
            size="sm"
            className="back-btn"
            text={dictionary.cancel}
            onClick={onCancel}
          />
          <Button
            size="md"
            key="action"
            className="footer-btn"
            onClick={onAction}
            text={dictionary.confirm}
          />
        </div>
      }
    />
  );
};

export default ModalConfirm;
