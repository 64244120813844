import React, { useEffect, useContext, useState, useMemo } from "react";
import { Link } from "react-router-dom";
import { Table, Spin, Empty, DatePicker } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { AppContext } from "../../../context/App";
import { LanguageContext } from "../../../context/Language";
import Select from "../../Form/Select";
import Search from "../../Form/Search";
import SearchIcon from "../../Icon/SearchIcon";
import moment from "moment";
import Bluebird from "bluebird";

const TableContracts = ({
  list,
  error,
  columns,
  offset,
  limit,
  setLimit,
  setWhere,
  setOrder,
  setOffset,
  updatePage,
  totalData,
}) => {
  const page = useMemo(() => Math.floor(offset / limit) + 1, [offset, limit]);
  const { RangePicker } = DatePicker;
  const { dictionary } = useContext(LanguageContext);
  const { statusContractOptions, assignedOptions } = useContext(AppContext);
  const [search, setSearch] = useState(null);
  const [dataSource, setTableData] = useState(null);
  const [statusFilter, setStatus] = useState(0);
  const [assignFilter, setAssign] = useState(0);
  const [loadList, setLoading] = useState(true);
  const [dateFilter, setDateFilter] = useState(null);

  useEffect(() => {
    getList();
  }, [list]);

  const getLastCharacter = (str, n) => {
    let newString = str.substring(str.length - n);
    return newString;
  };

  const getList = async () => {
    setLoading(true);
    setTableData(null);
    let listArray = [];
    try {
      await Bluebird.map(list, (el, index) => {
        let assigned = el.assigned_contract ? (
          <div className="yes-ac">{dictionary.yes}</div>
        ) : (
          <div className="no-ac">{dictionary.no}</div>
        );
        let estatus =
          el.status === "Pending" ? (
            <div className="pend-status">{dictionary.pending}</div>
          ) : el.status === "Synced" ? (
            <div className="sync-status">{dictionary.synced}</div>
          ) : (
            "-"
          );
        let shortPayee = el.payee_id ? getLastCharacter(el.payee_id, 10) : null;
        let payeeID = shortPayee ? (
          <Link
            target="_blank"
            rel="noopener noreferrer"
            className="link-table"
            to={
              "https://app.curveroyaltysystems.com/#/payees/" +
              el.payee_id +
              "/edit"
            }
          >
            ...{shortPayee}
          </Link>
        ) : (
          "-"
        );
        let curveId =
          el.contracts && el.contracts[0] ? el.contracts[0]._id : null;
        let contractName =
          el.contracts && el.contracts[0] ? el.contracts[0].name : "-";
        let contractLink = curveId ? (
          <Link
            target="_blank"
            rel="noopener noreferrer"
            className="link-table"
            to={"/contracts/update?c=" + curveId}
          >
            {contractName}
          </Link>
        ) : (
          "-"
        );
        listArray.push({
          index: index,
          key: el._id || index,
          id: index + 1,
          name: el.name,
          contracts: contractLink,
          assigned_contract: assigned,
          payee_id: payeeID,
          status: estatus,
          editValues: (
            <Link to={`/contracts/label?l=${el._id}`}>
              <EditOutlined className="view-edit-rel" />
            </Link>
          ),
          created_date: el.created_date
            ? moment(el.created_date).format("MMM DD, YYYY, h:mm a")
            : "-",
          sublabels: el.sublabels ?? null,
        });
      });
      setTableData(listArray);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const onSearch = (search) => {
    setLoading(true);
    setSearch(search.target.value);
  };

  const handleSelect = (e) => {
    setLoading(true);
    setStatus(e);
  };

  const handleSelectAssign = (e) => {
    setLoading(true);
    setAssign(e);
  };

  const datesSelect = (e) => {
    setLoading(true);
    setDateFilter(e);
  };

  useEffect(() => {
    const newWhere = [];
    if (statusFilter) {
      newWhere.push({ status: { $like: `/^${statusFilter}$/` } });
    }
    if (assignFilter !== 0) {
      newWhere.push({ assigned_contract: assignFilter });
    }
    if (dateFilter) {
      newWhere.push({
        created_date: {
          $gte: dateFilter[0].toISOString(),
          $lte: dateFilter[1].toISOString(),
        },
      });
    }
    if (search) {
      newWhere.push({
        $or: [
          { name: { $like: `/${search}/` } },
          { main_contract: { $like: `/${search}/` } },
          { payee_id: { $like: `/${search}/` } },
          { curve: { $like: `/${search}/` } },
          { "contracts.curve_id": { $like: `/${search}/` } },
        ],
      });
    }
    setOffset(0);
    setWhere(newWhere.length > 0 ? { $and: newWhere } : {});
  }, [statusFilter, assignFilter, dateFilter, search]);

  const expandedRowRender = (record) => {
    let subLabels = record.sublabels;
    const subColumn = [
      {
        title: dictionary.sublabel,
        dataIndex: "name",
        key: "name",
        className: "title-category",
      },
      {
        title: dictionary.contract,
        dataIndex: "contracts",
        key: "contracts",
        className: "title-category",
      },
      {
        title: dictionary.payee,
        dataIndex: "payee_id",
        key: "payee_id",
        className: "title-category",
      },
      {
        title: dictionary.editValues,
        dataIndex: "editValues",
        className: "title-category edit-vals",
      },
    ];
    const data = [];
    subLabels.map((el, index) => {
      let shortPayee = el.payee_id ? getLastCharacter(el.payee_id, 10) : null;
      let payeeID = shortPayee ? (
        <Link
          target="_blank"
          rel="noopener noreferrer"
          className="link-table"
          to={
            "https://app.curveroyaltysystems.com/#/payees/" +
            el.payee_id +
            "/edit"
          }
        >
          ...{shortPayee}
        </Link>
      ) : (
        "-"
      );
      let curveId =
        el.contracts && el.contracts[0] ? el.contracts[0]._id : null;
      let contractName =
        el.contracts && el.contracts[0] ? el.contracts[0].name : "-";
      let contractLink = curveId ? (
        <Link
          target="_blank"
          rel="noopener noreferrer"
          className="link-table"
          to={"/contracts/update?c=" + curveId}
        >
          {contractName}
        </Link>
      ) : (
        "-"
      );
      return data.push({
        index: index,
        key: el._id || index,
        name: el.name ?? "-",
        contracts: contractLink,
        payee_id: payeeID,
        editValues: <Link to={`/contracts/label?l=${el._id}`}>see</Link>,
      });
    });
    return (
      <Table
        columns={subColumn}
        dataSource={data}
        pagination={false}
        className="content-subtable"
      />
    );
  };

  return (
    <div className="tables">
      <div className="top-actions-srch">
        <div className="searchers">
          <Search
            name="searcher"
            value={search}
            mobile="responsive"
            onSearch={onSearch}
            className="searcher sec back"
            enterButton={<SearchIcon />}
            placeholder={dictionary.search}
          />
          <Select
            name="section"
            value={statusFilter}
            options={statusContractOptions}
            placeholder={dictionary.status}
            onChange={(e) => handleSelect(e)}
            className="selector-general search-sec"
          />
          <Select
            name="section"
            value={assignFilter}
            options={assignedOptions}
            placeholderOption={false}
            placeholder={dictionary.assignedContract}
            onChange={(e) => handleSelectAssign(e)}
            className="selector-general search-sec"
          />
          <div className="dateField">
            <div className="label">{dictionary.createdAt}</div>
            <RangePicker
              defaultValue={dateFilter}
              onChange={(e) => datesSelect(e)}
              className="date-range-admn"
            />
          </div>
        </div>
      </div>
      <div className="general-card-admin">
        <div className="table-admin inline-contend">
          <div className="header-tab-ad"></div>
          <Table
            rowKey="index"
            columns={columns}
            dataSource={dataSource}
            className="content-table-admin users contracts"
            loading={{
              spinning: loadList,
              indicator: <Spin className=" spin-contract empty " />,
            }}
            locale={{
              emptyText: (
                <Empty
                  description={dictionary.noData}
                  className="nodata-contracts"
                ></Empty>
              ),
            }}
            pagination={{
              current: page,
              total: totalData,
              pageSize: limit,
              onChange: (page) => {
                updatePage(page);
              },
            }}
            expandable={{
              expandedRowRender,
              rowExpandable: (record) =>
                record.sublabels.length !== 0 && record.sublabels !== null,
            }}
            onChange={(pagination, _filters, sorter) => {
              setLoading(true);
              setLimit(pagination.pageSize);
              setOrder([
                [sorter.order ? sorter.field : "created_date", sorter.order === "ascend" ? "ASC" : "DESC"],
              ]);
            }}
          />
        </div>
        {error ? <p className="text-error relative-text">{error}</p> : null}
      </div>
    </div>
  );
};

export default TableContracts;
