import React from 'react';
import Button from '../../../Buttons/GeneralButton';
import './label-cta.css';

const LabelCTA = ({ dictionary, onContactClick, customStyles = {} }) => {
  const buttonStyles = {
    margin: 0,
    minHeight: 'unset',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    ...(customStyles.button || {})
  };

  return (
    <div className="label-cta-banner" style={customStyles.container || {}}>
      <div className="label-cta-content">
        <h3 className="label-cta-title" style={customStyles.heading || {}}>
          {dictionary.pricingCustomPlan}
        </h3>
        <p className="label-cta-text" style={customStyles.text || {}}>
          {dictionary.pricingCustomPlanDesc}
        </p>
      </div>
      <Button
        text={dictionary.pricingCustomPlanBtn}
        onClick={onContactClick}
        className="lets-talk-btn"
        styles={buttonStyles}
      />
    </div>
  );
};

export default LabelCTA; 