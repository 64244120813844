import React, { useContext, useState, useEffect } from "react";
import { Switch, Checkbox } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { LanguageContext } from "../../../../context/Language";
import { UserContext } from "../../../../context/User";
import Button from "../../../Buttons/GeneralButton";
import InfoIcon from "../../../Icon/InfoIcon";
import Country from "../../../../classes/country/country.jsx";
import Release from "../../../../classes/release/release.jsx";
import Bluebird from "bluebird";
import Loader from "../../../Loader";

const Territories = ({
  state,
  setValid,
  setInput,
  validation,
  backSection,
  nextSection,
  validateStep,
  changeSection,
}) => {

  const CheckboxGroup = Checkbox.Group;
  const territory =
    state.territories.length > 0 && !state.territories.includes("WW")
      ? true
      : false;
  const { dictionary } = useContext(LanguageContext);
  const { plan, type } = useContext(UserContext); 
  const [dataRegions, setDataRegions] = useState([]);
  const [dataCountries, setData] = useState([]);
  const [checkedList, setCheckedList] = useState(state.territories);
  const [checkedListRegion, setCheckedListRegion] = useState([]);
  const [checked, setCheckedSwitch] = useState(territory);
  const [disabled, setDisabled] = useState(true);
  const [disableCountry, setDisabledCountry] = useState(true);
  const [checkAllRegion, setCheckAllRegion] = useState(false);
  const [checkAllCountry, setCheckAllCountry] = useState(false);
  const [loader, setLoader] = useState(false);
  const [generalError, setGeneralError] = useState(null);

  useEffect(() => {
    setLoader(true);
    getRegions();
    getCountries().then((data) => {
      const terr = state.territories;
      let checkCountries =
        terr.length === (data.length || state.regions.length === 6)
          ? true
          : false;
      let checkRegions =
        checkCountries || state.regions.length === 6 ? true : false;
      setLoader(false);
      if (!terr.includes("WW") && terr.length !== 0) {
        setDisabled(false);
        setDisabledCountry(false);
        setCheckedSwitch(true);
        setCheckedList(terr);
        setCheckAllRegion(checkRegions);
        setCheckAllCountry(checkCountries);
        getRegions().then((info) => {
          let totalRegions = getRegionByCountry(data, info);
          let getContinents = info.map((el) => el.value);
          setCheckedListRegion(
            checkRegions
              ? getContinents
              : totalRegions.length !== 0
              ? totalRegions
              : state.regions
          );
        });
      } else if (terr.length === 0) {
        setInput({
          ...state,
          territories: ["WW"],
        });
      }
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    validateStep(next);
    // eslint-disable-next-line
  }, [state.territories]);

  const getRegionByCountry = (countries, regions) => {
    const getting = [];
    try {
      regions.forEach((elRegion) => {
        let filtered = countries.filter(
          (el) =>
            state.territories.includes(el._id) && el.region === elRegion.value
        );
        let countOf = countries.filter(
          (e) => e.region === elRegion.value
        ).length;
        if (filtered.length !== 0 && countOf === filtered.length)
          getting.push(elRegion.value);
      });
      return getting;
    } catch (e) {
      return [];
    }
  };

  const getRegions = async () => {
    const regions = [];
    for (const region in dictionary.continents) {
      regions.push({
        value: region,
        label: dictionary.continents[region],
      });
    }
    setDataRegions(regions);
    return regions;
  };

  const getCountries = async () => {
    try {
      const countries = await Country.countries();
      countries.body.countries.splice(-1, 1);
      setData(countries.body.countries);
      return countries.body.countries;
    } catch (e) {
      setData([]);
    }
  };

  const getByRegion = async (region) => {
    let countries = [];
    await Bluebird.map(region, async (reg) => {
      const found = await dataCountries.filter((el) => el.region === reg);
      countries = [...countries, ...found];
      setCheckedList([countries.map((countries) => countries._id)][0]);
    });
    setCheckAllCountry(region.length === 6 ? true : false);
    return [countries.map((countries) => countries._id)][0];
  };

  const onChangeSwitch = (checked) => {
    setDisabled(!checked);
    setDisabledCountry(!checked);
    if (checked === false) {
      setCheckedList(["WW"]);
      setCheckedListRegion([]);
      setCheckAllRegion(false);
      setCheckAllCountry(false);
      setInput({
        ...state,
        territories: ["WW"],
        regions: [],
      });
    }
  };

  const onCheckAllRegionChange = (e) => {
    const newList = e.target.checked
      ? dataRegions.map((region) => region.value)
      : [];
    setCheckedListRegion(newList);
    setCheckAllRegion(e.target.checked);
    if (e.target.checked === false) {
      setCheckedList(["WW"]);
      setCheckAllCountry(false);
      setInput({
        ...state,
        territories: ["WW"],
      });
    } else {
      setCheckedList(
        [dataCountries.map((dataCountries) => dataCountries._id)][0]
      );
      setCheckAllCountry(true);
      setInput({
        ...state,
        territories: [
          dataCountries.map((dataCountries) => dataCountries._id),
        ][0],
        regions: newList,
      });
    }
    setDisabledCountry(false);
  };

  const onCheckAllCountryChange = (e) => {
    const newList = e.target.checked
      ? [dataCountries.map((dataCountries) => dataCountries._id)][0]
      : ["WW"];
    const newListRegions = e.target.checked
      ? dataRegions.map((region) => region.value)
      : [];
    setCheckedList(newList);
    setCheckAllRegion(e.target.checked);
    setCheckedListRegion(newListRegions);
    setInput({
      ...state,
      territories: newList,
      regions: e.target.checked === false ? [] : checkedListRegion,
    });
    setCheckAllCountry(e.target.checked);
    if (e.target.checked === false) {
      setCheckedListRegion([]);
      setCheckAllRegion(false);
      setDisabledCountry(false);
    }
  };

  const onChangeCheckRegion = async (list) => {
    setCheckedListRegion(list);
    let territories = ["WW"];
    if (list.length >= 1) {
      territories = await getByRegion(list);
    } else {
      setCheckedList(["WW"]);
    }
    setInput({
      ...state,
      regions: list,
      territories,
    });
    setCheckAllRegion(list.length === dataRegions.length);
    setDisabledCountry(false);
  };

  const onChangeCheck = (list) => {
    setCheckedList(list);
    setCheckAllCountry(list.length === dataCountries.length);
    setInput({
      ...state,
      territories: list,
      regions: list.length === 0 ? [] : checkedListRegion,
    });
    if (list.length === 0) {
      setDisabledCountry(false);
      setCheckedListRegion([]);
    }
  };

  const back = () => {
    changeSection(4);
    backSection(5);
    window.scrollTo(0, 0);
  };

  const next = async (n = null) => {
    const saved = await Release.setReleaseTerritories(
      state._id,
      state.territories
    );
    if (!saved.error) {
      setLoader(true);
      setValid({
        ...validation,
        step5: true,
      });
      if (n) {
        changeSection(n);
        nextSection(n);
      }
      window.scrollTo(0, 0);
    } else {
      setGeneralError(dictionary[saved.error] || dictionary.releaseCheckData);
    }
    setLoader(false);
  };

  return (
    <div>
      <h1 className="cust-territory">
        {dictionary.customTerritories}
        <Switch
          className="general-switch"
          onChange={onChangeSwitch}
          defaultChecked={checked}
          disabled={type !=="admin" && !plan?.features?.territories}
        />
        {type !=="admin" && !plan?.features?.territories ? (
          <InfoIcon tooltipKey="territoriesPlanInfo" placement="right" />
        ): null}
      </h1>
      <div className="sub-trr">
        {dictionary.territoriesDesc} <span>{dictionary.territoriesDefault}</span>
      </div>
      <div className="territories-box">
        <div className="check-div left-check">
          <Checkbox
            indeterminate={false}
            onChange={onCheckAllRegionChange}
            checked={checkAllRegion}
            disabled={disabled}
            className="check-all"
          >
            {dictionary.regions}
          </Checkbox>
          <CheckboxGroup
            value={checkedListRegion}
            disabled={disabled}
            onChange={onChangeCheckRegion}
            className="check-country"
            options={dataRegions}
          />
        </div>
        <div className="check-div right-check">
          <Checkbox
            indeterminate={false}
            onChange={onCheckAllCountryChange}
            checked={checkAllCountry}
            disabled={disableCountry}
            className="check-all"
          >
            {dictionary.countries}
          </Checkbox>
          <CheckboxGroup
            value={checkedList}
            disabled={disableCountry}
            onChange={onChangeCheck}
            className="check-country"
            options={dataCountries.map((dataCountries) => ({
              label: dataCountries.name,
              value: dataCountries._id,
            }))}
          />
        </div>
        {loader ? <Loader /> : null}
      </div>
      {generalError ? <div className="error-field">{generalError}</div> : null}
      <div className="align-right reg-btn">
        <Button
          size="md"
          onClick={back}
          className="back-btn"
          text={dictionary.back}
          icon={<ArrowLeftOutlined />}
        />
        <Button size="md" onClick={() => next(6)} text={dictionary.next} />
      </div>
    </div>
  );
};

export default Territories;
