import React, { useContext, useState, useEffect } from "react";
import { LanguageContext } from "../../../context/Language";
import { Tabs } from "antd";
import { useFieldArray, useFormContext } from "react-hook-form";
import CreateContractOverview from "../CreateContractOverview";
import CreateContractTerms from "../CreateContractTerms";
import CreateContractEscalations from "../CreateContractEscalations";
import CreateContractReserves from "../CreateContractReserves";
import CreateContractAliases from "../CreateContractAliases";
import ContractCalls from "../../../classes/contract/contract";
import { useSearchParams } from "react-router-dom";
import { AppContext } from "../../../context/App";
import moment from "moment";

const CreateContract = ({ reload, setIsDeleted, setSync }) => {
  const [searchParams] = useSearchParams();
  const contractId = searchParams.get("c");

  const { dictionary } = useContext(LanguageContext);
  const { setOpenedContract } = useContext(AppContext);
  const [contractData, setContractData] = useState(null);

  const { reset } = useFormContext();
  const { replace: replaceSalesTerms } = useFieldArray({ name: "salesTerms" });
  const { replace: replaceCostsTerms } = useFieldArray({ name: "costsTers" });
  const { replace: replaceDeductionsTerms } = useFieldArray({
    name: "deductionsTerms",
  });
  const { replace: replaceMechanicalTerms } = useFieldArray({
    name: "mechanicalDeductionsTerms",
  });
  const { replace: replaceWithholdingTerms } = useFieldArray({
    name: "withholdingTaxDeductionsTerms",
  });
  const { replace: replaceCrossContracts } = useFieldArray({
    name: "crossContracts",
  });
  const { replace: replaceAliases } = useFieldArray({ name: "alias" });
  const { replace: replaceReserves } = useFieldArray({ name: "reserve" });

  useEffect(() => {
    if (contractId) {
      const getContract = async () => {
        const contract = await ContractCalls.getContractCurve(contractId);
        if (contract.contractData) {
          setContractData(contract.contractData);
          if (contract.sync) {
            setSync();
          }
        } else {
          setIsDeleted(true);
        }
      };
      getContract();
    }
  }, [contractId, reload]);

  useEffect(() => {
    if (contractData) {
      setOpenedContract(contractData);
      reset({
        ...contractData,
        salesTerms: contractData.salesTerms || [],
        costsTerms: contractData.costsTerms || [],
        deductionsTerms: contractData.deductionsTerms || [],
        mechanicalTerms: contractData.mechanicalTerms || [],
        withholdingTaxTerms: contractData.withholdingTaxTerms || [],
        crossContracts: contractData.crossContracts || [],
        startDate: moment(contractData.startDate).toDate(),
        endDate: moment(contractData.endDate).toDate(),
        contractType: 'Royalty', // TODO: for now we are using royalty but we to get Profit Share too
        categories: contractData.categories,
        payeeId: contractData.payeeId,
        companyId: contractData.companyId,
        name: contractData.name,
        active: contractData.active,
        foreignId: contractData.foreignId,
        licenseNo: contractData.licenseNo,
        currency: contractData.currency,
        type: contractData.type,
        profitSharePercentage: contractData.profitSharePercentage,
        accountingPeriod: contractData.accountingPeriod,
        minPayout: contractData.minPayout,
        openingBalance: contractData.openingBalance,
        currentBalance: contractData.currentBalance,
        labels: contractData.labels,
        withholdingTaxesTerms: contractData.withholdingTaxesTerms,
        mechanicalContractsTerms: contractData.mechanicalContractsTerms,
        reserves: contractData.reserves,
        reserveBasis: contractData.reserveBasis,
        escalationTracking: contractData.escalationTracking,
        complete: contractData.complete,
        escalationTrackers: contractData.escalationTrackers,
        notes: contractData.notes,
        autoPayment: contractData.autoPayment,
        selfBilling: contractData.selfBilling,
        feesInvoice: contractData.feesInvoice,
        deductWithholding: contractData.deductWithholding,
        alias: contractData.alias,
        valid: contractData.valid,
        validationErrors: contractData.validationErrors,
        payees: contractData.payees,
        createdAt: contractData.createdAt,
        updatedAt: contractData.updatedAt,
        label: contractData.label,
        tariff_plan_id: contractData.tariff_plan_id,
      });
      if (contractData.salesTerms) {
        replaceSalesTerms(contractData.salesTerms);
      }
      if (contractData.costsTerms) {
        replaceCostsTerms(contractData.costsTerms);
      }
      if (contractData.deductionsTerms) {
        replaceDeductionsTerms(contractData.deductionsTerms);
      }
      if (contractData.mechanicalTerms) {
        replaceMechanicalTerms(contractData.mechanicalTerms);
      }
      if (contractData.withholdingTaxTerms) {
        replaceWithholdingTerms(contractData.withholdingTaxTerms);
      }
      if (contractData.crossContracts) {
        replaceCrossContracts(contractData.crossContracts);
      }
      if (contractData.aliases) {
        replaceAliases(contractData.aliases);
      }
      if (contractData.reserves) {
        replaceReserves(contractData.reserves);
      }
    }
  }, [contractData]);

  const items = [
    {
      key: "overview",
      label: dictionary.overview,
      children: <CreateContractOverview />,
    },
    {
      key: "terms",
      label: dictionary.terms,
      children: <CreateContractTerms />,
    },
    {
      key: "reserves",
      label: dictionary.reserves,
      children: <CreateContractReserves />,
    },
    {
      key: "escalations",
      label: dictionary.escalations,
      children: <CreateContractEscalations />,
    },
    {
      key: "catalogue",
      label: dictionary.catalog,
      children: "Content 5",
    },
    {
      key: "costs",
      label: dictionary.costs,
      children: "Content 6",
    },
    {
      key: "transactions",
      label: dictionary.transactions,
      children: "Content 7",
    },
    {
      key: "aliases",
      label: dictionary.aliases,
      children: <CreateContractAliases />,
    },
  ];

  return (
    <Tabs defaultActiveKey="1" items={items} className="tabs-contracts-upd" />
  );
};

export default CreateContract;
