import React, { useState, useContext, useEffect, useRef, useCallback } from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
// import { notification } from 'antd';
import { LanguageContext } from "../../context/Language";
import Visitor from "../../classes/visitor/visitor";
import StripeCalls from "../../classes/calls/payment/stripe";
import SecureLS from "secure-ls";
import Cookies from "js-cookie";
import Terms from "../../components/Register/Terms";
import Progress from "../../components/Steps/WizardBar";
import Create from "../../components/Register/Create";
import Details from "../../components/Register/Details";
import Pricing from "../../components/Register/Pricing";
import Code from "../../components/Register/Code";
import Captcha from "../../components/Captcha";
import "./register.css";

const ls = new SecureLS({ encodingType: "base64", isCompression: false });
const isDevelopment = process.env.NODE_ENV === 'development';

const CAPTCHA_KEY = '6LcXB9MqAAAAABw25n4mkgjIAHkW9WJdSge2brjw';

const Register = () => {
  const { dictionary, locale } = useContext(LanguageContext);
  // const notificationShown = useRef(false);
  // const [hasNotification, setHasNotification] = useState(false);
  const [section, setSection] = useState(0);
  const [validated, setValidated] = useState(false);
  const [showRedirectionAdvise, setShowRedirectionAdvise] = useState(false);
  const [state, setInput] = useState({
    name: "",
    last: "",
    email: "",
    externalId: null,
    platformExternal: null,
    type: 0,
    passwd: "",
    pwdCfm: "",
    company: "",
    address: "",
    city: "",
    zipCode: "",
    state: "",
    country: 0,
    website: "",
    phone: "",
    lada: "US",
    phoneCode: "1",
    plan: null,
    terms: false,
    verified: false,
  });

  const [token, setToken] = useState("");
  const [expiredCaptcha, setExpiredCaptcha] = useState(false);
  const [resentCaptcha, setResentCaptcha] = useState(false);
  const isSendingRef = useRef(false);

  useEffect(() => {
    // if (!notificationShown.current) {
    //   setHasNotification(true);
    //   const notificationKey = 'beta-notification';
    //   notification.warning({
    //     key: notificationKey,
    //     message: dictionary.beta.title,
    //     description: (
    //       <div>
    //         {dictionary.beta.description}{' '}
    //         <a href="https://dashboard.priamdigital.com" target="_blank" rel="noopener noreferrer">
    //           {dictionary.beta.liveUrl}
    //         </a>
    //       </div>
    //     ),
    //     duration: 0,
    //     placement: 'top',
    //     onClose: () => setHasNotification(false),
    //     className: 'beta-notification'
    //   });
    //   notificationShown.current = true;
    // }
    const key = ls.get("state");
    let userCookie = Cookies.get("userInfo");
    let userInfo = {};
    if (userCookie) userInfo = JSON.parse(userCookie.substring(2));

    if (key) {
      key.name = userInfo.name || key.name;
      key.last = userInfo.last || key.last;
      key.email = userInfo.email || key.email;
      key.externalId = userInfo.id || key.externalId;
      key.platformExternal = userInfo.platform || key.platformExternal;
      key.verified = userInfo.verified || key.verified;
      key.phoneCode = userInfo.phoneCode || key.phoneCode;
      setValidated(userInfo.verified || key.verified);
      setInput({
        ...state,
        ...key,
      });
    } else {
      state.name = userInfo.name || state.name;
      state.last = userInfo.last || state.last;
      state.email = userInfo.email || state.email;
      state.externalId = userInfo.id || state.externalId;
      state.platformExternal = userInfo.platform || state.platformExternal;
      state.verified = userInfo.verified || state.verified;
      state.phoneCode = userInfo.phoneCode || state.phoneCode;
      setValidated(userInfo.verified || state.verified);

      setInput(state);
    }
    // eslint-disable-next-line
  }, []);

  const goToPay = async (updatedStateParam = null, retry = 3) => {
    try {
      setShowRedirectionAdvise(true);
      setTimeout(async () => {
        try {
          // Use the passed state if available, otherwise use component state
          const stateToUse = updatedStateParam || state;
          const planId = stateToUse.plan || 'core';
          const billing = stateToUse.billing || 'monthly';          
          const url = await StripeCalls.createCheckoutSession(planId, billing);
          if (url) {
            window.location.href = url;
          } else {
            if (retry > 0) {
              goToPay(stateToUse, retry - 1);
            } else {
              console.error('Failed to create checkout session after all retries');
            }
          }
        } catch (error) {
          console.error('Error in createCheckoutSession:', error);
        }
      }, 3000);
    } catch (error) {
      console.error('Error in goToPay:', error);
    }
  };

  const signUp = useCallback(async (updatedState) => {
    if (isSendingRef.current) {
      return;
    }
    
    try {
      isSendingRef.current = true;
      
      let captchaToken;
      
      if (isDevelopment) {
        captchaToken = 'test-token-dev-environment';
      } else {
        if (!token) {
          console.error('No captcha token available');
          return;
        }
        captchaToken = token;
      }

      // Use the provided updatedState if available, otherwise use the current state
      const signupState = updatedState || { ...state };
      
      
      // If plan is selected but membership is not set, create it
      if (signupState.plan && !signupState.membership) {
        signupState.membership = {
          plan: signupState.plan,
          billing: signupState.billing || 'monthly'
        };
        
        // Update the state for future operations
        setInput(signupState);
        
        // Log for debugging
      }

      const signup = await Visitor.signup(signupState, captchaToken, locale);

      if (signup.status === 200 && signup.body.user) {
        try {
          if(ls.get("inviteToken")){
            const inviteToken = ls.get("inviteToken");
            await Visitor.deleteInviteToken(inviteToken);
          }
          ls.removeAll();
          
          // Direct all plans to payment
          await goToPay(signupState);
          // if not pricing, redirect to thanks not used anymore because of the new payment process
          // if (state.plan !== "core") {
          //   window.location.href = "/thanks";
          // }
        } catch (error) {
          console.error('Error in post-signup process:', error);
        }
      } else if (
        signup.status === 400 &&
        signup.body.errors?.length &&
        signup.body.errors.findIndex(
          (err) => err.message === "expiredCaptcha"
        ) >= 0
      ) {
        setResentCaptcha(false);
        setExpiredCaptcha(true);
      } else {
        console.error('Signup failed with response:', signup);
        window.location.href = "/signupError";
      }
    } catch (error) {
      console.error('Error in signup process:', error);
      console.error('Error details:', {
        message: error.message,
        stack: error.stack,
        state: state,
        hasToken: !!token
      });
    } finally {
      setTimeout(() => {
        isSendingRef.current = false;
      }, 1000);
    }
  }, [token, locale, state]);

  const setKeys = (info) => {
    try {
      ls.set("state", info);
    } catch (error) {
      console.error('Error setting local storage:', error);
    }
  };

  const changeSection = (n) => {
    try {
      setSection(n);
      window.scrollTo(0, 0);
    } catch (error) {
      console.error('Error changing section:', error);
    }
  };

  const drawSteps = () => {
    if (section === 0)
      return (
        <Create
          state={state}
          setKeys={setKeys}
          setInput={setInput}
          changeSection={changeSection}
          setToken={setToken}
        />
      );

    if (section === 1)
      return (
        <Details
          state={state}
          setKeys={setKeys}
          setInput={setInput}
          changeSection={changeSection}
        />
      );

    if (section === 2)
      return (
        <Terms
          state={state}
          setKeys={setKeys}
          setInput={setInput}
          changeSection={changeSection}
          token={token}
        />
      );

    return (
      <Pricing
        state={state}
        setKeys={setKeys}
        setInput={setInput}
        changeSection={changeSection}
        signUp={signUp}
      />
    );
  };

  const drawCode = () => {
    return (
      <Code
        state={state}
        setKeys={setKeys}
        setInput={setInput}
        setValidated={setValidated}
        setToken={setToken}
      />
    );
  };

  const drawForm = () => {
    return (
      <React.Fragment>
        {drawSteps()}
        {!isDevelopment && (
          <GoogleReCaptchaProvider
            reCaptchaKey={CAPTCHA_KEY}
            language={locale}
          >
            <Captcha
              setToken={setToken}
              expired={expiredCaptcha}
              resent={resentCaptcha}
              setResent={setResentCaptcha}
            />
          </GoogleReCaptchaProvider>
        )}
      </React.Fragment>
    );
  };

  const content = showRedirectionAdvise ? (
    <h3 className="p-4 center">
      {dictionary.redirectInN.replaceAll(/{{N}}/g, "3")}
    </h3>
  ) : validated ? (
    drawForm()
  ) : (
    drawCode()
  );

  return (
    <React.Fragment>
      <div className={`container-box wizard`}>
        <div className="wizard-box">
          {validated && section !== 3 && (
            <Progress total={5} step={validated ? section + 1 : 0} />
          )}
          {(validated && section !== 3) && (
            <>
              <h1>{dictionary.welcomeTo}</h1>
              <h3>{dictionary.welcomeToDesc}</h3>
            </>
          )}
          {content}
        </div>
      </div>
    </React.Fragment>
  );
};

export default Register;
