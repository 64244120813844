import React, { useContext, useState, useEffect, useRef } from "react";
import { Dropdown, Button, Breadcrumb } from "antd";
import { UserContext } from "../../../context/User";
import { Link, useLocation } from "react-router-dom";
import { LanguageContext } from "../../../context/Language";
import { DownOutlined, MenuOutlined, ExclamationCircleFilled } from "@ant-design/icons";
import Avatar from "./Avatar/Avatar";
import LogOutIcon from "../../Icon/LogOutIcon";
import Admin from "../../../classes/admin/admin";
import SettingsIcon from "../../Icon/SettingsIcon";
import UserCalls from "../../../classes/user/user";
import ReleaseCalls from "../../../classes/release/release";
import ContractCalls from "../../../classes/contract/contract";
import "../nav.css";

const UserMenu = ({changeItem, showDrawer, collapsed}) => {
  
  const location = useLocation();
  const { username, logout, alertPayee, type } = useContext(UserContext);
  const { dictionary } = useContext(LanguageContext);
  const [current, setCurrent] = useState(null);
  const [section, setSection] = useState(null);
  const [subsection, setSubsection] = useState(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [canCloseByClick, setCanCloseByClick] = useState(true);
  const timeoutRef = useRef(null);
  const searchParams = new URLSearchParams(location.search);

  useEffect(() => {
    setSubsection(null);
    setSection(null);
    if(location.pathname.includes("release/") && searchParams.get("r")){
      ReleaseCalls.getReleaseDetails(searchParams.get("r"))
      .then((data) => {
        setSubsection(data?.release?.title);
      });
    } else if(location.pathname.includes("user/") && searchParams.get("u")){
      UserCalls.getUserDetails(searchParams.get("u"))
        .then((data) => {
          setSubsection(data?.user?.profile?.name || data?.user?.profile?.company_name);
        });
    } else if(location.pathname.includes("contracts/") && searchParams.get("l")){
      Admin.getbyLabel(searchParams.get("l"))
      .then((data) => {
        setSubsection(data?.body?.label?.name);
      });
    } else if(location.pathname.includes("contracts/update") && searchParams.get("c")){
      ContractCalls.getContractCurve(searchParams.get("c"))
      .then((data) => {
        setSubsection(data?.contractData?.name ?? "-");
      });
    }
    setSection(searchParams.get("section") ?? null);
    const pathnameWithoutSlash = location.pathname.substring(1);
    setCurrent(pathnameWithoutSlash);
  }, [location.pathname]);

  const handleDropdownVisibleChange = (visible) => {
    // If the dropdown is opening
    if (visible && !isDropdownOpen) {
      setIsDropdownOpen(true);
      setCanCloseByClick(false);
      
      // Clear any existing timeout
      if (timeoutRef.current) {
        window.clearTimeout(timeoutRef.current);
      }
      
      // Set a timeout to allow closing by click after 1 second
      timeoutRef.current = setTimeout(() => {
        setCanCloseByClick(true);
      }, 300);
    } else {
      // If trying to close but we shouldn't allow it yet
      if (!canCloseByClick && visible === false) {
        return;
      }
      setIsDropdownOpen(visible);
    }
  };

  // Clear timeout on unmount
  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        window.clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  const items = [
    {
      label: <Link to="/settings">
              <span className="icon-menu-nav"><SettingsIcon color={"#45454599"}/></span>
              {dictionary.settings}
              {type!=="admin" && alertPayee && <ExclamationCircleFilled className="alert-p"/>}
            </Link>,
      key: "/settings",
      onClick: (e) => changeItem(e, true),
    },
    {
      label: <div>
            <span className="icon-menu-nav"><LogOutIcon color={"#45454599"}/></span>
            {dictionary.logOut}
          </div>,
      key: "3",
      onClick: () => {
        logout();
      },
    },
  ];

  const sideMenuOpener = (
    <Button
      text=" "
      size="sm"
      className="side-btn"
      onClick={showDrawer}
      icon={<MenuOutlined />}
    />
  );

  const title = (
    <h1 className="title">
      {current?.includes("/view") || current?.includes("/edit") || current?.includes("/label") || current?.includes("contracts/update")
        ? (
          <Breadcrumb className="sub-section-nav">
            <Breadcrumb.Item>
              <Link to={section || ("/" +current?.split("/")[0])}>{dictionary.path[section] ?? dictionary.path[current?.split("/")[0]]}</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link>{subsection}</Link>
            </Breadcrumb.Item>
          </Breadcrumb>
          )
          : current?.includes("/verification")
          ? (
            <Breadcrumb className="sub-section-nav">
              <Breadcrumb.Item>
                <Link to={section || ("/" +current?.split("/")[0])}>
                  {dictionary.path[section] ?? dictionary.path[current?.split("/")[0]]}
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to={"/release/view?r="+searchParams.get("r")+" &edit=true&section=review"}>{subsection}</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                {dictionary.copyrightVerification}
              </Breadcrumb.Item>
          </Breadcrumb>
          )
        : dictionary.path[current?.split("/")[0]] || dictionary.dashboardTitle}
    </h1>
  );

  const lastMenu = () =>{
    return(
      <div className={(collapsed ? "user-element-coll" :"") + " user-element"}>
        {sideMenuOpener}
        {title}
        <Dropdown
          menu={{ items }}
          trigger={['hover', 'click']}
          overlayClassName="drop-user"
          open={isDropdownOpen}
          onOpenChange={handleDropdownVisibleChange}
        >
          <button
            className="ant-dropdown-link user"
            onClick={(e) => {
              e.preventDefault();
              if (canCloseByClick || !isDropdownOpen) {
                setIsDropdownOpen(!isDropdownOpen);
              }
            }}
          >
            <Avatar />
            {type!=="admin" && alertPayee && <div className="alert-payee"></div>}
            <span className="name">
              {username}
            </span>
            <DownOutlined className="down" />
          </button>
        </Dropdown>
      </div>
    )
  }

  return (
    lastMenu()
  );
};

export default UserMenu;
