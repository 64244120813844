import AdminCalls from "../calls/admin/admin.jsx";

class Contract {
  /**
   * @param {object} contractData
   * @returns * @returns {null|object}
   */
  async saveContract(contractData) {
    const contract = this.mapContractFromCurveToApi(contractData);
    const res = await AdminCalls.saveContractCurve(contract);
    if (res.status === 200) {
      return res.body;
    }
    return { error: true, status: res.status, message: res.errors };
    //return { error: true, status: 400 };
  }

  async patchContract(contractId, contractData) {
    const contract = this.mapContractFromCurveToApi(contractData);
    const res = await AdminCalls.patchContractCurve(contractId, contract);
    if (res.status === 200) {
      return res.body;
    }
    return { error: true, status: res.status, message: res.errors };
    //return { error: true, status: 400 };
  }

  async deleteContract(contractId) {
    const res = await AdminCalls.deleteContractCurve(contractId);
    if (res.status === 200) {
      return res.body;
    }
    return { error: true, status: res.status, message: res.errors };
    //return { error: true, status: 400 };
  }

  async syncContract(contractId) {
    const res = await AdminCalls.syncContractCurve(contractId);
    if (res.status === 200) {
      return res.body;
    }
    return { error: true, status: res.status, message: res.errors };
    //return { error: true, status: 400 };
  }

  async getContractCurve(contractId) {
    const res = await AdminCalls.getContractCurve(contractId);
    const contractData = this.mapContractFromApiToCurve(
      res.body.contract,
      res.body.curveContract
    );
    if (res.status === 200) {
      return {
        contractData,
        sync: res.body.needSync
      };
    }
    return { error: true, status: res.status, message: res.errors };
  }

  mapContractFromCurveToApi(contractData) {
    const salesTerms = contractData.salesTerms?.map((term, index) => {
      if (contractData[`salesTerms-escalation_${index}`]) {
        term.escalations = contractData[`salesTerms-escalation_${index}`];
        contractData[`salesTerms-escalation_${index}`].map(
          (escalation, indexEscalation) => {
            if (
              contractData[
                `salesTerms-escalation-conditions_${index}-${indexEscalation}`
              ]
            ) {
              escalation.conditions =
                contractData[
                  `salesTerms-escalation-conditions_${index}-${indexEscalation}`
                ];
            }
            return escalation;
          }
        );
      }
      return term;
    });

    const costsTerms = contractData.costsTerms?.map((term, index) => {
      if (contractData[`costsTerms-escalation_${index}`]) {
        term.escalations = contractData[`costsTerms-escalation_${index}`];
        contractData[`costsTerms-escalation_${index}`].map(
          (escalation, indexEscalation) => {
            if (
              contractData[
                `costsTerms-escalation-conditions_${index}-${indexEscalation}`
              ]
            ) {
              escalation.conditions =
                contractData[
                  `costsTerms-escalation-conditions_${index}-${indexEscalation}`
                ];
            }
            return escalation;
          }
        );
      }
      return term;
    });

    const escalationTracking = {};
    contractData.escalation?.forEach((element) => {
      escalationTracking[element.name] = {
        units: element.units,
        gross: element.grossAmount,
        net: element.netAmount,
      };
    });

    const aliases = contractData.alias?.map((alias) => alias.alias);
    return {
      labelId: contractData.label,
      name: contractData.name,
      projectId: contractData.projectId,
      contractCategories: contractData.categories,
      companyId: contractData.companyId,
      associatedStatements: false,
      startDate: contractData.startDate.toISOString() || new Date(),
      endDate: contractData.endDate.toISOString() || new Date(),
      currency: contractData.currency || "USD",
      type: contractData.type === "Profit Share" ? "profit_share" : "Royalty",
      profitSharePercentage: contractData.profitSharePercentage,
      accountingPeriod: contractData.accountingPeriod,
      minPayout: contractData.minPayout || 0,
      tags: contractData.tags,
      notes: contractData.notes,
      selfBilling: !!contractData.selfBilling,
      autoPayment: !!contractData.autoPayment,
      feesInvoice: !!contractData.feesInvoices,
      deductWithholding: !!contractData.withholdingTax,
      aliases: aliases,
      salesTerms: salesTerms,
      costsTerms: costsTerms,
      reserves: contractData.reserve,
      escalationTracking: escalationTracking,
      crossContracts: contractData.crossContracts,
      deductionsTerms: contractData.deductionTerms,
      mechanicalTerms: contractData.mechanicalDeductionsTerms,
      withholdingTaxTerms: contractData.withholdingTaxDeductionsTerms,
    };
  }

  mapContractFromApiToCurve(contractData, contractCurve) {
    if (!contractCurve) {
      return null;
    }
    const newContractCurve = Object.assign({}, contractCurve);
    delete newContractCurve.salesTerms;
    delete newContractCurve.costsTerms;
    delete newContractCurve.deductionsTerms;
    delete newContractCurve.mechanicalTerms;
    delete newContractCurve.withholdingTaxTerms;
    delete newContractCurve.escalationTracking;
    delete newContractCurve.aliases;
    delete newContractCurve.contractCategories;

    newContractCurve.label = contractData.label_id;
    newContractCurve.type =
      contractCurve.type === "profit_share" ? "Profit Share" : "Royalty";
    newContractCurve.categories = contractData.categories || contractCurve.contractCategories || [];
    newContractCurve.companyId = contractData.companyId || contractCurve.companyId;
    
    newContractCurve.startDate = contractData.startDate ? new Date(contractData.startDate) : new Date(contractCurve.startDate);
    newContractCurve.endDate = contractData.endDate ? new Date(contractData.endDate) : new Date(contractCurve.endDate);
    
    newContractCurve.currency = contractData.currency || contractCurve.currency;
    newContractCurve.minPayout = contractData.min_payout || contractCurve.minPayout;
    newContractCurve.selfBilling = contractData.self_billing || contractCurve.selfBilling;
    newContractCurve.autoPayment = contractData.has_auto_payment || !!contractCurve.autoPayment;
    newContractCurve.feesInvoices = contractData.fees_invoice || contractCurve.feesInvoice;
    newContractCurve.withholdingTax = contractData.withholding_tax || contractCurve.deductWithholding;
    newContractCurve.accountingPeriod = contractCurve.accountingPeriod;
    newContractCurve.profitSharePercentage = contractCurve.profitSharePercentage || 0;
    newContractCurve.reserve = contractData.reserves || contractCurve.reserves || [];
    newContractCurve.crossContracts = contractData.cross_contracts || contractCurve.crossContracts || [];
    if (contractCurve.tags) {
      newContractCurve.tags = contractCurve.tags;
    }
    if (contractCurve.notes) {
      newContractCurve.notes = contractCurve.notes;
    }

    Object.defineProperty(newContractCurve, `salesTerms`, {
      value: [],
    });
    contractCurve.salesTerms?.forEach((term, index) => {
      Object.defineProperty(
        newContractCurve,
        `salesTerms-escalation_${index}`,
        {
          value: [],
        }
      );
      term.escalations?.forEach((escalation, indexEscalation) => {
        Object.defineProperty(
          newContractCurve,
          `salesTerms-escalation-conditions_${index}-${indexEscalation}`,
          {
            value: [],
          }
        );
        escalation.conditions?.forEach((condition) => {
          newContractCurve[
            `salesTerms-escalation-conditions_${index}-${indexEscalation}`
          ].push(condition);
        });
        newContractCurve[`salesTerms-escalation_${index}`].push(escalation);
      });
      delete term.escalationTrackers;
      newContractCurve.salesTerms.push(term);
    });

    Object.defineProperty(newContractCurve, `costsTerms`, {
      value: [],
    });
    contractCurve.costsTerms?.forEach((term, index) => {
      Object.defineProperty(
        newContractCurve,
        `costsTerms-escalation_${index}`,
        {
          value: [],
        }
      );
      term.escalations?.forEach((escalation, indexEscalation) => {
        Object.defineProperty(
          newContractCurve,
          `costsTerms-escalation-conditions_${index}-${indexEscalation}`,
          {
            value: [],
          }
        );
        escalation.conditions?.forEach((condition) => {
          newContractCurve[
            `costsTerms-escalation-conditions_${index}-${indexEscalation}`
          ].push(condition);
        });
        newContractCurve[`costsTerms-escalation_${index}`].push(escalation);
      });
      delete term.escalationTrackers;
      newContractCurve.costsTerms.push(term);
    });

    Object.defineProperty(newContractCurve, `deductionsTerms`, {
      value: [],
    });
    contractCurve.deductionsTerms?.forEach((term) => {
      newContractCurve.deductionsTerms.push(term);
    });

    Object.defineProperty(newContractCurve, `mechanicalDeductionsTerms`, {
      value: [],
    });
    contractCurve.mechanicalTerms?.forEach((term) => {
      newContractCurve.mechanicalDeductionsTerms.push(term);
    });

    Object.defineProperty(newContractCurve, `withholdingTaxDeductionsTerms`, {
      value: [],
    });
    contractCurve.withholdingTaxTerms?.forEach((term) => {
      newContractCurve.withholdingTaxDeductionsTerms.push(term);
    });

    const escalationTracking = [];
    if (contractCurve.escalationTracking) {
      for (const key in contractCurve.escalationTracking) {
        escalationTracking.push({
          name: key,
          units: contractCurve.escalationTracking[key].units,
          grossAmount: contractCurve.escalationTracking[key].gross,
          netAmount: contractCurve.escalationTracking[key].net,
        });
      }
      newContractCurve.escalation = escalationTracking;
    }

    const aliases = contractCurve.aliases?.map((alias) => ({ alias: alias })) || [];
    newContractCurve.alias = aliases;
    
    return newContractCurve;
  }
}

export default new Contract();
