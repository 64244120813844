import React, { useContext } from 'react';
import { Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { LanguageContext } from '../../../context/Language';
import './info-icon.css';

const InfoIcon = ({ tooltipKey, placement = 'right' }) => {
  const { dictionary } = useContext(LanguageContext);
  
  // Get the tooltip text from the dictionary using the provided key
  const tooltipText = dictionary[tooltipKey] || tooltipKey;
  
  // Convert tooltip text with newlines to JSX with proper formatting
  const renderTooltipContent = (text) => {
    if (!text) return 'No tooltip text found';
    
    // Split text by newline character
    const parts = text.split('\n');
    
    // Map each part to a JSX element, adding a line break after each except the last
    return (
      <div className="formatted-tooltip-content">
        {parts.map((part, index) => (
          <React.Fragment key={index}>
            {part}
            {index < parts.length - 1 && <br />}
          </React.Fragment>
        ))}
      </div>
    );
  };
  
  return (
    <span className="info-icon-container">
      <Tooltip 
        title={renderTooltipContent(tooltipText)}
        placement={placement}
        trigger="hover"
        overlayClassName="info-tooltip"
        color="#333"
        mouseEnterDelay={0.1}
        mouseLeaveDelay={0.1}
        destroyTooltipOnHide={true}
        zIndex={1099}
      >
        <span className="info-icon-wrapper">
          <InfoCircleOutlined className="info-icon" />
        </span>
      </Tooltip>
    </span>
  );
};

export default InfoIcon; 