import BaseClass from "../";
import * as analytics from '../../../util/analytics';

class StripeCalls extends BaseClass {
  /**
   * Generate a checkout url to stripe
   * @param {string} planId - The plan ID (core, extra, pro)
   * @param {string} billing - The billing period (monthly, annual)
   * @returns {Promise<string|boolean>}
   */
  async createCheckoutSession(planId = 'core', billing = 'monthly') {
    try {
      this.setRoute("/payment/create-checkout-session");
      
      // Ensure billing parameter is using the expected value
      // Server expects 'monthly' or 'annual'
      const billingCycle = ['monthly', 'annual'].includes(billing) ? billing : 'monthly';
      // Send the plan and billing data in the request body
      this.setBody({ planId, billing: billingCycle });
      
      // Add timeout options if your BaseClass supports it
      const res = await this.doPost();
      
      if (res.status === 200 && res.body) {
        // Track checkout session creation for analytics
        analytics.trackEvent('checkout_started', {
          plan_id: planId,
          billing_cycle: billingCycle
        });
        
        return res.body.url;
      } else {
        console.error('Checkout session creation failed:', res);
        return false;
      }
    } catch (error) {
      console.error('Error creating checkout session:', error);
      return false;
    }
  }

  /**
   *
   * @param {string} sessionId
   * @returns {Promise<Object|boolean>}
   */
  async retrieveCheckoutSession(sessionId) {
    this.setValues("/payment/retrieve-checkout-session", { sid: sessionId });
    const res = await this.doPost();

    if (res.status === 200 && res.body) {
      // Track successful checkout if payment was successful
      if (res.body.payment_status === 'paid') {
        analytics.trackPurchase({
          transactionId: res.body.id,
          value: res.body.amount_total / 100, // Convert from cents to dollars
          currency: res.body.currency,
          items: [{
            item_id: res.body.metadata?.plan_id || 'unknown',
            item_name: res.body.metadata?.plan_name || 'Subscription',
            price: res.body.amount_total / 100,
            quantity: 1
          }]
        });
      }
      return res.body;
    }
    
    return false;
  }

  /**
   *
   * @returns {Promise<Array<Object>|boolean>}
   */
  async retrievePaymentMethodList() {
    this.setValues("/payment/list-payment-method");
    const res = await this.doPost();

    return res.status === 200 && res.body ? res.body : false;
  }

  /**
   *
   * @param {Object} data
   * @returns {Promise<Object|boolean>}
   */
  async createPaymentMethod(data) {
    this.setValues("/payment/create-payment-method", data);
    const res = await this.doPost(data);

    return res.status === 200 && res.body ? res.body : false;
  }

  /**
   *
   * @param {string} pm_id
   * @returns {Promise<Object|boolean>}
   */
  async updatePaymentMethod(pm_id) {
    this.setValues("/payment/update-payment-method", { pm_id });
    const res = await this.doPost();

    return res.status === 200 && res.body ? res.body : false;
  }

  /**
   * Generate a checkout url to stripe
   * @returns {Promise<string|boolean>}
   */
  async confirmPayment() {
    this.setRoute("/payment/confirm-payment");
    const res = await this.doPost();

    if (res.status === 200 && res.body) {
      // Track successful payment confirmation
      if (res.body.confirmed) {
        analytics.trackEvent('payment_confirmed', {
          subscription_id: res.body.subscription_id || 'unknown',
          plan: res.body.plan || 'unknown'
        });
      }
      return res.body;
    }
    
    return false;
  }
}

export default new StripeCalls();
