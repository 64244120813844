import React, { useContext, useState } from "react";
import { MailOutlined } from "@ant-design/icons";
import { LanguageContext } from "../../../context/Language";
import { Row, Col } from "antd";
import validator from "validator";
import Input from "../../Form/Input";
import Radio from "../../Form/Radio";
import Password from "../../Form/InputPassword";
import Button from "../../Buttons/GeneralButton";
import "../../../containers/Register/register.css";

const Create = ({ state, setKeys, setInput, changeSection }) => {

  const { dictionary, locale } = useContext(LanguageContext);
  const [errors, setError] = useState({
    name: false,
    last: false,
    email: false,
    passwd: false,
    pwdCfm: false,
    type: false,
  });

  const userTypeRadioOptions = [
    { value: "label", name: dictionary.label },
    { value: "artist", name: dictionary.artist },
  ];

  const radioChangeHandler = (e) => {
    setInput({
      ...state,
      type: e.target.value,
    });
  };

  const inputChangeHandler = (e) => {
    const { name, value } = e.target;
    setInput({
      ...state,
      [name]: value,
    });

    if(value.length > 0 && state[name] && state[name].length > 0) validate(name, value);
  };

  /**
   * Sometimes this code is executed first than setState
   * @param {string} inputName
   * @param {string} value
   * @returns {boolean}
   */
  const validate = (inputName = "", value = "") => {
    let error = false;
    let baseError = {
      name: false,
      last: false,
      email: false,
      passwd: false,
      pwdCfm: false,
      type: false,
    };
    setError(baseError);
    const location = locale.includes("es") ? "es-ES" : locale === "en" ? "en-US" : "en-US";
    if (inputName === "" || inputName === "name")
      if (
        state.name === undefined ||
        validator.isLength(value || state.name, 0, 0)
      ) {
        baseError.name = dictionary.emptyField;
        error = true;
      } else if (
        !validator.isAlpha(value || state.name, ["en-US"], { ignore: "-s" })
      ) {
        baseError.name = dictionary.onlyLetters;
        error = true;
      }

    if (inputName === "" || inputName === "last")
      if (
        state.last === undefined ||
        validator.isLength(value || state.last, 0, 0)
      ) {
        baseError.last = dictionary.emptyField;
        error = true;
      } else if (
        !validator.isAlpha(value || state.name, ["en-US"], { ignore: "-s" })
      ) {
        baseError.last = dictionary.onlyLetters;
        error = true;
      }

    if (inputName === "" || inputName === "type")
      if (value === 0 || state.type === 0) {
        baseError.type = dictionary.radioError;
        error = true;
      }

    if (inputName === "" || inputName === "email")
      if (!validator.isEmail(state.email)) {
        baseError.email = dictionary.mailErr;
        error = true;
      }

    if (inputName === "" || inputName === "passwd")
      if ((value.length || state.passwd.length) < 8) {
        baseError.passwd = dictionary.passErr;
        error = true;
      }

    if (inputName === "" || inputName === "pwdCfm")
      if (
        (state.passwd !== value &&
          inputName === "" &&
          state.passwd !== (value || state.pwdCfm)) ||
        validator.isLength(state.pwdCfm, 0, 0)
      ) {
        baseError.pwdCfm = dictionary.confirmPwdErr;
        error = true;
      }

    if (error) setError(baseError);

    return !error;
  };

  const next = () => {
    if (validate()) {
      setKeys(state);
      changeSection(1);
    }
  };

  return (
    <div>
      <div className="forms">
        <Row className="gutter-row">
          <Col xs={24} sm={24} md={12} lg={12} className="col1">
            <Input
              name="name"
              required={true}
              value={state.name}
              error={errors.name}
              onChange={inputChangeHandler}
              label={dictionary.firstName}
              className={errors.name ? "inputErr" : ""}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} className="col2">
            <Input
              name="last"
              required={true}
              value={state.last}
              error={errors.last}
              onChange={inputChangeHandler}
              label={dictionary.lastName}
              className={errors.last ? "inputErr" : ""}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} className="col1">
            <Input
              name="email"
              required={true}
              disabled={true}
              value={state.email}
              icon={<MailOutlined />}
              error={errors.email}
              label={dictionary.email}
              onChange={inputChangeHandler}
              placeholder={dictionary.emailEx}
              className={errors.email ? "inputErr" : ""}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} className="col2">
            <Radio
              name={"type"}
              required={true}
              value={state.type}
              onChange={radioChangeHandler}
              options={userTypeRadioOptions}
              className="radio-options"
              label={dictionary.artistLabel}
              error={errors.type}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} className="col1">
            <Password
              name="passwd"
              required={true}
              value={state.passwd}
              error={errors.passwd}
              onChange={inputChangeHandler}
              label={dictionary.password}
              placeholder={dictionary.pwdChars}
              className={errors.passwd ? "inputErr" : ""}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} className="col2">
            <Password
              name="pwdCfm"
              required={true}
              value={state.pwdCfm}
              onChange={inputChangeHandler}
              error={errors.pwdCfm}
              placeholder={dictionary.pwdChars}
              label={dictionary.passwordConfirm}
              className={errors.pwdCfm ? "inputErr" : ""}
            />
          </Col>
        </Row>
      </div>
      <div className="align-right">
        <Button text={dictionary.next} size="md" onClick={next} />
      </div>
    </div>
  );
};

export default Create;
