import BaseClass from "..";

class TariffPlanCalls extends BaseClass {
  getTariffPlans() {
    this.setValues("/tariff_plan");
    
    return this.doGet()
      .then(response => {
        return response;
      })
      .catch(error => {
        console.error("TariffPlanCalls error:", error);
        throw error;
      });
  }
}

export default new TariffPlanCalls();
