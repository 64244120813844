import React from 'react';
import { Link } from 'react-router-dom';
import PlusIcon from '../../Icon/PlusIcon';
// import './style.css';

const PlusIconCard = ({ 
  onClick, 
  to,
  className = 'add-rel',
}) => {
  const content = (
    <>
      <PlusIcon className={className} />
    </>
  );

  if (to) {
    return (
      <Link to={to}>
        {content}
      </Link>
    );
  }

  return (
    <div onClick={onClick} >
      {content}
    </div>
  );
};

export default PlusIconCard; 