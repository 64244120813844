import React, { useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { 
  Input, 
  DatePicker, 
  Select, 
  Empty, 
  Spin,
  Pagination,
  notification
} from 'antd';
import { PlusOutlined, CalendarOutlined } from '@ant-design/icons';
import { LanguageContext } from '../../../context/Language';
import { UserContext } from '../../../context/User';
import MobileReleaseCard from './MobileReleaseCard';
import DeleteDraft from '../../Dashboard/Releases/ReleaseCard/DeleteDraft';
import Release from '../../../classes/release/release';
import Bluebird from 'bluebird';
import './mobile-releases.css';
import SearchIcon from "../../Icon/SearchIcon";
import moment from "moment";

const { RangePicker } = DatePicker;
const { Option } = Select;
const { Search } = Input;

const MobileReleasesList = ({
  releases, 
  getReleases, 
  loadList, 
  allStatus = []
}) => {
  const { dictionary } = useContext(LanguageContext);
  const { type } = useContext(UserContext);
  const [allReleases, setAllReleases] = useState([]);
  const [filteredReleases, setFilteredReleases] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [dateRange, setDateRange] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(10);
  const [deleteVisible, setDeleteVisible] = useState(false);
  const [deleteTitle, setDeleteTitle] = useState('');
  const [deleteId, setDeleteId] = useState(null);
  // const [processedReleases, setProcessedReleases] = useState([]);

  // Extract artist names function
  const extractArtist = async (main, featured) => {
    if (!main?.length) {
      return dictionary.noArtist;
    }
    let artistName = main ? main[0]?.name : "";
    let featuredArtists = "";
    if (featured && featured.length) {
      await featured.forEach(async (el, index) => {
        featuredArtists += el.name;
        if (index + 1 < featured.length) {
          featuredArtists += " & ";
        }
      });
    }
    return featuredArtists.length
      ? artistName + " ft. " + featuredArtists
      : artistName;
  };

  // Process releases to add artist name and other needed data
  const processReleases = async (releasesData) => {
    let processed = [];
    
    await Bluebird.map(releasesData, async (release) => {
      let artistName = await extractArtist(release.main_artist, release.featured_artist);
      processed.push({
        ...release,
        artist: artistName
      });
    });
    
    return processed;
  };
  
  // Convert releases object to a flat array
  useEffect(() => {
    const fetchReleases = async () => {
      const sections = Object.keys(releases);
      const all = [];
      
      sections.forEach((section) => {
        releases[section].forEach((element) => {
          element.step = section;
          all.push(element);
        });
      });
      
      // Sort by created date, newest first
      all.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      
      // Process the releases to add artist name
      const processed = await processReleases(all);
      // setProcessedReleases(processed);
      setAllReleases(processed);
      setFilteredReleases(processed);
    };
    
    fetchReleases();
  }, [releases]);
  
  // Update filtered releases when filters change
  useEffect(() => {
    let result = [...allReleases];
    
    // Apply search filter
    if (searchValue) {
      const searchLower = searchValue.toLowerCase();
      result = result.filter((release) => {
        return (
          (type==="admin" ?
            (release.title && release.title.toLowerCase().includes(searchLower) ||
            release.label?.name && release.label?.name.toLowerCase().includes(searchLower) ||
            moment.utc(release.date).startOf("day").format("YYYY/MM/DD").includes(searchLower) ||
            release.upc?.includes(searchLower))
          : (release.title && release.title.toLowerCase().includes(searchLower) ||
            release.artist && release.artist.toLowerCase().includes(searchLower) ||
            moment.utc(release.date).startOf("day").format("YYYY/MM/DD").includes(searchLower))
          )
        ); 
      });
    }
    
    // Apply status filter
    if (selectedStatus) {
      let finalFilter = [];
      if (selectedStatus === "inReview" && type !== "admin") {
        finalFilter = result.filter((el) => {
          const step = el.step.toLowerCase(); // Agrega esta línea para ver el valor de step
          return step.includes("readytosent");
        });
      } else if (selectedStatus === "sendingToStores" && type !== "admin") {
        finalFilter = result.filter((el) => {
          const step = el.step.toLowerCase();
          return step.includes("inreview");
        });
      } else if (selectedStatus === "rejected") {
        finalFilter = result.filter((el) => el.step.toLowerCase() === "rejected");
      } else {
        finalFilter = result.filter((el) =>
          el.step.toLowerCase().includes(selectedStatus.toLowerCase())
        );
      }
      result = finalFilter;
    }
    
    // Apply date filter
    if (dateRange && dateRange.length === 2) {
      let filteredDates = result.filter((el) => {
        const itemDate = moment.utc(el.date).startOf("day");
        return (
          itemDate >= moment.utc(dateRange[0]).startOf("day") &&
          itemDate <= moment.utc(dateRange[1]).startOf("day")
        );
      });
      result = filteredDates;
    }
    
    setFilteredReleases(result);
    setCurrentPage(1); // Reset to first page when filters change
  }, [allReleases, searchValue, selectedStatus, dateRange]);
  
  const onSearch = (e) => {
    setSearchValue(e.target.value);
  };
  
  const handleStatusChange = (value) => {
    setSelectedStatus(value);
  };
  
  const handleDateChange = (dates) => {
    setDateRange(dates);
  };
  
  const showDeleteDraft = (id, title) => {
    setDeleteId(id);
    setDeleteTitle(title);
    setDeleteVisible(true);
  };
  
  const handleDeleteCancel = () => {
    setDeleteVisible(false);
  };
  
  const handleDelete = (id) => {
    try {
      setDeleteVisible(false);
      
      Release.updateDraftStatus(id, false).then((res) => {
        if (res.error) {
          notification.error({
            message: dictionary.errorGeneral,
            duration: 3,
            placement: 'topRight',
          });
        } else {
          notification.success({
            message: dictionary.delRelSuccess,
            duration: 3,
            placement: 'topRight',
          });
          
          // Refresh the releases
          getReleases();
        }
      });
    } catch (e) {
      console.error(e);
    }
  };
  
  // Pagination logic
  const indexOfLastRelease = currentPage * pageSize;
  const indexOfFirstRelease = indexOfLastRelease - pageSize;
  const currentReleases = filteredReleases.slice(indexOfFirstRelease, indexOfLastRelease);
  
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className="mobile-releases-container">
      {/* Filters section */}
      <div className="mobile-releases-filter">
        {/* Search filter */}
        <div className="mobile-search-filter">
          <Search
            placeholder={dictionary.search}
            value={searchValue}
            onChange={(e) => onSearch(e)}
            enterButton={<SearchIcon/>} 
            allowClear
          />
        </div>
        
        {/* Status filter */}
        <div className="mobile-status-filter">
          <Select
            placeholder={dictionary.status}
            allowClear
            onChange={handleStatusChange}
            value={selectedStatus}
            style={{ width: '100%' }}
          >
            {allStatus.map((status) => (
              <Option key={status._id} value={status._id}>
                {status.name}
              </Option>
            ))}
          </Select>
        </div>
        
        {/* Date picker */}
        <div className="mobile-date-filter">
          <RangePicker 
            placeholder={[dictionary.startDate, dictionary.endDate]}
            onChange={handleDateChange}
            value={dateRange}
            format="YYYY/MM/DD"
            style={{ width: '100%' }}
            separator="	&#x2192;"
            suffixIcon={<CalendarOutlined style={{ color: '#bfbfbf' }} />}
            popupClassName="mobile-date-picker"
          />
        </div>
      </div>
      
      {/* Releases list */}
      <div className="mobile-releases-list">
        {loadList ? (
          <div className="mobile-releases-loading">
            <Spin size="large" />
          </div>
        ) : currentReleases.length > 0 ? (
          currentReleases.map((release) => (
            <MobileReleaseCard
              key={release._id}
              release={release}
              showDeleteDraft={showDeleteDraft}
            />
          ))
        ) : (
          <div className="mobile-release-empty">
            <Empty 
              description={dictionary.noReleases}
              image={Empty.PRESENTED_IMAGE_SIMPLE}
            />
          </div>
        )}
      </div>
      
      {/* Pagination */}
      {filteredReleases.length > pageSize && (
        <div className="mobile-release-pagination">
          <Pagination
            current={currentPage}
            pageSize={pageSize}
            total={filteredReleases.length}
            onChange={handlePageChange}
            showSizeChanger={false}
            simple
          />
        </div>
      )}
      
      {/* New release button - Always visible for users with permission */}
      <Link to="/newRelease" className="mobile-new-release-button" aria-label="Create New Release">
        <PlusOutlined />
      </Link>
      <DeleteDraft
        deleteTitle={deleteTitle}
        handleCancel={handleDeleteCancel}
        deleteRelease={() => handleDelete(deleteId)}
        deleteVisible={deleteVisible}
      />
    </div>
  );
};

export default MobileReleasesList; 