import React from "react";
import { Select } from "antd";
import "../form.css";

const SelectInput = ({
  refs = null,
  name = null,
  label = null,
  labelExtra = null,
  value = null,
  error,
  options = null,
  onChange = null,
  required = false,
  className = null,
  showSearch = false,
  placeholder = null,
  disabled = false,
  disableOpt = false,
}) => {
  const { Option } = Select;

  return (
    <div className="relative-input">
      {label ? (
        <label className="allWidth">
          {label}
          <span>{required ? "*" : ""}</span>
          {labelExtra}
        </label>
      ) : null}
      <Select
        ref={refs}
        name={name}
        value={value}
        onChange={onChange}
        defaultValue={value}
        className={className}
        style={{ width: "100%" }}
        showSearch={showSearch}
        placeholder={placeholder}
        optionFilterProp="children"
        disabled={disabled}
      >
        {placeholder && <Option disabled={disableOpt} value={0}>{placeholder}</Option>}
        {options.map((el, index) => {
          return (
            <Option
              key={index + 1}
              value={el._id}
              label={el.name}
              code={el.phone_code || ""}
            >
              {el.name}
            </Option>
          );
        })}
      </Select>
      {(error && (value.length === 0) || value===0) || (error && name==="dsp") 
        ? <div className="text-error">{error}</div> 
        : null
      }
    </div>
  );
};
export default SelectInput;
