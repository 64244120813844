export const planCategories = [
  {
    name: 'Plan Basics',
    features: [
      { name: 'Price', core: '$9.99/month', extra: '$14.99/month', pro: '$49/month' },
      { name: 'Streaming Revenue', core: '100%', extra: '100%', pro: '100%' },
      { name: 'YouTube Content ID', core: '15% Priam Fee', extra: '10% Priam Fee', pro: '9% Priam Fee' },
      { name: 'Releases', core: 'yes', extra: 'yes', pro: 'yes' },
      { name: 'Main Artists', core: 'One (1)', extra: 'One (1)', pro: 'Up to 5' },
      { name: 'Additional Label Names (Sublabels)', core: 'no', extra: 'no', pro: 'Up to 10' },
      { name: 'Advance Eligibility', core: 'no', extra: 'no', pro: 'yes' }
    ]
  },
  {
    name: 'Distribution & Release Features',
    features: [
      { name: 'Minimum Release Lead Time', core: '7 Days', extra: '24 Hours', pro: '24 Hours' },
      { name: 'DSP Pitch (4)', core: 'no', extra: 'yes', pro: 'yes' },
      { name: 'Promotional Opportunities', core: 'no', extra: 'yes', pro: 'yes' },
      { name: 'Store Selection', core: 'no', extra: 'yes', pro: 'yes' },
      { name: 'Store Automator', core: 'yes', extra: 'yes', pro: 'yes' },
      { name: 'Territory Restrictions', core: 'no', extra: 'yes', pro: 'yes' },
      { name: 'Pre-orders / Pre-Add', core: 'no', extra: 'yes', pro: 'yes' },
      { name: 'Pre-Save Smartlinks', core: 'yes', extra: 'yes', pro: 'yes' },
      { name: 'Online Radio (Pandora)', core: 'yes', extra: 'yes', pro: 'yes' }
    ]
  },
  {
    name: 'Promotion & Engagement',
    features: [
      { name: 'Lyrics (via Musixmatch)', core: 'yes', extra: 'yes', pro: 'yes' },
      { name: 'Apple Music Lyrics (Text & TTML)', core: 'no', extra: 'yes', pro: 'yes' },
      { name: 'Daily Streaming Stats (Coming Soon)', core: 'no', extra: 'yes', pro: 'yes' },
      { name: 'YouTube Optimization & Channel Management (6)', core: 'no', extra: 'yes', pro: 'yes' }
    ]
  },
  {
    name: 'Video Delivery',
    features: [
      { name: 'Video Delivery ($50/video/store)', core: 'yes', extra: 'yes', pro: 'yes' },
      { name: 'VEVO Channel (Video Only)', core: '$50 - Setup Fee', extra: 'yes', pro: 'yes' }
    ]
  },
  {
    name: 'Licensing & Customization',
    features: [
      { name: 'Cover Song Licensing', core: 'no', extra: 'yes', pro: 'yes' },
      { name: 'Custom iTunes/Download Pricing', core: 'no', extra: 'yes', pro: 'yes' },
      { name: 'Custom UPC & ISRC Codes', core: 'yes', extra: 'yes', pro: 'yes' },
      { name: 'Deliver Booklets to Apple Music', core: 'no', extra: 'yes', pro: 'yes' },
      { name: 'iTunes Instant Gratification', core: 'no', extra: 'yes', pro: 'yes' }
    ]
  },
  {
    name: 'Royalty & Monetization',
    features: [
      { name: 'Advanced Royalty Portal', core: 'no', extra: 'yes', pro: 'yes' },
      { name: 'Collaborator Splits', core: 'no', extra: 'yes', pro: 'yes' },
      { name: 'Marketing Services Discount', core: 'no', extra: '10%', pro: '10%' },
      { name: 'Neighboring Rights (SoundExchange)', core: 'no', extra: 'yes', pro: 'yes' }
    ]
  },
  {
    name: 'Protections & Metadata',
    features: [
      { name: 'Fraud & Content Protections', core: 'yes', extra: 'yes', pro: 'yes' },
      { name: 'Recording Location', core: 'no', extra: 'yes', pro: 'yes' }
    ]
  },
  {
    name: 'Other Tools & Options',
    features: [
      { name: 'Smartlinks', core: 'yes', extra: 'yes', pro: 'yes' },
      { name: 'Shazam', core: 'yes', extra: 'yes', pro: 'yes' },
      { name: 'Official Artist YouTube Channel (OAC)', core: 'yes', extra: 'yes', pro: 'yes' },
      { name: 'Social Media Delivery - TikTok, Facebook/Instagram', core: 'yes', extra: 'yes', pro: 'yes' },
      { name: 'Migrate from Other Distros', core: 'Coming Soon', extra: 'Coming Soon', pro: 'Coming Soon' }
    ]
  },
  {
    name: 'Support',
    features: [
      { name: 'Support Response (5)', core: '48H (Email)', extra: '24H (Email)', pro: '24H (Email + WhatsApp)' }
    ]
  }
];

export const plans = [
  {
    id: 'core',
    name: 'Priam Core',
    price: '9.99',
    currency: 'USD',
    period: 'month',
    subtitle: 'For independent artists',
    description: 'Perfect for artists just starting their journey'
  },
  {
    id: 'extra',
    name: 'Priam Extra',
    price: '14.99',
    currency: 'USD',
    period: 'month',
    subtitle: 'For growing artists',
    description: 'Enhanced features for established artists'
  },
  {
    id: 'pro',
    name: 'Priam Pro',
    subtitle: 'For labels & established artists',
    price: '49.99',
    currency: 'USD',
    period: 'month',
    description: 'Complete solution for record labels and professional artists'
  }
]; 