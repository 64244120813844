import React, { useContext, useEffect, useState } from "react";
import { Layout as Chart } from "antd";
import { UserContext } from "../../context/User";
import UserMenu from "../../components/Navbar/UserMenu";
import Navbar from "../../components/Navbar";
import UploadCard from "../../components/UploadCard";
import { useLocation } from "react-router-dom";
import Footer from "../../components/Footer/Footer";

const Layout = ({
  children,
  open,
  current = "/",
  setOpen,
  changeItem,
  showDrawer,
  noSession,
  uncode,
}) => {
  const location = useLocation();
  const { sessionAuth } = useContext(UserContext);
  const [isLogged, setIsLogged] = useState(current);
  const [collapsed, setCollapsed] = useState(false);
  
  useEffect(() => {
    current = location.pathname;
    let isLoged = sessionAuth
    ? current.includes("/newRelease")
      ? "/release"
      : current
    : current;
    setIsLogged(isLoged);
  }, [location.pathname]);

  return (
    <div className="content">
      <Chart>
        {uncode ? null : (
        <Navbar
          open={open}
          setOpen={setOpen}
          current={isLogged}
          changeItem={changeItem}
          collapsed={collapsed}
          setCollapsed={setCollapsed}
        />
        )}
        <Chart>
          {sessionAuth ? (
            <React.Fragment>
              <UserMenu
                changeItem={changeItem}
                showDrawer={showDrawer}
                collapsed={collapsed}
              />
            </React.Fragment>
          ) : null}
          <div className={noSession || uncode ? "" :(collapsed ? "dashboard-full" : "content-margin")}>
            {children}
          </div>
        </Chart>
        {noSession || uncode===true ?
        <Footer />
        : null}
      </Chart>
      <UploadCard />
    </div>
  );
};

export default Layout;
