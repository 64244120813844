import React from "react";

const SupportIcon = ({ className = null, color = null, onClick = null }) => {
  return (
  <svg
  width="20"
  height="21"
  viewBox="0 0 20 21"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
  onClick={onClick}
  className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 2.90039C5.48001 2.90039 2 6.17567 2 10.0004V11.7004H4C5.10457 11.7004 6 12.5958 6 13.7004V16.1004C6 17.7572 4.65686 19.1004 3 19.1004C1.34315 19.1004 0 17.7572 0 16.1004V12.7004V11.7004V10.0004C0 4.8781 4.57886 0.900391 10 0.900391C15.4211 0.900391 20 4.8781 20 10.0004V11.7004V12.7004V16.1004C20 17.7572 18.6569 19.1004 17 19.1004C15.3431 19.1004 14 17.7572 14 16.1004V13.7004C14 12.5958 14.8954 11.7004 16 11.7004H18V10.0004C18 6.17567 14.52 2.90039 10 2.90039Z"
      fill={color || "#ffffffa6"}
    />
    <path
      d="M5.54199 17.6719C6.61236 18.8889 7.43007 19.2487 9.01436 19.4081" 
      stroke={color || "#ffffffa6"}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <rect
      x="8"
      y="18"
      width="5"
      height="3"
      rx="1.5"
      fill={color || "#ffffffa6"}
    />
  </svg>
  );
};

export default SupportIcon;
