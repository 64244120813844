import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../../../../context/User";
import AlbumIcon from "../../../../Icon/AlbumIcon/AlbumIcon.svg";
import CustomCard from "../../../../Common/CustomCard/CustomCard";
const ReleaseList = ({
  img,
  title,
  artist,
  myClass,
  section,
  idRelease,
  released,
  imgResized,
  changeItem,
  showDeleteDraft,
  rejections,
  canDelete = false,
}) => {
  const { permission, type } = useContext(UserContext);
  const [link, setLink] = useState("/");
  const navigate = useNavigate();

  useEffect(() => {
    defineLink();
    // eslint-disable-next-line
  }, [img]);

  const defineLink = () => {
    if (type === "admin") {
      if (!released) {
        if (section === "draft") {
          setLink("/release/view?r=" + idRelease + "&edit=true");
        } else {
          setLink("/release/view?r=" + idRelease);
        }
      } else {
        setLink("/release/view?r=" + idRelease);
      }
    } else {
      setLink("/newRelease?r=" + idRelease);
    }
  };

  const previewImg = (e, el) => {
    let imgList = document.getElementById("imgList" + el);
    if (imgList) {
      imgList.src = img;
      let image = document.createElement("img");
      image.src = img;
      image.addEventListener(
        "error",
        () => {
          var isLoaded = image.complete && image.naturalHeight !== 0;
          if (!isLoaded) {
            imgList.src = AlbumIcon;
          }
        },
        false
      );
    }
    return;
  };

  return (
    <li
      className={myClass}
    >
      <CustomCard
        img={img}
        link={link}
        album={title}
        artist={artist}
        idRelease={idRelease}
        rejections={rejections}
        imageResized={imgResized}
        showFadeBackground={canDelete}
        onClick={() => changeItem("/release")}
        editCardOnClick={() => navigate(link)}
        onImageError={(e) => previewImg(e, idRelease)}
        redirect={permission?.release?.update && !canDelete}
        deleteCardOnClick={() => showDeleteDraft(idRelease, title)}
      />
    </li>
  );
};

export default ReleaseList;
