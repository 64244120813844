import React, { createContext, useState } from "react";
import AdminCalls from "../../classes/admin/admin";
const url_storage =
  // eslint-disable-next-line no-undef
  process.env.REACT_APP_S3_BUCKET_CDN_URL || process.env.REACT_APP_STORAGE;

/**
 * Here you can send the file to the server and update the upload status.
 * @see UploadCard component to see how to use/consume the uploadStatus
 * @see ReleaseDetails component to see how to use consume the addFiles
 * @param children
 * @returns addFiles, files, uploadStatus, clearFiles, handleFileChange,
 * @example const { addFiles, uploadStatus, clearFiles } = useContext(Upload
 */
export const UploadServiceContext = createContext();
export const UploadServiceProvider = ({ children }) => {
  // Notifys the progress of the any upload status change
  const [uploadStatus, setUploadStatus] = useState({});

  // this should be deleted in the future or replaced by userType condition
  const [showProgressCard, setShowProgressCard] = useState(true);

  const clearFiles = () => {
    setUploadStatus({});
  };

  const updateTrack = (data) => {
    const { trackId, asset, file, s3_url } = data;
    const dataToSend = [
      {
        type: "audio",
        mime_type: file.type,
        format: file.name.split(".").pop(),
        checksum: asset.checksum,
        filename: asset.filename,
      },
    ];
    AdminCalls.trackUpdate(trackId, { asset: dataToSend, s3_url });
  };

  const updateCover = (data) => {
    const { releaseId, asset, file, s3_url } = data;
    const dataToSend = [
      {
        type: "image",
        mime_type: file.type,
        format: file.name.split(".").pop(),
        checksum: asset.checksum,
        filename: asset.filename,
      },
    ];
    AdminCalls.releaseUpdate(releaseId, { asset: dataToSend, s3_url });
  };

  const onUploadProgress = (progressEvent, { trackId, releaseId, type }) => {
    const progress = Math.round(
      (progressEvent.loaded * 100) / progressEvent.total
    );

    // Update the upload status every 10% to avoid unnecessary renders
    if (progress % 10 === 0 && progress < 91) {
      setUploadStatus({ trackId, releaseId, type, progress });
    }
  };

  const addFiles = (filesData) => {
    if (!Array.isArray(filesData))
      console.log("UploadServices filesData is not an array");

    for (const fileData of filesData) {
      const { file, releaseId, trackId, type } = fileData;
      const s3_url = url_storage + releaseId;

      AdminCalls.uploadFile({
        file,
        releaseId,
        trackId,
        type,
        onUploadProgress,
      })
        .then((response) => {
          if (type === "cover") {
            updateCover({ releaseId, asset: response.asset, file, s3_url });
          } else {
            updateTrack({ trackId, asset: response.asset, file, s3_url });
          }
          setUploadStatus({
            releaseId,
            type,
            progress: 100,
            asset: response.asset,
            s3_url,
            trackId,
          });
        })
        .catch((error) => {
          console.error("Error uploading file ", releaseId, trackId, type);
          console.error(error);
        });
    }
  };

  return (
    <UploadServiceContext.Provider
      value={{
        addFiles,
        uploadStatus,
        clearFiles,
        showProgressCard,
        setShowProgressCard,
      }}
    >
      {children}
    </UploadServiceContext.Provider>
  );
};
