import React, { useContext, useEffect, useState } from "react";
import { Col, Table, Divider } from "antd";
import AddDSP from "./AddDSP";
import DspDelete from "./DspDelete";
import EditIcon from "../../Icon/EditIcon";
import TrashIcon from "../../Icon/TrashIcon";
import DSP from "../../../classes/dsp/dsp.jsx";
import { LanguageContext } from "../../../context/Language";

const DspsArtist = ({
  artist,
}) => {

  const { dictionary } = useContext(LanguageContext);
  const [dataDsp, setDsps] = useState([]);
  const [dataDsps, setDataDsps] = useState([]);
  const [itemDsp, setItemDelete] = useState([]);
  const [loadSave, setLoadSave] = useState(false);
  const [currentDsp, setCurrentDsp] = useState("");
  const [updateDsp, setUpdateDsp] = useState(false);
  const [dspModal, newDspVisible] = useState(false);
  const [deleteVisible, modalDelete] = useState(false);
  const [indexDspView, setIndexDspView] = useState(null);
  const [errorsDsp, setErrorDsp] = useState({
    dsp_id: false,
    dsp_identifier: false,
  });

  const options = [
    {
      title: dictionary.deliveryType,
      dataIndex: "type",
      key: "type",
    },
    {
      title: dictionary.identifier,
      dataIndex: "dsp_identifier",
      key: "dsp_identifier",
    },
    {
      title: "",
      dataIndex: "edit",
      key: "edit",
    },
  ];

  useEffect(() => {
    getDistributors();
    setDataDsps(artist.dsp ?? []);
    // eslint-disable-next-line
  }, [artist]);

    useEffect(() => {
      artist.dsp = dataDsps.length !== 0 ? dataDsps : artist.dsp;
    }, [dataDsps]);

  const getDistributors = async () => {
    try {
      const dsp = await DSP.dsps();
      if (dsp.status === 200) setDsps(dsp.body.dsps);
    } catch (e) {
      setDsps([]);
    }
  };

  const showNewDsp = (index = null) => {
    setErrorDsp({
      dsp_id: false,
      dsp_identifier: false,
    });
    setIndexDspView(index);
    if (index !== null) {
      setUpdateDsp(true);
    } else {
      setUpdateDsp(false);
    }
    newDspVisible(true);
  };

  const dspCancel = () => {
    newDspVisible(false);
    modalDelete(false);
  };

  const deleteNewDsp = (index, el) => {
    setCurrentDsp(el);
    modalDelete(true);
    setItemDelete(index);
  };

  const eraseDsp = () => {
    dataDsps.splice(itemDsp, 1);
    modalDelete(false);
  };

  const dataTable = () => {
    const dataSourceExamp = [];
    dataDsps.forEach((el) => {
      const index = dataDsps.indexOf(el);
      dataSourceExamp.push({
        key: index,
        dataIndex: index,
        type: el.dsp_name || "",
        dsp_identifier: el.dsp_identifier,
        edit: (
          <div className="editIcons">
            <EditIcon onClick={() => showNewDsp(index)} />
            <TrashIcon onClick={() => deleteNewDsp(index, el)} />
          </div>
        ),
      });
    });
    return dataSourceExamp;
  };

  const addDspBtn = [
    <b key={0} className="orng">
      +{" "}
    </b>,
    dictionary.addNewDSP,
  ];

  return (
    <React.Fragment>
      <Col xs={24} sm={24} md={24} lg={24}>
        <Divider orientation="left" style={{ color: "#808080" }}>
          <div className="subtitle-opt">
          {dictionary.customIdentifiers}
          </div>
        </Divider>
        <div className="identifier">
            <button onClick={() => showNewDsp()}>{addDspBtn}</button>
        </div>
        <div className="dsp-desc">
          {dictionary.dspDesc}
        </div>
        <Table
          className="table-preview-tracks dsps"
          dataSource={dataTable()}
          columns={options}
          pagination={false}
          scroll={true}
        />
      </Col>
      <AddDSP
        artist={artist}
        allDsps={dataDsp}
        loadSave={loadSave}
        dataDsps={dataDsps}
        dspModal={dspModal}
        index={indexDspView}
        dspCancel={dspCancel}
        errorsDsp={errorsDsp}
        updateDsp={updateDsp}
        setDataDsps={setDataDsps}
        setErrorDsp={setErrorDsp}
        setLoadSave={setLoadSave}
        newDspVisible={newDspVisible}
      />
      <DspDelete
        eraseDsp={eraseDsp}
        currentDsp={currentDsp}
        handleCancel={dspCancel}
        deleteVisible={deleteVisible}
      />
    </React.Fragment>
  );
};

export default DspsArtist;
