import React, { useContext } from "react";
import { LanguageContext } from "../../../../../context/Language";
import Modal from "../../../../Modal";
import Button from "../../../../Buttons/GeneralButton";

const DeleteDraft = ({
  deleteTitle,
  handleCancel,
  deleteRelease,
  deleteVisible,
} ) => {

  const { dictionary } = useContext(LanguageContext);

  return (
    <Modal
      destroyOnClose
      maskClosable={false}
      idModal="delete"
      className="deleteModal"
      handleCancel={handleCancel}
      isModalVisible={deleteVisible} 
      title={null}
      body={
        <div className="modal-title">
          {dictionary.deleteRelease.replace("--release--", deleteTitle)} 
        </div>
      }
      footer={
        <div className="modalAdd-btns">
          <Button
            key="cancel"
            size="sm"
            className="back-btn"
            text={dictionary.cancel}
            onClick={handleCancel}
          />
          <Button
            size="md"
            key="delete"
            className="footer-btn"
            onClick={() => deleteRelease(deleteVisible)}
            text={dictionary.delete}
          />
        </div>
      }
    />
  );
};

export default DeleteDraft;
