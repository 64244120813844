import React, { useContext, useEffect, useState } from "react";
import { Row, Col, Checkbox } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { ArtistContext } from "../../../../../../context/Artist";
import { LanguageContext } from "../../../../../../context/Language";
import Modal from "../../../../../Modal";
import Loader from "../../../../../Loader";
import Input from "../../../../../Form/Input";
import Radio from "../../../../../Form/Radio";
import Button from "../../../../../Buttons/GeneralButton";
import MultiSelect from "../../../../../Form/MultiSelect";
import Autocomplete from "../../../../../Form/Autocomplete";
import DspsArtist from "../../../../../Artists/DspsArtist/DspsArtist";

const AddArtist = ({
  open,
  isNew,
  artist,
  errors,
  checked,
  setOpen,
  loading,
  setIsNew,
  isUpdate,
  alertText,
  loaderAdd,
  listArtist,
  handleRadio,
  handleCancel,
  handleSelect,
  handleSubmit,
  changeComplete,
  isModalVisible,
  artistsOptions,
  onChangeCheckbox,
  changeHandlerName,
}) => {
  const [drawRoles, setDrawRoles] = useState({
    artist: [],
    writer: [],
    producer: [],
    participant: [],
  });
  const { roles, frontRoles } = useContext(ArtistContext);
  const { dictionary } = useContext(LanguageContext);
  const [allowMain, setAllowMain] = useState(true);

  useEffect(() => {}, [drawRoles.artist.length]);

  useEffect(() => {
    async function fetchData() {
      const newDrawRoles = {
        artist: [],
        writer: [],
        producer: [],
        participant: [],
      };
      if (frontRoles) {
        for (const type in frontRoles) {
          let arrRoles = [];
          for (const role in frontRoles[type]) {
            let add = true;

            if (add) {
              arrRoles.push({
                _id: frontRoles[type][role],
                name: dictionary.artistType[frontRoles[type][role]],
              });
            }
          }
          newDrawRoles[type] = arrRoles;
        }
      }
      setDrawRoles(newDrawRoles);
    }
    fetchData();
    // eslint-disable-next-line
  }, [roles, allowMain]);

  useEffect(() => {
    if (artist.type === "artist") {
      if (artist.role.includes("main") || artist.role.includes("primary")) {
        let mainType = artist.role.includes("main") ? "main" : "primary";
        let newDrawRoles = { ...drawRoles };
        for (let i = 0; i < newDrawRoles.artist.length; i++) {
          newDrawRoles.artist[i].disabled =
            newDrawRoles.artist[i]._id !== mainType;
        }
        setDrawRoles(newDrawRoles);
      } else if (artist.role.length) {
        let newDrawRoles = { ...drawRoles };
        for (let i = 0; i < newDrawRoles.artist.length; i++) {
          newDrawRoles.artist[i].disabled =
            newDrawRoles.artist[i]._id === "main" ||
            newDrawRoles.artist[i]._id === "primary";
        }
        setDrawRoles(newDrawRoles);
      } else {
        let newDrawRoles = { ...drawRoles };
        for (let i = 0; i < newDrawRoles.artist.length; i++) {
          newDrawRoles.artist[i].disabled = false;
        }
        setDrawRoles(newDrawRoles);
      }
    }
    // eslint-disable-next-line
  }, [artist.type, artist.role.length]);

  useEffect(() => {
    const hasMain = listArtist.artist.find(
      (el) => el.role === "main" || el.role === "primary"
    );
    if (hasMain) {
      setAllowMain(false);
    } else {
      setAllowMain(true);
    }
    // eslint-disable-next-line
  }, [listArtist.artist.length]);

  const handleNotFoundClick = () => {
    setIsNew(true);
    setOpen(false);  // Cerrar el menú cuando se hace clic en el contenido no encontrado
  };

  return (
    <Modal
      idModal="AddContributorToRelease"
      handleCancel={handleCancel}
      isModalVisible={isModalVisible}
      maskClosable={false}
      title={
        <div className="modal-title">
          {dictionary.add} {dictionary.category[artist.type]}
        </div>
      }
      body={
        <div className="forms add-form">
          <Row className="gutter-row">
            <Col xs={24} sm={24} md={24} lg={24} className="col1">
              {artist.type === "artist" ? (
                <Input
                  name="name"
                  required={true}
                  value={artist.name}
                  error={errors.name}
                  disabled={isUpdate}
                  label={dictionary.name}
                  className={errors.name ? "inputErr" : ""}
                  onChange={(e) => changeHandlerName(e.target.value, "name")}
                />
              ) : (
                <Autocomplete
                  required={true}
                  addNewOpt={true}
                  error={errors.name}
                  value={artist.name}
                  options={artistsOptions}
                  label={dictionary.name}
                  placeholder={dictionary.addNewSearch}
                  onChange={(e) => changeComplete(e, "name", artistsOptions)}
                  className={
                    (errors.name ? "autocomErr" : "") + " autocomp-general"
                  }
                  open={!isModalVisible ? false : open}
                  setOpen={setOpen}
                  handleNotFoundClick={handleNotFoundClick}
                />
              )}
              {artist.type !== 0 && artist.type !== "artist" ? (
                <MultiSelect
                  name="role"
                  required={true}
                  value={artist.role}
                  options={drawRoles[artist.type]}
                  error={errors.role}
                  label={dictionary.role}
                  placeholder={
                    <div className="rolesicon">
                      <PlusOutlined /> {dictionary.addRole}{" "}
                    </div>
                  }
                  onChange={(e) => handleSelect(e, "role")}
                  className={
                    (errors.role ? "countErr " : "") +
                    " select-role selector-general multiselect"
                  }
                />
              ) : artist.type !== 0 && artist.type === "artist" ? (
                <Radio
                  required={true}
                  options={drawRoles[artist.type]}
                  onChange={(e) => handleRadio(e.target, "role")}
                  value={"feature" || artist.role}
                  error={errors.role}
                  className="radio-options"
                  label={dictionary.role}
                />
              ) : null}
              {artist.type !== "artist" && isNew ? (
                <Checkbox
                  checked={checked}
                  className="check-addTo"
                  onChange={onChangeCheckbox}
                >
                  {dictionary.addToArtists}
                </Checkbox>
              ) : null}
              {(checked && artist.type !== "artist") || (artist.type !== 0 && artist.type === "artist") ?
                <div className="add-artist">
                  <DspsArtist artist={artist} />
                </div>
              : null }
            </Col>
          </Row>
          {loading || loaderAdd ? <Loader /> : null}
          {alertText ? <div className="alert-artist">{alertText}</div> : null}
        </div>
      }
      footer={
        <div className="modalAdd-btns">
          <Button
            key="cancel"
            size="sm"
            className="back-btn"
            text={dictionary.cancel}
            onClick={handleCancel}
          />
          <Button
            key="submit"
            size="md"
            text={isUpdate ? dictionary.update : dictionary.add}
            className="footer-btn"
            onClick={() => handleSubmit(isUpdate)}
            disabled={(artist.name.length !== 0 && artist.role.length !== 0) ? false : true}
          />
        </div>
      }
    />
  );
};

export default AddArtist;
