import { Card, Col, Row, Input, Select, Typography, DatePicker } from "antd";
import React, { useContext, useState, useMemo } from "react";
import { LanguageContext } from "../../../../context/Language";
import { UserContext } from "../../../../context/User";
import { AppContext } from "../../../../context/App";
import { useReleaseDetail } from "../../../../hooks/release/useReleaseDetail";
import GenreSubGenreSelect from "../../../Form/GenreSubGenreSelect";
import moment from "moment";
import "./GeneralInformation.css";

const GeneralInformation = ({ dispatch, dataToUpdate, enableEdit }) => {
  const { releaseData, isLoading } = useReleaseDetail();
  const { genres = [], permission = [] } = releaseData;
  const { subGenreObjects } = useContext(UserContext);
  const { languageOptions, dictionary } = useContext(LanguageContext);
  const { advisoryOptions, formatOptions, labelOptions } =
    useContext(AppContext);

  // only for force the re-render on genres select
  const [genresSelected, setGenresSelected] = useState(null);

  // Add selectedPreOrderDate to state
  const [selectedPreOrderDate, setSelectedPreOrderDate] = useState(null);

  // Options for genre select
  const genresMap = useMemo(() => {
    return genres.map((item) => {
      return subGenreObjects[item.subgenre_id || item.genre_id] || null;
    });
  }, [genres, subGenreObjects]);

  const getPreOrderdate = useMemo(() => {
    if (selectedPreOrderDate) {
      return selectedPreOrderDate;
    }

    const preOrderItem = (dataToUpdate?.permission || permission).find(
      (item) => item.type === "preorder" && item.enabled
    );

    return preOrderItem ? preOrderItem.start : new Date();
  }, [permission, dataToUpdate?.permission, selectedPreOrderDate]);

  return (
    <div className="general_information">
      <Row>
        <Col>
          <Typography.Title level={3}>
            {dictionary.generalInformation}
          </Typography.Title>
        </Col>
      </Row>
      <Row justify="space-around">
        <Col xs={24} lg={11} className="info-one">
          <Card loading={isLoading}>
            <Row justify="space-between" align="middle" className="mt-2 mr-4">
              <Col className="label" md={8} span={24}>
                {dictionary.format}
              </Col>
              <Col md={16} span={24}>
                <Select
                  className="selector-general"
                  defaultValue={dataToUpdate?.type || releaseData.type}
                  options={formatOptions}
                  onChange={(e) => {
                    dispatch({
                      type: "simpleChange",
                      payload: {
                        name: "type",
                        original: releaseData.type,
                        value: e,
                      },
                    });
                  }}
                  disabled={!enableEdit}
                />
              </Col>
            </Row>
            <Row justify="space-between" align="middle" className="mt-2 mr-4">
              <Col className="label" md={8} span={24}>
                {dictionary.label}
              </Col>
              <Col md={16} span={24}>
                <Select
                  className="selector-general add-top"
                  showSearch
                  defaultValue={dataToUpdate.label_id || releaseData.label_id}
                  placeholder={dictionary.select}
                  optionFilterProp="children"
                  onChange={(e) => {
                    dispatch({
                      type: "simpleChange",
                      payload: {
                        name: "label_id",
                        original: releaseData.label_id,
                        value: e,
                      },
                    });
                  }}
                  onSearch={() => {}}
                  filterOption={(input, option) =>
                    (option?.label ?? "")

                      .toLowerCase()

                      .includes(input.toLowerCase())
                  }
                  options={labelOptions}
                  disabled={!enableEdit}
                />
              </Col>
            </Row>
            <Row justify="space-between" align="middle" className="mt-2 mr-4">
              <Col className="label" md={8} span={24}>
                {dictionary.language}
              </Col>
              <Col md={16} span={24}>
                <Select
                  className="selector-general add-top"
                  name="language"
                  defaultValue={dataToUpdate?.language || releaseData.language}
                  options={languageOptions}
                  placeholder={dictionary.select}
                  onChange={(e) => {
                    dispatch({
                      type: "simpleChange",
                      payload: {
                        name: "language",
                        original: releaseData.language,
                        value: e,
                      },
                    });
                  }}
                  disabled={!enableEdit}
                />
              </Col>
            </Row>
            <Row justify="space-between" align="middle" className="mt-2 mr-4">
              <Col className="label" md={8} span={24}>
                {dictionary.version}
              </Col>
              <Col md={16} span={24}>
                <Input
                  name="version"
                  disabled={!enableEdit}
                  placeholder={dictionary.exVersion}
                  defaultValue={
                    dataToUpdate?.title_version ?? releaseData.title_version
                  }
                  className="input-general"
                  onChange={(e) => {
                    dispatch({
                      type: "simpleChange",
                      payload: {
                        name: "title_version",
                        original: releaseData.title_version,
                        value: e.target.value,
                      },
                    });
                  }}
                />
              </Col>
            </Row>
            <Row justify="space-between" align="middle" className="mt-2 mr-4">
              <Col className="label" md={8} span={24}>
                {dictionary.releaseDate}
              </Col>
              <Col md={16} span={24}>
                <DatePicker
                  className="date-picker-inp"
                  inputReadOnly={true}
                  onChange={(e) => {
                    dispatch({
                      type: "simpleChange",
                      payload: {
                        name: "date",
                        original: releaseData.releaseDate,
                        value: e?.toISOString(),
                      },
                    });
                  }}
                  placeholder={dictionary.releaseDate}
                  defaultValue={moment(dataToUpdate?.date || releaseData.date)}
                  value={moment(dataToUpdate?.date || releaseData.date)}
                  format={"D MMM, YYYY"}
                  disabledDate={(current) => {
                    return (
                      current < moment().endOf("day") &&
                      current < moment(dataToUpdate?.date || releaseData.date)
                    );
                  }}
                  allowClear={false}
                  disabled={!enableEdit}
                />
              </Col>
            </Row>
            {!releaseData.is_new && (
              <Row justify="space-between" align="middle" className="mt-2 mr-4">
                <Col className="label" md={8} span={24}>
                  {dictionary.originalRelDate}
                </Col>
                <Col md={16} span={24}>
                  <DatePicker
                    className="date-picker-inp"
                    inputReadOnly={true}
                    onChange={(e) => {
                      dispatch({
                        type: "simpleChange",
                        payload: {
                          name: "original_date",
                          original: releaseData.original_date,
                          value: e.toISOString(),
                        },
                      });
                    }}
                    placeholder={dictionary.releaseDate}
                    defaultValue={moment(
                      dataToUpdate?.original_date || releaseData.original_date
                    )}
                    value={moment(
                      dataToUpdate?.original_date || releaseData.original_date
                    )}
                    format={"D MMM, YYYY"}
                    disabledDate={(current) => {
                      return (
                        current > moment().endOf("day") &&
                        current >
                          moment(
                            dataToUpdate?.original_date ||
                              releaseData.original_date
                          )
                      );
                    }}
                    disabled={!enableEdit}
                  />
                </Col>
              </Row>
            )}
            <Row justify="space-between" align="middle" className="mt-2 mr-4">
              <Col className="label" md={8} span={24}>
                {dictionary.genre}
              </Col>
              <Col md={16} span={24}>
                <GenreSubGenreSelect
                  name="genres"
                  disabled={!enableEdit}
                  defaultValue={genresMap}
                  // The component only re-render if value change
                  value={genresSelected ? genresSelected : genresMap}
                  error={""}
                  onChange={(e) => {
                    setGenresSelected(e);
                    dispatch({
                      type: "objectChange",
                      payload: {
                        name: "genres",
                        original: genresMap,
                        value: e,
                      },
                    });
                  }}
                />
              </Col>
            </Row>
          </Card>
        </Col>
        <Col xs={24} lg={11}>
          <Card loading={isLoading}>
            <Row justify="space-between" align="middle" className="mt-2 mr-4">
              <Col className="label" md={8} span={24}>
                {dictionary.PreOrderDate}
              </Col>
              <Col md={16} span={24}>
                <DatePicker
                  className="date-picker-inp"
                  inputReadOnly={true}
                  onChange={(e) => {
                    setSelectedPreOrderDate(e); // Update the selected date
                    dispatch({
                      type: "partialPermission",
                      payload: {
                        permissionType: "preorder",
                        name: "start",
                        original: permission,
                        value: e.toISOString(),
                      },
                    });
                  }}
                  placeholder={dictionary.releaseDate}
                  defaultValue={moment.utc(getPreOrderdate)}
                  value={moment.utc(getPreOrderdate)}
                  format={"D MMM, YYYY"}
                  disabledDate={(current) => {
                    return (
                      current >
                        moment(dataToUpdate?.date || releaseData.date) ||
                      current < moment().startOf("day")
                    );
                  }}
                  disabled={!enableEdit}
                  allowClear={false}
                />
              </Col>
            </Row>
            <Row justify="space-between" align="middle" className="mt-2 mr-4">
              <Col className="label" md={8} span={24}>
                {dictionary.parentalAdvisory}
              </Col>
              <Col md={16} span={24}>
                <Select
                  className="selector-general add-top"
                  name="advocacy"
                  defaultValue={dataToUpdate?.advocacy || releaseData.advocacy}
                  options={advisoryOptions}
                  placeholder={dictionary.select}
                  onChange={(e) => {
                    dispatch({
                      type: "simpleChange",
                      payload: {
                        name: "advocacy",
                        original: releaseData.advocacy,
                        value: e,
                      },
                    });
                  }}
                  disabled={!enableEdit}
                />
              </Col>
            </Row>
            <Row justify="space-between" align="middle" className="mt-2 mr-4">
              <Col className="label" md={8} span={24}>
                {dictionary.metadataLanguage}
              </Col>
              <Col md={16} span={24}>
                <Select
                  className="selector-general add-top"
                  name="language"
                  defaultValue={
                    dataToUpdate?.metadata_lang || releaseData.metadata_lang
                  }
                  options={languageOptions}
                  placeholder={dictionary.select}
                  onChange={(e) => {
                    dispatch({
                      type: "simpleChange",
                      payload: {
                        name: "metadata_lang",
                        original: releaseData.metadata_lang,
                        value: e,
                      },
                    });
                  }}
                  disabled={!enableEdit}
                />
              </Col>
            </Row>
            <Row justify="space-between" align="middle" className="mt-2 mr-4">
              <Col className="label" md={8} span={24}>
                {dictionary.recordingLocation}
              </Col>
              <Col md={16} span={24}>
                <Input
                  className="input-general"
                  defaultValue={
                    dataToUpdate?.record_location ?? releaseData.record_location
                  }
                  onChange={(e) => {
                    dispatch({
                      type: "simpleChange",
                      payload: {
                        name: "record_location",
                        original: releaseData.record_location,
                        value: e.target.value,
                      },
                    });
                  }}
                  placeholder="Ex. California, USA..."
                  disabled={!enableEdit}
                />
              </Col>
            </Row>
            <Row justify="space-between" align="middle" className="mt-2 mr-4">
              <Col className="label" md={8} span={24}>
                {dictionary.recordingYear}
              </Col>
              <Col md={16} span={24}>
                <DatePicker
                  className="date-picker-inp"
                  inputReadOnly={true}
                  picker="year"
                  onChange={(e) => {
                    dispatch({
                      type: "simpleChange",
                      payload: {
                        name: "recording_year",
                        original: releaseData.recording_year,
                        value: e ? e.year() : null,
                      },
                    });
                  }}
                  placeholder={dictionary.selectYear}
                  defaultValue={
                    dataToUpdate?.recording_year || releaseData.recording_year
                      ? moment().year(
                          dataToUpdate?.recording_year ||
                            releaseData.recording_year
                        )
                      : null
                  }
                  value={
                    dataToUpdate?.recording_year || releaseData.recording_year
                      ? moment().year(
                          dataToUpdate?.recording_year ||
                            releaseData.recording_year
                        )
                      : null
                  }
                  allowClear={false}
                  format={"YYYY"}
                  disabled={!enableEdit}
                />
              </Col>
            </Row>
            <Row justify="space-between" align="middle" className="mt-2 mr-4">
              <Col className="label" md={8} span={24}>
                {dictionary.smartLink}
              </Col>
              <Col md={16} span={24}>
                <Input
                  className="input-general"
                  defaultValue={
                    dataToUpdate?.smart_url ?? releaseData.smart_url
                  }
                  onChange={(e) => {
                    dispatch({
                      type: "simpleChange",
                      payload: {
                        name: "smart_url",
                        original: releaseData.smart_url,
                        value: e.target.value,
                      },
                    });
                  }}
                  placeholder="Ex. https://stream.al/XXXX"
                  disabled={!enableEdit}
                />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default GeneralInformation;
