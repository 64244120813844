import React, { useState, useEffect } from 'react';
import ReleasesList from './ReleasesList';
import MobileReleasesList from './MobileReleasesList';

const ResponsiveReleasesList = (props) => {
  const [isMobile, setIsMobile] = useState(false);
  
  // Check screen size on mount and when window resizes
  useEffect(() => {
    const checkScreenSize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    
    // Initial check
    checkScreenSize();
    
    // Add listener for window resize
    window.addEventListener('resize', checkScreenSize);
    
    // Cleanup listener on component unmount
    return () => {
      window.removeEventListener('resize', checkScreenSize);
    };
  }, []);
  
  return isMobile ? (
    <MobileReleasesList {...props} />
  ) : (
    <ReleasesList {...props} />
  );
};

export default ResponsiveReleasesList; 