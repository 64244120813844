import React from 'react';
import Button from '../../../Buttons/GeneralButton';
import './pricing-card.css';

const PricingCard = ({ 
  plan, 
  isSelected, 
  isCurrent, 
  onSelect, 
  dictionary,
  isAnnual,
  features
}) => {
  // Get the appropriate button text based on the plan and current status
  const getButtonText = () => {
    if (isSelected) return dictionary.selected;
    if (isCurrent) return dictionary.keepThisPlan;
    
    if (plan.id === 'core') return dictionary.joinNow;
    if (plan.id === 'pro') return dictionary.joinNow;
    return dictionary.select;
  };

  // Apply Euro symbol instead of dollar for display
  const getCurrencySymbol = () => {
    return '$';
  };

  // Calculate monthly equivalent when on annual plan
  const getMonthlyEquivalent = () => {
    if (!isAnnual) return null;
    const monthlyPrice = (parseFloat(plan.price) / 12).toFixed(2);
    return monthlyPrice;
  };

  // Get original monthly price (non-discounted)
  const getOriginalMonthlyPrice = () => {
    if (!isAnnual) return null;
    return plan.originalPrice ? (plan.originalPrice / 12).toFixed(2) : parseFloat(plan.price) / 9.6;
  };

  // Render pricing for all plans
  const renderPricing = () => {
    const extraPriceStyle = plan.id === 'extra' ? {
      color: 'white',
      background: 'transparent',
      backgroundClip: 'text',
      WebkitBackgroundClip: 'text',
      WebkitTextFillColor: 'white'
    } : {};

    const monthlyEquivalent = getMonthlyEquivalent();
    const originalMonthlyPrice = getOriginalMonthlyPrice();

    return (
      <div className="card-pricing">
        <h2 className="price-amount" style={extraPriceStyle}>
          <span className="currency" style={extraPriceStyle}>{getCurrencySymbol()}</span>
          {plan.price}
        </h2>
        <div className="price-period" style={plan.id === 'extra' ? {color: 'white'} : {}}>{dictionary.per} {isAnnual ? dictionary.year : dictionary.month}</div>
        
        {isAnnual && monthlyEquivalent && (
          <div className={`monthly-equivalent ${plan.id === 'extra' ? 'white-text' : ''}`}>
            <span className="equivalent-text">
              {dictionary.thats}&nbsp;
              </span>
            <span className="original-price">
              <span className="currency-small">{getCurrencySymbol()}</span>{originalMonthlyPrice}
            </span>
            <span className="monthly-price">
              <span className="currency-small">{getCurrencySymbol()}</span>{monthlyEquivalent}
            </span>
            <span className="equivalent-text"> / {dictionary.month}</span>
          </div>
        )}
        
        {plan.id === 'core' && (
          <div className="split-rate-badge">
            {dictionary.pricingCore.note}
          </div>
        )}
        {plan.id === 'extra' && (
          <div className="split-rate-badge">
            {dictionary.pricingExtra.note}
          </div>
        )}
        {plan.id === 'pro' && (
          <div className="split-rate-badge">
            {dictionary.pricingPro.note}
          </div>
        )}
      </div>
    );
  };

  // Get plan-specific subtitle text
  const getSubtitle = () => {
    if (plan.id === 'core') {
      return dictionary.pricingCore.description;
    } else if (plan.id === 'extra') {
      return dictionary.pricingExtra.description;
    } else if (plan.id === 'pro') {
      return dictionary.pricingPro.description;
    }
    return plan.description;
  };

  const handleSelect = (e) => {
    // Detener la propagación para evitar clics duplicados
    e.preventDefault();
    e.stopPropagation();
    
    // Llamar a la función onSelect
    onSelect(plan.id);
  };

  // Get additional class names based on component state
  const getCardClasses = () => {
    let classes = `pricing-card ${plan.id}`;
    if (isSelected) classes += ' selected';
    if (isCurrent) classes += ' current-plan';
    return classes;
  };

  return (
    <div 
      className={getCardClasses()}
      onClick={handleSelect}
    >
      {isCurrent && (
        <div className="current-plan-badge">
          {dictionary.currentPlan}
        </div>
      )}
      
      <div className="card-header">
        <h3 className="plan-name">{plan.name}</h3>
        {plan.id === 'extra' && <div className="most-popular-badge">{dictionary.mostPopular}</div>}
      </div>

      {renderPricing()}

      <p className="plan-subtitle">{getSubtitle()}</p>

      {features && (
        <div className="feature-list">
          {features.map((feature, index) => (
            <div key={index} className="feature-item">
              <div className={`checkbox-pricing ${plan.id === 'extra' ? 'white-box' : 'green-box'}`}>
                <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path 
                    d="M1 4L3.5 6.5L9 1" 
                    stroke={plan.id === 'extra' ? 'white' : '#3ecf8e'} 
                    strokeWidth="2" 
                    strokeLinecap="round" 
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              <span>{feature}</span>
            </div>
          ))}
        </div>
      )}

      <Button
        size="fullWidth"
        onClick={handleSelect}
        className={`pricing-cta-btn ${plan.id} ${isCurrent ? 'current-plan-btn' : ''}`}
        text={getButtonText()}
        type="pricing"
        style={plan.id === 'extra' ? {
          color: '#FF6B4A',
          backgroundColor: 'white',
          background: 'white',
          fontWeight: '700'
        } : {}}
      />
    </div>
  );
};

export default PricingCard; 