import React, { useContext, useState, useEffect } from "react";
import { message, Row } from "antd";
import { AppContext } from "../../context/App/";
import { LanguageContext } from "../../context/Language/index.js";
import Select from "../../components/Form/Select";
import RoyaltyCalls from "../../classes/royalties/royalties.jsx";
import TableRoyalties from "../../components/Royalty/TableRoyalties/";
import GraphRoyalties from "../../components/Royalty/GraphRoyalties/";
import CountCard from "../../components/Dashboard/Counts/CountCard";
import { mergeObjectArrayByUniqueKey } from "../../util/index";
import "./royalties.css";

const customStyleCardTotal = {
  marginBottom: "2px",
};

const Royalties = () => {
  const { dictionary, locale } = useContext(LanguageContext);
  let formater;
  try {
    formater = new Intl.NumberFormat(locale, {
      style: "currency",
      currency: "USD",
    });
  } catch (error) {
    console.log("Locale is not valid", locale);
    formater = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    });
  }
  const { yearsOptions } = useContext(AppContext);

  const [loading, setLoading] = useState(true);
  const [filterParams, setFilterParams] = useState({
    year: new Date().getFullYear(),
    periodId: [
      "M1",
      "M2",
      "M3",
      "M4",
      "M5",
      "M6",
      "M7",
      "M8",
      "M9",
      "M10",
      "M11",
      "M12",
    ],
  });
  const [statements, setStatements] = useState([]);
  const [cachedStatements, setCachedStatements] = useState({});
  const [sumNetSales, setSumNetSales] = useState(0);

  useEffect(() => {
    upsertStatements();
  }, [filterParams.year]);

  useEffect(() => {
    setSumNetSales(
      formatToMoney(statements.reduce((acc, item) => acc + item.netAmount, 0))
    );
  }, [statements]);

  const formatToMoney = (value) => {
    return formater.format(value);
  };

  const upsertStatements = () => {
    if (!Object.hasOwnProperty.call(cachedStatements, filterParams.year)) {
      setLoading(true);
      Promise.all(createStatementPromises())
        .then((statements) => {
          const flatten = statements
            .flat()
            .sort(
              (a, b) => Number(a.accountingPeriod) - Number(b.accountingPeriod)
            );
          setStatements(flatten);
          updateCachedStatements(flatten);
        })
        .finally(() => setLoading(false));
    } else {
      const yearStatements = cachedStatements[filterParams.year];
      const rootStatements = yearStatements.root;
      setStatements(rootStatements);
    }
  };

  const createStatementPromises = (labelId = null) => {
    const promises = [];
    const end =
      filterParams.year === new Date().getFullYear()
        ? new Date().getMonth() + 1
        : 12;

    for (let index = 1; index <= end; index++)
      promises.push(
        labelId
          ? RoyaltyCalls.getSublabelRoyalties(labelId, {
              year: filterParams.year,
              period: `M${index}`,
            })
          : RoyaltyCalls.getRoyalties({
              year: filterParams.year,
              period: `M${index}`,
            })
      );

    return promises;
  };

  const updateCachedStatements = (statements, sublabelId = null) => {
    // if the year does not exist, dont exist any sublabel
    if (!Object.hasOwnProperty.call(cachedStatements, filterParams.year)) {
      setCachedStatements({
        ...cachedStatements, // another years
        [filterParams.year]: { root: statements },
      });

      return;
    }

    const yearStatements = cachedStatements[filterParams.year];

    // merge root statements
    if (!sublabelId) {
      const uniqueRootStatements = mergeObjectArrayByUniqueKey(
        yearStatements.root,
        statements,
        "accountingPeriod"
      );
      setCachedStatements({
        ...cachedStatements,
        [filterParams.year]: { ...yearStatements, root: uniqueRootStatements },
      });

      return;
    }

    // add sublabel statements if not exist
    if (!Object.hasOwnProperty.call(yearStatements, sublabelId)) {
      setCachedStatements({
        ...cachedStatements,
        [filterParams.year]: { ...yearStatements, [sublabelId]: statements },
      });

      return;
    }

    // merge sublabel statements
    const sublabelStatements = yearStatements[sublabelId];
    const uniqueSublabelStatements = mergeObjectArrayByUniqueKey(
      sublabelStatements,
      statements,
      "accountingPeriod"
    );
    setCachedStatements({
      ...cachedStatements,
      [filterParams.year]: {
        ...yearStatements,
        [sublabelId]: uniqueSublabelStatements,
      },
    });
  };

  const getSubalelStatements = async (labelId) => {
    if (
      !Object.hasOwnProperty.call(cachedStatements, filterParams.year) &&
      !Object.hasOwnProperty.call(cachedStatements[filterParams.year], labelId)
    ) {
      setLoading(true);
      return await Promise.all(createStatementPromises(labelId))
        .then((statements) => {
          const flatten = statements
            .flat()
            .sort(
              (a, b) => Number(a.accountingPeriod) - Number(b.accountingPeriod)
            );
          updateCachedStatements(flatten, labelId);
          return flatten;
        })
        .catch(() => {
          errorMsg();
          return [];
        })
        .finally(() => setLoading(false));
    } else {
      return cachedStatements[filterParams.year][labelId];
    }
  };

  const handleSelect = (e, name) => {
    setFilterParams({ ...filterParams, [name]: e });
  };

  const errorMsg = () => {
    message.error({
      content: dictionary.errorGeneral,
      style: {
        marginTop: "50px",
      },
    });
  };

  const columns = [
    {
      title: dictionary.createdAt,
      dataIndex: "createdAt",
      className: "title-category",
    },
    {
      title: dictionary.artist + " / " + dictionary.label,
      dataIndex: "name",
      className: "title-category",
    },
    {
      title: dictionary.netSales,
      dataIndex: "netSales",
      className: "title-category",
    },
    {
      title: dictionary.reports,
      dataIndex: "reports",
      className: "title-category",
    },
  ];

  return (
    <React.Fragment>
      <div className="container-box dashboard">
        <Row gutter={16} className="counts-cards">
          <CountCard
            name={dictionary.netSales}
            count={sumNetSales}
            loading={loading}
            customStyle={customStyleCardTotal}
            customColSize={{ xs: 24, sm: 24, md: 12, lg: 8  }}
          />
        </Row>
        <div className="royalt">
          <div className="searchers select-roy">
            <label>
              {dictionary.filterBy} {dictionary.year}
            </label>
            <Select
              name="year"
              value={filterParams.year}
              showSearch={true}
              options={yearsOptions}
              placeholder={dictionary.select}
              onChange={(e) => handleSelect(e, "year")}
              className="selector-general"
              disableOpt={true}
            />
          </div>
        </div>

        <div className="royalty-graph">
          <GraphRoyalties
            filter={filterParams}
            loading={loading}
            columns={columns}
            statements={cachedStatements}
            formater={formater}
          />
        </div>

        <div className="analytics-royalty">
          <TableRoyalties
            filter={filterParams}
            getSubalelStatements={getSubalelStatements}
            loading={loading}
            columns={columns}
            statements={statements || []}
            formater={formater}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default Royalties;
