import React, { useContext, useEffect, useState } from "react";
import { LanguageContext } from "../../../../context/Language";
import {
  Row,
  Col,
  Upload,
  Input as AntInput,
  Divider,
} from "antd";
import { PlusOutlined, LoadingOutlined } from "@ant-design/icons";
import Avatar from "react-avatar";
import Modal from "../../../Modal";
import Loader from "../../../Loader";
import Input from "../../../Form/Input";
import Phone from "../../../Form/Phone";
import Select from "../../../Form/Select";
import Logo from "../../../Icon/broken.png";
import Password from "../../../Form/InputPassword";
import Button from "../../../Buttons/GeneralButton";
import { beforeUploadImage } from "../../../../hooks/files/files";
import DspsArtist from "../../../Artists/DspsArtist/DspsArtist";

const { TextArea } = AntInput;

const AddArtist = ({
  type,
  colab,
  labels,
  artist,
  errors,
  hidedsp,
  loading,
  imageUrl,
  setImage,
  headArtist,
  loadArtist,
  labelArtist,
  urlOriginal,
  handleCancel,
  handleSubmit,
  handleChange,
  changeHandler,
  dataCountries,
  phoneCountries,
  isModalVisible,
  handleSubmitUpdate,
  selectChangeHandler,
}) => {

  const { dictionary } = useContext(LanguageContext);
  const [title, setTitle] = useState("");

  const requiredAddress = !!(
    artist.address ||
    artist.city ||
    artist.state ||
    artist.zip
  );
  const requiredUser = !!(
    artist.number ||
    artist.password ||
    artist.confirmPassword ||
    artist.email ||
    artist.address ||
    artist.city ||
    artist.state ||
    artist.zip
  );


  useEffect(() => {
    if (artist.update) setTitle(colab ? dictionary.updateContributor : dictionary.updateArtist);
    else setTitle(dictionary.addArtist);
  }, [artist.update, dictionary.addArtist, dictionary.updateArtist, colab]);

  const previewImg = async () => {
    await setImage(urlOriginal);
  };

  const previewImgOrg = async () => {
    await setImage([
      {
        name: "broken",
        status: "done",
        url: Logo,
      },
    ]);
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
          color: "#8c8c8c",
        }}
      >
        {dictionary.upload}
      </div>
    </div>
  );

  const typeBehavior = () => {
    if (artist.update) {
      return (
        <>
          <Col xs={24} sm={24} md={24} lg={24}>
            <Divider
              orientation="left"
              style={{ color: "#808080" }}
              className="less-pad"
            >
              <div className="subtitle-opt">
                {colab ? dictionary.contributor : dictionary.artist}
              </div>
            </Divider>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} className="col1">
            <Input
              name="name"
              value={artist.name}
              onChange={(e) => changeHandler(e, "name")}
              label={colab ? dictionary.contributorName : dictionary.artistName}
              required={true}
              className={errors.name ? "inputErr" : ""}
              error={dictionary[errors.name]}
            />
          </Col>

          <Col xs={24} sm={24} md={12} lg={12} className="col2">
            {type === "artist" ? (
                <Select
                name="label"
                value={labelArtist?._id}
                options={[labelArtist]}
                label={dictionary.label}
                placeholder={dictionary.select}
                onChange={(e) => selectChangeHandler(e, "label_id")}
                error={dictionary[errors.label_id]}
                className={" selector-general"}
                disabled={true}
                disableOpt={true}
              />
            ) : (
              <Select
                name="label"
                required={true}
                value={artist.label_id}
                options={labels}
                label={dictionary.label}
                placeholder={dictionary.select}
                className={" selector-general"}
                disabled={true}
              />)
            }
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} className="col1">
            <label>{dictionary.description}</label>
            <TextArea
              className={"text-area-general"}
              name="description"
              showCount
              maxLength={200}
              value={artist.description}
              onChange={(e) => changeHandler(e, "description")}
            />
          </Col>

          <Col xs={24} sm={24} md={12} lg={12} className="col2">
            <label className="allWidth">
              {colab ? dictionary.contribPicture : dictionary.artistPicture}
              </label>
            {urlOriginal.length !== 0 && (
              <img
                id="urloriginal"
                src={urlOriginal[0].url}
                alt="orgnl"
                style={{ display: "none" }}
                onError={(e) => previewImgOrg(e)}
              />
            )}
            {imageUrl[0] ? (
              <img
                id="urlimg"
                src={imageUrl[0].url}
                alt="broke"
                style={{ display: "none" }}
                onError={(e) => previewImg(e)}
              />
            ) : null}
            <Upload
              id={"img" + artist._id}
              name="avatar"
              onChange={(e) => handleChange(e)}
              listType="picture-card"
              className="avatar-uploader"
              accept={"image/*"}
              beforeUpload={(file) =>
                beforeUploadImage(file, dictionary, { min: 250, max: 3000 })
              }
              fileList={imageUrl}
              maxCount={1}
              showUploadList={{
                showPreviewIcon:
                  !(imageUrl.length && imageUrl[0].uid) ||
                  (imageUrl.length && imageUrl[0].uid.includes("__AUTO__")),
              }}
            >
              {!imageUrl.length && uploadButton}
            </Upload>
            {!imageUrl.length && (
              <div className="cover-desc">{dictionary.artistCover}</div>
            )}
          </Col>
          {!colab ?
          <React.Fragment>
            {artist.hasUser && type !== "admin" ? (
              <React.Fragment>
                <Col xs={24} sm={24} md={24} lg={24}>
                <Divider orientation="left" style={{ color: "#808080" }}>
                  <div className="subtitle-opt">
                    {dictionary.artistData}
                  </div>
                </Divider>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} className="col1">
                  <Input
                    name="email"
                    value={artist.email}
                    label={dictionary.artistEmail}
                    disabled={true}
                  />
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} className="col2">
                  <Phone
                    name="number"
                    maxLength={30}
                    value={artist.number}
                    onChange={(e) => changeHandler(e, "number")}
                    lada={artist.countryPhone}
                    onChangeSelect={(e) => selectChangeHandler(e, "code")}
                    phoneCountries={phoneCountries}
                    label={dictionary.phoneNumber}
                  />
                </Col>
                <Col xs={24} sm={24} md={24} lg={24}>
                  <Input
                    name="address"
                    value={artist.address}
                    onChange={(e) => changeHandler(e, "address")}
                    label={dictionary.address}
                    required={requiredAddress}
                    className={errors.address ? "inputErr" : ""}
                    error={dictionary[errors.address]}
                  />
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} className="col1">
                  <Input
                    name="city"
                    value={artist.city}
                    onChange={(e) => changeHandler(e, "city")}
                    label={dictionary.city}
                    required={requiredAddress}
                    className={errors.city ? "inputErr" : ""}
                    error={dictionary[errors.city]}
                  />
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} className="col2">
                  <Input
                    name="zip"
                    maxLength={10}
                    value={artist.zip}
                    onChange={(e) => changeHandler(e, "zip")}
                    label={"ZIP"}
                    required={requiredAddress}
                    className={errors.zip ? "inputErr" : ""}
                    error={dictionary[errors.zip]}
                  />
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} className="col1">
                  <Select
                    name="country"
                    label={dictionary.country}
                    showSearch={true}
                    value={artist.country}
                    options={dataCountries}
                    placeholder={dictionary.select}
                    onChange={(e) => selectChangeHandler(e, "country")}
                    style={{ width: "100%" }}
                    className={
                      (errors.country ? "countErr " : "") + " selector-general"
                    }
                    required={requiredAddress}
                    error={dictionary[errors.country]}
                  />
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} className="col2">
                  <Input
                    name="state"
                    value={artist.state}
                    onChange={(e) => changeHandler(e, "state")}
                    label={dictionary.state}
                    required={requiredAddress}
                    className={errors.state ? "inputErr" : ""}
                    error={dictionary[errors.state]}
                  />
                </Col>
              </React.Fragment>
            ) : null}
            <Col xs={24} sm={24} md={24} lg={24}>
              <DspsArtist artist={artist} />
            </Col>
          </React.Fragment>
          : null }
        </>
      );
    } else {
      return (
        <>
          <Col xs={24} sm={24} md={24} lg={24}>
            <Divider orientation="left" style={{ color: "#808080" }}>
              <div className="subtitle-opt">
                {dictionary.artist}
              </div>
            </Divider>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} className="col1">
            <Input
              name="name"
              value={artist.name}
              onChange={(e) => changeHandler(e, "name")}
              label={dictionary.artistName}
              required={true}
              className={errors.name ? "inputErr" : ""}
              error={dictionary[errors.name]}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} className="col2">
            {type === "artist" ? (
              <Select
                name="label"
                value={labelArtist?._id}
                options={[labelArtist]}
                label={dictionary.label}
                placeholder={dictionary.select}
                className={" selector-general"}
                disabled={true}
                disableOpt={true}
              />
            ) : (
              <Select
                name="label"
                required={true}
                value={artist.label_id}
                options={labels}
                label={dictionary.label}
                placeholder={dictionary.select}
                onChange={(e) => selectChangeHandler(e, "label_id")}
                error={dictionary[errors.label_id]}
                className={(errors.label_id ? "countErr " : " ")+" selector-general"}
              />
            )}
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} className="col1">
            <label>{dictionary.description}</label>
            <TextArea
              className={"text-area-general"}
              name="description"
              showCount
              maxLength={200}
              value={artist.description}
              onChange={(e) => changeHandler(e, "description")}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} className="col2">
            <label className="allWidth">{dictionary.artistPicture}</label>
            {artist?._id ? (
              artist.image ? (
                <img
                  src={artist.image}
                  alt={artist.name}
                  className="addPreviewImage"
                />
              ) : (
                <Avatar name={artist.name} size="115" round="5px" />
              )
            ) : (
              <React.Fragment>
                <Upload
                  name="avatar"
                  onChange={(e) => handleChange(e)}
                  listType="picture-card"
                  className="avatar-uploader"
                  accept={"image/*"}
                  fileList={imageUrl}
                  showUploadList={{ showPreviewIcon: false }}
                  beforeUpload={(file) =>
                    beforeUploadImage(file, dictionary, { min: 250, max: 3000 })
                  }
                  maxCount={1}
                >
                  {!imageUrl.length && uploadButton}
                </Upload>
                <div className="cover-desc">{dictionary.artistCover}</div>
              </React.Fragment>
            )}
          </Col>
          <Col xs={24} sm={24} md={24} lg={24}>
            <Divider orientation="left" style={{ color: "#808080" }}>
              <div className="subtitle-opt">{dictionary.artistAsUser} ({dictionary.optional})</div>
            </Divider>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} className="col1">
            <Input
              name="email"
              value={artist.email}
              required={requiredUser}
              onChange={(e) => changeHandler(e, "email")}
              label={dictionary.artistEmail}
              className={errors.email ? "inputErr" : ""}
              error={dictionary[errors.email]}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} className="col2">
            <Phone
              name="number"
              maxLength={30}
              value={artist.number}
              onChange={(e) => changeHandler(e, "number")}
              lada={artist.countryPhone}
              onChangeSelect={(e) => selectChangeHandler(e, "code")}
              phoneCountries={phoneCountries}
              label={dictionary.phoneNumber}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} className="col1">
            <Password
              name="password"
              style={{ background: "#F8F8F8" }}
              value={artist.password}
              required={requiredUser}
              onChange={(e) => changeHandler(e, "password")}
              label={dictionary.password}
              className={errors.password ? "inputErr" : ""}
              error={dictionary[errors.password]}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} className="col2">
            <Password
              style={{ background: "#F8F8F8" }}
              name="confirmPassword"
              value={artist.confirmPassword}
              required={requiredUser}
              onChange={(e) => changeHandler(e, "confirmPassword")}
              label={dictionary.passwordConfirm}
              className={errors.confirmPassword ? "inputErr" : ""}
              error={dictionary[errors.confirmPassword]}
            />
          </Col>
          <Col xs={24} sm={24} md={24} lg={24}>
            <Divider orientation="left" style={{ color: "#808080" }}>
              <div className="subtitle-opt">{dictionary.addAddress} ({dictionary.optional})</div>
            </Divider>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24}>
            <Input
              name="address"
              value={artist.address}
              onChange={(e) => changeHandler(e, "address")}
              label={dictionary.address}
              required={requiredAddress}
              className={errors.address ? "inputErr" : ""}
              error={dictionary[errors.address]}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} className="col1">
            <Input
              name="city"
              value={artist.city}
              onChange={(e) => changeHandler(e, "city")}
              label={dictionary.city}
              required={requiredAddress}
              className={errors.city ? "inputErr" : ""}
              error={dictionary[errors.city]}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} className="col2">
            <Input
              name="zip"
              maxLength={10}
              value={artist.zip}
              onChange={(e) => changeHandler(e, "zip")}
              label={"ZIP"}
              required={requiredAddress}
              className={errors.zip ? "inputErr" : ""}
              error={dictionary[errors.zip]}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} className="col1">
            <Select
              name="country"
              label={dictionary.country}
              showSearch={true}
              value={artist.country}
              options={dataCountries}
              placeholder={dictionary.select}
              onChange={(e) => selectChangeHandler(e, "country")}
              style={{ width: "100%" }}
              className={
                (errors.country ? "countErr " : "") + " selector-general"
              }
              required={requiredAddress}
              error={dictionary[errors.country]}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} className="col2">
            <Input
              name="state"
              value={artist.state}
              onChange={(e) => changeHandler(e, "state")}
              label={dictionary.state}
              required={requiredAddress}
              className={errors.state ? "inputErr" : ""}
              error={dictionary[errors.state]}
            />
          </Col>
          {hidedsp ? null : (
            <React.Fragment>
              <DspsArtist artist={artist} />
            </React.Fragment>
          )}
        </>
      );
    }
  };

  return (
    <Modal
      idModal="addArtistAcc"
      handleCancel={handleCancel}
      isModalVisible={isModalVisible}
      title={<div className="- modal-title-artist">{title}</div>}
      body={
        <div className="forms add-form">
          {loadArtist ? <Loader /> : null}
          <Row className="gutter-row">{typeBehavior()}</Row>
          {headArtist ? 
            <div className="head-artist">
              * {dictionary.principalAristCannotBeDeleted}
            </div>
          : null}
        </div>
      }
      footer={
        <div className="modalAdd-btns">
          <Button
            key="cancel"
            size="sm"
            className="back-btn"
            text={dictionary.cancel}
            onClick={handleCancel}
          />
          <Button
            key="submit"
            size="md"
            text={artist.update ? dictionary.update : dictionary.add}
            className="footer-btn"
            onClick={artist.update ? handleSubmitUpdate : handleSubmit}
          />
        </div>
      }
    />
  );
};

export default AddArtist;
