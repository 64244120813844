import React, { useContext, useState } from "react";
import { Checkbox } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { LanguageContext } from "../../../context/Language";
import "../../../containers/Register/register.css";
import Button from "../../Buttons/GeneralButton";
import TermsOfService from "../../TermsOfService/TermsOfService";

const Terms = ({ state, setKeys, setInput, changeSection } ) => {
  const { dictionary } = useContext(LanguageContext);
  const [error, errorTerms] = useState(null);

  const onChangeCheckbox = (e) => {
    errorTerms(null);
    setInput({
      ...state,
      terms: e.target.checked,
    });
  };

  const validate = () => {
    errorTerms(null);
    const valid = state.terms;
    if (!valid) errorTerms(dictionary.checkedError);

    return valid;
  };

  const back = () => {
    changeSection(1);
  };

  const next = async () => {
    if (validate()) {
      setKeys(state);
      changeSection(3);
    }
  };

  return (
    <div className="terms">
      <h3>{dictionary.termsAndCond}</h3>
      <div className="terms-box">
       <TermsOfService hideLogo={true} register={true}/>
      </div>
      <Checkbox
        checked={state.terms}
        className="check-terms"
        onChange={onChangeCheckbox}
      >
        {dictionary.agreeTerms}
      </Checkbox>
      {error ? <div className="error-field">{error}</div> : null}
      <div className="align-right reg-btn">
        <Button
          text={dictionary.back}
          size="md"
          icon={<ArrowLeftOutlined />}
          className="back-btn"
          onClick={back}
        />
        <Button text={dictionary.next} size="md" onClick={next} />
      </div>
    </div>
  );
};

export default Terms;
