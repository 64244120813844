import { Input, Skeleton, Typography, Tooltip, notification } from "antd";
import { WarningOutlined, CheckCircleOutlined } from "@ant-design/icons";
import React, { useContext, useEffect, useState } from "react";
import { LanguageContext } from "../../../../../context/Language";
import { useReleaseDetail } from "../../../../../hooks/release/useReleaseDetail";
import AdminCalls from "../../../../../classes/admin/admin";

const { Search } = Input;

const UPCSetter = ({ enableEdit }) => {
  const { dictionary } = useContext(LanguageContext);
  const { releaseId, releaseData, isLoading, mutateReleaseData } =
    useReleaseDetail();

  const [currentCode, setCurrentCode] = useState("");
  const [isWorking, setIsWorking] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);

  const { upc = "" } = releaseData;
  const disabled = !enableEdit;

  // to ensure the correct upc is set
  useEffect(() => {
    if (upc) setCurrentCode(upc);
  }, [upc]);

  const onlyNumbersAndSpaces = /^[0-9 ]*$/;
  const upcRegex = /^\d{12}$|^\d{13}$/;

  if (isLoading) return <Skeleton.Input active size="large"></Skeleton.Input>;

  if (disabled)
    return (
      <Typography.Title className="mb-0" level={4}>
        UPC:&nbsp;{upc ? upc : dictionary.withoutUPC}
      </Typography.Title>
    );

  const changeHandler = (e) => {
    if (onlyNumbersAndSpaces.test(e.target.value)) {
      setCurrentCode(e.target.value);
      if (success) setSuccess(false);
      if (error) setError("");
    }
  };

  const upsertUpc = (upcToSend) => {
    setIsWorking(true);
    AdminCalls.createUpc(upcToSend)
      .then((response) => {
        if (!response.error) {
          upsertReleaseUpc(upcToSend);
        } else {
          if (response.message === "alReadyUsed")
            setError(dictionary.upcDuplicatedErr);
          else setError(dictionary.errorGeneral);
        }
      })
      .catch((error) => {
        console.log(error);
        setError(dictionary.errorGeneral);
      })
      .finally(() => {
        setIsWorking(false);
      });
  };

  const upsertReleaseUpc = (upcToSend = null) => {
    setIsWorking(true);
    AdminCalls.releaseUPC(releaseId, upcToSend)
      .then((response) => {
        if (!response.error) {
          mutateReleaseData({ upc: response?.upc || upcToSend || currentCode });
          setError("");
          setSuccess(true);
          notification.success({
            key: "releaseDetailNotification",
            placement: "bottomRight",
            message: dictionary.successGeneral,
            duration: 5,
          });
        } else {
          if (response.message === "notAvailableUpc") {
            setError(dictionary.assignNewUpc);
          } else {
            setError(dictionary.errorGeneral);
          }
        }
      })
      .catch(() => {
        setError(dictionary.errorGeneral);
      })
      .finally(() => {
        setIsWorking(false);
      });
  };

  const liberateUpc = () => {
    AdminCalls.returnUpc(upc);
  };

  const redererError = () => {
    if (!error) return null;

    return (
      <Tooltip title={error}>
        <WarningOutlined />
      </Tooltip>
    );
  };

  const renderSuccess = () => {
    if (!success) return null;
    return (
      <Tooltip title={dictionary.upcSuccess}>
        <CheckCircleOutlined />
      </Tooltip>
    );
  };

  const onClick = () => {
    if (upcRegex.test(currentCode) || currentCode === "") {
      // auto assign upc, no pass the upc
      if (upc === "" && currentCode === "") {
        //console.log("autoasing");
        return upsertReleaseUpc();
      }

      // upsert and assign upc
      if (upc === "" && currentCode !== "") {
        //console.log("create and assing upc");
        return upsertUpc(currentCode);
      }

      // replace upc and liberate old one if not has an audiosalad_id
      if (upc !== "" && currentCode !== upc) {
        if (!releaseData?.audiosalad_id) liberateUpc();

        return upsertUpc(currentCode);
      }

      if (upc === currentCode) {
        return console.log("nothing");
      }
    } else setError(dictionary.upcErr3);
  };

  return (
    <Search
      status={error ? "error" : ""}
      prefix={redererError()}
      suffix={renderSuccess()}
      className="upc-input"
      addonBefore="UPC:"
      placeholder={upc ? upc : dictionary.withoutUPC}
      allowClear
      onChange={changeHandler}
      value={currentCode}
      enterButton={
        <span onClick={onClick}>
          {upc ? dictionary.update : dictionary.assign}
        </span>
      }
      loading={isWorking}
      maxLength={13}
    />
  );
};

export default UPCSetter;
