import React, { useContext, useEffect, useState } from "react";
import { ClockCircleOutlined, CalendarOutlined } from "@ant-design/icons";
import { LanguageContext } from "../../../../context/Language";
import { AppContext } from "../../../../context/App";
import { useReleaseDetail } from "../../../../hooks/release/useReleaseDetail";
import { Row, Col, Select, Card } from "antd";
import Date from "../../../../components/Form/Date";
import "./delivery.css";

const Delivery = ({ dataToUpdate, dispatch, enableEdit }) => {
  const { tracks, releaseData, isLoading } = useReleaseDetail();
  const priceTier = releaseData?.price_tier || {};

  const { dictionary } = useContext(LanguageContext);
  const { trackTiersOptions, albumTiersOptions } = useContext(AppContext);

  const [releaseTierName, setReleaseTierName] = useState(null);
  const [trackTierName, setTrackTierName] = useState(null);

  useEffect(() => {
    if (tracks.length) {
      setTrackTierName(tracks[0]?.price_tier[0]?.name || null);
    }
  }, [tracks]);

  useEffect(() => {
    if (priceTier.length) {
      setReleaseTierName(priceTier[0].name || null);
    }
  }, [priceTier]);

  return (
    <div className="rights-box">
      <Row className="cards-delivery">
        <Col sm={24} md={24} lg={12} className="col-deliv">
          <div className="title delivery">
            <h1>{dictionary.deliveryInstructions}</h1>
          </div>
          <Card loading={isLoading}>
            <div className="deliv-desc">
              {dictionary.deliveryInstructionsDesc}
            </div>
            <div className="rights-container delivery">
              <div className="right-column">
                <sub>{dictionary.dspRelDate}</sub>
                <Date
                  value={""}
                  onChange={""}
                  className="date-delivery"
                  disabled={!enableEdit.first}
                  suffixIcon={<CalendarOutlined className="orng" />}
                  placeholder={dictionary.selectDate}
                />
                <sub>{dictionary.assetLevelDate}</sub>
                <div className="asset-level-date">
                  {dictionary.notSupported}
                </div>
              </div>
              <div className="right-column">
                <sub>{dictionary.releaseTime}</sub>
                <Date
                  value={""}
                  onChange={""}
                  picker="time"
                  disabled={!enableEdit.first}
                  className="date-delivery"
                  suffixText="UTC"
                  suffixIcon={<ClockCircleOutlined className="orng" />}
                  placeholder="--:--"
                />
                <sub>{dictionary.assetLevelTerritory}</sub>
                <div className="asset-level-date">
                  {dictionary.notSupported}
                </div>
              </div>
            </div>
          </Card>
        </Col>
        <Col sm={24} md={24} lg={12} className="col-deliv">
          <div className="title delivery">
            <h1>{dictionary.pricing}</h1>
          </div>
          <Card loading={isLoading}>
            <div className="rights-container delivery">
              <div className="right-column">
                <sub>{dictionary.releasePriceTiers}</sub>
                <Select
                  className="selector-general tier"
                  defaultValue={dataToUpdate?.price_tier || releaseTierName}
                  value={dataToUpdate?.price_tier || releaseTierName}
                  options={albumTiersOptions}
                  placeholder={dictionary.select}
                  onChange={(e) => {
                    dispatch({
                      type: "stringChange",
                      payload: {
                        name: "price_tier",
                        original: releaseTierName,
                        value: e,
                      },
                    });
                  }}
                  disabled={!enableEdit}
                />
              </div>
              <div className="right-column">
                <sub>{dictionary.trackPriceTiers}</sub>
                <Select
                  className="selector-general tier"
                  defaultValue={dataToUpdate?.track_price_tier || trackTierName}
                  value={dataToUpdate?.track_price_tier || trackTierName}
                  options={trackTiersOptions}
                  placeholder={dictionary.select}
                  onChange={(e) => {
                    dispatch({
                      type: "stringChange",
                      payload: {
                        name: "track_price_tier",
                        original: trackTierName,
                        value: e,
                      },
                    });
                  }}
                  disabled={!enableEdit}
                />
              </div>
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Delivery;
