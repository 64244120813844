import React, { useContext } from "react";
import { Card } from "antd";
import { Link } from "react-router-dom";
import { LanguageContext } from "../../context/Language";

const Settings = () => {
  
  const { dictionary } = useContext(LanguageContext);

  return (
    <React.Fragment>
      <div className="container-box dashboard">
        <div className="general-card">
        <Card className="card-help">
          <h2>{dictionary.havingAnyTrouble}</h2>
          <h3>
            {dictionary.pleaseVisitOur} &nbsp;
            <Link
            to="https://help.priamdigital.com/portal/en/kb"
            target="_blank"
            className="link-help"
          >
            <div className="contact">
              {dictionary.helpCenter}
            </div>
          </Link>.
          </h3>
        </Card>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Settings;
