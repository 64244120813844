import React, { useContext, useEffect, useState } from "react";
import { LanguageContext } from "../../../../context/Language";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Row, Col, Popover } from "antd";
import Modal from "../../../Modal";
import Loader from "../../../Loader";
import Input from "../../../Form/Input";
import Select from "../../../Form/Select";
import Button from "../../../Buttons/GeneralButton";
import DSP from "../../../../classes/dsp/dsp.jsx";

const AddDSP = ({
  index,
  artist,
  allDsps,
  dspModal,
  loadSave,
  dataDsps,
  errorsDsp,
  dspCancel,
  updateDsp,
  setErrorDsp,
  setDataDsps,
  setLoadSave,
  newDspVisible,
}) => {

  const { dictionary } = useContext(LanguageContext);
  const [allDsp, setDsps] = useState([]);
  const [open, setOpen] = useState(false);
  const [infoDsp, setInfoDsp] = useState([]);
  const [description, setDescription] = useState(null);
  const [identifierError, setIdentifierError] = useState(null);

  useEffect(() => {
    getDistributors();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (index !== null) {
      setInfoDsp({ ...artist.dsp[index] });
    } else {
      setInfoDsp({
        ...infoDsp,
        dsp_id: "",
        dsp_name: "",
        dsp_identifier: "",
      });
    }
    // eslint-disable-next-line
  }, [index]);

  const getDistributors = async () => {
    try {
      const dsp = await DSP.dsps();
      if (dsp.status === 200) {
        const validDsps = ["Spotify", "Apple Music", "Amazon Music", "Vevo", "ISNI"].map(name => name.toLowerCase().replace(/\s+/g, ''));
        const filteredDsps = dsp.body.dsps.filter(d => 
        validDsps.includes(d.name.toLowerCase().replace(/\s+/g, ''))
      );
        setDsps(filteredDsps);
      }
    } catch (e) {
      setDsps([]);
    }
  };

  const validateDsp = () => {
    let data = null;
    let error = false;
    let errors = {
      dsp_id: false,
      dsp_identifier: false,
    };
    if (!infoDsp.dsp_id) {
      errors.dsp_id = dictionary.selectErr;
      error = true;
    }
    if (infoDsp.dsp_identifier.length === 0) {
      errors.dsp_identifier = dictionary.emptyField;
      error = true;
    }
    if (!error) {
      data = {
        dsp_id: infoDsp.dsp_id,
        dsp_name: infoDsp.dsp_name,
        dsp_identifier: infoDsp.dsp_identifier,
      };
    } else {
      errorsDsp.dsp_id = errors.dsp_id;
      errorsDsp.dsp_identifier = errors.dsp_identifier;
      setErrorDsp({
        ...errorsDsp,
        errorsDsp,
      });
    }
    return data;
  };

  const submitNewDsp = () => {
    let valid = validateDsp();
    if (valid) {
      setLoadSave(true);
      setDataDsps([...dataDsps, infoDsp]);

      setLoadSave(false);
      newDspVisible(false);

      setInfoDsp({
        ...infoDsp,
        dsp_id: "",
        dsp_name: "",
        dsp_identifier: "",
      });
    }
    setDescription(null);
  };

  const submitUpdateDsp = () => {
    let valid = validateDsp();
    if (valid) {
      setLoadSave(true);
      const myDsps = { ...dataDsps };
      myDsps[index].dsp_id = infoDsp.dsp_id;
      myDsps[index].dsp_identifier = infoDsp.dsp_identifier;
      myDsps[index].dsp_name = infoDsp.dsp_name;
      setLoadSave(false);
      newDspVisible(false);
    }
    setDescription(null);
  };

  const changeIdentifierCode = (e, name) => {
    const value = e.target.value;
    if (infoDsp.dsp_name === "Spotify" && !value.startsWith("spotify:artist:")) {
      setIdentifierError(dictionary.errorSpotifyFormat);
    } else if (infoDsp.dsp_name === "Apple Music" && isNaN(value)) {
      setIdentifierError(dictionary.errorAppleMusicFormat);
    } else {
      setIdentifierError(null);
    }
    setInfoDsp({
      ...infoDsp,
      [name]: value,
    });
  };

  const selectChangeDsp = (e, name) => {
    setErrorDsp({
      ...errorsDsp, ["dsp_id"]: false
    });
    const getNameDsp = e !== 0 ? allDsps.filter((element) => element._id === e)[0].name : "";
    setInfoDsp({
      ...infoDsp,
      [name]: e,
      dsp_name: getNameDsp,
    });
    let knowIfExists = (dataDsps.filter((element) => element.dsp_id === e).length > 0 
    ? true : false);
    if (knowIfExists) {
      setErrorDsp({
        ...errorsDsp,
        ["dsp_id"]: dictionary.dspErrExist,
      });
    }
    const selectedDsp = allDsps.find((element) => element._id === e);
    const getDescription = selectedDsp ? selectedDsp.description : "";
    setDescription(getDescription);
  };

  const handleOpenInfo = (newOpen) => {
    setOpen(newOpen);
  };

  const modalAddDsp = () => {
    return (
      <Modal
        idModal="addDsp"
        handleCancel={dspCancel}
        isModalVisible={dspModal}
        title={
          <div className="- modal-title-artist">{dictionary.addNewDSP}</div>
        }
        body={
          <div className="forms add-form dsp-form">
            {loadSave ? <Loader /> : null}
            <Row className="gutter-row">
              <Col xs={24} sm={24} md={12} lg={12} className="col1">
                <Select
                  name="dsp"
                  label={dictionary.dspSelect}
                  showSearch={true}
                  value={infoDsp?.dsp_id || 0}
                  options={allDsp}
                  placeholder={dictionary.select}
                  onChange={(e) => selectChangeDsp(e, "dsp_id")}
                  style={{ width: "100%" }}
                  className={
                    (errorsDsp.dsp_id ? "countErr " : "") + " selector-general"
                  }
                  required={true}
                  error={errorsDsp.dsp_id}
                  information={description}
                />
                {description && dictionary.dspDescription[description] ?
                  <Popover
                    content={dictionary.dspDescription[description]}
                    trigger="click"
                    open={open}
                    onOpenChange={handleOpenInfo}
                    overlayClassName="popover-dsp"
                    placement="topRight"
                  >
                    <InfoCircleOutlined className="dsp-info-circ" />
                  </Popover>
                : null }
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} className="col2">
                <Input
                  name="name"
                  value={infoDsp?.dsp_identifier}
                  onChange={(e) => changeIdentifierCode(e, "dsp_identifier")}
                  label={dictionary.identifier}
                  required={true}
                  className={errorsDsp.dsp_identifier ? "inputErr" : ""}
                  error={errorsDsp.dsp_identifier}
                />
                {identifierError && (
                  <div className="text-error">{identifierError}</div>
                )}
              </Col>
            </Row>
          </div>
        }
        footer={
          <div className="modalAdd-btns">
            <Button
              key="cancel"
              size="sm"
              className="back-btn"
              text={dictionary.cancel}
              onClick={dspCancel}
            />
            <Button
              key="submit"
              size="md"
              text={updateDsp ? dictionary.update : dictionary.add}
              className="footer-btn"
              onClick={updateDsp ? submitUpdateDsp : submitNewDsp}
              disabled={errorsDsp.dsp_id || identifierError ? true : false}
            />
          </div>
        }
      />
    );
  };

  return modalAddDsp();
};

export default AddDSP;
