import React from 'react';
import Logo from "../../logo.svg";
import {Link} from 'react-router-dom';
import '../TermsOfService/TermsOfService.css';

const DistrAgreement = () => {

  return (
    <div
      className={`container-box fadein sign`}
    >
      <Link to="/">
        <img src={Logo} alt="Priam" className="logo-terms" />
      </Link>
      <div className="terms-layout">
        <h2>PRIAM MEDIA INC. DISTRIBUTION AGREEMENT</h2>
        <p>
          This Distribution Agreement (this &ldquo;Agreement&rdquo;) is made available by Priam Media Inc. (&ldquo;Priam Digital,&rdquo; &ldquo;we,&rdquo; &ldquo;us,&rdquo; or &ldquo;our&rdquo;) to you (&ldquo;Artist,&rdquo; &ldquo;you,&rdquo; or &ldquo;your&rdquo;) and governs your use of our digital music distribution and related services (the &ldquo;Services&rdquo;). This Agreement is effective as of the date you accept it by clicking &ldquo;I Agree&rdquo; or by using the Services (March 1, 2025).
        </p>
        <h3>SERVICE TIERS AND PLANS</h3>
        <p>
          Priam Digital offers multiple service tiers, each with different features, revenue splits, and fees. Complete and current details about our service plans, including pricing, features, and revenue splits, are available at priamdigital.com/pricing-list.
        </p>
        <p>
          The specific plan you have selected, along with its associated terms, will be confirmed in your account dashboard after registration. Priam Digital reserves the right to modify, amend, or update the features, pricing, and terms of any plan at any time in its sole discretion. Such modifications may include, but are not limited to, changes in revenue share percentages, distribution platforms, features, and fees. Your continued use of the Services following any such changes constitutes your acceptance of the modified terms.
        </p>
        <p>
          By agreeing to this Agreement, you acknowledge that you have reviewed the plan details and pricing on our website, and you accept the terms of your selected plan, as well as Priam Digital&apos;s right to modify such terms.
        </p>
        <h3>1. DEFINITIONS</h3>
        <p>
          <b>&ldquo;Authorized Platforms&rdquo;</b> means digital streaming services, download stores, social media, user-generated content sites, social audio platforms, metaverse environments, gaming platforms, fitness applications, and any other online or offline channels or technologies now known or hereafter developed or discovered, regardless of business model, where Priam Digital distributes, exploits, or monetizes Content.
        </p>
        <p>
          <b>&ldquo;Content&rdquo;</b> means any sound recordings, audiovisual works, musical compositions, performances, associated artwork, liner notes, metadata, lyrics, remixes, edits, album artwork, photographs, music videos, and any other materials in any format that you submit to Priam Digital for distribution or administration.
        </p>
        <p>
          <b>&quot;Distribution&quot;</b> means the process of delivering, making available, promoting, monetizing, and/or exploiting your Content on Authorized Platforms through any means and in any format now known or hereafter developed.
        </p>
        <p>
          <b>&quot;Net Revenue&quot;</b> means all monies actually received by Priam Digital from Authorized Platforms directly attributable to the exploitation of the Content, less any and all deductions, including but not limited to: (i) Authorized Platform commissions and fees; (ii) payment processing fees and transaction costs; (iii) taxes and tariffs of any kind; (iv) currency conversion fees; (v) collection agency and aggregator fees; (vi) administration and operational costs reasonably allocated to your Content; (vii) advances or other payments recouped by Authorized Platforms; (viii) refunds, chargebacks, and fraudulent transaction adjustments; (ix) third-party licensing fees; (x) mechanical royalties and publishing clearances; (xi) content delivery and hosting costs; (xii) rights and royalty administration fees; (xiii) legal compliance costs; (xiv) content protection and anti-piracy measures; and (xv) any other costs or fees reasonably incurred by Priam Digital in connection with the distribution, administration, and monetization of your Content. Priam Digital shall have sole discretion to determine which costs and expenses are appropriately deducted when calculating Net Revenue.
        </p>
        <p>
          <b>&quot;Fallback Plan&quot;</b> means the revenue share arrangement that may apply upon your cancellation of a paid subscription plan, wherein you receive seventy percent (70%) of Net Revenue, and Priam Digital retains thirty percent (30%) of Net Revenue.

        </p>
        <p>
          <b>&quot;Term&quot;</b> means the period from the Effective Date until termination of this Agreement in accordance with Section 4.
        </p>
        <h3>2. GRANT OF RIGHTS</h3>
        <b>2.1 License Grant</b>
        <p>
          You hereby grant to Priam Digital a non-exclusive, worldwide, royalty-free license during the Term and thereafter as necessary to effectuate the wind-down provisions of this Agreement to:
        </p> 
        <ul className="list-ltrs">
          <li>Reproduce, store, encode, transcode, cache, record, digitize, compress, decompress, distribute, synchronize, publicly perform, publicly display, adapt, modify, edit, enhance, create derivative works, and otherwise use and exploit your Content in any and all formats and by any and all means, methods, or technologies now known or hereafter developed;</li>
          <li>Deliver your Content to Authorized Platforms for them to sell, license, distribute, exploit, or sublicense to their customers by any and all applicable digital formats and methods;</li>
          <li>Create and authorize the creation of promotional and preview clips, snippets, samples, excerpts, stills, and other derivative formats of your Content for marketing and promotional purposes;</li>
          <li>Use, reproduce, publish, and display your name, likeness, biography, photographs, voice, approved logos, trademarks, and other identifying attributes in connection with the exploitation, marketing, and promotion of your Content or the Services;</li>
          <li>Collect, administer, and receive all income derived from the exploitation of your Content;</li>
          <li>Register and claim ownership of any content identification, fingerprinting, or rights management information associated with your Content on any system or platform globally;</li>
          <li>Edit, modify, or otherwise alter your Content, including by creating clean versions, radio edits, excerpts, or promotional versions; and</li>
          <li>Sublicense any or all of these rights to Authorized Platforms, affiliated companies, service providers, and other third parties as necessary to provide the Services or otherwise exploit the rights granted herein.</li>
        </ul>
        <b>2.2 User-Generated Content Platforms</b>
        <p>
          You authorize us to grant licenses to user-generated content platforms (including but not limited to YouTube, Facebook, Instagram, TikTok, Snapchat, and any similar platforms now known or hereafter developed) to:
        </p> 
        <ul className="list-ltrs">
          <li>Allow users to synchronize, combine, remix, or otherwise incorporate your Content with visual images, video content, texts, user performances, or other content;</li>
          <li>Create, host, stream, distribute, and monetize remixes, mashups, derivative works, user-generated content, or other creative works incorporating or derived from your Content;</li>
          <li>Store, host, cache, reproduce, and make available on-demand streams, conditional downloads, or other technological means of delivery of your Content;</li>
          <li>Include your Content in their music libraries, sound collections, and other repositories for use in user videos, posts, stories, reels, or other user-generated content;</li>
          <li>Create, use, and store reference files, fingerprints, content identification information, or other technical measures to identify and manage your Content; and</li>
          <li>Display advertisements in connection with any of the foregoing and collect and retain revenue generated thereby.</li>
        </ul>
        <b>2.3 Future Technologies and Uses</b>
        <p>
          You acknowledge and agree that the rights granted in this Agreement extend to technologies, platforms, uses, and business models that do not yet exist or are unknown at the time of entering this Agreement. You grant Priam Digital all rights necessary to exploit your Content through any such future technologies, platforms, uses, or business models without additional consent or compensation. This includes, but is not limited to, artificial intelligence applications, augmented and virtual reality environments, blockchain and distributed ledger technologies, and any other technological developments or innovations that may emerge during the Term.
        </p>
        <b>2.4 Reservation of Rights and Ownership</b>
        <p>
          You retain ownership of the underlying copyright in your Content, subject to the licenses granted herein. This Agreement grants only the rights expressly set forth and necessary for Priam Digital to provide the Services and otherwise exploit the rights granted. You acknowledge that Priam Digital retains all right, title, and interest in and to the Services, including all intellectual property rights therein.
        </p>
        <b>2.5 Moral Rights Waiver</b>
        <p>
        To the fullest extent permitted by applicable law, you hereby waive and agree not to assert any and all moral rights you may have in connection with the Content, including but not limited to rights of attribution, integrity, disclosure, withdrawal, and any other rights that may be known as &quot;moral rights,&quot; &quot;artist rights,&quot; &quot;droit moral,&quot; or other similar rights, regardless of whether such rights are recognized under the law of any jurisdiction. Where such moral rights cannot be waived under applicable law, you agree not to enforce such rights against Priam Digital or its licensees without prior written consent.
        </p>
        <h3>3. YOUR RESPONSIBILITIES</h3>
        <b>3.1 Representations and Warranties</b>
        <p>
          You represent and warrant, on an ongoing basis throughout the Term, that:
        </p>
        <ul className="list-ltrs">
          <li>
            You have the full right, power, authority, and capacity to enter into this Agreement, to grant all rights granted herein, and to perform all obligations hereunder, without any third-party approvals or consents;
          </li>
          <li>
            Your Content and Priam Digital&apos;s use thereof does not and will not infringe, violate, or misappropriate any copyright, trademark, right of publicity, right of privacy, moral right, or any other intellectual property, proprietary, or other right of any third party;
          </li>
          <li>
            You have secured and will maintain all necessary licenses, permissions, releases, consents, authorizations, and clearances from all persons and entities whose names, likenesses, performances, compositions, or other contributions or materials are embodied, reproduced, or otherwise used in your Content, including but not limited to: i. All mechanical rights and synchronization rights for any compositions embodied in your Content; ii. All master use licenses and sample clearances for any third-party recordings embodied in your Content; iii. All performances and contributions by musicians, vocalists, producers, engineers, and other creative personnel; iv. All name, likeness, and biographical rights for any individuals featured in your Content; and v. All trademark and other proprietary rights for any marks, logos, or other proprietary material appearing in your Content;
          </li>
          <li>
            All metadata, credits, ownership information, and other information provided with your Content is complete, accurate, and current;
          </li>
          <li>
            You will comply with all applicable laws, regulations, and industry standards related to your Content and use of the Services, including but not limited to copyright laws, privacy laws, consumer protection laws, and regulations regarding artificial intelligence-generated content;
          </li>
          <li>
            You are at least 18 years old or the age of majority in your jurisdiction, whichever is greater;
          </li>
          <li>
            Your Content does not and will not contain any viruses, spyware, malware, or other harmful code or materials;
          </li>
          <li>
            Your Content does not and will not promote or glorify illegal activity, hate speech, violence, pornography, or discriminatory conduct;
          </li>
          <li>
            You have and will maintain all rights to enter into this Agreement throughout the Term and to perform your obligations hereunder; and
          </li>
          <li>
            You have not entered and will not enter into any agreement that would conflict with, limit, or impair Priam Digital&apos;s exercise of the rights granted under this Agreement.
          </li>
        </ul>
        <b>3.2 Content Requirements</b>
        <p>You agree to:</p>
        <ul className="list-ltrs">
          <li>
            Provide Content in the formats, resolutions, and specifications required by Priam Digital and Authorized Platforms;
          </li>
          <li>
            Provide accurate, complete, and properly formatted metadata for all Content, including all required fields as specified by Priam Digital;
          </li>
          <li>
            Ensure your Content complies with all applicable laws and regulations, industry standards, and all terms, conditions, and policies of the Authorized Platforms;
          </li>
          <li>
            Not submit Content that is defamatory, obscene, threatening, abusive, harassing, hateful, offensive, unlawful, or otherwise inappropriate;
          </li>
          <li>
            Not engage in, authorize, or enable any streaming manipulation, artificial inflation of stream counts, fraudulent activities, or other metrics manipulation through any means;
          </li>
          <li>
            Not make any false or misleading statements or representations regarding Priam Digital, the Services, or any Authorized Platform;
          </li>
          <li>
            Not interfere with or disrupt the Services or servers or networks connected to the Services;
          </li>
          <li>
            Promptly comply with any request from Priam Digital to provide documentation or other evidence demonstrating your compliance with the representations, warranties, and obligations set forth in this Agreement;
          </li>
          <li>
            Cooperate with any investigation, audit, or request for information by Priam Digital or any Authorized Platform regarding your Content, account activity, or compliance with this Agreement; and
          </li>
          <li>
            Promptly notify Priam Digital in writing if any of your representations or warranties becomes inaccurate, incomplete, or misleading.
          </li>
        </ul>
        <b>3.3 Rights Clearance and Documentation</b>
        <p>
        You are solely responsible for securing, clearing, and paying for all rights, licenses, permissions, releases, and authorizations necessary for the distribution and exploitation of your Content as contemplated by this Agreement, including but not limited to:
        </p>
        <ul className="list-ltrs">
          <li>All mechanical licenses, compulsory licenses, or other clearances for compositions (including for cover versions of songs);</li>
          <li>All sample clearances, master use licenses, and other permissions for third-party recordings;</li>
          <li>All synchronization, performance, and other music publishing rights;</li>
          <li>All payments to featured and non-featured artists, producers, engineers, vocalists, musicians, songwriters, publishers, and other creative contributors;</li>
          <li>All payments required under union, guild, or collective bargaining agreements;</li>
          <li>All name, likeness, and publicity right releases; and</li>
          <li>All trademark, copyright, and other intellectual property clearances.</li>
        </ul>
        <p>
          Upon Priam Digital&apos;s request, you agree to promptly provide written documentation of any or all such clearances, licenses, and permissions. Failure to provide such documentation within five (5) business days of request shall constitute a material breach of this Agreement.
        </p>
        <h3>4. TERM AND TERMINATION</h3>
        <b>4.1 Term</b>
        <p>
          The initial term of this Agreement is one (1) year from the Effective Date, unless earlier terminated in accordance with the provisions herein. The Agreement will automatically renew for successive one-year periods unless either party provides written notice of non-renewal at least thirty (30) days before the end of the current term.
        </p>
        <b>4.2 Monthly Subscription Flexibility</b>
        <p>
          Notwithstanding the one-year term specified in Section 4.1, if you have selected a paid subscription plan, you may cancel your subscription at any time on a monthly basis, and upon such cancellation, you may choose either of the following options:
        </p>
        <ul className="list-ltrs">
          <li>Fallback Plan Option: Convert to the Fallback Plan, wherein you receive seventy percent (70%) of Net Revenue and Priam Digital retains thirty percent (30%) of Net Revenue, with your Content remaining in distribution; or</li>
          <li>Complete Removal Option: Remove your Content completely from distribution, subject to the takedown timeframes and procedures described in Section 7.3.</li>
        </ul>
        <p>
          You may exercise either option by selecting the appropriate choice in your account dashboard or by providing written notice to support@priamdigital.com. Your selection will become effective at the end of your current billing cycle.
        </p>
        <b>4.3 Termination by You</b>
        <p>
          You may terminate this Agreement by providing written notice to Priam Digital through your account dashboard or by email to support@priamdigital.com. Your termination will be effective at the end of the current billing cycle, and no refunds will be provided for any prepaid fees. Upon termination:
        </p>
        <ul className="list-ltrs">
          <li>
            You must specify whether you wish to convert to the Fallback Plan or have your Content completely removed from distribution;
          </li>
          <li>
            If you select complete removal, Priam Digital will process the removal of your Content from Authorized Platforms, which may take up to 90 business days, depending on the policies and processing times of the Authorized Platforms;
          </li>
          <li>
            You will receive any earned but unpaid Net Revenue in accordance with the payment schedule, subject to minimum payout thresholds and other provisions of this Agreement;
          </li>
          <li>
            Your termination will not affect any rights, licenses, or permissions already granted to Authorized Platforms or their users with respect to your Content prior to removal; and
          </li>
          <li>
            Priam Digital may continue to receive and collect revenue from the exploitation of your Content that occurred during the Term, even if such revenue is received after termination.
          </li>
        </ul>
        <b>4.4 Termination by Priam Digital </b>
        <p>
          Priam Digital may terminate this Agreement:
        </p>
        <ul className="list-ltrs">
          <li>
            Immediately upon written notice if you breach any provision of this Agreement;
          </li>
          <li>
            Immediately if Priam Digital receives any claim, notice, or allegation that your Content infringes, misappropriates, or otherwise violates any third-party rights;
          </li>
          <li>
            Immediately if Priam Digital, in its reasonable business judgment, determines or suspects that you have engaged in streaming manipulation, fraud, or other prohibited activities;
          </li>
          <li>
            Immediately if you become the subject of regulatory action, criminal investigation, or other legal proceedings related to your Content or activities;
          </li>
          <li>
            Immediately if you make any disparaging, defamatory, or otherwise negative statements about Priam Digital, its affiliates, partners, or services;
          </li>
          <li>
            Immediately if required by any Authorized Platform, regulatory authority, or court order; or
          </li>
          <li>
            For any or no reason with 30 days&apos; written notice.
          </li>
        </ul>
        <b>4.5 Effect of Termination</b>
        <p>
          Upon termination of this Agreement:
        </p>
        <ul className="list-ltrs">
          <li>
            All licenses granted herein will terminate, except those that by their nature or express terms survive termination, including but not limited to: i. Licenses necessary to process final payments; ii. Licenses necessary to complete the removal of your Content from Authorized Platforms; iii. Licenses with respect to Content already acquired, downloaded, or otherwise accessed by consumers; iv. Licenses with respect to user-generated content incorporating your Content; and v. Licenses necessary for the wind-down activities described herein;
          </li>
          <li>
            Priam Digital will initiate the takedown of your Content from Authorized Platforms, subject to the timeframes, policies, and requirements of each Authorized Platform;
          </li>
          <li>
            Content already purchased, downloaded, streamed, or otherwise accessed by consumers, Authorized Platforms, or their users may remain with those parties indefinitely;
          </li>
          <li>
            User-generated content incorporating your Content that was created during the Term may continue to be hosted, streamed, and monetized by the applicable Authorized Platforms;
          </li>
          <li>
            Priam Digital will pay you any earned but unpaid Net Revenue according to the regular payment schedule and subject to the minimum payout thresholds and other provisions of this Agreement; and
          </li>
          <li>
            Any provisions of this Agreement that by their nature should survive termination shall survive, including but not limited to payment obligations, warranty provisions, indemnification obligations, limitations of liability, and dispute resolution provisions.
          </li>
        </ul>
        <b>4.6 Termination for Breach</b>
        <p>
          Notwithstanding anything to the contrary in this Agreement, if Priam Digital terminates this Agreement due to your breach or alleged breach, including but not limited to violations of third-party rights, streaming manipulation, or fraud, Priam Digital shall have the right, in its sole discretion, to:
        </p>
        <ul className="list-ltrs">
          <li>Withhold any and all amounts otherwise due to you under this Agreement;</li>
          <li>Recoup and recover from you any costs, expenses, damages, penalties, or liabilities incurred by Priam Digital as a result of your breach;</li>
          <li>Retain any and all revenue subsequently received that is attributable to the exploitation of your Content during the Term, without any payment obligation to you; and</li>
          <li>Pursue any other remedies available to it under law or equity.</li>
        </ul>
        <b>4.7 Wind-Down Period</b>
        <p>
          Following termination, there will be a wind-down period during which:
        </p>
        <ul className="list-ltrs">
          <li>Your Content may continue to appear on Authorized Platforms until removal is processed;</li>
          <li>Revenue may continue to be collected for streams, downloads, or other exploitation that occurred during the Term;</li>
          <li>User-generated content incorporating your Content may continue to be available; and</li>
          <li>Priam Digital may engage in the necessary administrative activities to properly complete all obligations to Authorized Platforms and fulfill final accounting and payment obligations to you. </li>
        </ul>
        <p>
          You acknowledge that this wind-down period is a necessary part of the distribution process, and you grant Priam Digital all rights needed to conduct these wind-down activities.
        </p>
        <h3>5. REVENUE SHARE AND PAYMENT</h3>
        <b>5.1 Revenue Share</b>
        <p>
          Priam Digital will pay you the percentage of Net Revenue specified in your selected plan, as outlined on our pricing page <a href='https://priamdigital.com/#pricing' target="_blank" rel="noreferrer">priamdigital.com/pricing-list</a> and confirmed in your account dashboard. If you cancel your paid subscription and select the Fallback Plan option, you will receive seventy percent (70%) of Net Revenue, and Priam Digital will retain thirty percent (30%) of Net Revenue. You acknowledge that Priam Digital has the right to modify these percentages upon notice to you, and your continued use of the Services after such notice constitutes acceptance of the modified terms.
        </p>
        <b>5.2 Payment Schedule and Thresholds</b>
        <p>Payments will be made according to the schedule specified in your selected plan, subject to the following conditions:
        </p>
        <ul className="list-ltrs">
          <li>You have reached the minimum payment threshold of $100 USD;</li>
          <li>You have provided all required tax documentation, payment information, and any other information requested by Priam Digital;</li>
          <li>Your account is in good standing and not subject to any investigation, hold, or review;</li>
          <li>Priam Digital has actually received the corresponding payments from the applicable Authorized Platforms; and</li>
          <li>The amounts due are not subject to any dispute, claim, or investigation regarding streaming manipulation, fraud, or other prohibited activities.</li>
        </ul>
        <p>Priam Digital reserves the right to modify payment schedules and minimum thresholds upon notice to you.</p>
        <b>5.3 Payment Methods and Processing</b>
        <p>We offer payments via direct deposit, Wire Transfer, SEPA and other methods available to Priam, subject to:</p>
        <ul className="list-ltrs">
          <li>Availability of the payment method in your jurisdiction;</li>
          <li>Your provision of accurate and complete payment information;</li>
          <li>Any applicable fees, charges, or costs imposed by the payment provider, which may be deducted from your payment; and</li>
          <li>Compliance with all applicable laws, regulations, and requirements related to payments, including but not limited to anti-money laundering laws, sanctions, and tax regulations.</li>
        </ul>
        <p>You are solely responsible for providing and maintaining accurate payment information, and Priam Digital shall not be liable for any delay, loss, or misdirection of payments due to inaccurate or incomplete payment information.</p>
        <b>5.4 Statements and Accounting</b>
        <p>We will provide statements showing your earnings through your account dashboard. Such statements will be:</p>
        <ul className="list-ltrs">
          <li>
            Provided on the schedule applicable to your selected plan;
          </li>
          <li>
            Based on the data and reporting provided to Priam Digital by the Authorized Platforms;
          </li>
          <li>
            Considered accurate and binding unless you notify Priam Digital in writing of any specific discrepancies within ninety (90) days from the date the statement is made available; and
          </li>
          <li>
            Subject to adjustment or correction if Priam Digital subsequently discovers any errors, omissions, or adjustments, including those resulting from Authorized Platform reporting corrections or streaming manipulation investigations.
          </li>
        </ul>
        <p>Priam Digital shall have no obligation to share underlying or supporting data beyond what is provided in the standard statements, except as may be required by an audit conducted in accordance with Section 5.6.</p>
        <b>5.5 Taxes and Withholding</b>
        <p>You are responsible for all applicable taxes on your earnings. To the extent required by applicable law, Priam Digital may:</p>
        <ul className="list-ltrs">
          <li>Require you to provide tax identification documentation (such as W-9, W-8BEN, or other applicable forms);</li>
          <li>Withhold taxes from your payments at the applicable rates;</li>
          <li>Report your earnings to applicable tax authorities; and</li>
          <li>Delay or withhold payments pending receipt of required tax documentation.</li>
        </ul>
        <p>You agree to indemnify and hold Priam Digital harmless from any claims, demands, penalties, or liabilities arising from your failure to properly report or pay taxes on revenue received under this Agreement.</p>
        <b>5.6 Audit Rights</b>
        <p>You may, no more than once per calendar year and at your own expense, audit Priam Digital&apos;s books and records directly relating to your Content and account, subject to the following conditions:</p>
        <ul className='list-ltrs'>
          <li>
            You must provide at least thirty (30) days&apos; written notice of your intent to audit;
          </li>
          <li>
            The audit must be conducted during normal business hours at Priam Digital&apos;s designated location by a certified public accountant who is not compensated on a contingency basis;
          </li>
          <li>
            The audit may only cover records for the twelve (12) month period immediately preceding the notice of audit;
          </li>
          <li>
            You may only access records specifically related to your Content and account, and may not access records related to other artists, content, or Priam Digital&apos;s overall business;
          </li>
          <li>
            You must execute a confidentiality agreement prior to the audit; and
          </li>
          <li>
            If the audit reveals an underpayment of less than 5% of the amounts due for the period audited, you shall bear all costs of the audit; if the underpayment exceeds 5%, Priam Digital shall reimburse your reasonable audit costs and pay the underpayment within thirty (30) days.
            </li>
        </ul>
        <b>5.7 Recoupment and Set-Off</b>
        <p>Priam Digital shall have the right to recoup, deduct, or set off from any amounts payable to you under this Agreement:</p>
        <ul className="list-ltrs">
          <li>Any overpayments previously made to you;</li>
          <li>Any amounts paid to you that are subsequently subject to refunds, chargebacks, or adjustments;</li>
          <li>Any fees, costs, or expenses incurred by Priam Digital due to your breach of this Agreement;</li>
          <li>Any amounts paid or payable by Priam Digital to third parties as a result of your Content, including mechanical royalties, settlements, judgments, or licensing fees;</li>
          <li>Any advances or other pre-payments made to you; and</li>
          <li>Any other amounts properly chargeable to you under this Agreement.</li>
        </ul>
        <b>5.8 Abandoned Payments</b>
        <p>If Priam Digital is unable to process payment to you due to inaccurate payment information, closed accounts, or your failure to claim payment, and such situation continues for twelve (12) consecutive months despite reasonable attempts by Priam Digital to contact you using the information provided in your account, any unpaid amounts may be deemed abandoned and forfeited. Priam Digital may establish reasonable procedures for you to reclaim such funds within a specified period.
        </p>
        <h3>6. CONTENT MANAGEMENT AND DISTRIBUTION</h3>
        <b>6.1 Distribution Control</b>
        <p>
          Priam Digital shall have sole discretion regarding:
        </p>
        <ul className="list-ltrs">
        <li>The specific Authorized Platforms to which your Content is distributed, based on platform availability, your selected plan, and other business factors;</li>
        <li>The timing and scheduling of delivery of your Content to Authorized Platforms;</li>
        <li>The technical format, encoding, and delivery methods used for your Content;</li>
        <li>The metadata, categorization, and organization of your Content on Authorized Platforms; and</li>
        <li>The removal or withholding of your Content from any Authorized Platform if Priam Digital determines, in its sole discretion, that such action is necessary or advisable due to legal concerns, platform requirements, quality issues, or other business considerations.</li>
        </ul>
        <b>6.2 Marketing and Promotion</b>
        <p>
        Priam Digital may promote your Content through various channels, including but not limited to social media, email marketing, blog posts, and other promotional activities. You grant Priam Digital the right to:
        </p>
        <ul className="list-ltrs">
          <li>Create, reproduce, and distribute promotional materials incorporating your Content, name, likeness, and biographical information;</li>
          <li>Modify, edit, or adapt your Content as necessary for marketing and promotional purposes;</li>
          <li>Highlight, feature, or otherwise showcase your Content as part of Priam Digital&apos;s marketing initiatives;</li>
          <li>Submit your Content for playlist consideration, editorial features, or other promotional opportunities with Authorized Platforms; and</li>
          <li>Engage in other marketing activities that Priam Digital deems appropriate in its business judgment.</li>
        </ul>
        <p>Priam Digital makes no guarantees regarding the nature, extent, effectiveness, or results of any marketing or promotional efforts. While Priam Digital may submit your Content for consideration for playlists, editorial features, or other promotional opportunities, placement decisions are made solely by the Authorized Platforms, and Priam Digital cannot and does not guarantee any specific placement.</p>
        <b>6.3 Content Identification and Rights Management</b>
        <p>Priam Digital may register your Content with content identification systems (e.g., YouTube Content ID, Facebook Rights Manager, and similar systems) to help identify, monetize, or block unauthorized uses of your Content. You authorize Priam Digital to:
        </p>
        <ul className="list-ltrs">
          <li>Create and register fingerprints, reference files, and other technical identifiers of your Content;</li>
          <li>Establish and enforce content usage policies through such systems;</li>
          <li>Collect and administer revenue from authorized uses identified through such systems;</li>
          <li>Issue takedown notices for unauthorized uses; and</li>
          <li>Otherwise manage and enforce your rights through available content identification technologies.</li>
        </ul>
        <p>The specific content identification services available to you will depend on your selected plan. Priam Digital makes no guarantees regarding the effectiveness, accuracy, or completeness of any content identification system, which are operated by third parties and subject to technological limitations.
        </p>
        <b>6.4 Quality Control</b>
        <p>
        Priam Digital reserves the right to review your Content for quality, compliance, and suitability before distribution. Priam Digital may, in its sole discretion:
        </p>
        <ul className="list-ltrs">
          <li>Reject any Content that does not meet the technical specifications or quality standards required by Priam Digital or Authorized Platforms;</li>
          <li>Reject any Content that Priam Digital reasonably believes may infringe third-party rights, violate laws or regulations, or otherwise pose legal risks;</li>
          <li>Request modifications, corrections, or additional information regarding your Content; and</li>
          <li>Remove previously distributed Content if quality or compliance issues are subsequently discovered.  </li>
        </ul>
        <h3>7. TAKEDOWNS AND CONTENT REMOVAL</h3>
        <b>7.1 Takedown Requests</b>
        <p>If you wish to remove your Content from distribution, you must submit a takedown request through your account dashboard. Takedown requests are subject to the following terms:        </p>
        <ul className="list-ltrs">
          <li>
            Priam Digital will process standard takedown requests in accordance with the timeframes specified in your selected plan, but complete removal from all Authorized Platforms may take up to 90 business days or longer, depending on the Authorized Platforms policies and processes;
          </li>
          <li>
            Some Authorized Platforms may retain certain metadata or information about your Content even after removal;
          </li>
          <li>
            Removal of your Content does not affect any licenses or rights with respect to user-generated content that incorporated your Content during the period it was available for such use;
          </li>
          <li>
            Your obligation to indemnify Priam Digital with respect to the removed Content survives takedown; and
          </li>
          <li>
            You remain responsible for all obligations accrued prior to removal.
          </li>
          </ul>
        <b>7.2  Emergency Takedowns</b>
        <p>For urgent takedown requests due to legal concerns or other critical issues, contact support@priamdigital.com with the subject line &quot;EMERGENCY TAKEDOWN REQUEST.&quot; Emergency takedowns:</p>
        <ul className='list-ltrs'>
        <li>May be processed on an expedited basis at Priam Digital&apos;s discretion, subject to the capabilities and cooperation of the relevant Authorized Platforms;</li>
        <li>May be subject to additional fees depending on your plan, the nature of the emergency, and the resources required to process the request;</li>
        <li>Require you to provide a detailed explanation of the emergency situation and any relevant documentation; and</li>
        <li>Are still subject to the ultimate control and timelines of the Authorized Platforms, which may vary significantly.</li>
        </ul>
        <b>7.3 Takedowns by Priam Digital        </b>
        <p>Priam Digital may remove your Content from distribution at any time, with or without prior notice to you, if:</p>
        <ul className="list-ltrs">
        <li>Priam Digital receives any claim, notice, or allegation that your Content infringes, misappropriates, or otherwise violates any third-party rights;</li>
        <li>Priam Digital determines or reasonably suspects that you have engaged in streaming manipulation, fraud, or other prohibited activities;</li>
        <li>Your Content or account activity violates this Agreement, the terms of any Authorized Platform, or applicable laws or regulations;</li>
        <li>Priam Digital is required to do so by any Authorized Platform, regulatory authority, or court order;</li>
        <li>Your Content fails to meet the technical specifications or quality standards required by Priam Digital or any Authorized Platform;</li>
        <li>Priam Digital determines, in its reasonable business judgment, that continued distribution of your Content poses legal, business, or reputational risks; or</li>
        <li>Your account is delinquent, suspended, or terminated for any reason.</li>
        </ul>
        <b>7.4 Takedowns by Authorized Platforms</b>
        <p>Authorized Platforms may remove or restrict access to your Content at their discretion based on their terms of service, content policies, or business considerations. Priam Digital:</p>
        <ul className='list-ltrs'>
        <li>Has no control over Authorized Platforms&apos; content policies or enforcement decisions;</li>
        <li>Cannot guarantee continued availability of your Content on any Authorized Platform;</li>
        <li>Is not responsible for any damages, losses, or lost revenue resulting from an Authorized Platform&apos;s decision to remove or restrict your Content; and</li>
        <li>Will make reasonable commercial efforts to notify you of any significant content removal by Authorized Platforms of which Priam Digital becomes aware.</li>
        </ul>
        <b>7.5 Effect of Takedowns on Payment        </b>
        <p>If your Content is removed from distribution for any reason:</p>
        <ul className="list-ltrs">
        <li>You will still receive any earned but unpaid Net Revenue for the period during which your Content was available, subject to the payment terms and conditions of this Agreement;</li>
        <li>If your Content is removed due to your breach of this Agreement, Priam Digital may withhold payments as provided in Section 4.6;</li>
        <li>If your Content is removed due to a third-party claim, Priam Digital may withhold payments related to the disputed Content pending resolution of the claim; and</li>
        <li>You will not receive any refund of subscription fees or other payments made to Priam Digital.</li>
        </ul>
        <h3>8. PROHIBITED ACTIVITIES AND COMPLIANCE</h3>
        <b>8.1 Streaming Manipulation</b>
        <p>You agree not to engage in streaming manipulation, which includes any activity that artificially inflates or manipulates stream counts, downloads, views, likes, follows, shares, or other metrics. Prohibited activities include, but are not limited to:        </p>
        <ul className="list-ltrs">
        <li>Using or engaging bots, scripts, automated programs, click farms, stream farms, paid streaming services, or other artificial means to generate plays, downloads, or engagement;</li>
        <li>Creating or using multiple accounts or profiles to artificially increase streams or engagement;</li>
        <li>Offering or providing payments, benefits, or other incentives specifically to generate streams, plays, or downloads of your Content without genuine listener interest;</li>
        <li>Repeatedly streaming your own Content without listening to substantial portions;</li>
        <li>Using stream manipulation services, regardless of whether provided by third parties;</li>
        <li>Coordinating with others to artificially inflate streaming metrics;</li>
        <li>Using VPNs, proxies, or other means to disguise the geographic origin of streams or to circumvent territorial restrictions; and</li>
        <li>Any other activity designed to artificially manipulate performance metrics rather than reflect genuine listener interest and engagement.</li>
        </ul>
        <b>8.2 Detection and Investigation of Prohibited Activities </b>
        <p>Priam Digital employs various methods to detect and investigate potential streaming manipulation and other prohibited activities. You acknowledge and agree that:</p>
        <ul className="list-ltrs">
        <li>Priam Digital has sole discretion to determine, through any means or methodology it deems appropriate, whether streaming manipulation or other prohibited activities have occurred;</li>
        <li>Evidence of streaming manipulation may include, but is not limited to: unusual streaming patterns, abnormal geographic distribution of streams, streams from suspicious IP addresses, unusual ratios of streams to unique listeners, </li>correlations with known manipulation patterns, and reports or information from Authorized Platforms;
        <li>Authorized Platforms may independently detect and act upon suspected streaming manipulation, and their determination shall be binding for purposes of this Agreement;</li>
        <li>Priam Digital may investigate suspected prohibited activities by reviewing your account data, stream patterns, engagement metrics, and any other relevant information;</li>
        <li>You will cooperate fully with any investigation, including by providing information, documentation, or explanations as requested by Priam Digital; and</li>
        <li>Priam Digital is not required to disclose the specific methodologies, tools, or evidence used to detect streaming manipulation, as such disclosure could enable circumvention of detection measures.</li>
        </ul>
        <b>8.3 Consequences of Prohibited Activities</b>
        <p>If Priam Digital determines, in its sole discretion, that you have engaged in streaming manipulation or other prohibited activities:</p>
        <ul className="list-ltrs">
        <li>Your account may be immediately suspended or terminated without prior notice;</li>
        <li>All payments associated with the affected Content may be permanently withheld;</li>
        <li>Your Content may be removed from all Authorized Platforms;</li>
        <li>Priam Digital may recoup any payments previously made to you that are attributable to prohibited activities;</li>
        <li>You may be charged for any penalties, fees, or charges imposed on Priam Digital by Authorized Platforms as a result of your activities;</li>
        <li>You may be permanently banned from using the Services; and</li>
        <li>Priam Digital may, at its discretion, report your activities to Authorized Platforms, industry organizations, or law enforcement authorities.</li>
        </ul>
        <p>For clarity, a determination of streaming manipulation may be based on patterns, data, and information related to your Content and account activity in aggregate, and Priam Digital is not required to identify specific streams or activities that it believes to be artificial or manipulated.
        </p>
        <b>8.4 </b>
        <p>You agree to comply with all policies, guidelines, and requirements of each Authorized Platform with respect to your Content, artist profiles, and related activities. You acknowledge that:</p>
        <ul>
        <li>Each Authorized Platform has its own terms of service, content guidelines, and technical requirements that may change from time to time;</li>
        <li>Violation of an Authorized Platform&apos;s policies may result in removal of your Content, suspension of your artist profile, or other penalties imposed by the Authorized Platform;</li>
        <li>Priam Digital is not responsible for Authorized Platforms&apos; policies or enforcement decisions; and</li>
        <li>Repeated or serious violations of Authorized Platform policies may constitute a breach of this Agreement.</li>
        </ul>
        <b>8.5 Compliance with Laws and Regulations        </b>
        <p>You agree to comply with all applicable laws, regulations, and industry standards related to your Content and use of the Services, including but not limited to:</p>
        <ul className="list-ltrs">
        <li>Copyright laws, including the Digital Millennium Copyright Act and similar laws in other jurisdictions;</li>
        <li>Privacy laws and regulations, including those related to consent for use of personal information;</li>
        <li>Consumer protection laws and regulations;</li>
        <li>Laws regarding defamation, obscenity, and illegal content;</li>
        <li>Advertising and marketing regulations;</li>
        <li>Tax laws and regulations; and</li>
        <li>Export control laws and sanctions.</li>
        </ul>
        <p>Failure to comply with applicable laws and regulations constitutes a material breach of this Agreement.
        </p>
        <h3>9. INTELLECTUAL PROPERTY PROTECTION</h3>
        <b>9.1 Content Protection</b>
        <p>Priam Digital may employ various measures to protect your Content from unauthorized use, subject to the features available in your selected plan. These measures may include:
        </p>
        <ul className="list-ltrs">
        <li>Registration with content identification systems;</li>
        <li>Digital fingerprinting and watermarking;</li>
        <li>Automated monitoring for unauthorized uses;</li>
        <li>DMCA and other takedown notices for infringing uses;</li>
        <li>Participation in industry anti-piracy initiatives; and</li>
        <li>Other technological or legal measures as Priam Digital deems appropriate.</li>
        </ul>
        <b>9.2 You acknowledge that no content protection measures can be 100% effective, and Priam Digital makes no guarantees regarding the prevention of all unauthorized uses of your Content.</b>
        <p>If you believe that your copyrighted work has been infringed by a third party through the Services, please submit a notice of infringement to:</p>
        <p>
        Priam Media Inc.<br/>
        Attn: Copyright Agent <br/>
        440 N Barranca Ave #9552 <br/>
        Covina, CA 91723 <br/>
        Email: DMCA@priamdigital.com
        </p>
        Your notice must include:
        <ul className="list-ltrs">
        <li>A physical or electronic signature of the copyright owner or a person authorized to act on their behalf;</li>
        <li>Identification of the copyrighted work claimed to have been infringed;</li>
        <li>Identification of the material that is claimed to be infringing and information reasonably sufficient to permit us to locate the material;</li>
        <li>Your contact information, including your address, telephone number, and email address;</li>
        <li>A statement that you have a good faith belief that use of the material in the manner complained of is not authorized by the copyright owner, its agent, or the law; and</li>
        <li>A statement that the information in the notification is accurate, and, under penalty of perjury, that you are authorized to act on behalf of the copyright owner.</li>
        </ul>
        <p>Upon receipt of a properly formulated notice, Priam Digital will take appropriate action in accordance with the Digital Millennium Copyright Act and other applicable laws.
        </p>
        <b>9.3 Repeat Infringer Policy</b>
        <p>Priam Digital maintains a policy of terminating, in appropriate circumstances, the accounts of users who are deemed to be repeat infringers. A repeat infringer is a user who has been notified of infringing activity more than twice and/or has had Content removed from the Services more than twice.        </p>
        <h3>10. LIMITATION OF LIABILITY</h3>
        <b>10.1 Disclaimer of Warranties</b>
        <p>EXCEPT AS EXPRESSLY SET FORTH IN THIS AGREEMENT, THE SERVICES ARE PROVIDED &quot;AS IS&quot; AND &quot;AS AVAILABLE&quot; WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. TO THE FULLEST EXTENT PERMISSIBLE UNDER APPLICABLE LAW, PRIAM DIGITAL DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND NON-INFRINGEMENT.</p>
        <p>WITHOUT LIMITING THE FOREGOING, PRIAM DIGITAL DOES NOT WARRANT THAT:</p>
        <ul className="list-ltrs">
        <li>THE SERVICES WILL MEET YOUR REQUIREMENTS OR EXPECTATIONS;</li>
        <li>THE SERVICES WILL BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE;</li>
        <li>THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE SERVICES WILL BE ACCURATE, RELIABLE, OR MEET YOUR EXPECTATIONS;</li>
        <li>ANY ERRORS IN THE SERVICES WILL BE CORRECTED;</li>
        <li>YOUR CONTENT WILL BE DISTRIBUTED TO OR ACCEPTED BY ANY PARTICULAR AUTHORIZED PLATFORM;</li>
        <li>YOUR CONTENT WILL ACHIEVE ANY PARTICULAR LEVEL OF STREAMS, DOWNLOADS, SALES, OR ENGAGEMENT;</li>
        <li>CONTENT IDENTIFICATION OR ANTI-PIRACY MEASURES WILL PREVENT ALL UNAUTHORIZED USES; OR</li>
        <li>THE SERVICES WILL OPERATE COMPATIBLY WITH ALL HARDWARE, SOFTWARE, OR INTERNET CONNECTIONS.</li>
        </ul>
        <p>SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN WARRANTIES OR LIMITATIONS ON HOW LONG AN IMPLIED WARRANTY LASTS, SO SOME OR ALL OF THE ABOVE EXCLUSIONS AND LIMITATIONS MAY NOT APPLY TO YOU.</p>
        <b>10.2 Limitation of Liability</b>
        <p>TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL PRIAM DIGITAL, ITS AFFILIATES, DIRECTORS, OFFICERS, EMPLOYEES, AGENTS, PARTNERS, LICENSORS, OR SUPPLIERS BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, EXEMPLARY, OR PUNITIVE DAMAGES WHATSOEVER, INCLUDING WITHOUT LIMITATION DAMAGES FOR LOSS OF PROFITS, LOSS OF DATA, BUSINESS INTERRUPTION, LOSS OF BUSINESS OPPORTUNITY, LOSS OF GOODWILL, OR ANY OTHER INTANGIBLE LOSSES ARISING OUT OF OR RELATED TO:</p>
        <ul className="list-ltrs">
        <li>YOUR ACCESS TO OR USE OF, OR INABILITY TO ACCESS OR USE, THE SERVICES;</li>
        <li>ANY CONDUCT OR CONTENT OF ANY THIRD PARTY ON THE SERVICES, INCLUDING WITHOUT LIMITATION ANY DEFAMATORY, OFFENSIVE, OR ILLEGAL CONDUCT OF OTHER USERS OR THIRD PARTIES;</li>
        <li>ANY CONTENT OBTAINED FROM THE SERVICES;</li>
        <li>UNAUTHORIZED ACCESS, USE, OR ALTERATION OF YOUR CONTENT OR TRANSMISSIONS;</li>
        <li>THE DELETION OF, CORRUPTION OF, OR FAILURE TO STORE OR DISTRIBUTE ANY CONTENT MAINTAINED OR TRANSMITTED THROUGH THE SERVICES;</li>
        <li>ERRORS, MISTAKES, OR INACCURACIES IN YOUR CONTENT;</li>
        <li>PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF THE SERVICES;</li>
        <li>ANY UNAUTHORIZED ACCESS TO OR USE OF PRIAM DIGITAL&apos;S SERVERS OR ANY PERSONAL OR FINANCIAL INFORMATION STORED THEREIN;</li>
        <li>ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE SERVICES;</li>
        <li>ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE THAT MAY BE TRANSMITTED TO OR THROUGH THE SERVICES BY ANY THIRD PARTY; OR</li>
        <li>ANY ERRORS OR OMISSIONS IN ANY CONTENT OR FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF ANY CONTENT POSTED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE SERVICES.</li>
        </ul>
        <b>10.3 Cap on Liability        </b>
        <p>NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, THE TOTAL AGGREGATE LIABILITY OF PRIAM DIGITAL AND ITS AFFILIATES, DIRECTORS, OFFICERS, EMPLOYEES, AGENTS, PARTNERS, LICENSORS, AND SUPPLIERS ARISING FROM OR RELATED TO THIS AGREEMENT OR YOUR USE OF THE SERVICES SHALL BE LIMITED TO THE GREATER OF: (A) THE TOTAL AMOUNT PAID BY YOU TO PRIAM DIGITAL DURING THE SIX (6) MONTHS IMMEDIATELY PRECEDING THE EVENT GIVING RISE TO THE CLAIM; OR (B) $100 USD.
        </p>
        <p>THE FOREGOING LIMITATIONS OF LIABILITY SHALL APPLY WHETHER THE ALLEGED LIABILITY OR LOSSES ARE BASED ON CONTRACT, NEGLIGENCE, TORT, STRICT LIABILITY, OR ANY OTHER BASIS, EVEN IF PRIAM DIGITAL HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGE.</p>
        <p>SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU. IN SUCH CASES, PRIAM DIGITAL&apos;S LIABILITY WILL BE LIMITED TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW.        </p>
        <b>10.4 Essential Basis of the Agreement</b>
        <p>THE LIMITATIONS OF DAMAGES SET FORTH ABOVE ARE FUNDAMENTAL ELEMENTS OF THE BASIS OF THE BARGAIN BETWEEN PRIAM DIGITAL AND YOU. YOU ACKNOWLEDGE AND AGREE THAT PRIAM DIGITAL WOULD NOT BE ABLE TO PROVIDE THE SERVICES ON AN ECONOMICALLY FEASIBLE BASIS WITHOUT THESE LIMITATIONS.</p>
        <b>10.5 Exclusions</b>
        <p>NOTHING IN THIS AGREEMENT SHALL LIMIT OR EXCLUDE OUR LIABILITY FOR:</p>
        <ul className="list-ltrs">
          <li>DEATH OR PERSONAL INJURY RESULTING FROM OUR NEGLIGENCE;</li>
          <li>FRAUD OR FRAUDULENT MISREPRESENTATION; OR</li>
          <li>ANY OTHER LIABILITY THAT CANNOT BE EXCLUDED OR LIMITED BY APPLICABLE LAW.</li>
        </ul>
        <h3>11. INDEMNIFICATION</h3>
        <b>11.1 Your Indemnification Obligations</b>
        <p>You agree to defend, indemnify, and hold harmless Priam Digital and its affiliates, subsidiaries, licensors, and service providers, and its and their respective officers, directors, employees, contractors, agents, licensors, suppliers, successors, and assigns from and against any and all claims, liabilities, damages, judgments, awards, losses, costs, expenses, fines, penalties, or fees (including reasonable attorneys&apos; fees and investigation costs) arising out of or relating to:</p>
        <ul className='list-ltrs'>
        <li>Your breach of this Agreement, including any representations, warranties, or covenants;</li>
        <li>Your Content or Priam Digital&apos;s use thereof in accordance with this Agreement;</li>
        <li>Your violation or alleged violation of any third-party right, including without limitation any intellectual property right, publicity, confidentiality, property, or privacy right;</li>
        <li>Your violation or alleged violation of any laws, rules, regulations, codes, statutes, ordinances, or orders of any governmental or quasi-governmental authorities;</li>
        <li>Any misrepresentation made by you;</li>
        <li>Any streaming manipulation, fraud, or other prohibited activities engaged in by you or through your account;</li>
        <li>Any claim by any artist, producer, mixer, composer, publisher, performer, or other third party that provided services or content in connection with your Content; or</li>
        <li>Any other activities in connection with your use of the Services.</li>
        </ul>
        <b>11.2 Defense and Control</b>
        <p>Priam Digital reserves the right, at your expense, to assume the exclusive defense and control of any matter for which you are required to indemnify us, and you agree to cooperate with our defense of these claims. You agree not to settle any matter without the prior written consent of Priam Digital. Priam Digital will use reasonable efforts to notify you of any such claim, action, or proceeding upon becoming aware of it.        </p>
        <b>11.3 Pre-Litigation Mediation</b>
        <p>Prior to initiating any legal action against Priam Digital arising out of or relating to this Agreement or the Services, you agree to first engage in a good faith effort to resolve the dispute through mediation administered by a mediator mutually agreed upon by both parties. If the parties cannot reach agreement on a mediator within fifteen (15) days of written notice of the dispute, the mediation shall be administered by JAMS under its then-current mediation rules. The mediation shall take place in Delaware, or via video conference if agreed by the parties. The costs of mediation shall be borne equally by both parties. You acknowledge and agree that this pre-litigation mediation requirement is a condition precedent to initiating litigation or arbitration proceedings</p>
        <b>11.4 Legal Fees</b>
        <p>In any dispute arising out of or relating to this Agreement or the Services, the prevailing party shall be entitled to recover its reasonable attorneys&apos; fees, costs, and expenses from the non-prevailing party, in addition to any other relief to which the prevailing party may be entitled.</p>
        <h3>12. DISPUTE RESOLUTION</h3>
        <b>12.1 Governing Law</b>
        <p>This Agreement and any dispute or claim (including non-contractual disputes or claims) arising out of or in connection with it or its subject matter or formation shall be governed by and construed in accordance with the laws of the State of Delaware, without giving effect to any choice or conflict of law provision or rule that would require or permit the application of the laws of any other jurisdiction.</p>
        <b>12.2 Agreement to Arbitrate</b>
        <p>Except for disputes expressly excluded below, you and Priam Digital agree that any dispute, claim, or controversy arising out of or relating to this Agreement or the Services, including the determination of the scope or applicability of this agreement to arbitrate, shall be determined by binding arbitration in Wilmington, Delaware before one arbitrator.</p>
        <p>THE PARTIES AGREE THAT DISPUTES RESOLVED UNDER THIS ARBITRATION PROVISION WILL BE DECIDED BY A NEUTRAL ARBITRATOR AND NOT A JUDGE OR JURY. YOU AND PRIAM DIGITAL WAIVE THE RIGHT TO TRIAL BY A JURY AND TO PARTICIPATE IN A CLASS ACTION OR SIMILAR PROCEEDING.</p>
        <p>The arbitration shall be administered by JAMS pursuant to its Comprehensive Arbitration Rules and Procedures then in effect. The arbitrator shall issue a written decision setting forth the essential findings and conclusions on which the award is based. The arbitrator shall have the power to rule on any challenge to its own jurisdiction or to the validity or enforceability of any portion of this agreement to arbitrate.</p>
        <b>12.3 Arbitration Procedures</b>
        <p>The arbitration process shall include the following specifications:</p>
        <ul className="list-ltrs">
        <li>The arbitrator shall have experience in digital music distribution, intellectual property, or entertainment law;</li>
        <li>The arbitration shall be conducted in English;</li>
        <li>The arbitration shall be conducted on an expedited basis with minimal discovery;</li>
        <li>At the request of either party, the arbitrator shall issue a written opinion explaining the essential findings and conclusions on which the award is based;</li>
        <li>The arbitrator may award declaratory or injunctive relief only in favor of the individual party seeking relief and only to the extent necessary to provide relief warranted by that party&apos;s individual claim;</li>
        <li>The arbitrator shall follow the law and shall not have the power to change or ignore any terms of this Agreement;</li>
        <li>The decision of the arbitrator shall be final and binding on both parties, subject to review only on grounds explicitly set forth in the Federal Arbitration Act; and</li>
        <li>Judgment on the award rendered by the arbitrator may be entered in any court having jurisdiction thereof.</li>
        </ul>
        <b>12.4 Class Action Waiver</b>
        <p>YOU AND PRIAM DIGITAL AGREE THAT EACH MAY BRING CLAIMS AGAINST THE OTHER ONLY IN YOUR OR ITS INDIVIDUAL CAPACITY AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS, CONSOLIDATED, OR REPRESENTATIVE PROCEEDING. Further, unless both you and Priam Digital agree otherwise in writing, the arbitrator may not consolidate more than one person&apos;s claims with your claims and may not otherwise preside over any form of a representative or class proceeding.
        If this specific provision is found to be unenforceable, then the entirety of this arbitration provision shall be null and void, and all claims shall be decided by a court.</p>
        <b>12.5 Exceptions to Arbitration</b>
        <p>Notwithstanding the foregoing, either party may bring an individual action in small claims court for disputes or claims within the scope of that court&apos;s jurisdiction. In addition, either party may seek injunctive or other equitable relief in a court of competent jurisdiction in Delaware for disputes relating to:</p>
        <ul className="list-ltrs">
        <li>The infringement or unauthorized use of intellectual property rights, including copyrights, trademarks, trade secrets, or patents;</li>
        <li>Confidentiality obligations;</li>
        <li>The enforcement of the class action waiver in Section 12.4; or</li>
        <li>To prevent imminent and irreparable harm for which monetary damages would be an inadequate remedy.</li>
        </ul>
        <p>The pursuit of equitable relief under this Section 12.5 shall not be deemed a waiver of the right or obligation of either party to submit any other claim to arbitration.</p>
        <b>12.6 Arbitration Opt-Out</b>
        <p>You have the right to opt out of this arbitration provision by notifying Priam Digital in writing within thirty (30) days of the Effective Date of this Agreement. To opt out, you must send a written notification to legal@priamdigital.com that includes: (a) your name, (b) your email address, and (c) a clear statement that you do not wish to resolve disputes through arbitration. If you opt out of arbitration, this entire Section 12 will not apply to you, except for Sections 12.1 and 12.5, and all disputes will be resolved exclusively in the state or federal courts located in Delaware, and you consent to the personal jurisdiction of those courts.</p>
        <b>12.7 Changes to Dispute Resolution Provision</b>
        <p>Notwithstanding anything to the contrary in this Agreement, if Priam Digital makes any future change to this dispute resolution provision (other than a change to the notice address), you may reject any such change by sending us written notice within 30 days of the change to legal@priamdigital.com. By rejecting any future change, you are agreeing that you will arbitrate any dispute between us in accordance with the language of this provision as of the Effective Date.</p>
        <b>12.8 Integration with Other Terms</b>
        <p>This Section 12 shall supersede any conflicting arbitration or dispute resolution provisions contained in any other agreement between you and Priam Digital relating to the Services.</p>
        <h3>13. MISCELLANEOUS </h3>
        <b>13.1 Entire Agreement</b>
        <p>This Agreement, together with any other legal notices, policies, or guidelines published by Priam Digital on the Services (including the Privacy Policy), constitutes the entire agreement between you and Priam Digital regarding the Services and supersedes all prior and contemporaneous agreements, proposals, or representations, written or oral, concerning its subject matter.</p>
        <p>No statement, information, or representation not expressly contained in this Agreement or incorporated herein by reference shall be binding upon Priam Digital as a warranty or otherwise. No agent, employee, or representative of Priam Digital has any authority to bind Priam Digital to any affirmation, representation, or warranty concerning the Services beyond those expressly included in this Agreement.</p>
        <b>13.2 Integration</b>
        <p>This Agreement is a fully integrated agreement that supersedes any oral or written proposals, prior agreements, understandings, representations, conditions, warranties, covenants, or other communications between the parties relating to the subject matter of this Agreement.</p>
        <b>13.3 Amendments</b>
        <p>Priam Digital may modify this Agreement from time to time. All changes will be effective upon posting on the Services or otherwise providing notice to you. You will be deemed to have accepted the changes if you continue to use the Services after the changes are posted or notice is provided. If you do not agree to the modified Agreement, your exclusive remedy is to stop using the Services and terminate your account.</p>
        <p>Material changes to your payment rights or obligations will not be applied retroactively without your express consent and will become effective no sooner than 14 days after posting or notification unless we have legal or security reasons to implement the changes sooner.</p>
        <b>13.4 Assignment</b>
        <p>You may not assign, transfer, or sublicense this Agreement or any rights or obligations hereunder, by operation of law or otherwise, without Priam Digital&apos;s prior written consent, and any attempt to do so without such consent shall be void.</p>
        <p>Priam Digital may assign, transfer, or sublicense any or all of its rights or obligations under this Agreement to any affiliate, successor entity, or any other entity that acquires all or substantially all of Priam Digital&apos;s assets or business, without restriction or notification to you. Subject to the foregoing, this Agreement will bind and inure to the benefit of the parties, their successors and permitted assigns.</p>
        <b>13.5 Severability</b>
        <p>If any provision of this Agreement is found to be unenforceable or invalid under applicable law, such provision shall be modified and interpreted to accomplish the objectives of such provision to the greatest extent possible under applicable law, and the remaining provisions shall continue in full force and effect.</p>
        <b>13.6 No Waiver</b>
        <p>The failure of Priam Digital to exercise or enforce any right or provision of this Agreement shall not operate as a waiver of such right or provision. No waiver of any term of this Agreement shall be deemed a further or continuing waiver of such term or any other term, and Priam Digital&apos;s failure to assert any right or provision under this Agreement shall not constitute a waiver of such right or provision.</p>
        <b>13.7 Force Majeure</b>
        <p>Priam Digital shall not be liable for any failure or delay in performance of its obligations under this Agreement where such failure or delay results from causes beyond its reasonable control, including but not limited to: acts of God; natural disasters; terrorism; war; civil unrest; actions or decrees of governmental bodies; changes in applicable laws; failure of the Internet; strikes; labor disputes; riots; shortages of supplies; failures of suppliers, third-party providers or telecommunications providers; denial of service attacks; fire; floods; storms; earthquakes; or other force majeure events.</p>
        <b>13.8  Relationship of the Parties</b>
        <p>This Agreement does not create a partnership, franchise, joint venture, agency, fiduciary, or employment relationship between the parties. Neither party has the authority to bind the other party or to incur any obligation on behalf of the other party.</p>
        <b>13.9 Notices</b>
        <p>All notices to Priam Digital required or permitted under this Agreement must be in writing and shall be sent to:</p>
        <p>
        Priam Media Inc. <br/>
        Attn: Legal Department<br/> 
        440 N Barranca Ave #9552<br/>
        Covina, CA 91723<br/>
        Email: legal@priamdigital.com
        </p>
        <p>All notices to you will be sent to the email address associated with your account or posted on the Services. It is your responsibility to keep your email address and other contact information current. Notice shall be deemed given 24 hours after the email is sent or posted on the Services, unless the sending party is notified that the email address is invalid.</p>
        <b>13.10 No Third-Party Beneficiaries</b>
        <p>This Agreement does not confer any rights, remedies, or benefits upon any person other than you and Priam Digital, except that Priam Digital&apos;s affiliates, licensors, and service providers are third-party beneficiaries of this Agreement.</p>
        <b>13.11 Electronic Communications</b>
        <p>By using the Services, you consent to receiving electronic communications from Priam Digital. These communications may include notices about your account, transactional information, and other information concerning or related to the Services. You agree that any notices, agreements, disclosures, or other communications that we send to you electronically will satisfy any legal communication requirements, including that such communications be in writing.</p>
        <b>13.12 Export Control</b>
        <p>You may not use, export, re-export, import, or transfer the Services except as authorized by U.S. law, the laws of the jurisdiction in which you obtained the Services, and any other applicable laws. In particular, but without limitation, the Services may not be exported or re-exported: (a) into any United States embargoed countries; or (b) to anyone on the U.S. Treasury Department&apos;s list of Specially Designated Nationals or the U.S. Department of Commerce&apos;s Denied Person&apos;s List or Entity List. By using the Services, you represent and warrant that (i) you are not located in a country that is subject to a U.S. Government embargo, or that has been designated by the U.S. Government as a &quot;terrorist supporting&quot; country and (ii) you are not listed on any U.S. Government list of prohibited or restricted parties.</p>
        <b>13.13 Non-Disparagement</b>
        <p>During the Term and for a period of two (2) years thereafter, you agree not to make any statements, written or oral, or cause or encourage others to make any statements, written or oral, that defame, disparage, or in any way criticize the personal or business reputation, practices, or conduct of Priam Digital, its officers, directors, employees, or agents. You acknowledge and agree that this prohibition extends to statements, written or oral, made to anyone, including but not limited to the news media, social media, industry analysts, competitors, strategic partners, vendors, and employees or former employees of Priam Digital.</p>
        <b>13.14 Contact Information</b>
        <p>
          Priam Media Inc.<br/>
          440 N Barranca Ave #9552<br/>
          Covina, CA 91723<br/>
          Email: support@priamdigital.com<br/>
        </p>
        By clicking &quot;I Agree,&quot; creating an account, or using the Services, you acknowledge that you have read, understood, and agree to be bound by this Agreement.
    </div>
  </div>
  );
};

export default DistrAgreement; 