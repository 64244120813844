import React, { useContext, useState, useEffect, useRef } from "react";
import { LanguageContext } from "../../../context/Language";
import "../progress.css";

const CreateBar = ({
  orangeBar,
  validation,
  nextSection,
  changeSection,
  validStep,
  canMove,
  visibility
}) => {
  const { dictionary } = useContext(LanguageContext);
  const [isExpanded, setIsExpanded] = useState(false);
  
  // Use ref to store the current section to avoid race conditions
  const currentSectionRef = useRef(0);
  
  // We'll track an independent state for UI rendering only
  const [displaySection, setDisplaySection] = useState(0);

  const steps = [
    { id: 0, nameStep: dictionary.start },
    { id: 1, nameStep: dictionary.releaseInfo },
    { id: 2, nameStep: dictionary.rights },
    { id: 3, nameStep: dictionary.participants },
    { id: 4, nameStep: dictionary.tracks },
    { id: 5, nameStep: dictionary.territories },
    { id: 6, nameStep: dictionary.distribution },
    { id: 7, nameStep: dictionary.review },
  ];

  // This effect runs only once to initialize our step tracking
  useEffect(() => {
    // Check if we need to start at a different step based on validation
    for (let i = steps.length - 1; i >= 0; i--) {
      if (validation[`step${i}`]) {
        const nextStep = Math.min(i + 1, steps.length - 1);
        currentSectionRef.current = nextStep;
        setDisplaySection(nextStep);
        break;
      }
    }
  }, []);

  // Directly observe the orangeBar's class to track the current section
  // This is a reliable way to stay in sync with the parent component
  useEffect(() => {
    const detectSectionFromClasses = (className) => {
      for (let i = steps.length - 1; i >= 0; i--) {
        if (className.includes(`stepFilled${i}`)) {
          return i;
        }
      }
      return 0;
    };

    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
          const newSection = detectSectionFromClasses(mutation.target.className);
          if (newSection !== currentSectionRef.current) {
            currentSectionRef.current = newSection;
            setDisplaySection(newSection);
          }
        }
      });
    });

    if (orangeBar?.current) {
      // Initialize display section based on current orangeBar state
      setDisplaySection(detectSectionFromClasses(orangeBar.current.className));
      
      // Start observing
      observer.observe(orangeBar.current, { 
        attributes: true,
        attributeFilter: ['class'] 
      });
    }

    return () => observer.disconnect();
  }, [orangeBar, steps.length]);

  const next = async (n) => {
    if (canMove) {
      if (n === 0 || validation["step" + (n - 1)] || (await validStep())) {
        // Update our tracking ref immediately to prevent race conditions
        currentSectionRef.current = n;
        setDisplaySection(n);
        
        // Notify parent component
        changeSection(n);
        
        // Update visual elements
        for (let k = 0; k <= 7; k++) {
          if (k > n) {
            orangeBar.current.classList.remove("stepFilled" + k);
            document.getElementById("bar" + k).classList.remove("fillStep");
          } else {
            nextSection(k);
            orangeBar.current.classList.add("stepFilled" + k);
          }
        }
        
        // Close the menu after selection on mobile
        setIsExpanded(false);
      }
    }
  };

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  // Handle keyboard navigation
  const handleKeyDown = (event, stepId) => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      next(stepId);
    } else if (event.key === 'ArrowRight') {
      event.preventDefault();
      const nextStepId = Math.min(displaySection + 1, steps.length - 1);
      if (canMove && (validation[`step${nextStepId - 1}`] || nextStepId === 0)) {
        next(nextStepId);
      }
    } else if (event.key === 'ArrowLeft') {
      event.preventDefault();
      const prevStepId = Math.max(displaySection - 1, 0);
      if (canMove) {
        next(prevStepId);
      }
    }
  };

  // Render the mini step indicators that are always visible
  const renderMiniStepIndicators = () => {
    return (
      <div className="mini-stepper">
        {steps.map((step, index) => {
          const isCompleted = validation[`step${step.id}`];
          const isActive = displaySection === step.id;
          // Can only navigate to completed steps or the next available step
          const canNavigateToStep = canMove && (isCompleted || step.id === 0 || validation[`step${step.id - 1}`]) && step.id !== 7;
          
          return (
            <div 
              key={`mini-step-${index}`}
              className={`mini-step ${isCompleted ? 'completed' : ''} ${isActive ? 'active' : ''}`}
              onClick={canNavigateToStep ? () => next(step.id) : null}
              title={`${step.nameStep}${!canNavigateToStep && step.id !== 7 ? ' (complete previous steps first)' : ''}`}
              disabled={!canNavigateToStep}
              role="button"
              tabIndex={canNavigateToStep ? 0 : -1}
              aria-label={`Step ${step.id + 1}: ${step.nameStep} ${isCompleted ? "(completed)" : isActive ? "(current)" : ""}`}
              onKeyDown={(e) => canNavigateToStep ? handleKeyDown(e, step.id) : null}
            >
              <span className="mini-step-number">
                <span className="number">{step.id + 1}</span>
                {isCompleted && (
                  <svg className="mini-status-icon" width="10" height="10" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z" fill="#ffffff" />
                  </svg>
                )}
                {isActive && !isCompleted && <span className="mini-status-active"></span>}
              </span>
            </div>
          );
        })}
        <div className="mini-progress-bar">
          <div 
            className="mini-progress-fill" 
            style={{width: `${((displaySection + 1) / steps.length) * 100}%`}}
          ></div>
        </div>
      </div>
    );
  };

  const renderMobileStepIndicator = () => {
    const currentStep = displaySection;
    const totalSteps = steps.length;
    const currentStepName = steps[currentStep].nameStep;
    
    return (
      <div className="stepper-mobile-indicator">
        <div 
          className="stepper-header" 
          onClick={toggleExpand}
          role="button"
          tabIndex={0}
          aria-expanded={isExpanded}
          aria-label="Toggle step menu"
          onKeyDown={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              e.preventDefault();
              toggleExpand();
            }
          }}
        >
          <span className="current-step">{currentStepName}</span>
          <span className="step-counter">{currentStep+1}/{totalSteps}</span>
          {/* <span className={`expand-icon ${isExpanded ? 'expanded' : ''}`}>▼</span> */}
        </div>
        {!isExpanded && renderMiniStepIndicators()}
      </div>
    );
  };

  const renderStepStatus = (isCompleted, isActive) => {
    if (isCompleted) {
      return (
        <svg className="step-status-icon" width="12" height="12" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z" fill="#ffffff" />
        </svg>
      );
    } else if (isActive) {
      return <span className="step-status-active"></span>;
    }
    return null;
  };

  const renderBar = () => {
    return steps.map((item, index) => {
      const isCompleted = validation[`step${item.id}`];
      const isActive = displaySection === item.id;
      const isPending = !isCompleted && !isActive;
      // Can only navigate to completed steps or the next available step
      const canNavigateToStep = canMove && (isCompleted || item.id === 0 || validation[`step${item.id - 1}`]) && item.id !== 7;
      
      let classPosition = item.id === 0 ? "left fillStep " : item.id === 7 ? "right " : "";
      let stateClass = isCompleted ? "completed " : isActive ? "active " : isPending ? "pending " : "";

      return (
        <div
          key={index}
          id={"bar" + item.id}
          onClick={canNavigateToStep ? () => next(item.id) : null}
          className={`${classPosition}${stateClass}bar`}
          aria-current={isActive ? "step" : null}
          aria-label={`Step ${item.id + 1}: ${item.nameStep} ${isCompleted ? "(completed)" : isActive ? "(current step)" : ""}`}
          role={canNavigateToStep ? "button" : "presentation"}
          tabIndex={canNavigateToStep ? 0 : -1}
          onKeyDown={(e) => canNavigateToStep ? handleKeyDown(e, item.id) : null}
          disabled={!canNavigateToStep}
        >
          <div className="step-content">
            <span className="step-number">
              <span className="number">{item.id + 1}</span>
              {renderStepStatus(isCompleted, isActive)}
            </span>
            <span className="step-label">{item.nameStep}</span>
          </div>
        </div>
      );
    });
  };

  return (
    <div 
      className={`stepper-container ${isExpanded ? 'expanded' : ''}`} 
      style={{visibility: visibility}}
      role="navigation"
      aria-label="Release creation steps"
    >
      {renderMobileStepIndicator()}
      <div className={`bar-text ${isExpanded ? 'expanded' : ''}`}>
        {renderBar()}
        <div id="orangeBar" ref={orangeBar} className="topBar stepFilled"></div>
      </div>
    </div>
  );
};

export default CreateBar;
