import React, { useState, useContext, useEffect } from "react";
import { LanguageContext } from "../../context/Language";
import { UserContext } from "../../context/User/User";
import Loader from "../../components/Loader";
import StripeCalls from "../../classes/calls/payment/stripe";
import GeneralButton from "../../components/Buttons/GeneralButton";
import "../Register/register.css";
import Pricing from '../../components/Register/Pricing/Pricing';

const Unpaid = () => {
  const { dictionary } = useContext(LanguageContext);
  const { plan: userPlan } = useContext(UserContext);
  const [showRedirectionAdvise, setShowRedirectionAdvise] = useState(false);
  const [loading, setloading] = useState(true);
  const [didMount, setDidMount] = useState(true);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [apiError, setApiError] = useState(null);

  useEffect(() => {
    if (didMount)
      setTimeout(() => {
        confirmPayment();
        setDidMount(false);
      }, 3000);
    // eslint-disable-next-line
  }, [loading]);

  // Convert period from plan to billing format (monthly/annual)
  const convertPeriodToBilling = (period) => {
    if (!period) return 'monthly';
    
    // Normalize to lowercase for consistent comparison
    const periodLower = period.toLowerCase();
    
    // Check for any variation of annual/yearly periods
    if (periodLower.includes('year') || 
        periodLower.includes('annual') || 
        periodLower === 'yr' || 
        periodLower === 'yrs') {
      return 'annual';
    }
    
    // Default to monthly for all other values
    return 'monthly';
  };

  useEffect(() => {
    if (userPlan && !selectedPlan) {
      const initialSelectedPlan = {
        name: userPlan.name,
        billing: convertPeriodToBilling(userPlan.period), // Convert period to billing format
        period: userPlan.period, // Keep original period for display purposes
        originalPeriod: userPlan.period // Store the original value to avoid any transformation issues
      };
      setSelectedPlan(initialSelectedPlan);
    }
  }, [userPlan, selectedPlan]);

  const goToPay = (directPlan = null, retry = 3) => {
    // Use directPlan if provided (from Pricing component), otherwise use selectedPlan from state
    const planToUse = directPlan || selectedPlan;
    
    // Ensure we have a selected plan before proceeding
    if (!planToUse) {
      console.error('No plan selected, defaulting to Core plan');
    }
    
    // Log the selected plan for debugging
    
    setShowRedirectionAdvise(true);
    setTimeout(() => {
      // If a plan is selected, use its name and billing cycle
      const planId = planToUse ? planToUse.name : 'core';
      const billing = planToUse ? planToUse.billing : 'monthly';
            
      StripeCalls.createCheckoutSession(planId, billing).then((url) => {
        if (url) {
          window.location.href = url;
        }
        else {
          console.error('Failed to create checkout session, url:', url);
          if (retry > 0) {
            goToPay(planToUse, retry - 1);
          }
        }
      }).catch(error => {
        console.error('Error creating checkout session:', error);
        setApiError(`Error creating checkout: ${error.message || 'Unknown error'}`);
        setShowRedirectionAdvise(false);
      });
    }, 3000);
  };

  const confirmPayment = (btn = false) => {
    setloading(true);
    StripeCalls.confirmPayment()
      .then((resp) => {
        if(resp){
          if (resp) window.location.href = "/";
        } 
        if (btn && !resp) {
          goToPay();
        }
      })
      .catch((e) => {
        console.error('Error confirming payment:', e);
        setApiError(`Error confirming payment: ${e.message || 'Unknown error'}`);
      })
      .finally(() => {
        setloading(false);
      });
  };

  const handlePlanSelect = (plan) => {
    // Immediately update the local state
    setSelectedPlan(plan);
  };

  // Handler para manejar errores del componente Pricing
  const handleApiError = (error) => {
    console.error('Error in Pricing component:', error);
    setApiError(error);
  };

  // If we're showing the redirection advise or loading, show appropriate content
  if (showRedirectionAdvise) {
    return (
      <div className="container-box">
        <div className="wizard-box">
          <h3 className="p-4 center">
            {dictionary.redirectInN.replaceAll(/{{N}}/g, "3")}
          </h3>
        </div>
      </div>
    );
  }

  if (loading) {
    return (
      <div className="container-box">
        <div className="wizard-box">
          <Loader />
        </div>
      </div>
    );
  }

  return (
    <div className="container-box">
      <div className="wizard-box">
        {apiError && (
          <div className="api-error-message">
            <p>Error loading plans: {apiError}</p>
            <GeneralButton
              onClick={() => window.location.reload()}
              size={"md"}
              text={"Retry"}
              className="retry-btn"
            />
          </div>
        )}
        
        {/* Integrating the Pricing component with minimal required props */}
        <Pricing 
          isRegistered={true}
          onPlanSelect={handlePlanSelect}
          onError={handleApiError}
          currentPlan={userPlan}
          selectedPlan={selectedPlan}
          proceedToPayment={goToPay}
          // Pasar objetos vacíos o funciones noop para evitar errores
          state={{}}
          setInput={() => {}}
          setKeys={() => {}}
          signUp={() => {}}
          changeSection={() => {}}
        />
      </div>
    </div>
  );
};

export default Unpaid;
