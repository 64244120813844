import React, { useContext, useState, useEffect, useRef } from "react";
import { Table, message } from "antd";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { LanguageContext } from "../../../../context/Language";
import { UploadServiceContext } from "../../../../context/Services/Services";
import {
  ArrowLeftOutlined,
  MenuOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import Release from "../../../../classes/release/release";
import ModalTrackInfo from "./TrackInfo";
import EditIcon from "../../../Icon/EditIcon";
import ModalTrackDelete from "./TrackDelete";
import CrossIcon from "../../../Icon/CrossIcon";
import DownloadIcon from "../../../Icon/DownloadIcon";
import Button from "../../../Buttons/GeneralButton";
import Loader from "../../../Loader";
import AudioPlayer from "../../../AudioPlayer";
import InfoIcon from "../../../Icon/InfoIcon";
const notDraggableOptions = ["In Review", "Delivered", "Published"];
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
// we need to delagate all track responsabilities to the TrackClass
// for make it functional, I mean convert the class in a real representation
// of the track in the release context
// import TrackClass from "../../../../classes/track/track";
// let trackInstance = new TrackClass();

/**
 * @todo In the refactor we should inverse the flow, make the user drag an drop their files
 * and then iterate over the files to create the tracks, while the tracks are
 * being created, the files are being uploaded and shared data between the tracks
 * can be omited like genres, participants etc.
 */
const Tracks = ({
  releaseId,
  releaseTracks,
  releaseFormat,
  // pre processed data from release
  // this shouldnt exist, but subcomponents need it
  state,
  setStepsFulfilled,
  stepsFulfilled,
  backSection,
  nextSection,
  // Used to pass the validation function to the progress in the parent component
  updateStepValidationFn,
  //mutateTracks,
  changeSection,
  setCanMove,
  audiosalad_status,
  tracksToUpload,
  setTracksToUpload,
}) => {
  const { dictionary } = useContext(LanguageContext);

  const { addFiles, setShowProgressCard, uploadStatus } =
    useContext(UploadServiceContext);
  // at this time, I ignore the UploadServiceContext, cus coupling
  // const [tracksToUpload, setTracksToUpload] = useState([]);
  const [trackOrder, setTrackOrder] = useState({});

  // tracks grouped by _id
  const [tracks, setTracks] = useState({});

  // keep the track id selected to manipulate (can be ['id1', 'id2', 'id3'])
  const [selectedTrackId, setSelectedTrackId] = useState(null);

  const [isModalVisible, setIsModalVisible] = useState({
    add: false,
    delete: false,
  });

  const [errors, setError] = useState({
    trackErr: false,
    trackUploading: false,
    missingfile: [],
  });

  //
  //  Testing
  //
  const [loader, setLoader] = useState(false);
  const columns = [
    {
      title: "Sort",
      dataIndex: "sort",
      width: 30,
      className: "drag-visible",
      render: () => <MenuOutlined style={{ cursor: "grab", color: "#999" }} />,
    },
    {
      title: dictionary.order,
      dataIndex: "order",
      className: "drag-visible",
    },
    {
      title: dictionary.title,
      dataIndex: "title",
    },
    {
      title: dictionary.displayArtist,
      dataIndex: "displayArtist",
    },
    {
      title: dictionary.details,
      dataIndex: "details",
    },
  ];

  useEffect(
    () => {
      updateStepValidationFn(next);
      // disable the progress card from the context
      setShowProgressCard(false);
    },
    [] // [releaseTracks]
  );

  // This effect should be triggered when:
  // Data from the server is loaded
  // UploadService finish their work and return the asset
  // when the order of the tracks is changed
  useEffect(() => {
    if (releaseTracks.length === 0 && tracksToUpload === 0) return;

    const newTrackState = {};

    // Populate with the data from the server
    for (const rt of releaseTracks) {
      if (rt?.asset?.length)
        rt.dataFromAudioURI = {
          url: rt?.s3_url || "",
          filename: rt?.asset[0]?.filename || "",
          format: rt?.asset[0]?.format || "",
        };

      if (
        Object.hasOwnProperty.call(trackOrder, rt._id) &&
        rt.number !== trackOrder[rt._id]
      )
        rt.number = trackOrder[rt._id];

      newTrackState[rt._id] = rt;
    }

    // pupulate with the data from the upload(frontend)
    for (const tu of tracksToUpload) {
      if (tu?.asset?.length)
        tu.dataFromAudioURI = {
          url: tu?.s3_url || "",
          filename: tu?.asset[0]?.filename || "",
          format: tu?.asset[0]?.format || "",
        };

      if (
        Object.hasOwnProperty.call(trackOrder, tu._id) &&
        tu.number !== trackOrder[tu._id]
      )
        tu.number = trackOrder[tu._id];

      newTrackState[tu._id] = tu;
    }

    const trackEntries = Object.entries(newTrackState);
    trackEntries.sort(([, a], [, b]) => a.number - b.number);
    const sortedTracks = Object.fromEntries(trackEntries);

    // Used to make the rules of max tracks per release
    setTracks(sortedTracks);
  }, [releaseTracks, tracksToUpload, trackOrder]);

  // If a track is uploaded, we need to add the asset to the track to
  // update the table
  useEffect(() => {
    if (uploadStatus?.progress === 100) {
      const trackId = uploadStatus.trackId;

      setTracksToUpload(
        tracksToUpload.map((el) => {
          if (el._id === trackId) {
            el.loading = false;
            if (uploadStatus?.asset) {
              el.asset = [uploadStatus.asset];
              el.s3_url = uploadStatus.s3_url;
            }
          }

          return el;
        })
      );
    }
  }, [uploadStatus]);

  const back = () => {
    changeSection(3);
    backSection(4);
    window.scrollTo(0, 0);
  };

  const next = (n = null) => {
    const data = validate();

    if (!data) {
      setLoader(true);
      setStepsFulfilled({
        ...stepsFulfilled,
        step4: true,
      });
      if (n) {
        // mutateTracks(Object.values(tracks));
        changeSection(n);
        nextSection(n);
      }
      window.scrollTo(0, 0);
      setLoader(false);
    }
  };

  const checkAddable = () => {
    const countTracks = Object.keys(tracks).length;

    switch (releaseFormat) {
      case "Single":
        return countTracks < 3;

      case "EP":
        return countTracks < 6;

      case "Album":
        return countTracks < 35;

      default:
        return true;
    }
  };

  const orderedList = (list = [], roles = false) => {
    const count = list.length;
    if (Array.isArray(list)) {
      return list.map((el, index) => {
        if (count === index + 1 && count > 1) {
          return (
            " " +
            dictionary.and +
            " " +
            (roles ? dictionary.artistType[el] : el.name)
          );
        } else if (count - 2 === index && count > 1) {
          return roles ? dictionary.artistType[el] : el.name;
        } else if (count < 3) {
          return (roles ? dictionary.artistType[el] : el.name) + " ";
        } else {
          return (roles ? dictionary.artistType[el] : el.name) + ", ";
        }
      });
    }
  };

  const artistsPreview = (artists) => {
    const onlyMain = artists.filter((el) => el.role.includes("main"));
    const onlyFeat = artists.filter((el) => el.role.includes("feature"));
    const countFeat = onlyFeat.length;

    let setMains = orderedList(onlyMain);

    let setFeat = onlyFeat.map((el, index) => {
      if (countFeat === index + 1 && countFeat > 1) {
        return " " + dictionary.and + " " + el.name;
      } else if (countFeat < 3) {
        return dictionary.featuring + el.name + " ";
      } else if (index === 0) {
        return dictionary.featuring + el.name + ", ";
      } else {
        return el.name + ", ";
      }
    });
    setMains.push(setFeat);
    return setMains;
  };

  /**
   * Set the state of the table to render
   * @return {Array<React.Component>}
   */
  const populateTrackTable = () => {
    let trackTable = [];
    const arrayTracks = Object.values(tracks);
    for (const trackItem of arrayTracks) {
      const artist = artistsPreview(trackItem.artists);
      trackTable.push({
        key: "track-table-row-" + trackItem._id,
        index: trackItem._id,
        dataIndex: trackItem._id,
        order: trackItem.number,
        title: trackItem.title || trackItem.name,
        displayArtist: artist,
        details: (
          <div className="track-details">
            {trackItem?.loading || !trackItem.dataFromAudioURI ? null : (
              <AudioPlayer
                releaseId={releaseId}
                showAsIconButton
                dataFromURI={trackItem.dataFromAudioURI}
                className="audio-player-icon"
              />
            )}
            {trackItem?.loading || !trackItem.dataFromAudioURI ? null : (
              <a
                href={
                  trackItem.dataFromAudioURI.url +
                  "/" +
                  trackItem.dataFromAudioURI.filename
                }
                download={trackItem.title}
                target="_blank"
                rel="noopener noreferrer"
              >
                <DownloadIcon className="download-track-icon"/>
              </a>
            )}
            {trackItem?.loading ||
            notDraggableOptions.includes(audiosalad_status) ? null : (
              <EditIcon onClick={() => showAdd(trackItem._id)} />
            )}
            {trackItem?.loading ||
            notDraggableOptions.includes(audiosalad_status) ? null : (
              <CrossIcon onClick={() => showDelete(trackItem._id)} />
            )}
            {trackItem?.loading ? <Spin indicator={antIcon} /> : null}
          </div>
        ),
      });
    }

    return trackTable;
  };

  const moveRow = (dragIndex, hoverIndex) => {
    const data = Object.values(tracks);
    const fromItem = data[dragIndex];
    const toItem = data[hoverIndex];
    data[dragIndex] = toItem;
    data[hoverIndex] = fromItem;

    const newTrackOrderState = {};
    const newOrderToSend = [];
    data.forEach((el, index) => {
      newTrackOrderState[el._id] = index + 1;
      newOrderToSend.push({ id: el._id, order: index + 1 });
    });

    setTrackOrder(newTrackOrderState);
    Release.setReleaseTrackOrder(releaseId, newOrderToSend);
  };

  const DraggableBodyRow = ({
    index,
    moveRow,
    className,
    style,
    ...restProps
  }) => {
    const type = "DraggableBodyRow";
    const ref = useRef();
    const [, drop] = useDrop({
      accept: type,
      collect: (monitor) => {
        const { index: dragIndex } = monitor.getItem() || {};
        if (dragIndex === index) return {};

        return {
          isOver: monitor.isOver(),
          dropClassName:
            dragIndex < index ? " drop-over-downward" : " drop-over-upward",
        };
      },
      drop: (item) => {
        moveRow(item.index, index);
      },
    });

    const [, drag] = useDrag({
      type,
      item: { index },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    });
    drop(drag(ref));

    return (
      <tr
        ref={ref}
        className={`${className}`}
        style={{ cursor: "move", ...style }}
        {...restProps}
      />
    );
  };

  const handleCancel = () => {
    setIsModalVisible({ add: false, delete: false });
    setSelectedTrackId(null);
  };

  const showDelete = (trackId) => {
    setSelectedTrackId(trackId);
    setIsModalVisible({ ...isModalVisible, delete: true });
  };

  const showAdd = (trackId) => {
    setError({
      trackErr: false,
      trackUploading: false,
      missingfile: [],
    });
    // trackId = edtiting track
    if (trackId) setSelectedTrackId(trackId);

    setIsModalVisible({ ...isModalVisible, add: true });
  };

  const deleteTrack = () => {
    // this call should be inside of the modal component
    Release.removeTracksRelease(releaseId, selectedTrackId)
      .then(() => {
        // todo: here should remove the track from the state
        // reload or remove the track from the state
        message.success(dictionary.deletedTrack);
      })
      .catch(() => {
        message.error(dictionary.generalError);
      })
      .finally(() => {
        setIsModalVisible({ ...isModalVisible, delete: false });
        setSelectedTrackId(null);
        const newTracks = { ...tracks };
        delete newTracks[selectedTrackId];
        setTracks(newTracks);
      });
  };

  /**
   * Upsert the track to the release, and upload the file to the bucket
   * @param {object} trackInfo
   * @param {File} file
   */
  const addTrack = async (trackInfo, file) => {
    setCanMove(false);
    const isEdit = selectedTrackId !== null;
    const dataToSend = { ...trackInfo };

    if (isEdit) {
      // delete trackInfoToUpload._id;
      if (selectedTrackId && dataToSend._id !== selectedTrackId) {
        dataToSend._id = selectedTrackId;
        trackInfo._id = selectedTrackId;
      }

      delete dataToSend.categories;
      delete dataToSend.createdAt;
      delete dataToSend.updatedAt;
      delete dataToSend.__v;
      // in the flow genres are replaced by subgenres
      delete dataToSend.genres;
      delete dataToSend.dsp_track;
      delete dataToSend.price_tier;
      dataToSend.asset = [];
      delete dataToSend.dataFromAudioURI;
      dataToSend.permission = dataToSend.permission.map((item) => {
        delete item._id;
        return item;
      });
    } else {
      // the addFiles from the serviceContext should update this once completed
      dataToSend.s3_url = "https://cdn.priamdigital.com/";
    }

    // yes, for both cases
    delete dataToSend.participants;

    // upsert the track, response should contain the track id
    const newTrack = await Release.setTracksRelease(releaseId, dataToSend);

    if (file) {
      if (newTrack?.track) {
        dataToSend._id = newTrack.track;
        trackInfo._id = newTrack.track;
      }

      trackInfo.loading = true;

      // Usar el setter de props
      setTracksToUpload([...tracksToUpload, trackInfo]);

      addFiles([
        {
          type: "track",
          releaseId,
          file: file.originFileObj,
          trackId: dataToSend._id,
        },
      ]);
    }
  };

  const validate = () => {
    let error = false;
    let errors = {
      trackErr: false,
      trackUploading: false,
      missingfile: [],
    };
    const countTracks = Object.keys(tracks).length;
    switch (releaseFormat) {
      case "Single":
        if (countTracks === 0 || countTracks > 3) {
          errors.trackErr = dictionary.trackSingleErr;
          error = true;
        }
        break;
      case "EP":
        if (countTracks < 2 || countTracks > 6) {
          errors.trackErr = dictionary.trackEPErr;
          error = true;
        }
        break;
      case "Album":
        if (countTracks < 7 || countTracks > 35) {
          errors.trackErr = dictionary.trackAlbumErr;
          error = true;
        }
        break;
      default:
        break;
    }

    const isUploading = tracksToUpload.filter((el) => el.loading);

    if (isUploading.length) {
      errors.trackUploading = dictionary.trackErrUploading;
      error = true;
    }

    for (const t_id of Object.keys(tracks)) {
      const t = tracks[t_id];
      if (t.asset.length === 0) {
        errors.missingfile.push(t.title || t.name);
        error = true;
      }
    }

    if (error) {
      setError({
        ...errors,
        errors,
      });
    }

    return error;
  };

  const disableMoves = () => {
    // verify if all tracks are uploaded
    if (tracksToUpload.length === 0) return false;
    // si todos los tracks estan cargados y no hay errores
    const isUploading = tracksToUpload.filter((el) => el.loading);

    return isUploading.length > 0;
  };

  return (
    <div>
      <div className="forms tracks">
        <DndProvider backend={HTML5Backend}>
          <Table
            rowKey="index"
            columns={columns}
            pagination={false}
            dataSource={populateTrackTable()}
            className="table-tracks"
            components={{
              body: {
                row:
                  !notDraggableOptions.includes(audiosalad_status) ||
                  !disableMoves()
                    ? DraggableBodyRow
                    : null,
              },
            }}
            onRow={(record, index) => ({
              index,
              moveRow,
            })}
          />
        </DndProvider>
        {checkAddable() && !notDraggableOptions.includes(audiosalad_status) ? (
          <div className="add-track-div" onClick={() => showAdd()}>
            <div className="add-track" >
              <PlusOutlined />
            </div>
            {dictionary.addTrack}
            <InfoIcon tooltipKey="tracksInfo" placement="right" />
          </div>
        ) : null}
        {loader ? <Loader /> : null}
      </div>
      {errors.trackErr ? (
        <div className="error-field">{errors.trackErr}</div>
      ) : null}
      {errors.trackUploading ? (
        <div className="error-field">{dictionary.trackErrUploading}</div>
      ) : null}
      {errors.missingfile.length > 0 ? (
        <div className="error-field">
          {dictionary.tracksMissingFile}
          <br />
          {errors.missingfile.join("/n")}
        </div>
      ) : null}
      <div className="align-right reg-btn">
        <Button
          disabled={disableMoves()}
          size="md"
          onClick={back}
          className="back-btn"
          text={dictionary.back}
          icon={<ArrowLeftOutlined />}
        />
        <Button
          disabled={disableMoves()}
          size="md"
          onClick={() => next(5)}
          text={dictionary.next}
        />
      </div>
      <ModalTrackDelete
        trackName={
          tracks[selectedTrackId]?.title || tracks[selectedTrackId]?.name
        }
        deleteTrack={deleteTrack}
        handleCancel={handleCancel}
        deleteVisible={isModalVisible.delete}
      />
      <ModalTrackInfo
        trackDataToEdit={tracks[selectedTrackId]}
        index={selectedTrackId}
        state={state}
        addTrack={addTrack}
        isModalVisible={isModalVisible.add}
        handleCancel={handleCancel}
        trackToUpload={tracksToUpload}
        newNumber={Object.keys(tracks).length + 1}
      />
    </div>
  );
};

export default Tracks;
