import React, { useState, useEffect, useContext } from "react";
import { Row } from "antd";
import { ArtistContext } from "../../../../../../context/Artist";
import ParticipantsForm from "../../../ParticipantsForm";

const TrackInfoSecond = ({ index, state, infoTrack, saveArtists, errorsTrack, planError, setPlanError }) => {

  const { frontRoles } = useContext(ArtistContext);

  const [artists, setArtists] = useState({
    artist: [],
    producer: [],
    writer: [],
    // TODO Remove participants from this flow
    participant: [],
  });
  const [frontArtists, setFrontArtists] = useState({
    artist: [],
    writer: [],
    producer: [],
    // TODO Remove participants from this flow
    participant: [],
  });

  useEffect(() => {
    if (infoTrack) {
      const participantsLists = orderFrontParts({
        artist: infoTrack.artists,
        producer: infoTrack.producers || [],
        writer: infoTrack.writers,
        participant: infoTrack.participants || [],
      });
      setArtists(participantsLists.backList);
      setFrontArtists(participantsLists.frontList);
    } else {
      const participantsLists = orderFrontParts({
        artist: state.tracks[index].artists,
        producer: state.tracks[index].producers || [],
        writer: state.tracks[index].writers,
        participant: state.tracks[index].participants || [],
      });
      setArtists(participantsLists.backList);
      setFrontArtists(participantsLists.frontList);
    }
    // eslint-disable-next-line
  }, []);

  const orderFrontParts = (allArtist) => {
    let keys = Object.keys(allArtist) || [];
    let all = [];
    let onlyArtist = [];
    let onlyWrit = [];
    let onlyPart = [];
    let ordered = {
      artist: [],
      writer: [],
      participant: [],
    };
    let orderedFront = ordered;
    //to save the back part

    keys.forEach((el) => {
      allArtist[el].forEach((element) => {
        return all.push(element);
      });
    });

    all.forEach(async (el) => {
      for (let artistRole in frontRoles.artist) {
        if (artistRole === el.role) {
          onlyArtist.push(el);
        }
      }
      for (let writRole in frontRoles.writer) {
        if (writRole === el.role) {
          onlyWrit.push(el);
        }
      }
      for (let partRole in frontRoles.participant) {
        if (partRole === el.role) {
          onlyPart.push(el);
        }
      }
      if (!el.role) {
        el["role"] = "participant";
        onlyPart.push(el);
      }
      ordered = {
        artist: onlyArtist,
        writer: onlyWrit,
        participant: onlyPart,
      };
    });

    //to save the front part
    Object.keys(ordered).forEach(function (el) {
      let newone = ordered[el].map((member) => {
        const role = ordered[el].reduce((acc, cur) => {
          if (cur.name === member.name) {
            acc.push(cur.role);
          }
          return acc;
        }, []);
        return {
          name: member.name,
          role,
          artist_id: member.artist_id,
          _id: member._id,
          image: member.image,
        };
      });

      let dataFilter = newone.filter(
        (value, index, self) =>
          index ===
          self.findIndex(
            (t) => t.place === value.place && t.name === value.name
          )
      );
      orderedFront[el] = dataFilter;
    });

    let newLists = {
      backList: ordered,
      frontList: orderedFront,
    };
    return newLists;
  };

  return (
    <Row className="gutter-row">
      <ParticipantsForm
        showCover={false}
        label={state.label}
        artistsAll={artists}
        planError={planError}
        setPlanError={setPlanError}
        saveArtists={saveArtists}
        nameRelease={infoTrack?.title}
        validationErrors={errorsTrack}
        artistsFrontAll={frontArtists}
      />
    </Row>
  );
};

export default TrackInfoSecond;
