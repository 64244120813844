import React, { createContext, useState, useEffect, useMemo } from "react";
import Spanish from "./lang/es.json";
import English from "./lang/en.json";
import Albanian from "./lang/alb.json";
import French from "./lang/fr.json";
import { IntlProvider } from "react-intl";
import Language from "../../classes/language/language";
export const LanguageContext = createContext();

const validAlphanumericLocales = new Set([
  "en-US", "es-AR", "es-ES",
  "fr-FR", "en-AU", "en-GB", "en-HK", "en-IN",
  "en-NZ", "en-ZA", "en-ZM", "sq", "sq-AL", 
  "fr-FR"
]); // Added Albanian locales (sq and sq-AL)

const getValidLocale = (locale) => {
  // necesario para validator
  if (typeof locale !== "string") return "en-US";
  if (locale.startsWith("es")) return "es-ES";
  if (locale.startsWith("fr")) return "fr-FR";
  if (locale.startsWith("sq") || locale.startsWith("alb")) return "sq-AL";
  return validAlphanumericLocales.has(locale) ? locale : "en-US"; // Default locale
};

let local = getValidLocale(navigator.language);
try {
  new Intl.DateTimeFormat(local); // Prueba si el locale es válido
} catch {
  console.log("Locale is not valid", local);
  local = "en-US"; // Asigna el valor predeterminado si no es válido
}

// Determine initial language based on browser locale
const getInitialLang = (locale) => {
  if (typeof locale !== "string") return English;
  if (locale.startsWith("es")) return Spanish;
  if (locale.startsWith("fr")) return French;
  if (locale.startsWith("sq") || locale === "sq-AL") return Albanian;
  return English;
};

let monthNames = Array.from({ length: 12 }, (e, i) =>
  new Intl.DateTimeFormat(local, { month: "long" }).format(new Date(2024, i))
);

export const LanguageProvider = ({ children }) => {
  // Get initial locale from localStorage or browser
  const initialLocale = localStorage.getItem("userLocale") || local;
  const initialLang = getInitialLang(initialLocale);

  const [locale, setLocale] = useState(initialLocale);
  const [languagesList, setLanguagesList] = useState([]);
  const [dictionary, setMessages] = useState(initialLang);
  const [currentLanguage, setCurrentLanguage] = useState(initialLocale);

  useEffect(() => {
    if (locale !== currentLanguage) {
      setCurrentLanguage(locale);
    }
  }, [locale, currentLanguage]);

  const getCurrentLanguage = () => {
    return currentLanguage;
  };

  const setLanguagesState = () => {
    Language.languages()
      .then((langData) => {
        if (langData.status === 200) setLanguagesList(langData.body.languages);
        else setLanguagesList([]);
      })
      .catch((err) => {
        console.log(err);
        setLanguagesList([]);
      });
  };

  // Options for language select
  const languageOptions = useMemo(() => {
    return languagesList.map((language) => {
      return { value: language._id, label: language.name };
    });
  }, [languagesList]);

  useEffect(() => {
    setLanguagesState();
  }, []);

  const setLanguage = (lang) => {
    if (!lang && !localStorage.getItem("userLocale")) {
      setMessages(English);
      setLocale("en");
      return;
    }
    const newLocale = lang;
    setLocale(newLocale);
    localStorage.setItem("userLocale", newLocale);

    if (newLocale === "en") {
      setMessages(English);
    } else if (newLocale === "es") {
      setMessages(Spanish);
    } else if (newLocale === "sq" || newLocale === "alb") {
      setMessages(Albanian);
    } else if (newLocale === "fr") {
      setMessages(French);
    }
  };

  const selectLanguage = (e) => {
    const newLocale = e.target.value;
    setLocale(newLocale);
    // Save to localStorage
    localStorage.setItem("userLocale", newLocale);

    if (newLocale === "en") {
      setMessages(English);
    } else if (newLocale === "es") {
      setMessages(Spanish);
    } else if (newLocale === "sq" || newLocale === "alb") {
      setMessages(Albanian);
    } else if (newLocale === "fr") {
      setMessages(French);
    }
  };

  return (
    <LanguageContext.Provider
      value={{
        dictionary,
        locale,
        setLanguage,
        selectLanguage,
        currentLanguage,
        getCurrentLanguage,
        languagesList,
        monthNames,
        getValidLocale,
        languageOptions,
      }}
    >
      <IntlProvider dictionary={dictionary} locale={locale}>
        {children}
      </IntlProvider>
    </LanguageContext.Provider>
  );
};
