import React, { useContext, useEffect, useState, useMemo } from "react";
import { notification } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { WarningOutlined } from "@ant-design/icons";
import { AppContext } from "../../../../context/App";
import { LanguageContext } from "../../../../context/Language";
import { useReleaseDetail } from "../../../../hooks/release/useReleaseDetail";
import Button from "../../../Buttons/GeneralButton";
import Release from "../../../../classes/release/release";
import Admin from "../../../../classes/admin/admin.jsx";
import LeftArrowIcon from "../../../../components/Icon/LeftArrowIcon";
import ReviewNoteModal from "../../../../components/Release/Detail/Modals/ReviewNoteModal";
import "./actions.css";

const Actions = ({ setIsLoading, dataToUpdate, sendDataToUpdate }) => {
  const navigate = useNavigate();
  const { releaseData, releaseId, isLoading, refreshRelease } =
    useReleaseDetail();

  const { dictionary } = useContext(LanguageContext);
  const { audiosaladStatus } = useContext(AppContext);
  const [errorMsg, setErrMsg] = useState(null);
  const [showReviewNoteModal, setShowReviewNoteModal] = useState(false);

  const actualstatus = useMemo(() => {
    if (audiosaladStatus) {
      return Object.keys(audiosaladStatus).find(
        (key) => audiosaladStatus[key] === releaseData.audiosalad_status
      );
    }
  }, [releaseData.audiosalad_status, audiosaladStatus]);

  useEffect(() => {
    if (errorMsg !== null) {
      notification.error({
        message: errorMsg,
        duration: 15,
        placement: "bottomRight",
      });
    }
  }, [errorMsg]);

  const sendInReview = async () => {
    setErrMsg(null);
    setIsLoading(true);
    const updateStatus = await Admin.updateStatus(releaseData._id);
    let titleRel = releaseData.title;
    if (updateStatus.status === 200) {
      setIsLoading(false);
      if (updateStatus.body.error === "releaseCheckData") {
        setErrMsg(dictionary.releaseCheckDataAdmin);
      } else {
        if (updateStatus.body.error === "releaseInReview") {
          setErrMsg(dictionary.releaseInReview);
        } else {
          let succesRel = dictionary.releaseSendToReview.replace(
            "--name--",
            titleRel
          );
          notification.success({
            message: succesRel,
            duration: 6,
            placement: "bottomRight",
          });
          setTimeout(() => {
            refreshRelease();
            window.location.reload();
          }, 2000);
        }
      }
    } else {
      setIsLoading(false);
      setErrMsg(dictionary.stripeError);
    }
  };

  const changeAudiosaladStatus = (status, type) => {
    setIsLoading(true);
    Release.updateAudiosaladStatus(releaseData._id, status).then((res) => {
      if (!res.error && res.message) {
        let succesRel =
          type === 1
            ? dictionary.releaseSendToDistribution.replace(
                "--name--",
                releaseData.title
              )
            : type === 2
            ? dictionary.releaseResendToReview.replace(
                "--name--",
                releaseData.title
              )
            : dictionary.releaseRevokeDistribution.replace(
                "--name--",
                releaseData.title
              );
        notification.success({
          message: succesRel,
          duration: 6,
          placement: "bottomRight",
        });
        setIsLoading(false);
        setTimeout(() => {
          refreshRelease();
          window.location.reload();
        }, 2000);
      } else {
        notification.warning({
          message: dictionary.stripeError,
          duration: 6,
          placement: "bottomRight",
        });
        setIsLoading(false);
      }
    });
  };

  const reviewButtons = () => {
    let stepActions = "";
    switch (actualstatus) {
      case "readyToSent":
        stepActions = (
          <React.Fragment>
            <Button
              size="ms"
              className="reject"
              onClick={() => setShowReviewNoteModal(true)}
              text={dictionary.rejectSendToClient}
              disabled={isLoading}
            ></Button>
            {releaseData.copyright_response &&
            Object.keys(releaseData.copyright_response).length > 0 ? (
              <Link
                to={
                  "/release/verification?r=" +
                  releaseData._id +
                  "&section=review"
                }
                className="view-fingerprint-release"
              >
                <WarningOutlined className="warn" />
                {dictionary.copyrightVerification}
              </Link>
            ) : (
              <Button
                size="ms"
                className="grn"
                onClick={() =>
                  changeAudiosaladStatus("Pending Distribution", 1)
                }
                text={dictionary.sendForDistribution}
                disabled={isLoading}
              ></Button>
            )}
          </React.Fragment>
        );
        break;
      case "inReview":
        stepActions = (
          <div className="in-review-text">{dictionary.inReview}</div>
        );
        break;
      case "pendingDistribution":
        stepActions = (
          <React.Fragment>
            <Button
              size="ms"
              className="reject"
              onClick={() => changeAudiosaladStatus("Ready to Sent", 2)}
              text={dictionary.resendToReview}
              disabled={isLoading}
            ></Button>
            <Button
              size="ms"
              className="grn"
              onClick={() => sendInReview()}
              text={dictionary.approve + "/" + dictionary.sendToAudiosalad}
              disabled={isLoading}
            ></Button>
          </React.Fragment>
        );
        break;
      default:
        break;
    }
    return stepActions;
  };

  return (
    <>
      <div className="actions-box">
        {errorMsg ? (
          <div className="error-release-admin">{errorMsg}</div>
        ) : null}
        <div className="actions-release">
          <Link to="/release">
            <Button
              key="deny"
              size="ms"
              icon={<LeftArrowIcon />}
              className="return"
              text={dictionary.return}
              disabled={isLoading}
            />
          </Link>
          {Object.keys(dataToUpdate).length > 0 ? null : reviewButtons()}
          {Object.keys(dataToUpdate).length > 0 ? (
            <Button
              key="saveBtnReleaseDetail"
              size="ms"
              className="save"
              text={dictionary.save}
              onClick={sendDataToUpdate}
              disabled={isLoading}
            />
          ) : null}
        </div>
      </div>
      <ReviewNoteModal
        releaseId={releaseId}
        isModalVisible={showReviewNoteModal}
        releaseTitle={releaseData.title}
        reviewNotes={releaseData.rejections}
        onCancel={() => setShowReviewNoteModal(false)}
        onConfirm={() => navigate("/release")}
      />
    </>
  );
};

export default Actions;
