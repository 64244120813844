import React from 'react';
import Logo from "../../logo.svg";
import { Link } from 'react-router-dom';
import './TermsOfService.css';

const TermsOfService = ({hideLogo=false, register =false}) => {

  return (
    <div
      className={`container-box fadein sign ` + (register ? 'register' : '')}
    >
    {!hideLogo &&
      <Link to="/">
        <img src={Logo} alt="Priam" className="logo-terms"/>
      </Link>
    }
    <div className="terms-layout">
      <h2>PRIAM DIGITAL TERMS OF SERVICE</h2>
        <h3>1. INTRODUCTION</h3>
        <p>
          Priam Media Inc. (&quot;Priam Digital,&quot; &quot;we,&quot; &quot;us,&quot; or &quot;our&quot;) provides access to the Priam Digital website, currently located at priamdigital.com and studio.priamdigital.com, including all associated subpages (collectively, &quot;Site&quot;), any related mobile applications we may make available (collectively, &quot;Application&quot;), together with all enabled features, functionality, and services offered by us in connection with the Site and Application (collectively, &quot;Services&quot;).
        </p>
        <p>
          These Terms of Service (&quot;Terms&quot;) govern your access and use of the Services. By accessing or using the Services in any way, you represent and warrant that you have read, understood, and accept and agree to be bound by these Terms. If you do not agree, you may not access or use the Services.
        </p>
        <p>
          PLEASE NOTE: These Terms govern your access to and use of our Services only. If you use our distribution services, your relationship with us regarding the distribution of your musical content will be governed by whichever of the following applies to you:
          <ol>
            <li>
              The Priam Digital Distribution Agreement (available at <Link to="/distribution-agreement" target="_blank" rel="noreferrer"> priamdigital.com/agreement </Link>) if you have enrolled in our distribution services through our online platform; OR
            </li>
            <li>
              Any separate written or electronically executed distribution agreement between you and Priam Digital, if you have entered into such an agreement with us offline or through other channels.
            </li>
          </ol>
        </p>
        <p>
          In the event of any conflict between these Terms and any separate distribution agreement you have with us, the terms of that separate agreement will govern with respect to distribution services, while these Terms will continue to govern your use of the Site and other Services
        </p>
        <h3>2. ELIGIBILITY AND ACCESS</h3>
        <b>2.1 Age Requirements</b>
        <p>
          You must be at least 18 years of age or the applicable age of majority in your jurisdiction. By accessing or using the Services, you represent and warrant that you meet this requirement. If you are under 18, you may not use the Services.
        </p> 
        <b>2.2 Limited License</b>
        <p>
          Subject to these Terms, we grant you a limited, non-exclusive, non-transferable personal license to access and use the Services. This license may be revoked by Priam Digital at any time, in our sole discretion.
        </p>
        <h3>3. ACCOUNT REGISTRATION AND SECURITY</h3>
        <b>3.1 Account Information</b>
        <p>
          To use certain features of the Services, you may need to register for an account (&quot;Account&quot;). You represent and warrant that all registration information you submit is truthful and accurate, and that you will maintain the accuracy of such information.
        </p>
        <b>3.2 Account Security</b>
        <p>
          You are responsible for maintaining the confidentiality of your Account login information and are fully responsible for all activities that occur under your Account. You agree to immediately notify us of any unauthorized use of your Account or any other breach of security. We cannot and will not be liable for any loss or damage arising from unauthorized access to your Account or your failure to comply with these requirements.
        </p>
        <b>3.3 One Account Per Person</b>
        <p>
          You may maintain only one Account unless explicitly permitted by Priam Digital. We reserve the right to terminate duplicate accounts.
        </p>
        <h3>4. FEES AND PAYMENTS</h3>
        <b>4.1 Service Fees</b>
        <p>
          We may charge fees for access to certain features of the Services. All fees will be determined by Priam Digital in its sole discretion and may change without notice. Fees must be paid using a valid payment method on account with us.
        </p>
        <b>4.2 Payment Authorization</b>
        <p>
          You authorize Priam Digital to charge the applicable amount, plus any applicable taxes, to your payment method. Any refunds are at Priam Digital&apos;s sole discretion.
        </p>
        <b>4.3 Payment Failure</b>
        <p>
          If we are unable to charge fees to your payment method, we may suspend your access to the Services until payment is resolved.
        </p>
        <b>4.4 Distribution-Related Payments</b>
        <p>
          If you have a distribution relationship with Priam Digital, payments for royalties and other distribution-related compensations will be governed by your Distribution Agreement with us, not these Terms.
          </p>
        <h3>5. PRIVACY</h3>
        <p>
          For information regarding our collection and use of information, please refer to our Privacy Policy, which is incorporated into these Terms by reference. By accepting these Terms, you agree to our collection, use, and disclosure of your personal information in accordance with our Privacy Policy.
        </p>
        <h3>6. USER CONTENT</h3>
        <b>6.1 User Content Definition</b>
        <p>
          The Services may allow you to submit, upload, store, or share reviews, comments, questions, messages, audio recordings, musical compositions, images, artwork, photographs, audio-visual content, text, and other materials (collectively, &quot;User Content&quot;).
        </p>
        <b>6.2 License Grant</b>
        <p>
          You retain ownership of your User Content. However, by uploading, publishing, or otherwise submitting User Content to the Services, you grant us a non-exclusive, transferable, fully paid, worldwide license to use, copy, reproduce, publicly perform, publicly display, communicate to the public, stream, make available, reformat, translate, excerpt, transmit, and distribute such User Content for any purpose in connection with operating, improving, and promoting the Services or our business.
        </p>
        <b>6.3 Content Representations</b>
        <p>You represent, warrant, and agree that:</p>
        <ol>
        <li>You have the right to submit the User Content to our Services.</li>
        <li>All of your User Content is original with you or used with necessary permissions.</li>
        <li>Your User Content does not and will not violate third-party rights of any kind.</li>
        <li>Your User Content does not contain material which is unlawful, harmful, abusive, offensive, defamatory, invasive of privacy, harassing, threatening, or otherwise objectionable.</li>
        </ol>
        <b>6.4 Content Removal</b>
        <p>
          Priam Digital reserves the right to reject and/or remove any User Content that we believe, in our sole discretion, violates these Terms. We take no responsibility and assume no liability for any User Content that you or other users post or share through the Services.
        </p>
        <b>6.5 Distribution Content</b>
        <p>
          If you have a distribution relationship with Priam Digital and upload content for distribution, such content is also subject to your Distribution Agreement with us. In the event of any conflict or inconsistency between these Terms and your Distribution Agreement with respect to content uploaded for distribution, the Distribution Agreement shall control.
        </p>
        <h3>7. PROHIBITED ACTIVITIES</h3>
        <b>7.1 Prohibited Content</b>
        <p>You agree not to submit User Content or otherwise engage in activity that:</p>
        <ol>
          <li> create a risk of harm, loss, injury, or distress to any person.</li>
          <li>May create a risk of damage to any person or property</li>
          <li>Seeks to harm or exploit children.</li>
          <li>May constitute or contribute to a crime or tort.</li>
          <li>Contains information or content that is unlawful, harmful, abusive, offensive, defamatory, or objectionable.</li>
          <li>Contains information or content that is illegal.</li>
          <li>Contains information or content that you do not have a right to make available.</li>
          <li>Contains information or content that you know is not correct and current.</li>
        </ol>
        <b>7.2 Prohibited Actions</b>
        <p>In addition, you may not:</p>
        <ol>
          <li>Rent, transfer, assign, resell, or sublicense access to the Services.</li>
          <li>Combine or integrate the Services with hardware, software, or technology not provided by us.</li>
          <li>Modify or create any derivative product based on the Services.</li>
          <li>Decompile, disassemble, reverse engineer, or attempt to obtain the source code of the Services.</li>
          <li>Use the Services to violate any law, stalk or harm another individual, collect personal data about users, impersonate any person or entity, or interfere with the Services.</li>
          <li>Use automated means to access the Services (including robots, spiders, or scripts).</li>
          <li>Frame the Services or place pop-up windows over its pages.</li>
          <li>Engage in streaming manipulation or fraudulent activities.</li>
        </ol>
        <h3>8. INTELLECTUAL PROPERTY</h3>
        <b>8.1 Ownership</b>
        <p>
          As between you and us, we retain all right, title, and interest in and to the Services and all related intellectual property rights. All trademarks, logos, and service marks displayed on the Services are either our property or the property of third parties. You are not permitted to use these marks without our prior written consent or the consent of the third-party owner.
        </p>
        <b>8.2 Limited License</b>
        <p>
          All rights in and to the Services not expressly granted herein are reserved. You may not copy, distribute, publicly perform, publicly display, digitally perform, or create derivative works from any copyrighted work made available or accessible via the Services.
        </p>
        <h3>9. COPYRIGHT CLAIMS</h3>
        <b>9.1 DMCA Compliance</b>
        <p>
          We respect the intellectual property rights of others. If you believe that any content on our Services infringes upon your copyright, please send a notice to our designated Copyright Agent at:
        </p>
        <p>
          Priam Media Inc.<br/>
          Attn: Copyright Agent<br/>
          440 N Barranca Ave #9552<br/>
          Covina, CA 91723<br/>
          Email: DMCA@priamdigital.com<br/>
        </p>
        <b>9.2 Notice Requirements</b>
        <p>Your notice should include:</p>
        <ol>
          <li>An electronic or physical signature of the copyright owner or authorized person</li>
          <li>Identification of the copyrighted work claimed to have been infringed</li>
          <li>A description of where the allegedly infringing material is located on the Services</li>
          <li>Your contact information (address, telephone number, email)</li>
          <li>A statement that you have a good faith belief that the use is not authorized</li>
          <li>A statement, under penalty of perjury, that the information in your notice is accurate and that you are authorized to act on the copyright owner&apos;s behalf</li>
        </ol>
        <b>9.3 Repeat Infringers</b>
        <p>
          In accordance with the Digital Millennium Copyright Act (DMCA), we have adopted a policy of terminating accounts of users who are deemed to be repeat infringers, in appropriate circumstances and in our sole discretion.
        </p>
        <h3>10. THIRD-PARTY SERVICES</h3>
        <b>10.1 Third-Party Links</b>
        <p>
          The Services may contain links to third-party websites, content, products, or services (&quot;Third-Party Services&quot;). We are not responsible for any such Third-Party Services or any transactions or use thereof. Our inclusion of links to Third-Party Services does not imply endorsement of the goods, services, or materials available through such Third-Party Services.
        </p>
        <b>10.2 Integration with Distribution Platforms</b>
        <p>
          If you have a distribution relationship with us, we may provide integration with various third-party distribution platforms as specified in your Distribution Agreement. Such integration is subject to the terms of that agreement rather than these Terms.
        </p>
        <h3>11. INDEMNIFICATION</h3>
        <p>
          You agree to defend, indemnify, and hold harmless Priam Digital and its affiliates, officers, directors, employees, and agents from all claims, damages, liabilities, costs, and expenses (including attorney&apos;s fees) arising from your use of the Services, violation of these Terms, or infringement of any third-party rights.
        </p>
        <h3>12. DISCLAIMER OF WARRANTIES</h3>
        <p>
          THE SERVICES ARE PROVIDED &quot;AS IS&quot; AND &quot;AS AVAILABLE.&quot; WE MAKE NO WARRANTIES, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT, ACCURACY, OR USEFULNESS OF ANY INFORMATION OR MATERIALS PROVIDED VIA THE SERVICES.
        </p>
        <p>
          WE CANNOT GUARANTEE THAT ACCESS TO OR USE OF THE SERVICES WILL BE CONTINUOUS, UNINTERRUPTED, ERROR-FREE, OR SECURE. WE EXPRESSLY DISCLAIM ALL WARRANTIES TO THE FULLEST EXTENT PERMITTED BY LAW.
        </p>
        <h3>13. LIMITATION OF LIABILITY</h3>
        <p>
          NEITHER PRIAM DIGITAL NOR ANY OF OUR OFFICERS, DIRECTORS, EMPLOYEES, AFFILIATES, REPRESENTATIVES, OR AGENTS SHALL BE RESPONSIBLE FOR ANY INDIRECT, CONSEQUENTIAL, SPECIAL, PUNITIVE, OR EXEMPLARY DAMAGES OR LOSSES INCURRED IN CONNECTION WITH USE OF THE SERVICES OR ANY INFORMATION OR MATERIALS PROVIDED, REGARDLESS OF THE NATURE OF THE CLAIM, EVEN IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGE OR LOSS.
        </p>
        <p>
          OUR AGGREGATE LIABILITY TO YOU OR ANY THIRD PARTIES IN ANY CIRCUMSTANCE IS LIMITED TO THE GREATER OF (A) THE AMOUNT YOU PAID US FOR ACCESS TO THE SERVICES IN THE TWELVE MONTHS PRIOR TO THE ACTION GIVING RISE TO LIABILITY OR (B) $100.
        </p>
        <h3>14. MODIFICATIONS TO TERMS</h3>
        <p>
          We may change these Terms from time to time as we evolve and expand the Services. Any changes will become effective immediately upon posting to the Site. Your continued use of the Services after changes have been posted constitutes your acceptance of the revised Terms. If you object to any changes, your sole recourse is to cease using the Services.
        </p>
        <h3>15. TERMINATION</h3>
        <b>15.1 Termination by Us</b>
        <p>We reserve the right to terminate these Terms, your Account, and your access to the Services at any time without notice. Possible grounds for termination include, but are not limited to:</p>
        <ol>
        <li>Violation of these Terms</li>
        <li>Failure to pay applicable fees</li>
        <li>Fraudulent or illegal activity</li>
        <li>Creating risk or legal exposure for Priam Digital</li>
        </ol>
        <b>15.2 Termination by You</b>
        <p>
          You may request to delete your Account at any time by following the instructions on the Site. However, your rights under these Terms will automatically terminate if you fail to comply with them. Please note that account deletion is not permitted if you owe an outstanding balance to Priam or if such deletion would conflict with any obligations or conditions set forth in this Agreement.
        </p>
        <b>15.3 Effect of Terminatio</b>
        <p>
          Upon termination, your right to use the Services will immediately cease. Provisions of these Terms that by their nature should survive termination shall survive, including ownership provisions, warranty disclaimers, indemnification, and limitations of liability.
        </p>
        <b>15.4 Distribution Relationships</b>
          <p>
          Termination of these Terms does not necessarily terminate any separate Distribution Agreement you may have with Priam Digital. Please refer to your Distribution Agreement for its specific termination provisions.
          </p>
        <h3>16. DISPUTE RESOLUTION</h3>
        <b>16.1 Governing Law</b>
        <p>
          These Terms shall be construed and enforced in accordance with the laws of the State of California, without regard to any conflict of law provisions.
        </p>
        <b>16.2 Informal Resolution</b>
        <p>
          Before filing a claim, you agree to attempt to resolve any dispute informally by contacting us at legal@priamdigital.com. We&apos;ll try to resolve the dispute by contacting you through email within 60 days.
        </p>
        <b>16.3 Arbitration</b>
        <p>
          Except for small claims court cases and intellectual property disputes, any dispute arising from these Terms shall be resolved through binding arbitration administered by the American Arbitration Association under its Commercial Arbitration Rules.
        </p>
        <b>16.4 Class Action Waiver</b>
        <p>
          YOU AND PRIAM DIGITAL AGREE THAT EACH MAY BRING CLAIMS AGAINST THE OTHER ONLY IN YOUR OR ITS INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY CLASS OR REPRESENTATIVE PROCEEDING.
        </p>
        <h3>17. GENERAL PROVISIONS</h3>
        <b>17.1 Entire Agreement</b>
        <p>
          These Terms constitute the entire agreement between you and us regarding your access to and use of the Services, superseding any prior agreements between you and us relating to your use of the Services. These Terms do not supersede or replace any Distribution Agreement you may have with us.
        </p>
        <b>17.2 No Waiver</b>
        <p>
          Our failure to exercise or enforce any right under these Terms shall not constitute a waiver of such right. If any provision of these Terms is found invalid or unenforceable, that provision will be limited or eliminated to the minimum extent necessary, and the remaining provisions shall remain in full effect.
        </p>
        <b>17.3 Assignment</b>
        <p>
          You may not assign or transfer these Terms or your rights hereunder without our prior written consent. We may assign these Terms without restriction.
        </p>
        <b>17.4 Contact Information</b>
        <p>
          If you have any questions about these Terms, please contact us at:
        </p>
        <p>
          Priam Media Inc.<br/>
          440 N Barranca Ave #9552<br/>
          Covina, CA 91723<br/>
          Email: support@priamdigital.com<br/>
        </p>
        © Priam Digital 2025
    </div>
    </div>
  );
};

export default TermsOfService; 