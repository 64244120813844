import BaseClass from "../";

class ReleaseCalls extends BaseClass {
  releaseType() {
    this.setValues("/release/formats");
    return this.doGet();
  }

  audiosaladStatus() {
    this.setValues("/release/audiosaladStatus");
    return this.doGet();
  }

  getRoles() {
    this.setValues("/release/roles");
    return this.doGet();
  }

  getFrontRoles() {
    this.setValues("/release/front/roles");
    return this.doGet();
  }

  getReleaseDetails(release) {
    this.setValues("/release/details/" + release);
    return this.doGet();
  }

  getReleaseResume(release) {
    this.setValues("/release/resume/" + release);
    return this.doGet();
  }

  createRelease(release) {
    this.setValues("/release/create", { release });
    return this.doPost();
  }

  setInfoRelease(info) {
    this.setValues("/release/info", { info });
    return this.doPost();
  }

  setRightsRelease(rights) {
    this.setValues("/release/rights", rights);
    return this.doPost();
  }

  setParticipantsRelease(participants) {
    this.setValues("/release/set/artists", participants);
    return this.doPatch();
  }

  setTracksRelease(tracks) {
    this.setValues("/release/tracks", tracks);
    return this.doPost();
  }

  removeTracksRelease(track, release) {
    this.setValues("/release/track/" + track, release);
    return this.doDelete();
  }

  setReleaseTrackOrder(order) {
    this.setValues("/release/track/order", order);
    return this.doPost();
  }

  setReleaseTerritories(territories) {
    this.setValues("/release/territories", territories);
    return this.doPost();
  }

  setReleaseDelivery(delivery) {
    this.setValues("/release/delivery", delivery);
    return this.doPost();
  }

  pricingTier() {
    this.setValues("/release/price_tier");
    return this.doGet();
  }

  trackPricingTier() {
    this.setValues("/release/track_price_tier");
    return this.doGet();
  }

  updateAudiosaladStatus(adStatus) {
    this.setValues("/release/updateAudiosaladStatus", adStatus);
    return this.doPatch();
  }

  updateDraftStatus(draftStatus) {
    this.setValues("/release/updateDraftStatus", draftStatus);
    return this.doPatch();
  }

  canRollback(release) {
    this.setValues("/release/can_rollback/" + release);
    return this.doGet();
  }

  requestRollback(release) {
    this.setValues("/release/rollback/" + release);
    return this.doPost();
  }

  getDSPstatus(release) {
    this.setValues("/release/dsp_status/" + release);
    return this.doGet();
  }
}

export default new ReleaseCalls();
