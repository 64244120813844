import React, { useContext, useState, useMemo } from "react";
import { Row, Col, TimePicker, Select, Checkbox, Input } from "antd";
import Date from "../../../../../Form/Date";
import Radio from "../../../../../Form/Radio";
import GenreSubGenreSelect from "../../../../../Form/GenreSubGenreSelect";
import { LanguageContext } from "../../../../../../context/Language";
import { AppContext } from "../../../../../../context/App";
import { UserContext } from "../../../../../../context/User";
import moment from "moment";

const TrackGeneralInformation = ({
  dataToUpdateDispatcher,
  dataToUpdate,
  isEditMode,
  trackData,
}) => {
  const { dictionary, languageOptions } = useContext(LanguageContext);
  const { advisoryOptions, trackTypeOptions } = useContext(AppContext);
  const [genresSelected, setGenresSelected] = useState(null);
  const { subGenreObjects } = useContext(UserContext);

  // Options for genre select
  const genresMap = useMemo(() => {
    if (!trackData || !trackData?.genres || trackData.genres.length === 0)
      return [];
    // from the server come with objects, but from the user come with strings
    if (typeof trackData.genres[0] === "string") return trackData.genres;

    return trackData.genres.map((item) => {
      return subGenreObjects[item.subgenre_id || item.genre_id] || null;
    });
  }, [trackData, subGenreObjects]);

  const permissionExist = (type) => {
    const permission =
      (dataToUpdate?.permission || trackData?.permission || [])?.filter(
        (item) => item.type === type
      ) || [];
    return permission.length && permission[0].enabled;
  };

  return (
    <Row justify="space-between" className="general-info-tracks pb-4">
      <Col xs={24} lg={11}>
        <h2>{dictionary.generalInformation}</h2>
        <Row justify="space-between" align="middle" className="mt-2 mr-4">
          <Col className="label" md={8} span={24}>
            {dictionary.trackName}
          </Col>
          <Col md={16} span={24}>
            <Input
              className="input-general"
              onChange={(e) => {
                dataToUpdateDispatcher({
                  type: "simpleChange",
                  payload: {
                    name: "title",
                    original: trackData.title,
                    value: e.target.value,
                  },
                });
              }}
              defaultValue={dataToUpdate?.title ?? trackData.title}
              value={dataToUpdate?.title ?? trackData.title}
              placeholder="My awsome track title"
              disabled={!isEditMode}
            />
          </Col>
        </Row>
        <Row justify="space-between" align="middle" className="mt-2 mr-4">
          <Col className="label" md={8} span={24}>
            {dictionary.titleVersion}
          </Col>
          <Col md={16} span={24}>
            <Input
              className="input-general"
              onChange={(e) => {
                dataToUpdateDispatcher({
                  type: "simpleChange",
                  payload: {
                    name: "version",
                    original: trackData.version || "",
                    value: e.target.value,
                  },
                });
              }}
              defaultValue={dataToUpdate?.version ?? trackData.version}
              value={dataToUpdate?.version ?? trackData.version}
              disabled={!isEditMode}
            />
          </Col>
        </Row>
        <Row justify="space-between" align="middle" className="mt-2 mr-4">
          <Col className="label" md={8} span={24}>
            {dictionary.audioLanguage}
          </Col>
          <Col md={16} span={24}>
            <Select
              className="selector-general"
              name="language"
              defaultValue={dataToUpdate?.audio_lang || trackData.audio_lang}
              options={languageOptions}
              placeholder={dictionary.select}
              onChange={(e) => {
                dataToUpdateDispatcher({
                  type: "simpleChange",
                  payload: {
                    name: "audio_lang",
                    original: trackData.audio_lang,
                    value: e,
                  },
                });
              }}
              disabled={!isEditMode}
            />
          </Col>
        </Row>
        <Row justify="space-between" align="middle" className="mt-2 mr-4">
          <Col className="label" md={8} span={24}>
            {dictionary.parentalAdvisory}
          </Col>
          <Col md={16} span={24}>
            <Select
              className="selector-general"
              name="advocacy"
              defaultValue={dataToUpdate?.advocacy || trackData.advocacy}
              options={advisoryOptions}
              placeholder={dictionary.select}
              onChange={(e) => {
                dataToUpdateDispatcher({
                  type: "simpleChange",
                  payload: {
                    name: "advocacy",
                    original: trackData.advocacy,
                    value: e,
                  },
                });
              }}
              disabled={!isEditMode}
            />
          </Col>
        </Row>
        <Row justify="space-between" align="middle" className="mt-2 mr-4">
          <Col className="label" md={8} span={24}>
            {dictionary.genre}
          </Col>
          <Col md={16} span={24}>
            <GenreSubGenreSelect
              name="genres"
              disabled={!isEditMode}
              defaultValue={genresMap}
              // The component only re-render if value change
              value={genresSelected ? genresSelected : genresMap}
              error={""}
              onChange={(e) => {
                setGenresSelected(e);
                dataToUpdateDispatcher({
                  type: "objectChange",
                  payload: {
                    name: "genres",
                    original: genresMap,
                    value: e,
                  },
                });
              }}
            />
          </Col>
        </Row>
      </Col>
      <Col xs={24} lg={11} className="creactive-info-title">
        <h2>{dictionary.trackCreativeInfo}</h2>
        <Row justify="space-between" align="middle" className="mt-2 mr-4">
          <Col className="label" md={8} span={24}>
            {dictionary.clipStartTime}
          </Col>
          <Col md={16} span={24}>
            <TimePicker
              showNow={false}
              onChange={(_, dateString) => {
                dataToUpdateDispatcher({
                  type: "simpleChange",
                  payload: {
                    name: "clip_start_time",
                    original: trackData.clip_start_time,
                    value: dateString,
                  },
                });
              }}
              placeholder="00:00"
              defaultValue={moment(
                dataToUpdate.clip_start_time ||
                  trackData.clip_start_time ||
                  "00:00",
                "mm:ss"
              )}
              format={"mm:ss"}
              disabled={!isEditMode}
            />
          </Col>
        </Row>
        <Row justify="space-between" align="middle" className="mt-2 mr-4">
          <Col className="label" md={8} span={24}>
            {dictionary.youtubeShortsClipDuration}
          </Col>
          <Col md={16} span={24}>
            <TimePicker
              showNow={false}
              onChange={(_, dateString) => {
                dataToUpdateDispatcher({
                  type: "simpleChange",
                  payload: {
                    name: "yt_clip_duration",
                    original: trackData.yt_clip_duration,
                    value: dateString,
                  },
                });
              }}
              placeholder="00:00"
              defaultValue={moment(
                dataToUpdate.yt_clip_duration ||
                  trackData.yt_clip_duration ||
                  "01:00",
                "mm:ss"
              )}
              format={"mm:ss"}
              disabled={!isEditMode}
            />
          </Col>
        </Row>
        <Row justify="space-between" align="middle" className="mt-2 mr-4">
          <Col className="label" md={8} span={24}>
            {dictionary.youtubeShortsPreviewStartDate}
          </Col>
          <Col md={16} span={24}>
            <Date
              value={
                dataToUpdate.yt_preview_start_date ||
                trackData.yt_preview_start_date
              }
              onChange={(_, dateString) => {
                dataToUpdateDispatcher({
                  type: "simpleChange",
                  payload: {
                    name: "yt_preview_start_date",
                    original: trackData.yt_preview_start_date,
                    value: dateString,
                  },
                });
              }}
              placeholder={dictionary.selectDate}
              disabled={!isEditMode}
            />
          </Col>
        </Row>
        <Row justify="space-between" align="middle" className="mt-2 mr-4">
          <Col className="label" md={8} span={24}>
            {dictionary.trackPermission}
          </Col>
          <Col md={16} span={24}>
            <Checkbox
              name={"track_sale"}
              checked={permissionExist("track_sale")}
              className="check-terms"
              onChange={(e) => {
                dataToUpdateDispatcher({
                  type: "partialPermission",
                  payload: {
                    permissionType: "track_sale",
                    name: "enabled",
                    original: trackData.permission || [],
                    value: e.target.checked,
                  },
                });
              }}
              disabled={!isEditMode}
            >
              {dictionary.sale}
            </Checkbox>
            <Checkbox
              name={"stream"}
              checked={permissionExist("stream")}
              className="check-terms"
              onChange={(e) => {
                dataToUpdateDispatcher({
                  type: "partialPermission",
                  payload: {
                    permissionType: "stream",
                    name: "enabled",
                    original: trackData.permission || [],
                    value: e.target.checked,
                  },
                });
              }}
              disabled={!isEditMode}
            >
              {dictionary.stream}
            </Checkbox>
            <Checkbox
              name={"download"}
              checked={permissionExist("download")}
              className="check-terms"
              onChange={(e) => {
                dataToUpdateDispatcher({
                  type: "partialPermission",
                  payload: {
                    permissionType: "download",
                    name: "enabled",
                    original: trackData.permission || [],
                    value: e.target.checked,
                  },
                });
              }}
              disabled={!isEditMode}
            >
              {dictionary.download}
            </Checkbox>
          </Col>
        </Row>
        <Row justify="space-between" align="middle" className="mt-2 mr-4">
          <Col className="label" md={8} span={24}>
            {dictionary.trackType}
          </Col>
          <Col md={16} span={24}>
            <Radio
              required={true}
              options={trackTypeOptions}
              onChange={(e) =>
                dataToUpdateDispatcher({
                  type: "simpleChange",
                  payload: {
                    name: "type",
                    original: trackData.type,
                    value: e.target.value,
                  },
                })
              }
              value={dataToUpdate?.type || trackData.type || "original"}
              className="radio-options"
              disabled={!isEditMode}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default TrackGeneralInformation;
