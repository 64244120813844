import React from 'react';
import './animated-logo.css';

const AnimatedLogoLoader = () => {
  return (
    <div className="animated-logo-container">
      <svg width="1200" height="160" viewBox="0 0 1200 160" fill="none" xmlns="http://www.w3.org/2000/svg" className="animated-logo">
        {/* P */}
        <path d="M46.5468 2.55515L46.3722 2.46783C58.4819 2.46783 68.1464 6.16422 75.3657 13.557C82.6432 21.008 86.2819 30.8747 86.2819 43.1572C86.2819 55.4979 82.6723 65.3064 75.453 72.5827C68.2338 79.9173 58.5984 83.5846 46.5468 83.5846H18.9506V117.9C18.9506 120.869 17.4078 122.353 14.3221 122.353H4.62848C1.54283 122.353 0 120.869 0 117.9V7.00827C0 4.03952 1.54283 2.55515 4.62848 2.55515H46.5468ZM44.8876 66.1213V66.296C51.8157 66.296 57.2884 64.2004 61.3056 60.0092C65.3228 55.818 67.3313 50.2007 67.3313 43.1572C67.3313 36.1137 65.3228 30.4963 61.3056 26.3051C57.2884 22.114 51.8157 20.0184 44.8876 20.0184H18.7759V66.1213H44.8876Z" className="logo-fill" />
        {/* R */}
        <path d="M173.35 77.8217L196.405 117.027C197.22 118.715 197.366 120.025 196.842 120.956C196.318 121.887 195.241 122.353 193.61 122.353H181.21C178.59 122.353 176.756 121.16 175.708 118.773L154.225 80.0046H128.462V117.9C128.462 120.869 126.919 122.353 123.834 122.353H114.14C111.055 122.353 109.512 120.869 109.512 117.9V7.00827C109.512 4.03952 111.055 2.55515 114.14 2.55515H159.814C171.225 2.55515 180.394 6.04779 187.323 13.0331C194.251 20.0766 197.715 29.4194 197.715 41.0616C197.715 50.2589 195.532 58.0882 191.165 64.5496C186.799 71.0692 180.86 75.4933 173.35 77.8217ZM128.375 20.1057V62.716H157.892C164.238 62.716 169.333 60.795 173.175 56.9531C177.018 53.1112 178.939 47.9305 178.939 41.4108C178.939 34.8912 177.018 29.7105 173.175 25.8686C169.333 22.0267 164.238 20.1057 157.892 20.1057H128.375Z" className="logo-fill" />
        {/* I */}
        <path d="M226.97 122.353C223.885 122.353 222.342 120.869 222.342 117.9V7.00827C222.342 4.03952 223.885 2.55515 226.97 2.55515H236.664C239.75 2.55515 241.292 4.03952 241.292 7.00827V117.9C241.292 120.869 239.75 122.353 236.664 122.353H226.97Z" className="logo-fill" />
        {/* A */}
        <path d="M365.126 117.376H364.952C365.941 120.694 364.719 122.353 361.284 122.353H349.32C346.933 122.353 345.448 121.101 344.866 118.598L337.53 94.8483H291.158L283.997 118.598C283.415 121.101 281.93 122.353 279.543 122.353H268.103C264.726 122.353 263.503 120.694 264.435 117.376L300.589 6.48438C301.172 3.86489 302.831 2.55515 305.567 2.55515H323.994C326.614 2.55515 328.273 3.86489 328.972 6.48438L365.126 117.376ZM296.31 77.8217H332.203L314.912 21.5028H313.427L296.31 77.8217Z" className="logo-fill" />
        {/* Animated M (Audio Bars) */}
        <g className="audio-bars">
          {/* Static bottom parts */}
          <rect x="389.05" y="80" width="18.989" height="42.264" className="logo-fill" />
          <rect x="425.567" y="80" width="18.989" height="42.264" className="logo-fill" />
          <rect x="462.084" y="80" width="18.989" height="42.264" className="logo-fill" />
          <rect x="497.14" y="80" width="18.989" height="42.264" className="logo-fill" />
          
          {/* Animated top parts */}
          <rect x="389.05" y="0" width="18.989" height="80" className="logo-fill bar-1" />
          <rect x="425.567" y="20" width="18.989" height="60" className="logo-fill bar-2" />
          <rect x="462.084" y="20" width="18.989" height="60" className="logo-fill bar-3" />
          <rect x="497.14" y="0" width="18.989" height="80" className="logo-fill bar-4" />
          
          <rect x="389.05" y="140.377" width="127.079" height="19.623" className="logo-fill" />
        </g>
        {/* D */}
        <path d="M636.024 2.55515C649.938 2.55515 661.029 6.42616 669.296 14.1682C677.622 21.9684 681.784 32.2718 681.784 45.0781V79.83C681.784 92.6946 677.593 102.998 669.209 110.74C660.883 118.482 649.793 122.353 635.936 122.353H597.074C593.989 122.353 592.446 120.869 592.446 117.9V7.00827C592.446 4.03952 593.989 2.55515 597.074 2.55515H636.024ZM663.008 79.0441V45.6893C663.008 37.7145 660.534 31.4568 655.585 26.9164C650.637 22.3759 643.912 20.1057 635.412 20.1057H611.397V104.802H635.412C643.912 104.802 650.637 102.503 655.585 97.9044C660.534 93.3058 663.008 87.019 663.008 79.0441Z" className="logo-fill" />
        {/* I */}
        <path d="M713.398 122.353C710.312 122.353 708.769 120.869 708.769 117.9V7.00827C708.769 4.03952 710.312 2.55515 713.398 2.55515H723.091C726.177 2.55515 727.72 4.03952 727.72 7.00827V117.9C727.72 120.869 726.177 122.353 723.091 122.353H713.398Z" className="logo-fill" />
        {/* G */}
        <path d="M841.161 60.0965L841.336 60.3585C844.422 60.3585 845.965 61.9301 845.965 65.0735V83.6719C845.965 96.0708 841.831 106.025 833.564 113.534C825.238 121.043 814.235 124.798 800.553 124.798C786.871 124.798 775.839 120.956 767.455 113.272C759.071 105.588 754.88 95.4305 754.88 82.7987V42.3713C754.88 29.6814 759.071 19.5527 767.455 11.9853C775.897 4.35968 786.93 0.546875 800.553 0.546875C813.827 0.546875 824.452 3.98131 832.428 10.8502C840.521 17.7773 844.946 27.4112 845.703 39.7518C846.052 42.4877 844.742 43.8557 841.773 43.8557H831.206C828.993 43.8557 827.422 42.7206 826.49 40.4504C825.85 33.174 823.23 27.6149 818.63 23.773C813.973 19.9311 807.918 18.0101 800.466 18.0101C792.373 18.0101 785.882 20.2803 780.991 24.8208C776.101 29.3612 773.655 35.386 773.655 42.8952V83.0607C773.655 90.5699 776.101 96.5947 780.991 101.135C785.882 105.676 792.373 107.946 800.466 107.946C808.384 107.946 814.759 105.763 819.591 101.397C824.423 97.0313 826.839 91.2102 826.839 83.9338V75.2895H807.539C804.57 75.2895 803.086 73.7469 803.086 70.6618V64.8116C803.086 61.7264 804.57 60.1547 807.539 60.0965H841.161Z" className="logo-fill" />
        {/* I */}
        <path d="M877.403 122.353C874.318 122.353 872.775 120.869 872.775 117.9V7.00827C872.775 4.03952 874.318 2.55515 877.403 2.55515H887.097C890.183 2.55515 891.725 4.03952 891.725 7.00827V117.9C891.725 120.869 890.183 122.353 887.097 122.353H877.403Z" className="logo-fill" />
        {/* T */}
        <path d="M996.259 2.55515C999.229 2.55515 1000.71 4.03952 1000.71 7.00827V15.3906C1000.71 18.4758 999.229 20.0475 996.259 20.1057H966.916V117.9C966.916 120.869 965.432 122.353 962.463 122.353H952.594C949.625 122.353 948.141 120.869 948.141 117.9V20.1057H918.798C915.712 20.1057 914.169 18.534 914.169 15.3906V7.00827C914.169 4.03952 915.712 2.55515 918.798 2.55515H996.259Z" className="logo-fill" />
        {/* A */}
        <path d="M1106.03 117.376H1105.86C1106.85 120.694 1105.63 122.353 1102.19 122.353H1090.23C1087.84 122.353 1086.35 121.101 1085.77 118.598L1078.44 94.8483H1032.06L1024.9 118.598C1024.32 121.101 1022.84 122.353 1020.45 122.353H1009.01C1005.63 122.353 1004.41 120.694 1005.34 117.376L1041.5 6.48438C1042.08 3.86489 1043.74 2.55515 1046.47 2.55515H1064.9C1067.52 2.55515 1069.18 3.86489 1069.88 6.48438L1106.03 117.376ZM1037.22 77.8217H1073.11L1055.82 21.5028H1054.33L1037.22 77.8217Z" className="logo-fill" />
        {/* L */}
        <path d="M1195.55 104.802C1198.52 104.802 1200 106.374 1200 109.517V117.9C1200 120.869 1198.52 122.353 1195.55 122.353H1133.63C1130.54 122.353 1129 120.869 1129 117.9V7.00827C1129 4.03952 1130.54 2.55515 1133.63 2.55515H1143.32C1146.41 2.55515 1147.95 4.03952 1147.95 7.00827V104.802H1195.55Z" className="logo-fill" />
      </svg>
    </div>
  );
};

export default AnimatedLogoLoader; 