import React, { useContext } from "react";
import { Card, Row, Col } from "antd";
import { useFieldArray } from "react-hook-form";
import { LanguageContext } from "../../../context/Language";
import Button from "../../../components/Buttons/GeneralButton";
import EscalationItem from "../../../components/Contracts/Create/Escalations/EscalationItem/EscalationItem";
import EscalationEquivalent from "../../../components/Contracts/Create/Escalations/EscalationEquivalent/EscalationEquivalent";

const CreateContractEscalations = () => {
  const { dictionary } = useContext(LanguageContext);

  const {
    fields: escalationfields,
    append: appendEscalation,
    remove: removeEscalation,
  } = useFieldArray({
    name: "escalation",
  });

  const {
    fields: escalationEquivalentfields,
    append: appendEscalationEquivalent,
    remove: removeEscalationEquivalent,
  } = useFieldArray({
    name: "escalationEquivalent",
  });

  const addEscalation = () => {
    appendEscalation({
      name: "",
      units: "",
      grossAmmount: 0,
      netammount: 0,
    });
  };

  const addEscalationEquivalent = () => {
    appendEscalationEquivalent({
      configuration: "",
      grossAmmount: 0,
    });
  };

  const drawEscalation = () => {
    return escalationfields.map((st, index) => {
      return (
        <EscalationItem
          key={st.id}
          data={st}
          index={index}
          remove={() => removeEscalation(index)}
        />
      );
    });
  };

  const drawEscalationEsquivalent = () => {
    return escalationEquivalentfields.map((st, index) => {
      return (
        <EscalationEquivalent
          key={st.id}
          data={st}
          index={index}
          remove={() => removeEscalationEquivalent(index)}
        />
      );
    });
  };

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Card bordered={false}>
          <h3>{dictionary.escalationsTracking}</h3>
          {drawEscalation()}
          <Button
            text={"+ " + dictionary.escalations}
            onClick={addEscalation}
            className="add-item"
          />
        </Card>
      </Col>
      <Col span={24}>
        <Card bordered={false}>
          <h3>{dictionary.escalationEquivalent}</h3>
          <p>{dictionary.escalationEquivalentDescription1}</p>
          <p>{dictionary.escalationEquivalentDescription2}</p>
          {drawEscalationEsquivalent()}
          <Button
            text={"+ " + dictionary.escalationEquivalent}
            onClick={addEscalationEquivalent}
            className="add-item"
          />
        </Card>
      </Col>
    </Row>
  );
};

export default CreateContractEscalations;
