import React, { useEffect, useState, useContext } from "react";
import { Card } from "antd";
import { LanguageContext } from "../../../context/Language";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

const GraphRoyalties = ({ filter, loading, statements = {} }) => {
  const { dictionary, locale, monthNames } = useContext(LanguageContext);
  const [data, setData] = useState({ labels: [], datasets: [] });
  let NumberFormat;
  try {
    NumberFormat = new Intl.NumberFormat(locale, {});
  } catch (error) {
    console.log("Locale is not valid", locale);
    NumberFormat = new Intl.NumberFormat("en-US", {});
  }

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: dictionary.netSales,
      },
    },
    scales: {
      y: {
        ticks: {
          callback: function (value) {
            return "$ " + NumberFormat.format(value);
          },
        },
        min: 0,
      },
    },
  };

  useEffect(() => {
    const years = Object.keys(statements);
    const months = filter.periodId;
    if (years.length === 0 || months.length === 0) return;

    const colorArray = [
      {
        backgroundColor: "rgba(54, 162, 235, 0.3)",
        borderColor: "rgba(54, 162, 235, 1)",
      }, // Blue
      {
        backgroundColor: "rgba(75, 192, 192, 0.3)",
        borderColor: "rgba(75, 192, 192, 1)",
      }, // Green
      {
        backgroundColor: "rgba(153, 102, 255, 0.3)",
        borderColor: "rgba(153, 102, 255, 1)",
      }, // Purple
      {
        backgroundColor: "rgba(255, 159, 64, 0.3)",
        borderColor: "rgba(255, 159, 64, 1)",
      }, // Orange
      {
        backgroundColor: "rgba(255, 99, 132, 0.3)",
        borderColor: "rgba(255, 99, 132, 1)",
      }, // Red
    ];

    const datasets = [];
    let count = 0;
    for (const year of years) {
      const data = [];
      for (const m of months) {
        const items = statements[year].root.filter(
          (item) => item.accountingPeriod === m
        );
        data.push(items.length === 0 ? 0 : items[0]?.netAmount || 0);
      }

      datasets.push({
        fill: true,
        hidden: Number(filter.year) !== Number(year),
        label: year,
        ...(colorArray[count] || {
          backgroundColor: "rgba(54, 162, 235, 0.5)",
          borderColor: "rgba(54, 162, 235, 1)",
        }),
        data,
        tension: 0.4,
      });
      count++;
    }
    setData({ labels: monthNames, datasets });
  }, [statements, filter]);

  return (
    <Card className="mb-2" loading={loading}>
      <Line options={options} data={data} />
    </Card>
  );
};

export default GraphRoyalties;
