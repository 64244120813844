import React, { useContext } from "react";
import { LanguageContext } from "../../../context/Language";
import ReleaseCard from "./ReleaseCard";

const Releases = ({ releases, changeItem, getReleases, type }) => {
  const { dictionary } = useContext(LanguageContext);

  return (
    <React.Fragment>
      <ReleaseCard
        changeItem={changeItem}
        releaseList={releases.published || []}
        listName={dictionary.publishedReleases}
        released={true}
        plus={true}
        section="published"
      />
      {releases.inReview.length !== 0 ? (
        <ReleaseCard
          changeItem={changeItem}
          releaseList={releases.inReview || []}
          listName={
            type !== "admin"
              ? dictionary.sendingToStores
              : dictionary.inReviewByAudioSalad
          }
          released={false}
          section="inReview"
        />
      ) : null}
      {releases.pendingDistribution.length !== 0 ? (
        <ReleaseCard
          changeItem={changeItem}
          releaseList={releases.pendingDistribution || []}
          listName={dictionary.pendingDistribution}
          released={false}
          section="pendingDistribution"
        />
      ) : null}
      {releases.readyToSent.length !== 0 ? (
        <ReleaseCard
          changeItem={changeItem}
          releaseList={releases.readyToSent || []}
          listName={type === "admin" ? dictionary.sent : dictionary.inReview}
          released={false}
          section="readyToSent"
        />
      ) : null}
      <ReleaseCard
        canDelete={true}
        getReleases={getReleases}
        changeItem={changeItem}
        releaseList={releases.draft || []}
        listName={dictionary.draftReleases}
        released={false}
        section="draft"
      />
      {type === "admin" && (
        <ReleaseCard
          changeItem={changeItem}
          releaseList={releases.rejected_team || []}
          listName={dictionary.rejected_team}
          released={false}
          section="rejected_team"
        />
      )}
      <ReleaseCard
        canDelete={true}
        getReleases={getReleases}
        changeItem={changeItem}
        releaseList={releases.rejected || []}
        listName={dictionary.rejectedReleases}
        released={false}
        section="rejected"
      />
    </React.Fragment>
  );
};

export default Releases;
