import React, { useEffect, useState, useReducer, useContext } from "react";
import { useLocation } from "react-router-dom";
import { notification } from "antd";
import { AppContext } from "../../../context/App";
import { ArtistContext } from "../../../context/Artist";
import { LanguageContext } from "../../../context/Language";
import UserCalls from "../../../classes/user/user";
import Actions from "../../../components/Users/Details/Actions";
import Profile from "../../../components/Users/Details/Profile";
import Contract from "../../../components/Users/Details/Contract";
import LabelList from "../../../components/Users/Details/LabelList";
import ArtistsList from "../../../components/Users/Details/ArtistsList";
import OtherDetails from "../../../components/Users/Details/OtherDetails";
import "./UserDetail.css";

const userDataChangeReducer = (state, action) => {
  try {
    const { name, value, original } = action.payload;
    let equal = false;
    switch (action.type) {
      case "cleanState": {
        return {};
      }
      case "stringChange": {
        equal = value.toLowerCase() === original.toLowerCase();
        break;
      }
      case "simpleChange": {
        equal = value === original;
        break;
      }
      case "cover": {
        equal = false;
        break;
      }
      case "objectChange": {
        equal = JSON.stringify(value) === JSON.stringify(original);
        break;
      }
      case "ladaphoneChange": {
        return { ...state, ["ladaCountry"]: value.value, ["code"]: value.code };
      }
      case "verify": {
        equal = value === original;
        return {
          ...state,
          ["verified"]: value.toLowerCase() === "no" ? false : true,
        };
      }
      default: {
        break;
      }
    }
    if (equal) {
      if (Object.prototype.hasOwnProperty.call(state, name)) {
        const nextState = { ...state };
        delete nextState[name];
        return nextState;
      } else {
        return state;
      }
    }
    return { ...state, [name]: value };
  } catch (error) {
    console.log(error);
    return state;
  }
};

const UserDetail = () => {
  const [dataUserChanged, dataUserChangedDispatcher] = useReducer(
    userDataChangeReducer,
    {}
  );

  const { getLabels } = useContext(AppContext);
  const { getArtists } = useContext(ArtistContext);
  const { dictionary } = useContext(LanguageContext);
  const [userId, setUserId] = useState(null);
  const [dataUser, setdataUser] = useState({});
  const [userLabels, setUserLabels] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [userArtrist, setUserArtrist] = useState([]);
  const [isEditMode, setIsEditMode] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const editParam = searchParams.get("edit");
    const userId = searchParams.get("u");
    setUserId(userId);
    loadUser(userId);
    loadArtists(userId);
    loadLabels(userId);
    setIsEditMode(!!editParam);
  }, [location.search]);

  const loadArtists = (userId) => {
    if (!userId) return;
    UserCalls.getUserArtists(userId)
      .then((data) => {
        setUserArtrist(data.artists ?? []);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const loadLabels = (userId) => {
    if (!userId) return;
    UserCalls.getUserLabels(userId)
      .then((data) => {
        setUserLabels(data.labels ?? []);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const loadUser = (userId) => {
    if (!userId) return;
    setIsLoading(true);
    UserCalls.getUserDetails(userId)
      .then((userData) => {
        setdataUser(userData.user);
        return UserCalls.getMembershipById(userId);
      })
      .then((membershipData) => {
        setdataUser((prevDataUser) => ({
          ...prevDataUser,
          membership: membershipData,
        }));
      })
      .catch((error) => {
        console.log(error);
        setdataUser({});
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  /*User and track save handler*/
  const sendDataToUpdate = () => {
    if (!userId) return;
    setIsLoading(true);
    const dataToSend = { ...dataUserChanged };
    UserCalls.userUpdate(userId, dataToSend, dataUser)
      .then((res) => {
        if (res.status === 200) {
          let label = {
            label_id: userLabels[0]?._id,
            name: userLabels[0]?.name ?? dataUser.profile?.company_name,
            email: dataToSend?.email,
          };
          UserCalls.updateLabel(label).then((res) => {
            if (res.status === 200) {
              notification.success({
                key: "UserDetailsNotification",
                placement: "topRight",
                message: dictionary.userUpdateSuccess,
                duration: 4,
              });
              getLabels();
              getArtists();
              loadUser(userId);
            } else {
            notification.error({
              key: "UserDetailsNotification",
              placement: "topRight",
              message: dictionary.stripeError,
              duration: 4,
            });
          }
        }); 
      } else {
        notification.error({
          key: "UserDetailsNotification",
          placement: "topRight",
          message: dictionary.stripeError,
          duration: 4,
        });
      }
    }).catch(() => {
        notification.error({
          key: "UserDetailsNotification",
          placement: "topRight",
          message: dictionary.stripeError,
          duration: 4,
        });
      })
      .finally(() => {
        loadUser(userId);
        setIsLoading(false);
      });
  };

  return (
    <div id="user-detail-container" className="container-box header">
      <Profile
        dispatch={dataUserChangedDispatcher}
        dataToUpdate={dataUserChanged}
        isLoading={isLoading}
        isEditMode={isEditMode}
        {...dataUser}
      />
      <OtherDetails
        dispatch={dataUserChangedDispatcher}
        dataToUpdate={dataUserChanged}
        isLoading={isLoading}
        isEditMode={isEditMode}
        {...dataUser}
      />
      <Contract
        dispatch={dataUserChangedDispatcher}
        dataToUpdate={dataUserChanged}
        isLoading={isLoading}
        isEditMode={isEditMode}
        userId={userId}
        membership={dataUser.membership}
        refreshUser={() => loadUser(userId)}
      />
      <ArtistsList
        userId={userId}
        isLoading={isLoading}
        artists={userArtrist}
        userLabels={userLabels}
        getArtists={() => loadArtists(userId)}
      />
      <LabelList
        userId={userId}
        isLoading={isLoading}
        labels={userLabels}
        getLabels={() => loadLabels(userId)}
      />
      <Actions
        sendDataToUpdate={() => {
          sendDataToUpdate();
        }}
        isEditMode={isEditMode}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        loadUser={() => loadUser(userId)}
        dataToUpdate={dataUserChanged}
        {...dataUser}
      />
    </div>
  );
};
export default UserDetail;
