/**
 * Create a new array with unique items by key, the items in the original array
 * will be replaced by the items in the new array
 * @module util
 * @param {Array<object>} originalArray
 * @param {Array<object>} newArray
 * @param {String} uniqueKey
 * @returns {Array<object>} merged array with unique items by key
 */
export const mergeObjectArrayByUniqueKey = (
  originalArray,
  newArray,
  uniqueKey
) => {
  const uniqueValues = {};

  originalArray.forEach((obj) => {
    uniqueValues[obj[uniqueKey]] = obj;
  });

  newArray.forEach((obj) => {
    uniqueValues[obj[uniqueKey]] = obj;
  });

  return Object.values(uniqueValues);
};

/**
 * Replace the file extension of a given filename with a new extension.
 * @param {string} filename - The original filename.
 * @param {string} newExtension - The new extension to replace the old one.
 * @returns {string} The filename with the new extension.
 */
export const replaceFileExtension = (filename, newExtension) => {
  const lastDotIndex = filename.lastIndexOf(".");

  if (lastDotIndex === -1) return filename.concat(newExtension);

  const filenameWithoutExt = filename.substring(0, lastDotIndex);
  return `${filenameWithoutExt}${newExtension}`;
};

/**
 * Reducer for the releaseDetail state and the TrackCrudModal state
 * @param {object} state
 * @param {object} action
 * @returns {object} - new state
 */
export const releaseDetailReducer = (state, action) => {
  try {
    const { name, value, original } = action.payload;
    // Except for the partialPermission action and contributors,
    // all the other actions are evaluated
    // out of the switch
    let equal = false;
    switch (action.type) {
      case "directChange": {
        return { ...state, ...value };
      }

      case "cleanState": {
        return {};
      }

      case "stringChange": {
        equal = value.toLowerCase() === original.toLowerCase();
        break;
      }

      case "simpleChange": {
        equal = value === original;
        break;
      }

      case "cover": {
        // This is a file, the original is a url
        equal = false;
        break;
      }

      case "objectChange": {
        equal = JSON.stringify(value) === JSON.stringify(original);
        break;
      }

      // This case make a return
      case "partialPermission": {
        // permissionType is required, and its aditional
        // for all the other parts of the regular payload
        const { permissionType } = action.payload;
        if (!permissionType) {
          console.error(
            "permissionType is required on partialPermission action"
          );
          return state;
        }

        // New track are empty, so for any lack of data,
        // the original data and the new data will be merged
        const actualPermission = mergeObjectArrayByUniqueKey(
          original || [],
          state?.permission || [],
          "type"
        );

        // Find the index of the permissionType
        const foundIndex = actualPermission.findIndex(
          (item) => item.type === permissionType
        );

        // If the permissionType is not found, then it will be added
        if (foundIndex === -1) {
          const permissionStruct = {
            type: permissionType,
            enabled: value,
            country: ["WW"],
          };
          // Add the new permission to the actual permission array
          const newPermission = actualPermission;
          newPermission.push(permissionStruct);
          const newState = {
            ...state,
            permission: newPermission,
          };
          return newState;
        } else {
          // If the permissionType is found, then it will be updated
          const foundItem = actualPermission[foundIndex];
          if (foundItem[name] !== value) {
            const newPermission = actualPermission;
            newPermission[foundIndex][name] = value;
            const newState = {
              ...state,
              permission: newPermission,
            };
            return newState;
          }
          // If the permissionType is found, and the value is the same,
          return state;
        }
      }

      // This case make a return
      // come from the Contributors and ContributorsEditView components
      case "contributors": {
        const { type, addKey, contributor, contributorIterable } =
          action.payload;

        // Contributors are added or deleted, roles are changed
        switch (type) {
          case "contributor": {
            const editedState = {};
            // We need go through each role, find the contributor in their origin
            // and update the name and artist_id
            for (const contributorItem of contributorIterable) {
              // Since contributorIterable is an array its possible that the
              // origin already exist in the editedState, so we need to check
              if (
                Object.hasOwnProperty.call(editedState, contributorItem.origin)
              ) {
                editedState[contributorItem.origin] = editedState[
                  contributorItem.origin
                ].map((item) => {
                  if (item._id !== contributorItem._id) return item;
                  return {
                    ...item,
                    name: value.name,
                    artist_id: value.artist_id,
                  };
                });
              } else {
                // If the origin is not in the editedState, we need to check
                // in the original state, and in the dispatched state
                if (Object.hasOwnProperty.call(state, contributorItem.origin)) {
                  editedState[contributorItem.origin] = state[
                    contributorItem.origin
                  ].map((item) => {
                    if (item._id !== contributorItem._id) return item;
                    return {
                      ...item,
                      name: value.name,
                      artist_id: value.artist_id,
                    };
                  });
                } else {
                  if (
                    Object.hasOwnProperty.call(original, contributorItem.origin)
                  ) {
                    editedState[contributorItem.origin] = original[
                      contributorItem.origin
                    ].map((item) => {
                      if (item.artist_id !== contributorItem._id) return item;
                      return {
                        ...item,
                        name: value.name,
                        artist_id: value.artist_id,
                      };
                    });
                  } else {
                    editedState[contributorItem.origin] = [value];
                  }
                }
              }
            }

            return { ...state, ...editedState };
          }
          case "add": {
            return Object.prototype.hasOwnProperty.call(state, addKey)
              ? {
                  ...state,
                  [addKey]: state[addKey].concat(value),
                }
              : {
                  ...state,
                  // This validation never will be, but ¯\_(ツ)_/¯
                  [addKey]: (Array.isArray(original[addKey])
                    ? original[addKey]
                    : []
                  ).concat(value),
                };
          }
          case "delete": {
            const editedState = {};
            //We need to remove all the roles and can be in different parts of the state
            for (const contributorItem of contributorIterable) {
              if (Object.hasOwnProperty.call(state, contributorItem.origin)) {
                editedState[contributorItem.origin] = state[
                  contributorItem.origin
                ].filter((item) => item._id !== contributorItem._id);
              } else if (
                Object.hasOwnProperty.call(editedState, contributorItem.origin)
              ) {
                editedState[contributorItem.origin] = editedState[
                  contributorItem.origin
                ].filter((item) => item._id !== contributorItem._id);
              } else {
                editedState[contributorItem.origin] = original[
                  contributorItem.origin
                ].filter((item) => item._id !== contributorItem._id);
              }
            }
            return { ...state, ...editedState };
          }
          case "change": {
            const editedState = {};
            // For non muiltilist roles
            if (!Array.isArray(value)) {
              for (const contributorItem of contributorIterable) {
                if (Object.hasOwnProperty.call(state, contributorItem.origin)) {
                  editedState[contributorItem.origin] = state[
                    contributorItem.origin
                  ].map((item) => {
                    if (item._id !== contributorItem._id) return item;
                    return {
                      ...item,
                      role: value,
                    };
                  });
                } else {
                  editedState[contributorItem.origin] = original[
                    contributorItem.origin
                  ].map((item) => {
                    if (item._id !== contributorItem._id) return item;
                    return {
                      ...item,
                      role: value,
                    };
                  });
                }
              }
              return { ...state, ...editedState };
            }

            // Role deletion
            if (value.length < contributorIterable.length) {
              const deletedRoles = contributorIterable.filter(
                (item) => !value.includes(item.role)
              );

              for (const deletedRole of deletedRoles) {
                if (Object.hasOwnProperty.call(state, deletedRole.origin)) {
                  editedState[deletedRole.origin] = state[
                    deletedRole.origin
                  ].filter((item) => item._id !== deletedRole._id);
                } else {
                  editedState[deletedRole.origin] = original[
                    deletedRole.origin
                  ].filter((item) => item._id !== deletedRole._id);
                }
              }
            } else {
              const invertedRoles = action.payload.invertedRoles;
              // Role addition
              const actualRoles = contributorIterable.map((item) => item.role);
              const addedRoles = value.filter(
                (item) => !actualRoles.includes(item)
              );

              for (const addedRole of addedRoles) {
                // We return the role to the server structure
                const newAddKey = invertedRoles[addedRole].key || addKey;
                const newRoleObjet = {
                  artist_id: contributor._id,
                  name: contributor.name,
                  role: addedRole,
                  _id: "new_" + new Date().toISOString(),
                  origin: newAddKey,
                };
                if (Object.hasOwnProperty.call(state, newAddKey)) {
                  editedState[newAddKey] =
                    state[newAddKey].concat(newRoleObjet);
                } else {
                  editedState[newAddKey] =
                    original[newAddKey].concat(newRoleObjet);
                }
              }
              // Add the new role
            }

            return { ...state, ...editedState };
          }

          default:
            break;
        }

        return state;
      } // End of the contributors case

      case "tracks": {
        const newTracksState = [...(state?.tracks || [])];
        const trackIndex = newTracksState.findIndex(
          (item) => item._id === value._id
        );
        if (trackIndex === -1) {
          newTracksState.push(value);
        } else {
          newTracksState[trackIndex] = {
            ...newTracksState[trackIndex],
            ...value,
          };
        }

        return { ...state, tracks: newTracksState };
      }

      default: {
        console.log("Unknown action: " + action.type);
        break;
      }
    }

    if (equal) {
      if (Object.prototype.hasOwnProperty.call(state, name)) {
        const nextState = { ...state };
        delete nextState[name];
        return nextState;
      } else {
        return state;
      }
    }

    return { ...state, [name]: value };
  } catch (error) {
    console.log(error);
    return state;
  }
};

/**
 * // TODO: this function should be part of an ResizedImage component
 * Get a resized image from the CDN or the frontend storage.
 * @param {object} params - The parameters for the resized image.
 * @param {string} params.url - The URL of the original image.
 * @param {string} params.type - The type of the resource (e.g., "artist" or "release").
 * @param {string} params.resourceId - The ID of the resource(e.g., userid | releaseid).
 * @param {string} [params.size="md"] - The size of the resized image (e.g., "xs", "sm", "md", "lg", "xl").
 * @param {string} checksum - The checksum for cache busting.
 * @returns {string} The URL of the resized image.
 */
export const getResizedImage = (
  { url, type, resourceId, size = "md" },
  checksum = null
) => {
  let origin =
    // eslint-disable-next-line no-undef
    process.env.REACT_APP_S3_BUCKET_CDN_URL || process.env.REACT_APP_STORAGE;

  if (origin.endsWith("/")) origin = origin.slice(0, -1);

  try {
    const sizes = {
      xs: "50x50",
      sm: "150x150",
      md: "250x250",
      lg: "640x640",
      xl: "1024x1024",
    };

    let subpath = "";
    switch (type) {
      case "artist":
        subpath = "public/artists";
        break;

      case "release":
        subpath = "public/releases";
        break;

      case "user":
        subpath = "public/users";
        break;

      default:
        subpath = "public/artists";
        break;
    }
    const base = `${origin}/${subpath}/${resourceId}/`;

    const newFilename = url
      .split("/")
      .pop()
      .replace(
        /.jpg|.png|.jpeg|.heic/gi,
        `${sizes[size]}.webp${checksum ? "v=" + checksum : ""}`
      );

    return base + newFilename;
  } catch (error) {
    console.log("getResizedError", error);
    return url;
  }
};
