import React, { useContext } from "react";
import { useFieldArray, useFormContext, Controller } from "react-hook-form";
import ReserveSchedule from "../../../components/Contracts/Create/Reserves/ReserveSchedule";
import { Row, Col, Select } from "antd";
import { LanguageContext } from "../../../context/Language";
import Button from "../../../components/Buttons/GeneralButton";

const CreateContractReserves = () => {
  const { dictionary } = useContext(LanguageContext);
  const { control } = useFormContext();
  const {
    fields: reservefields,
    append: appendReserve,
    remove: removeReserve,
  } = useFieldArray({
    name: "reserve",
  });

  const addPeriod = () => {
    appendReserve({
      rate: 0,
    });
  };

  const drawReserves = () => {
    return reservefields.map((st, index) => {
      return (
        <ReserveSchedule
          key={st.id}
          data={st}
          index={index}
          remove={() => removeReserve(index)}
        />
      );
    });
  };

  return (
    <Row gutter={[16, 16]} className="form-contrcts">
      <Col xs={24} lg={12}>
        <div className="form-box">
          <h3>{dictionary.reserveReleaseSchedule}</h3>
          {drawReserves()}
          <Button
            text={"+ " + dictionary.reservePeriod}
            onClick={addPeriod}
            className="add-item"
          />
        </div>
      </Col>
      <Col xs={24} lg={12}>
        <div className="form-box">
          <h3>{dictionary.reserveBasis}</h3>
          <p>{dictionary.reserveBasisDescription}</p>
          <Controller
            control={control}
            name="reserve.basis"
            rules={{
              required: true,
            }}
            render={({ field }) => (
              <Select
                className={"fullWidth selector-general"}
                options={[
                  { value: "Pre-Calculation", name: "Pre-Calculation" },
                  { value: "Post-Calculation", name: "Post-Calculation" },
                ]}
                {...field}
              />
            )}
          />
        </div>
      </Col>
    </Row>
  );
};

export default CreateContractReserves;
