import React from "react";

const AccountIcon = ({ className = null, onClick = null, color = null}) => {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M10.7451 1.05469C10.7432 1.05469 10.7414 1.05469 10.7395 1.05469C8.29817 1.05469 6.45215 3.17265 6.45215 5.6145C6.45215 8.05635 8.29817 10.1743 10.7395 10.1743C13.1808 10.1743 15.0268 8.05635 15.0268 5.6145C15.0268 3.17453 13.1836 1.05794 10.7451 1.05469ZM5.97461 11.9204C3.21319 11.9204 0.974609 14.159 0.974609 16.9204V18.04C0.974609 19.6969 2.31776 21.04 3.97461 21.04H17.5071C19.1639 21.04 20.5071 19.6969 20.5071 18.04V16.9204C20.5071 14.159 18.2685 11.9204 15.5071 11.9204H5.97461Z" fill="transparent"/>
      <path d="M10.7451 1.05469L10.7444 1.55469L10.7444 1.55469L10.7451 1.05469ZM10.7458 0.554692C10.7437 0.554689 10.7416 0.554688 10.7395 0.554688V1.55469C10.7411 1.55469 10.7428 1.55469 10.7444 1.55469L10.7458 0.554692ZM10.7395 0.554688C7.98285 0.554688 5.95215 2.93739 5.95215 5.6145H6.95215C6.95215 3.40791 8.61349 1.55469 10.7395 1.55469V0.554688ZM5.95215 5.6145C5.95215 8.29161 7.98285 10.6743 10.7395 10.6743V9.67431C8.61349 9.67431 6.95215 7.82108 6.95215 5.6145H5.95215ZM10.7395 10.6743C13.4961 10.6743 15.5268 8.29161 15.5268 5.6145H14.5268C14.5268 7.82108 12.8655 9.67431 10.7395 9.67431V10.6743ZM15.5268 5.6145C15.5268 2.93942 13.4993 0.558361 10.7458 0.554692L10.7444 1.55469C12.868 1.55752 14.5268 3.40964 14.5268 5.6145H15.5268ZM1.47461 16.9204C1.47461 14.4351 3.48933 12.4204 5.97461 12.4204V11.4204C2.93704 11.4204 0.474609 13.8828 0.474609 16.9204H1.47461ZM1.47461 18.04V16.9204H0.474609V18.04H1.47461ZM3.97461 20.54C2.5939 20.54 1.47461 19.4207 1.47461 18.04H0.474609C0.474609 19.973 2.04161 21.54 3.97461 21.54V20.54ZM17.5071 20.54H3.97461V21.54H17.5071V20.54ZM20.0071 18.04C20.0071 19.4207 18.8878 20.54 17.5071 20.54V21.54C19.4401 21.54 21.0071 19.973 21.0071 18.04H20.0071ZM20.0071 16.9204V18.04H21.0071V16.9204H20.0071ZM15.5071 12.4204C17.9924 12.4204 20.0071 14.4351 20.0071 16.9204H21.0071C21.0071 13.8828 18.5447 11.4204 15.5071 11.4204V12.4204ZM5.97461 12.4204H15.5071V11.4204H5.97461V12.4204Z" fill={color}/>
    </svg>
  );
};

export default AccountIcon;
