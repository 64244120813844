import React, { useContext } from "react";
import { Menu, Divider } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { UserContext } from "../../../context/User";
import { LanguageContext } from "../../../context/Language";
import Avatar from "../../Navbar/UserMenu/Avatar/Avatar";
import "../set.css";
import IdIcon from "../../Icon/IdIcon";
import CardIcon from "../../Icon/CardIcon";
import PwdIcon from "../../Icon/PwdIcon";
import PayeeIcon from "../../Icon/PayeeIcon";
import AccountIcon from "../../Icon/AccountIcon";

const MenuSettings = ({ section, changeSection, alert }) => {

  const { profileData, type } = useContext(UserContext);
  const { dictionary } = useContext(LanguageContext);

  const changeItem = (e) => {
    changeSection(e.key);
  };

  const returnHover = () => {
    let ref = window.location.hash;
    switch (ref) {
      case "":
        document
          .getElementById("profile-set")
          .classList.add("ant-menu-item-selected");
        break;
      case "#membership":
        document
          .getElementById("payment-set")
          .classList.add("ant-menu-item-selected");
        break;
      case "#password":
        document
          .getElementById("password-set")
          .classList.add("ant-menu-item-selected");
        break;
      case "#payee":
        document
          .getElementById("payee-set")
          .classList.add("ant-menu-item-selected");
        break;
      default:
        break;
    }
  };

  const items = [
    {
      label: (
        <div className="top-info">
          <Avatar otherClass="avatar-stt" />
          <div className="name">
            <sub>{dictionary.hello} 👋</sub>
            {profileData.name} {profileData.surname}
          </div>
        </div>
      ),
      className: "user-item",
    },
    {
      label: <Divider />,
      className: "divisor",
    },
    {
      label: <Link to="/settings">{dictionary.profile}</Link>,
      key: "",
      className: "item-list item-back",
      icon: <IdIcon />,
      onClick: changeItem,
      id: "profile-set",
      onMouseOut: returnHover,
    },
    (type !== "admin") ?{
      label: <Link to="#membership">{dictionary.membership}</Link>,
      key: "#membership",
      className: "item-list item-back",
      icon: <CardIcon />,
      onClick: changeItem,
      id: "payment-set",
      onMouseOut: returnHover,
    }: null,
    (type !== "admin") ? 
    {
      label: <Link to="#payee" className="payee-link">
        <span className="payee-text">{dictionary.payee}</span>
        {alert && <ExclamationCircleFilled className="circle-alert" />}
      </Link>,
      key: "#payee",
      className: "item-list item-back",
      icon: <PayeeIcon className="payee-icon" />,
      onClick: changeItem,
      id: "payee-set",
      onMouseOut: returnHover,
    }: null,
    {
      label: <Link to="#password">{dictionary.password}</Link>,
      key: "#password",
      className: "item-list item-back",
      icon: <PwdIcon />,
      onClick: changeItem,
      id: "password-set",
      onMouseOut: returnHover,
    },
    {
      label: <Link to="#account">{dictionary.account}</Link>,
      key: "#account",
      className: "item-list item-back",
      icon: <AccountIcon/>,
      onClick: changeItem,
      id: "account-set",
      onMouseOut: returnHover,
    },
  ];

  return (
    <Menu
      id="parents"
      items={items}
      mode="inline"
      className="settings"
      selectedKeys={[section]}
    />
  );
};

export default MenuSettings;
