import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { UserContext } from '../../../context/User';
import { LanguageContext } from '../../../context/Language';
import { getResizedImage } from '../../../util';
import moment from "moment";
import AlbumIcon from '../../Icon/AlbumIcon/AlbumIcon.svg';

const MobileReleaseCard = ({ release, showDeleteDraft }) => {
  const { permission, type } = useContext(UserContext);
  const { dictionary } = useContext(LanguageContext);
  const [coverImage, setCoverImage] = useState(null);
  const [originalImageUrl, setOriginalImageUrl] = useState(null);
  
  // Get and set cover image on component mount
  useEffect(() => {
    if (release.image || release.image_cover) {
      const url = release.image_cover
        ? release.s3_url + '/' + release.image_cover?.filename
        : release.image;
      const type = release.image_cover ? 'release' : 'artist';
      setOriginalImageUrl(url);
      
      // Get resized image URL
      const imageResized = getResizedImage({
        url,
        type,
        size: 'xs',
        resourceId: release._id,
      });
      
      setCoverImage(imageResized);
    } else if (release.cover) {
      setCoverImage(release.cover);
      setOriginalImageUrl(release.cover);
    }
  }, [release]);
  
  // Get release date from multiple possible properties
  const getReleaseDate = () => {
    // Check multiple possible properties for the release date
    const dateValue = release.date || release.release_date || release.releaseDate;
    return moment.utc(dateValue).startOf("day").format("YYYY/MM/DD")
  };
  
  // Get appropriate status display text
  const getStatusDisplay = (status) => {
    if (!status) return '';
    
    let steps =
      status ? (
        <div
          className={
            "mobile-status-tag " + status + (type !== "admin" ? "client" : "")
          }
          style={status === "rejected_team" ? { fontSize: '10px' } : {}}
        >
          {status === "readyToSent" && type !== "admin"
            ? dictionary.inReview
            : status === "inReview" && type !== "admin"
            ? dictionary.sendingToStores
            : status === "inReview" && type === "admin"
              ? dictionary.inReviewByAudioSalad
            : status === "rejected_team" && type !== "admin"
            ? dictionary.rejected
            :dictionary[status]}
        </div>
      ) : status ? (
        status === "inReview" && type === "admin" ? (
          dictionary.inReviewByAudioSalad
        ) : (
          dictionary[status]
        )
      ) : null;
    return steps;
  };
  
  // Handle image loading error - try original image URL first, if undefined s3_url then fallback to AlbumIcon
  const handleImageError = (e) => {
    // e.target.onerror = null;
    // If the current src is not the original, try using original image URL
    if (!originalImageUrl.includes("undefined") && e.target.src !== originalImageUrl) {
      e.target.src = originalImageUrl;
    } else {
      // If original image also fails, use AlbumIcon
      e.target.src = AlbumIcon;
    }
  };
  
  // Handle click on the card
  const cardClickHandler = () => {
    // Navigate to edit page based on permission
    if (permission?.release?.update) {
      if(type === "admin"){
        window.location.href= `release/view?r=${release._id}&edit=true&section=release`;
      } else{
        window.location.href = `/newRelease?r=${release._id}`;
      }
    }
  };
  
  return (
    <div 
      className="mobile-release-card" 
      onClick={cardClickHandler}
    >
      <div className="mobile-release-cover">
        {coverImage ? (
          <img 
            src={coverImage} 
            alt={release.title || dictionary.noTitle} 
            id={`img-mobile-${release._id}`}
            onError={handleImageError}
          />
        ) : (
          <img 
            src={AlbumIcon} 
            alt={release.title || dictionary.noTitle} 
            className="default-album-cover"
          />
        )}
      </div>
      
      <div className="mobile-release-info">
        <div className="mobile-release-header">
          <h3 className="mobile-release-title">
            {release.title || dictionary.noTitle}
          </h3>
          <p className="mobile-release-artist">
            {type === "admin" 
              ? dictionary.label+": "+(release.label?.name || dictionary.noLabels)
              : release.artist || dictionary.noArtists
            }
            
          </p>
        </div>
        
        <div className="mobile-release-details">
          <div className="mobile-release-date">
            <span className="label">
              {dictionary.releaseDate}: {getReleaseDate()}
            </span>
          </div>
          
          <div className="mobile-release-status">
            {getStatusDisplay(release.step)}
          </div>
        </div>
      </div>
      
      <div className="mobile-release-actions" onClick={(e) => e.stopPropagation()}>
        {permission?.release?.update && (
          <Link 
            to={type === "admin"
              ? `view?r=${release._id}&edit=true&section=release`
              : `/newRelease?r=${release._id}`} 
            className="action-btn"
            onClick={(e) => e.stopPropagation()}
          >
            <EditOutlined />
          </Link>
        )}
        
        {release.step === 'draft' && permission?.release?.delete && (
          <div 
            className="action-btn delete"
            onClick={(e) => {
              e.stopPropagation();
              showDeleteDraft(release._id, release.title);
            }}
          >
            <DeleteOutlined />
          </div>
        )}
      </div>
    </div>
  );
};

export default MobileReleaseCard; 