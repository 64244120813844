import React, { useEffect, useState, useContext } from "react";
import { AppContext } from "../../../context/App";
import { CheckCircleFilled, LoadingOutlined } from "@ant-design/icons";
import { LanguageContext } from "../../../context/Language";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Collapse, Row, Col, List, Typography, Empty, notification, Divider } from "antd";
import Admin from "../../../classes/admin/admin";
import Modal from "../../../components/Modal/Modal";
import PenIcon from "../../../components/Icon/PenIcon";
import MicIcon from "../../../components/Icon/MicIcon";
import UpcIcon from "../../../components/Icon/UpcIcon";
import MusicIcon from "../../../components/Icon/MusicIcon";
import ReleaseIcon from "../../../components/Icon/ReleaseIcon";
import ReleaseCalls from "../../../classes/release/release";
import Button from "../../../components/Buttons/GeneralButton";
import ReviewNoteModal from "../../../components/Release/Detail/Modals/ReviewNoteModal";
const { Panel } = Collapse;
import "./verification.css";

const CopyrightVerification = () => {
  
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const relId = searchParams.get("r");

  const { labelOptions } = useContext(AppContext);
  const { dictionary } = useContext(LanguageContext);
  const [track, setTracks] = useState([]);
  const [release, setRelease] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showModal, setShowModal] = useState({
    reviewNote: false,
    confirm: false,
  });

  useEffect(() => {
    getTracksData();
  }, []);

  const getTracksData = () => {
    ReleaseCalls.getReleaseDetails(relId)
    .then((data) => {
      setRelease(data?.release);
      setTracks(data?.tracks);
    }).catch((e) => {
      console.log(e);
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const dataCard = (icon = null, section = null, data = null) => {
    return (
      <Col lg={12} md={12} sm={24} xs={24}>
      <div className="row-preview-verif">
        <div className="icon-head">{icon}</div>
        <div className="info-head">
          <div className="sub">{section}</div>
          <div className="names">{data}</div>
        </div>
      </div>
      </Col>
    );
  };

  const toggleModal = (key) => {
    setShowModal((prev) => ({ ...prev, [key]: !prev[key] }));
  };

  const confirmVerification = () => {
    toggleModal("confirm");
    setIsLoading(true);
  };

  const closeConfim = () => {
    toggleModal("confirm");
    setIsLoading(false);
  };

  const finishVerification = () => {
    setIsLoading(true);
    Admin.releaseUpdate(release._id, { copyright_response: {} })
    .then(() => {
      let countdown = 3;
      const interval = setInterval(() => {
        countdown -= 1;
        if (countdown === 0) {
          clearInterval(interval);
          navigate(`/release/view?r=${release._id}&edit=true&section=review`);
        }
      }, 1000);
      notification.success({
        message: dictionary.verificationSuccess,
        description: (
          <div>
            {dictionary.verificationSuccessDesc}
            <LoadingOutlined  className="success-load" />
          </div>
        ),
        placement: "top",
        icon: <CheckCircleFilled className="success-top" />,
        duration: 3,
      });
    }).catch((e) => {
      setIsLoading(false);
      console.log(e);
    }).finally(() => {
      toggleModal("confirm");
      setIsLoading(false);
    });
  };

  const eachDataTrack = () => {
    const labelName = labelOptions.find(option => option.value === release.label_id)?.label || "N/A";
    return track.map((el, index) => {
      const panelClassName = index === 0 ? "first-panel" : "";
      
      const copyrightData = release?.copyright_response?.data;
      const resultData = copyrightData?.results?.[index];
      const externalData = resultData?.identificationResponse?.metadata?.music;
      
      const externalMetadata = externalData?.[0]?.external_metadata || {};
      const externalMetadataArray = Object.keys(externalMetadata).map(key => ({
        key,
        data: externalMetadata[key]
      }));
      return (
        <Panel
          key={index}
          header={dictionary.track +" "+ (index+1)+": "+ el.title}
          className={panelClassName}
        >
          <p>
            <Typography.Title level={4}>{dictionary.foundFingerprintMetadata}</Typography.Title>
            <Row className="row-verif">
              {externalData ?
              <React.Fragment>
                {dataCard(
                  <PenIcon />,
                  dictionary.title,
                  externalData[0]?.title || "N/A")
                }
                {dataCard(
                  <MicIcon />,
                  dictionary.artists,
                  externalData[0]?.artists.map(artist => artist.name).join(", "))
                }
                {dataCard(
                  <ReleaseIcon color="#FF5151" />,
                  dictionary.album,
                  externalData[0]?.album?.name || "N/A")
                }
                {dataCard(
                  <MusicIcon />,
                  dictionary.label,
                  externalData[0]?.label || "N/A")
                }
                {dataCard(
                  <UpcIcon color="#FF5151" />,
                  dictionary.isrc,
                  externalData[0]?.external_ids?.isrc || "N/A")
                }
                {dataCard(
                  <UpcIcon color="#FF5151" />,
                  "UPC",
                  externalData[0]?.external_ids?.upc || "N/A")
                } 
              </React.Fragment>
              : 
                <div className="no-data-found">
                  {dataCard(
                    <ReleaseIcon color="#c3c3c3" />,
                    "--",dictionary.noDataFound)
                  }
                </div>
              }
            </Row>
            <h3 className="external-links-title">
            {dictionary.externalLinks}
            </h3>
            {externalMetadataArray
            ?
              <List
                size="small"
                className="external-links"
                style={{ marginBottom: '20px' }}
                dataSource={externalMetadataArray.length > 0 ? externalMetadataArray : []}
                renderItem={(item) => (
                  <List.Item>
                    <List.Item.Meta
                      title={<div className="metadata-title">{item.key}</div>}
                      description={
                        item.key === "spotify" ? (
                          <Link
                            to={"https://open.spotify.com/track/" + (item.data?.track?.id)}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            open.spotify.com/track/{item.data?.track?.id}
                          </Link>
                        ) : item.key === "youtube" ? (
                          <Link
                            to={"https://www.youtube.com/watch?v=" + item.data?.vid}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            www.youtube.com/watch?v={item.data?.vid}
                          </Link>
                        ) : null
                      }
                    />
                  </List.Item>
                )}
                locale={{ emptyText:
                  <Empty
                  description={dictionary.noData}
                  className="nodata-links"
                  ></Empty>
                }}
              />
            : null}
            <Divider/>
            <Typography.Title level={4}>{dictionary.userTrackInfo}</Typography.Title>
            <Row className="row-verif">
              {dataCard(<PenIcon />, dictionary.title, el.title || "N/A")}
              {dataCard(<MicIcon />, dictionary.artists, el.artists.map(artist => artist.name).join(", "))}
              {dataCard(<ReleaseIcon color="#FF5151" />, dictionary.album, release.title || "N/A")}
              {dataCard(<MusicIcon />, dictionary.label, labelName)}
              {dataCard(<UpcIcon color="#FF5151" />, dictionary.isrc, el.isrc || "N/A")}
              {dataCard(<UpcIcon color="#FF5151" />, "UPC", release.upc || "N/A")}
            </Row>
          </p>
        </Panel>
      );
    });
  };

  return (
    <div id="release-detail-container ">
      <div className="container-admin">
        <Collapse
          defaultActiveKey={['0']}
          className="verify-box"
        >
          <div className="album-desc">{dictionary.copyrightVerificationDesc}</div>
          {eachDataTrack()}
          <div className="verify-btns">
            <Button
              size="ms"
              className="reject"
              onClick={() => toggleModal("reviewNote")}
              text={dictionary.rejectSendToClient}
              disabled={isLoading}
            />
            <Button
              size="ms"
              className="grn"
              onClick={() => confirmVerification()}
              text={dictionary.completeVerif}
              disabled={isLoading}
            />
          </div>
        </Collapse>
        <ReviewNoteModal
          releaseId={release._id}
          isModalVisible={showModal.reviewNote}
          releaseTitle={release.title}
          reviewNotes={release.rejections}
          onCancel={() => toggleModal("reviewNote")}
          onConfirm={() => navigate("/release")}
        />
        <Modal
          title={dictionary.completeVerif}
          body={dictionary.completeVerifDesc}
          footer={
            <div className="verify-btns">
              <Button
                size="ms"
                className="close"
                onClick={() => closeConfim()}
                text={dictionary.close}
              />
              <Button
                size="ms"
                className="grn"
                onClick={() => finishVerification()}
                text={dictionary.confirm}
              />
            </div>
          }
          handleCancel={() => closeConfim()}
          isModalVisible={showModal.confirm}
          closeable = {true}
          closeIcon = {true}
        />
      </div>
    </div>
  );
};
export default CopyrightVerification;
