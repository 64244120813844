import React, { useContext, useEffect } from "react";
import Input from "../../../../../Form/Input/GeneralInput";
import { useFieldArray, useFormContext, Controller } from "react-hook-form";
import { Button, Select } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import CreateSalesEscalationCondition from "./Condition";
import { AppContext } from "../../../../../../context/App/AppProvider";
import { LanguageContext } from "../../../../../../context/Language";

const CreateSalesEscalation = ({ parentIndex, index, remove }) => {

  const { dictionary } = useContext(LanguageContext);
  const formMethods = useFormContext();
  const { dealTypeSalesContractOptions, openedContract, setOpenedContract } =
    useContext(AppContext);
  const { control } = formMethods;

  const {
    fields: salesEscalationCondition,
    append: appendSalesEscalationCondition,
    remove: removeSalesEscalationCondition,
    replace: replaceSalesEscalationCondition,
  } = useFieldArray({
    control,
    name: `salesTerms-escalation-conditions_${parentIndex}-${index}`,
  });

  const addSalesEscalationConditions = () => {
    appendSalesEscalationCondition(
      {
        escalationTracker: "",
        thresholdType: "",
        when: "",
        threshold: "",
      },
      {
        shouldFocus: false,
      }
    );
  };

  useEffect(() => {
    if (
      openedContract &&
      openedContract[`salesTerms-escalation-conditions_${parentIndex}-${index}`]
    ) {
      replaceSalesEscalationCondition(
        openedContract[
          `salesTerms-escalation-conditions_${parentIndex}-${index}`
        ]
      );
      delete openedContract[
        `salesTerms-escalation-conditions_${parentIndex}-${index}`
      ];
      setOpenedContract({
        ...openedContract,
      });
    }
  }, [openedContract]);

  useEffect(() => {
    addSalesEscalationConditions();
  }, [appendSalesEscalationCondition]);

  const drawConditions = () => {
    return salesEscalationCondition.map((st, conditionIndex) => {
      return (
        <CreateSalesEscalationCondition
          key={`sales-escalation-condition-${index}`}
          parentIndex={index}
          salesIndex={parentIndex}
          index={conditionIndex}
          addItem={
            conditionIndex >= salesEscalationCondition.length - 1
              ? addSalesEscalationConditions
              : null
          }
          remove={
            conditionIndex < salesEscalationCondition.length - 1
              ? () => removeSalesEscalationCondition(conditionIndex)
              : null
          }
          data={st}
        />
      );
    });
  };

  return (
    <div className="input-row-group input-row-subgroup">
      {drawConditions()}
      <div className="input-row">
        <div className={"relative-input"}>
          <Controller
            control={control}
            name={`salesTerms-escalation_${parentIndex}.${index}.type`}
            rules={{ required: "This field is required" }}
            render={({ field, fieldState }) => (
              <React.Fragment>
                <label className="allWidth">{dictionary.dealType}</label>
                <Select
                  className={
                    "fullWidth selector-general" +
                    (fieldState.error ? " input-general-error" : "")
                  }
                  options={dealTypeSalesContractOptions}
                  {...field}
                />
                {fieldState.error && (
                  <div className="text-error">{fieldState.error.message}</div>
                )}
              </React.Fragment>
            )}
          />
        </div>
        <Controller
          control={control}
          name={`salesTerms-escalation_${parentIndex}.${index}.rate`}
          rules={{
            required: "This field is required",
            min: "Field must be grather or equal than 0",
          }}
          render={({ field, fieldState }) => (
            <Input
              className={"form-box-input"}
              label={dictionary.rate}
              placeholder={dictionary.rate}
              type="number"
              min={0}
              {...field}
              error={fieldState.error?.message}
            />
          )}
        />
        <Controller
          control={control}
          name={`salesTerms-escalation_${parentIndex}.${index}.multiplier`}
          rules={{
            required: "This field is required",
            min: "Field must be grather or equal than 0",
          }}
          render={({ field, fieldState }) => (
            <Input
              className={"form-box-input"}
              label={dictionary.multiplier}
              placeholder={dictionary.multiplier}
              type="number"
              min={0}
              {...field}
              error={fieldState.error?.message}
            />
          )}
        />
        <Controller
          control={control}
          name={`salesTerms-escalation_${parentIndex}.${index}.reductionRate`}
          rules={{
            required: "This field is required",
            min: "Field must be grather or equal than 0",
          }}
          render={({ field, fieldState }) => (
            <Input
              className={"form-box-input"}
              label={dictionary.reductionRate}
              placeholder={dictionary.reductionRate}
              type="number"
              min={0}
              {...field}
              error={fieldState.error?.message}
            />
          )}
        />
        <Controller
          control={control}
          name={`salesTerms-escalation_${parentIndex}.${index}.reserve`}
          rules={{
            required: "This field is required",
            min: "Field must be grather or equal than 0",
          }}
          render={({ field, fieldState }) => (
            <Input
              label={dictionary.reserve}
              placeholder={dictionary.reserve}
              type="number"
              min={0}
              {...field}
              error={fieldState.error?.message}
            />
          )}
        />
        <Button
          className="remove remove-cross"
          type="danger"
          onClick={() => remove()}
          icon={<DeleteOutlined />}
        />
      </div>
    </div>
  );
};

export default CreateSalesEscalation;
