import React, { useContext, useState, useEffect } from "react";
import { UserContext } from "../../context/User";
import { LanguageContext } from "../../context/Language";
import User from "../../classes/user/user.jsx";
import Release from "../../classes/release/release.jsx";
import ReviewNoteModal from "../../components/Release/Detail/Modals/ReviewNoteModal";
import ResponsiveReleasesList from "../../components/Catalog/ReleasesList/ResponsiveReleasesList";
import "../../components/Catalog/catalog.css";

/**
 * Cairo & Lino order priority ASC
 */
const voidReleases = {
  published: [], // 1
  rejected: [], // 2
  inReview: [], // 3
  readyToSent: [], // 4
  pendingDistribution: [], // 5
  delivered: [], // 6
  draft: [], // 7
};

const Releases = () => {

  const { dictionary } = useContext(LanguageContext);
  const { isAdmin, type } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [showReviewNoteModal, setShowReviewNoteModal] = useState(false);
  const [releaseSelected, setReleaseSelected] = useState({});
  const [releases, setReleases] = useState(voidReleases);
  const [allStatus, setAllStatus] = useState([]);

  useEffect(() => {
    getReleases();
    getStatusOptions();
    // eslint-disable-next-line
  }, []);

  const getReleases = async () => {
    setLoading(true);
    try {
      const resp = await User.getReleases();
      const releasesObject = resp.body.releases || voidReleases;
      setReleases(releasesObject);
      setLoading(false);
    } catch (e) {
      setReleases(voidReleases);
      setLoading(false);
    }
  };

  const getStatusOptions = () => {
    try {
      const list = [];
    Release.audiosaladStatus().then((res) => {
      let allstatus = res?.body?.status || [];
      let keys = Object.keys(allstatus);
      
      if (type === "admin") {
        for (let i = 0; i < keys.length; i++) {
          list.push({ _id: keys[i], name: dictionary[keys[i]] });
        }
      } else {
        // Add all statuses except readyToSent and rejected_team for clients 
        for (let i = 0; i < keys.length; i++) {
          if (keys[i] !== "readyToSent" && keys[i] !== "rejected_team") {
            list.push({ _id: keys[i], name: dictionary[keys[i]] });
          }
          if (keys[i] === "pendingDistribution") {
            list.push({ _id: "sendingToStores", name: dictionary.sendingToStores });
          }
        }
      }
      setAllStatus(list);
    });
    } catch (error) {
      console.error("Error fetching status options:", error);
    }
  };

  return (
    <React.Fragment>
      <div className={isAdmin}>
        <ResponsiveReleasesList
          filter={1}
          releases={releases}
          getReleases={getReleases}
          loadList={loading}
          canDelete={true}
          allStatus={allStatus}
        />
      </div>
      <ReviewNoteModal
        releaseId={releaseSelected._id}
        isModalVisible={showReviewNoteModal}
        releaseTitle={releaseSelected.title}
        reviewNotes={releaseSelected.review_note}
        onCancel={() => setShowReviewNoteModal(false)}
        onConfirm={() => {
          setShowReviewNoteModal(false);
          setReleaseSelected({});
          getReleases();
        }}
      />
    </React.Fragment>
  );
};

export default Releases;
