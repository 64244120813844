import React, { useEffect, useCallback, useContext } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import Button from "../Buttons/GeneralButton";
import { LanguageContext } from "../../context/Language";

const ReCaptcha = ({ setToken, expired, resent, setResent }) => {

  const { dictionary } = useContext(LanguageContext);

  const { executeRecaptcha } = useGoogleReCaptcha();

  const resend = () => {
    handleReCaptchaVerify();
    setResent(true);
  };

  const handleReCaptchaVerify = useCallback(async () => {
    try {
      if (!executeRecaptcha) {
        console.warn('reCAPTCHA not yet available');
        return;
      }

      const token = await executeRecaptcha();
      if (!token) {
        throw new Error('Failed to get reCAPTCHA token');
      }
      
      setToken(token);
    } catch (error) {
      console.error('reCAPTCHA verification failed:', error);
      setToken(null);
      setResent(false);
    }
  }, [executeRecaptcha, setToken, setResent]);

  useEffect(() => {
    handleReCaptchaVerify();
  }, [handleReCaptchaVerify]);

  return expired && resent ? (
    <p>{dictionary.captchaResend}</p>
  ) : expired ? (
    <React.Fragment>
      <p>{dictionary.captchaExpired}</p>
      <Button
        text={dictionary.captchaButton}
        size="md"
        onClick={() => resend()}
      />
    </React.Fragment>
  ) : null;
};

export default ReCaptcha;
