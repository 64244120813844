import React, { useContext, useState, useEffect, useRef } from 'react';
import { Switch } from 'antd';
import { LanguageContext } from '../../../context/Language';
import { UserContext } from '../../../context/User/User';
import '../../../containers/Register/register.css';
import PricingCard from './PricingCard/PricingCard';
import FeatureModal from './FeatureModal/FeatureModal';
import LabelCTA from './LabelCTA/LabelCTA';
import { plans } from './plans-data';
import './pricing.css';
//import TariffPlanCalls from '../../../classes/calls/tariff_plan/tariff_plan';

const Pricing = ({ 
  state, 
  setKeys, 
  setInput,
  signUp,
  isRegistered,
  onPlanSelect,
  currentPlan,
  selectedPlan: externalSelectedPlan,
  proceedToPayment,
}) => {
  const { dictionary } = useContext(LanguageContext);
  const { type: userContextType } = useContext(UserContext);
  const [error, setError] = useState(null);
  const [isAnnual, setIsAnnual] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [showFeatureModal, setShowFeatureModal] = useState(false);
  //const [plans, setPlans] = useState([]);
  //const [loading, setLoading] = useState(true);
  
  // Agregar un useRef para controlar los clics
  const isSelectingRef = useRef(false);
  
  // Check if a period is annual (yearly)
  const isPeriodAnnual = (period) => {
    if (!period) return false;
    
    // Normalize to lowercase for consistent comparison
    const periodLower = period.toLowerCase();
    
    // Check for any variation of annual/yearly periods
    return periodLower.includes('year') || 
           periodLower.includes('annual') || 
           periodLower === 'yr' || 
           periodLower === 'yrs';
  };
  
  // Determine user type - check both context and state
  const getUserType = () => {
    // eslint-disable-next-line no-debugger
    //debugger;
    // For logged in users, use the UserContext type
    if (isRegistered && userContextType !== undefined && userContextType !== null) {
      return userContextType;
    }
    // For users in registration, use the state type
    if (state && state.type !== undefined && state.type !== null) {
      return state.type;
    }
    // Default to artist (type 0) if not specified
    return "artist";
  };
  
  // Filter plans based on user type
  const getFilteredPlans = () => {
    const userType = getUserType();
    const adjustedPlans = getAdjustedPlans();
    
    if (userType.toLowerCase() === "artist") { // Artist
      return adjustedPlans.filter(plan => plan.id !== 'pro');
    } else if (userType.toLowerCase() === "label") { // Label
      return adjustedPlans.filter(plan => plan.id === 'pro');
    }
    
    // Default: return all plans
    return adjustedPlans;
  };
  
  // Check if user is a label
  const isUserLabel = () => {
    const userType = getUserType();
    return userType === "label";
  };
  
  // Initialize isAnnual based on currentPlan period if available
  useEffect(() => {
    if (currentPlan && currentPlan.period) {
      const shouldBeAnnual = isPeriodAnnual(currentPlan.period);
      setIsAnnual(shouldBeAnnual);
    }
  }, [currentPlan]);
  
  // Initialize selectedPlan if currentPlan is provided
  useEffect(() => {
    if (currentPlan && isRegistered) {
      console.log('Initializing selectedPlan from currentPlan:', currentPlan);
      
      // Set selectedPlan with the proper structure including period
      const updatedPlan = {
        name: currentPlan.name,
        period: currentPlan.period || 'Monthly',
        billing: isPeriodAnnual(currentPlan.period) ? 'annual' : 'monthly',
        originalPeriod: currentPlan.period // Store original value to prevent transformation issues
      };
      
      // Only update if there's no existing selection or forced update
      if (!selectedPlan) {
        console.log('Setting selectedPlan to:', updatedPlan);
        setSelectedPlan(updatedPlan);
      }
      
      // If a plan is already selected initially, call onPlanSelect
      if (onPlanSelect) {
        const selectedPlanData = getAdjustedPlans().find(p => 
          p.id && currentPlan.name && p.id.toUpperCase() === currentPlan.name.toUpperCase()
        );
        
        if (selectedPlanData) {
          console.log('Calling onPlanSelect with initial plan:', {
            name: currentPlan.name,
            billing: isPeriodAnnual(currentPlan.period) ? 'annual' : 'monthly',
            price: selectedPlanData.price,
            period: isPeriodAnnual(currentPlan.period) ? 'year' : 'month',
            originalPeriod: currentPlan.period
          });
          
          onPlanSelect({
            name: currentPlan.name,
            billing: isPeriodAnnual(currentPlan.period) ? 'annual' : 'monthly',
            price: selectedPlanData.price,
            period: isPeriodAnnual(currentPlan.period) ? 'year' : 'month',
            originalPeriod: currentPlan.period
          });
        }
      }
    }
  }, [currentPlan, isRegistered]);
  
  // Use external selectedPlan if provided
  useEffect(() => {
    if (externalSelectedPlan) {
      setSelectedPlan(externalSelectedPlan);
    }
  }, [externalSelectedPlan]);
  
  // Helper function to check if a plan should be visually selected based on ID and billing cycle
  const isPlanSelected = (planId) => {
    // If no plan is selected, nothing is selected
    if (!selectedPlan) return false;
    
    // Get the name from the selectedPlan (which could be a string or an object)
    const selectedName = typeof selectedPlan === 'string' ? selectedPlan : selectedPlan.name;
    
    
    // Check if the plan ID matches
    const idMatches = selectedName && selectedName.toUpperCase() === planId.toUpperCase();
    
    // If ID doesn't match, it's not selected
    if (!idMatches) return false;
    
    // If selectedPlan has period information, check if it matches current view
    if (typeof selectedPlan === 'object' && selectedPlan.period) {
      // Use the updated isPeriodAnnual function for consistency
      const planIsAnnual = isPeriodAnnual(selectedPlan.period);
      // Return true if the plan's period matches the current isAnnual state
      return planIsAnnual === isAnnual;
    }
    
    // If we don't have period information, just match by ID
    return idMatches;
  };
  
  // Helper function to check if a plan is the user's current plan
  const isPlanCurrent = (planId) => {
    if (!isRegistered || !currentPlan) return false;
    
    // Check if the plan ID matches
    const idMatches = currentPlan.name.toUpperCase() === planId.toUpperCase();
    
    // If ID doesn't match, it's not the current plan
    if (!idMatches) return false;
    
    // If the plan has a period, check if it matches the current view (monthly/yearly)
    if (currentPlan.period) {
      // Use the updated isPeriodAnnual function for consistency
      const currentPlanIsAnnual = isPeriodAnnual(currentPlan.period);
      return currentPlanIsAnnual === isAnnual;
    }
    
    // If we don't have period information, just match by ID
    return true;
  };
  
  // Apply pricing-screen class to wizard box container
  useEffect(() => {
    // Find the wizard box element and add the pricing-screen class
    const wizardBox = document.querySelector('.wizard-box');
    if (wizardBox) {
      wizardBox.classList.add('pricing-screen');
    }
    
    // Cleanup function to remove the class when component unmounts
    return () => {
      const wizardBox = document.querySelector('.wizard-box');
      if (wizardBox) {
        wizardBox.classList.remove('pricing-screen');
      }
    };
  }, []);
  
  // TODO: Implementar la llamada a la API para obtener los planes
  // Mover la definición de fetchPlans dentro del useEffect para evitar problemas de dependencias
  // useEffect(() => {
  //   // Definir fetchPlans dentro del useEffect para que no sea una dependencia
  //   const fetchPlans = () => {
  //     setLoading(true);
  //     TariffPlanCalls.getTariffPlans()
  //       .then(response => {
  //         console.log('API Response:', response);
          
  //         if (response && response.body && response.body.plans) {
  //           setPlans(response.body.plans);
  //           setError(null);
  //         } else {
  //           const errorMsg = 'Unexpected API response structure';
  //           console.error(errorMsg, response);
  //           setError(errorMsg);
  //           if (onError) onError(errorMsg);
  //         }
  //         setLoading(false);
  //       })
  //       .catch(err => {
  //         const errorMsg = `Error fetching tariff plans: ${err.message || 'Unknown error'}`;
  //         console.error(errorMsg, err);
  //         setError(errorMsg);
  //         if (onError) onError(errorMsg);
  //         setLoading(false);
  //       });
  //   };

  //   fetchPlans();
  // }, [onError]); // incluir onError como dependencia
  
  // Definir una función de recarga que el botón de retry pueda usar
  // const retryFetchPlans = () => {
  //   setLoading(true);
  //   TariffPlanCalls.getTariffPlans()
  //     .then(response => {
  //       console.log('API Response on retry:', response);
        
  //       if (response && response.body && response.body.plans) {
  //         setPlans(response.body.plans);
  //         setError(null);
  //       } else {
  //         const errorMsg = 'Unexpected API response structure on retry';
  //         console.error(errorMsg, response);
  //         setError(errorMsg);
  //         if (onError) onError(errorMsg);
  //       }
  //       setLoading(false);
  //     })
  //     .catch(err => {
  //       const errorMsg = `Error fetching tariff plans on retry: ${err.message || 'Unknown error'}`;
  //       console.error(errorMsg, err);
  //       setError(errorMsg);
  //       if (onError) onError(errorMsg);
  //       setLoading(false);
  //     });
  // };
  
  // Apply 20% discount for annual plans
  const getAdjustedPlans = () => {
    if (!isAnnual) return plans;
    
    return plans.map(plan => ({
      ...plan,
      price: (parseFloat(plan.price) * 0.8 * 12).toFixed(2),
      period: 'year',
      isDiscounted: true,
      originalPrice: parseFloat(plan.price) * 12
    }));
  };

  // Combined select and proceed function
  const selectAndProceed = (planId) => {
    // Verificar si ya estamos procesando una selección
    if (isSelectingRef.current) {
      console.log('Prevented duplicate selection');
      return;
    }
    
    // Marcar que estamos procesando
    isSelectingRef.current = true;
    console.log(`Starting plan selection for: ${planId}`);
    
    setError(null);
    
    // Use consistent period names
    const periodName = isAnnual ? 'Annual' : 'Monthly';
    const billingValue = isAnnual ? 'annual' : 'monthly';
    
    // Find the complete plan data
    const selectedPlanData = getAdjustedPlans().find(p => p.id === planId);
    if (!selectedPlanData) {
      console.error('Selected plan data not found for ID:', planId);
      return;
    }
    
    // Create plan selection object with all necessary data
    const planSelection = {
      name: planId,
      period: periodName,
      billing: billingValue,
      price: selectedPlanData.price,
      originalPeriod: periodName // Store original value for consistency
    };
        
    // Update the input state
    const updatedState = {
      ...state,
      plan: planId,
      billing: billingValue,
      // Add membership information
      membership: {
        plan: planId,
        billing: billingValue
      }
    };
    
    setInput(updatedState);
    
    // Save to local storage
    setKeys(updatedState);
    
    if (isRegistered) {
      // For registered users, call onPlanSelect first
      if (onPlanSelect) {
        // Call onPlanSelect with complete plan information
        onPlanSelect({
          name: planId,
          billing: billingValue,
          price: selectedPlanData.price,
          period: isAnnual ? 'year' : 'month',
          originalPeriod: periodName
        });
        
        // Set the selected plan in component state and then proceed to payment
        setSelectedPlan(planSelection);
        
        if (proceedToPayment) {
          proceedToPayment(planSelection);
        }
      }
    } else {
      // For new users, set state then proceed with signup directly
      setSelectedPlan(planSelection);
      console.log(`Calling signUp with plan: ${planId}, billing: ${billingValue}`);
      signUp(updatedState);
    }
    
    // Permitir una nueva selección después de un breve periodo
    setTimeout(() => {
      isSelectingRef.current = false;
      console.log('Plan selection process completed');
    }, 1000);
  };

  // const back = () => {
  //   changeSection(2);
  // };
  
  const toggleBillingCycle = (checked) => {
    setIsAnnual(checked);
    
    // Use consistent period names
    const periodName = checked ? 'Annual' : 'Monthly';
    const billingValue = checked ? 'annual' : 'monthly';
    
    // If a plan is already selected, update the billing cycle
    if (state.plan) {
      // Create updated membership object
      const membership = {
        plan: state.plan,
        billing: billingValue
      };
      
      const updatedState = {
        ...state,
        billing: billingValue,
        membership
      };
      
      // Update the state in the parent component
      setInput(updatedState);
      
      // Also update keys in local storage
      setKeys(updatedState);
    }
    
    // If a selectedPlan exists, update its period and call onPlanSelect with the new billing cycle
    if (selectedPlan) {
      // Update the selected plan's period
      const updatedSelectedPlan = {
        ...selectedPlan,
        period: periodName,
        billing: billingValue,
        originalPeriod: periodName
      };
      
      // Update the selected plan state
      setSelectedPlan(updatedSelectedPlan);
      
      // Call onPlanSelect if available
      if (onPlanSelect) {
        const selectedPlanName = typeof selectedPlan === 'string' ? selectedPlan : selectedPlan.name;
        const selectedPlanData = getAdjustedPlans().find(p => p.id === selectedPlanName);
        
        if (selectedPlanData) {
          onPlanSelect({
            name: selectedPlanName,
            billing: billingValue,
            price: selectedPlanData.price,
            period: checked ? 'year' : 'month',
            originalPeriod: periodName
          });
        }
      }
    }
  };

  // Get main features for each plan to match the image
  const getMainFeatures = (planId) => {
    if (planId === 'core') {
      return dictionary.pricingCore.benefits;
    } else if (planId === 'extra') {
      return dictionary.pricingExtra.benefits;
    } else if (planId === 'pro') {
      return dictionary.pricingPro.benefits;
    }
    return [];
  };

  const handleContactClick = () => {
    // This could redirect to a contact page or open a contact form modal
    // en lugar de hacer un redirect abrir pestana nueva
    window.open('https://help.priamdigital.com/portal/en/newticket?departmentId=152968000000007061', '_blank');
  };

  // if (error) {
  //   // También notificar al padre del error si no se hizo antes
  //   if (onError && error) {
  //     onError(error);
  //   }
    
  //   return (
  //     <div className="pricing-error">
  //       <p>{error}</p>
  //       <button 
  //         className="retry-btn" 
  //         onClick={retryFetchPlans}
  //       >
  //         Retry
  //       </button>
  //     </div>
  //   );
  // }

  return (
    <div className="pricing-container">
      <h2>Priam Digital - {dictionary.pricing}</h2>
      <p className="pricing-description">
        {dictionary.pricingDescription}
      </p>
      
      {isRegistered && currentPlan && (
        <div className="current-plan-info">
          <p className="current-plan-notice">
            {dictionary.pricingCurrent}
          </p>  
          <p className="current-plan-change">
            {dictionary.pricingAccess}
          </p>
        </div>
      )}
      
      <div className={`billing-toggle ${isAnnual ? 'yearly' : 'monthly'}`}>
        <span 
          className="label monthly-label" 
          onClick={() => toggleBillingCycle(false)}
        >
          {dictionary.monthly}
        </span>
        <span 
          className="label yearly-label"
          onClick={() => toggleBillingCycle(true)}
        >
          {dictionary.yearly}
        </span>
        {/* Hidden switch for state management */}
        <Switch
          className="billing-switch"
          checked={isAnnual}
          onChange={toggleBillingCycle}
        />
      </div>
      
      {/* Card-based pricing display - show filtered plans based on user type */}
      <div className="pricing-cards-grid">
        {getFilteredPlans().map((plan) => (
          <PricingCard
            key={plan.id}
            plan={plan}
            isSelected={isPlanSelected(plan.id)}
            isCurrent={isPlanCurrent(plan.id)}
            onSelect={selectAndProceed}
            dictionary={dictionary}
            mainFeatures={getMainFeatures(plan.id)}
            features={getMainFeatures(plan.id)}
            isAnnual={isAnnual}
          />
        ))}
      </div>

      {/* Label CTA Banner - only show for labels */}
      {isUserLabel() && (
        <LabelCTA 
          dictionary={dictionary}
          onContactClick={handleContactClick}
        />
      )}

      {/* View all features link */}
      <div className="view-all-features">
        <button 
          className="view-all-link"
          onClick={() => setShowFeatureModal(true)}
        >
          {dictionary.viewAllFeatures}
        </button>
      </div>

      {/* Feature Comparison Modal */}
      <FeatureModal
        visible={showFeatureModal}
        onClose={() => setShowFeatureModal(false)}
        plans={plans}
        selectedPlan={state.plan}
        onSelect={selectAndProceed}
        dictionary={dictionary}
        isAnnual={isAnnual}
      />

      {error && (
        <div className="pricing-error-field">
          <span className="pricing-error-icon">!</span>
          {error}
        </div>
      )}
    </div>
  );
};

export default Pricing;
