import React, { useEffect, useContext, useState } from "react";
import {
  Table,
  notification,
  DatePicker,
  Popconfirm,
  Dropdown,
  Spin,
} from "antd";
import { UserContext } from "../../../context/User";
import { LanguageContext } from "../../../context/Language";
import { getResizedImage } from "../../../util";
import {
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  WarningOutlined,
  EllipsisOutlined
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import moment from "moment";
import Bluebird from "bluebird";
import Avatar from "react-avatar";
import Select from "../../Form/Select";
import Search from "../../Form/Search";
import AlbumIcon from "../../Icon/AlbumIcon/AlbumIcon.svg";
import SearchIcon from "../../Icon/SearchIcon";
import Button from "../../Buttons/GeneralButton";
import Admin from "../../../classes/admin/admin.jsx";
import Release from "../../../classes/release/release";
import ReleaseElement from "../../Release/Create/Release/ReleaseElement";
import "./style.css";

const Tables = ({
  list,
  error,
  route,
  distro,
  remove,
  columns,
  search,
  subjects,
  redirect,
  loadList,
  getSearch,
  isRelease,
  allStatus,
  canUpdate,
  canDelete,
  typeTable,
  showModal,
  isUserRel,
  statusTag,
  setNewList,
  isAdminRels,
  getReleases,
  section = "",
  labelsOriginal,
  getAllReleases,
  setLoadingList,
  showDeleteDraft,
  allReleasesAdmin,
  showRejectNoteModal = () => {},
}) => {
  const { RangePicker } = DatePicker;
  const { dictionary } = useContext(LanguageContext);
  const { permission, type } = useContext(UserContext);
  const [errorMsg, setErrMsg] = useState(null);
  const [filters, filterOptions] = useState([]);
  const [dataSource, setTableData] = useState([]);
  const [filterStatus, getFilterStatus] = useState(0);
  const [filterCreated, getFilterCreated] = useState([]);
  const [filterUpdated, getFilterUpdated] = useState([]);
  const [initialized, setIinitialized] = useState(false);
  const [releaseData, setReleaseData] = useState({ _id: null });
  const [originalTable, setOriginalTable] = useState([...subjects]);
  const [statusSection] = useState(window.location.hash.substring(1) || null);

  useEffect(() => {
    if (statusSection) {
      statusSelect(statusSection);
    }
    // eslint-disable-next-line
  }, [statusSection, originalTable]);

  useEffect(() => {
    getFilters();
    if (!statusSection) getList();
  }, [list]);

  useEffect(() => {
    if (!initialized && subjects.length) {
      setOriginalTable(subjects);
      setIinitialized(true);
    } else if(labelsOriginal && labelsOriginal.length) {
      setOriginalTable(subjects);
    }
    // eslint-disable-next-line
  }, [subjects.length, labelsOriginal]);

  useEffect(() => {
    if (releaseData._id) {
      mainDisplayData();
    }
    // eslint-disable-next-line
  }, [releaseData._id]);

  useEffect(() => {
    if (errorMsg !== null) {
      notification.error({
        message: errorMsg,
        duration: 15,
        placement: "bottomRight",
      });
    }
  }, [errorMsg]);

  const mainDisplayData = async () => {
    const genres = await getDisplayGenres(releaseData.genres);
    const artist = await artistsPreview(releaseData.artists);
    const tracks = await getDisplayTracks(releaseData.tracks);
    setReleaseData({
      ...releaseData,
      displayArtist: artist,
      displayGenres: genres,
      displayTracks: tracks,
    });
  };

  const getFilters = () => {
    const list = [];
    for (let i = 2; i < columns.length; i++) {
      let nameCol = columns[i].dataIndex;
      if (nameCol !== "detail" && nameCol !== "details") {
        if (
          isRelease &&
          nameCol !== "requestedBy" &&
          nameCol !== "actions" &&
          nameCol !== "priority" &&
          nameCol !== "storesRequested" &&
          nameCol !== "releaseDate"
        ) {
          list.push({ _id: nameCol, name: columns[i].title });
        }
      }
    }
    filterOptions(list);
  };

  const saveSection = (
    data,
    filterVar = 0,
    attr,
    isDate = false,
    isBoolean = false,
    isUpdate = false
  ) => {
    let dataNames = [...data];
    if (isDate) {
      if (filterVar.length !== 0) {
        dataNames = data.filter((el) => {
          const itemDate = isUpdate
            ? moment.utc(el.updatedAt).startOf("day")
            : moment.utc(el.createdAt).startOf("day");
          return (
            itemDate >= moment.utc(filterVar[0]).startOf("day") &&
            itemDate <= moment.utc(filterVar[1]).startOf("day")
          );
        });
      }
    } else if (isBoolean) {
      if (filterVar !== 0) {
        dataNames = filterVar
          ? data.filter((el) => el[attr])
          : data.filter((el) => !el[attr]);
      }
    } else {
      if (filterVar !== 0) {
        if (filterVar === "inReview" && type !== "admin") {
          dataNames = data.filter((el) => {
            const step = el[attr].toLowerCase();
            return step.includes("inreview") || step.includes("readytosent");
          });
        } else {
          dataNames = data.filter((el) =>
            el[attr].toLowerCase().includes(filterVar.toLowerCase())
          );
        }
      }
    }
    return dataNames;
  };

  const statusSelect = (e) => {
    getFilterStatus(e);
    getSearch("");
    let saveDates = saveSection(originalTable, filterCreated, null, true);
    let finalFilter = [...saveDates];
    if (e !== 0) {
      if (e === "inReview" && type !== "admin") {
        finalFilter = saveDates.filter((el) => {
          const step = el.step.toLowerCase(); // Agrega esta línea para ver el valor de step
          return step.includes("readytosent");
        });
      } else if (e === "sendingToStores" && type !== "admin") {
        finalFilter = saveDates.filter((el) => {
          const step = el.step.toLowerCase();
          return step.includes("inreview");
        });
      } else if (e === "rejected") {
        finalFilter = saveDates.filter((el) => el.step.toLowerCase() === "rejected");
      } else {
        finalFilter = saveDates.filter((el) =>
          el.step.toLowerCase().includes(e.toLowerCase())
        );
      }
      setNewList(finalFilter);
      getListByStatus(finalFilter);
    } else {
      getListByStatus(originalTable);
      setNewList(originalTable);
    }
  };

  const datesSelect = (date, isCreated = false, attr) => {
    let saveStatus = isRelease
      ? saveSection(originalTable, filterStatus, "step")
      : originalTable;
    let savedOtherDate = !isCreated
      ? saveSection(saveStatus, filterCreated, null, true)
      : !isRelease
      ? saveSection(saveStatus, filterUpdated, null, true, false, true)
      : saveStatus;
    let filteredDates = [...savedOtherDate];
    if (date) {
      let getDates = [
        moment.utc(date[0]).startOf("day"),
        moment.utc(date[1]).startOf("day"),
      ];
      if (isCreated) {
        getFilterCreated(getDates);
      } else {
        getFilterUpdated(getDates);
      }
      filteredDates = savedOtherDate.filter((el) => {
        const itemDate = moment.utc(el[attr]).startOf("day");
        return (
          itemDate >= moment.utc(date[0]).startOf("day") &&
          itemDate <= moment.utc(date[1]).startOf("day")
        );
      });
    } else {
      if (isCreated) {
        getFilterCreated([]);
      } else {
        getFilterUpdated([]);
      }
    }
    setNewList(filteredDates);
  };

  const checkAllReleasesFilters = () => {
    let saveStatus = saveSection(originalTable, filterStatus, "step");
    let saveDates = saveSection(saveStatus, filterCreated, null, true);
    return saveDates;
  };

  const filtersBar = () => {
    return filters.map((el) => {
      if (el._id === "createdAt" || el._id === "updated") {
        let isCreate = el._id === "createdAt" ? true : false;
        let attr = el._id === "createdAt" ? el._id : "updatedAt";
        return (
          <div key={el._id} className="dateField">
            <div className="label">{dictionary[el._id]}</div>
            <RangePicker
              placeholder={[dictionary.startDate, dictionary.endDate]}
              defaultValue={
                el._id === "createdAt" ? filterCreated : filterUpdated
              }
              onChange={(e) => datesSelect(e, isCreate, attr)}
              className="date-range-admn"
            />
          </div>
        );
      } else if (el._id === "estatus") {
        return (
          <Select
            key={el._id}
            name="section"
            value={filterStatus}
            options={allStatus}
            placeholder={el.name}
            onChange={(e) => statusSelect(e)}
            className="selector-general search-sec"
          />
        );
      }
    });
  };

  const previewImg = (e, el, originalImg) => {
    try {
      let imgAdmin = document.getElementById("imgAdm" + el);
      if (imgAdmin) {
        imgAdmin.src = originalImg;
        let image = document.createElement("img");
        image.src = originalImg;
        image.addEventListener(
          "error",
          () => {
            var isLoaded = image.complete && image.naturalHeight !== 0;
            if (!isLoaded) {
              imgAdmin.src = AlbumIcon;
            }
          },
          false
        );
      }
    } catch (error) {
      console.log(error);
    }
    return;
  };

  const showStores = (stores) => {
    let all = [];
    for (var i = 0; i < stores.length; i++) {
      all.push(<li key={i}>{stores[i]}</li>);
    }
    return <ul className="view-stores">{all}</ul>;
  };

  const getDspList = (relDsps) => {
    let namesList = [];
    relDsps.forEach((el) => {
      namesList.push(el.name);
    });
    namesList.sort((a, b) => a.localeCompare(b));
    let order = orderedDsps(namesList);
    return order;
  };

  const orderedDsps = (list = []) => {
    let openList = showStores(list);
    if (Array.isArray(list)) {
      return list.map((el, index) => {
        if (index <= 3) {
          if (index === 3 && list.length >= 4) {
            return (
              <Popconfirm
                key={index}
                title={openList}
                placement="bottom"
                okText={dictionary.close}
                icon={null}
                showCancel={false}
              >
                <div className="seemore-btn">{dictionary.seeMore}</div>
              </Popconfirm>
            );
          } else if (
            (index > 0 && list.length < 3) ||
            list.length === 1 ||
            (index === 2 && list.length === 3)
          ) {
            return el;
          } else if (index === 0 && list.length < 3 && list.length > 1) {
            return el + " " + dictionary.and + " ";
          } else {
            return el + ", ";
          }
        }
        return null;
      });
    }
  };

  const changeAudiosaladStatus = (relId, status, type) => {
    Release.updateAudiosaladStatus(relId._id, status).then((res) => {
      if (!res.error && res.message) {
        getReleases();
        for (let i = 0; i <= list.length; i++) {
          if (list[i]?._id === relId._id) {
            list[i].step = type === 1 ? "pendingDistribution" : "readyToSent";
          }
        }
        setNewList(list);
        let succesRel =
          type === 1
            ? dictionary.releaseSendToDistribution.replace(
                "--name--",
                relId.title
              )
            : type === 2
            ? dictionary.releaseResendToReview.replace("--name--", relId.title)
            : dictionary.releaseRevokeDistribution.replace(
                "--name--",
                relId.title
              );
        notification.success({
          message: succesRel,
          duration: 6,
          placement: "bottomRight",
        });
      } else {
        notification.warning({
          message: dictionary.stripeError,
          duration: 6,
          placement: "bottomRight",
        });
      }
    });
  };

  const getActionsReview = (rel) => {
    let stepActions = "";
    switch (rel.step) {
      case "readyToSent":
        stepActions = (
          <div className="action-btn">
            {rel.copyright_response &&
            Object.keys(rel.copyright_response).length > 0 ? (
              <Link
                to={
                  "/release/verification?r=" + rel._id + "&section=" + section
                }
                className="view-fingerprint-rel"
              >
                <WarningOutlined className="warn" />
                {dictionary.copyrightVerification}
              </Link>
            ) : (
              <Button
                size="ms"
                className="grn"
                onClick={() =>
                  changeAudiosaladStatus(rel, "Pending Distribution", 1)
                }
                text={dictionary.sendForDistribution}
              ></Button>
            )}
            <Button
              size="ms"
              className="rd"
              onClick={() => showRejectNoteModal(rel)}
              text={dictionary.rejectSendToClient}
            ></Button>
          </div>
        );
        break;
      case "inReview":
        stepActions = (
          <div className="action-review">{dictionary.sendingToStores}</div>
        );
        break;
      case "pendingDistribution":
        stepActions = distro ? (
          <div className="action-btn">
            <Button
              size="ms"
              className="grn"
              onClick={() => sendInReview(rel)}
              text={dictionary.sendToAudiosalad}
            ></Button>
            <Button
              size="ms"
              className="rd"
              onClick={() => changeAudiosaladStatus(rel, "Ready to Sent", 2)}
              text={dictionary.resendToReview}
            ></Button>
          </div>
        ) : (
          <div className="action-btn">
            <Button
              size="ms"
              className="rd"
              onClick={() => changeAudiosaladStatus(rel, "Ready to Sent", 3)}
              text={dictionary.revokeDistribution}
            ></Button>
          </div>
        );
        break;
      default:
        break;
    }
    return stepActions;
  };

  const items = [
    {
      label: <div className="item-priority none">{dictionary.none}</div>,
      key: "none",
    },
    {
      label: <div className="item-priority low">{dictionary.low}</div>,
      key: "low",
    },
    {
      label: <div className="item-priority med">{dictionary.medium}</div>,
      key: "medium",
    },
    {
      label: <div className="item-priority high">{dictionary.high}</div>,
      key: "high",
    },
  ];

  const handlePriority = (priority, relId) => {
    Admin.changePriority(relId, priority).then((res) => {
      if (res.status === 200) {
        // getReleases()
        for (let i = 0; i <= list.length; i++) {
          if (list[i]?._id === relId) {
            list[i].priority = priority;
          }
        }
        setNewList(list);
        getList();
      } else {
        notification.warning({
          message: dictionary.stripeError,
          duration: 6,
          placement: "bottomRight",
        });
      }
    });
  };

  const getPriority = (relId, data) => {
    let type = data ? data : "none";
    return (
      <Dropdown
        menu={{
          items,
          selectable: true,
          defaultSelectedKeys: [type],
          onClick: (e) => handlePriority(e.key, relId),
        }}
        trigger={["click"]}
        placement="bottomRight"
        overlayClassName="drop-priorities"
      >
        <button className={"priority-btn " + type}>
          {type ? dictionary[type] : dictionary.none}
        </button>
      </Dropdown>
    );
  };

  const detailDropdown = (rel, title) => {
    let itemsDetails = [
      isAdminRels && {
        label: (
          <Link to={"/release/view?r=" + rel._id + "&section=" + section}>
            <EyeOutlined className="icon-details" /> {dictionary.view}
          </Link>
        ),
        key: "viewAdmin",
      },
      isUserRel && (rel.step !== "draft" && rel.step !== "rejected") &&  {
        label: (
          <Link ref={redirect} to={route + rel._id}>
            <EyeOutlined className="icon-details" /> {dictionary.view}
          </Link>
        ),
        key: "viewUser",
      },
      isUserRel && (rel.step === "draft" || rel.step === "rejected") && {
        label: (
          <Link ref={redirect} to={route + rel._id}>
            <EditOutlined onClick={() => showModal(rel)} className="icon-details" /> {dictionary.edit}
          </Link>
        ),
        key: "editUser", 
      },
      isAdminRels && rel.step !== "published" && rel.step !== "inReview" && rel.step !== "delivered" && {
        label: (
          <Link to={"/release/view?r=" + rel._id + "&edit=true&section=" + section}>
            <EditOutlined className="icon-details" /> {dictionary.edit}
          </Link>
        ),
        key: "editAdmin",
      },
      rel.step === "draft" && {
        label: (
          <Link onClick={(e) => {
            e.stopPropagation();
            showDeleteDraft(rel._id, title);
          }}>
            <DeleteOutlined
              className="icon-details"
            /> {dictionary.delete}
          </Link>
        ),
        key: "delete",
      },
    ].filter(Boolean); 
    return (
      <div className="detail-dropdown">
        <Dropdown
          menu={{ items: itemsDetails }} // ¡Importante: usa `items` en lugar de `itemsDetails`!
          trigger={["click"]}
          placement="bottomRight"
          overlayClassName="drop-details"
        >
          <EllipsisOutlined className="ellipsis" />
        </Dropdown>
      </div>
    );
  };

  const processListData = (items, options = {}) => {
    const { status = null } = options;
    const listArray = [];

    const itemsToProcess = status ? 
      items.filter(item => item.step === status) : 
      items;

    itemsToProcess.map((el, index) => {
      let image = null;
      let imageResized = null;
      if (el.image || el.image_cover) {
        const url = el.image_cover
          ? el.s3_url + "/" + el.image_cover.filename
          : el.image;
        const type = el.image_cover ? "release" : "artist";
        image = url;
        imageResized = getResizedImage({
          url,
          type,
          size: "xs",
          resourceId: el._id,
        });
      }

      let name = el.name ? el.name : el.title ? el.title : dictionary.noTitle;
      let title = !el.name
        ? el.title
          ? el.title
          : dictionary.noTitle
        : el.name;
      let titleImg = name.substring(0, 4);
      let created = el.createdAt
        ? moment.utc(el.createdAt).startOf("day").format("YYYY/MM/DD")
        : null;
      let updated = el.updatedAt
        ? moment.utc(el.updatedAt).startOf("day").format("YYYY/MM/DD")
        : null;
      let featured = el.is_featured ? dictionary.yes : dictionary.no;
      let isartist = el.is_contributor ? dictionary.no : dictionary.yes;
      let labelName =
        typeTable === 1
          ? el.label
            ? el.label.name
            : ""
          : el.email
          ? el.email
          : "-";
      let formats = el.type ? el.type : "-";
      let steps =
        el.step && statusTag ? (
          <div
            className={
              " tag-step " + el.step + (type !== "admin" ? "client" : "")
            }
          >
            {el.step === "readyToSent" && type !== "admin"
              ? dictionary.inReview
              : el.step === "inReview" && type !== "admin"
              ? dictionary.sendingToStores
              : el.step === "inReview" && type === "admin"
              ? dictionary.inReviewByAudioSalad
              : el.step === "rejected_team" && type !== "admin"
              ? dictionary.rejected
              :dictionary[el.step]}
          </div>
        ) : el.step ? (
          el.step === "inReview" && type === "admin" ? (
            dictionary.inReviewByAudioSalad
          ) : (
            dictionary[el.step]
          )
        ) : null;
      let reqBy = el.label ? el.label.name : "-";
      let dsps = el.dsp_release ? getDspList(el.dsp_objects) : "";
      let actions = el.step ? getActionsReview(el) : "";
      let dateRelease = el.date
        ? moment.utc(el.date).startOf("day").startOf("day").format("YYYY/MM/DD")
        : null;
        let stepBase = el.step ? el.step.toLowerCase().replace(/\s+/g, '') : "";
      let priority =
        (stepBase !== "delivered" &&
         stepBase !== "published" &&
         stepBase !== "inreview"
        ) ? getPriority(el._id, el.priority) : "-";
        let listDetails = (
          <div className="center-details">
            {canUpdate && !isAdminRels && !isUserRel ? (
              <EditOutlined
                onClick={() => showModal(el)}
                className="edit-detail"
              />
            ) : null}
            {canDelete && !isAdminRels && !isUserRel ? (
              <DeleteOutlined
                onClick={() => remove(index, el)}
                className="delete-detail"
              />
            ) : null}
          </div>
        );
      let drawDetails = typeTable === 2 ? detailDropdown(el, title) : listDetails;
      if (
        !allReleasesAdmin &&
        el.step &&
        (el.step === "draft" ||
          el.step === "rejected" ||
          el.step === "published" ||
          (el.step === "delivered" && section ==="review") ||
          (distro && el.step === "readyToSent"))
      )
        return null;

      return listArray.push({
        index: index,
        key: el._id || index,
        details: (
          <div key={el._id} className="avatar-admin">
            {image ? (
              <img
                src={imageResized}
                alt={titleImg}
                id={"imgAdm" + el._id}
                onError={(e) => previewImg(e, el._id, image)}
              />
            ) : (
              <Avatar name={name || dictionary.noTitle} size="50" round="5px" />
            )}
          </div>
        ),
        detail: drawDetails,
        requestedBy: <div className="req-by">{reqBy}</div>,
        storesRequested: <div className="list-dsp">{dsps}</div>,
        releaseDate: dateRelease,
        actions: actions,
        priority: priority,
        name: title,
        title: <div className="title-rel">{title}</div>,
        desc: labelName,
        featured: featured,
        upc: <div className={"title-rel "+((el.upc)?"":"unassign")}>{el.upc || dictionary.unassigned}</div>,
        createdAt: created,
        estatus: steps,
        format: formats,
        isArtist: isartist,
        updated: updated,
      });
    });

    return listArray;
  };

  const getList = () => {
    const listArray = processListData(list);
    setTableData(listArray);
  };

  const getListByStatus = (listStatus) => {
    const listArray = processListData(listStatus);
    setTableData(listArray);
  };

  const searchInAdmin = (value) => {
    let filteredNames = isRelease ? checkAllReleasesFilters() : originalTable;
    if (value) {
      typeTable === 1
        ? (filteredNames = filteredNames.filter(
            (el) =>
              el?.name?.toLowerCase().includes(value.toLowerCase()) ||
              el?.label?.name?.toLowerCase().includes(value.toLowerCase()) ||
              (el?.is_featured
                ? dictionary.yes.includes(toTitleCase(value))
                : dictionary.no.includes(toTitleCase(value))) ||
              moment.utc(el?.createdAt).startOf("day").format("YYYY/MM/DD").includes(value) ||
              moment.utc(el?.updatedAt).startOf("day").format("YYYY/MM/DD").includes(value)
          ))
        : typeTable === 2
        ? (filteredNames = filteredNames.filter(
            (el) =>
              (el && el?.title
                ? el?.title?.toLowerCase().includes(value.toLowerCase())
                : dictionary.noTitle.includes(value.toUpperCase())) ||
              el?.label?.name?.toLowerCase().includes(value.toLowerCase()) ||
              (el?.type ? el?.type?.toLowerCase().includes(value.toLowerCase()) : false) ||
              (el?.step ? dictionary[el?.step]?.toLowerCase().includes(value.toLowerCase()) : false) ||
              (el?.upc ? el?.upc?.toLowerCase().includes(value.toLowerCase()) : false) ||
              (el?.date ? moment.utc(el?.date).startOf("day").format("YYYY/MM/DD").includes(value) : false) ||
              (el?.createdAt ? moment.utc(el?.createdAt).startOf("day").format("YYYY/MM/DD").includes(value) : false)
          ))
        : (filteredNames = filteredNames.filter(
            (el) =>
              el?.name?.toLowerCase().includes(value.toLowerCase()) ||
              el?.email?.toLowerCase().includes(value.toLowerCase()) ||
              moment.utc(el?.createdAt).startOf("day").format("YYYY/MM/DD").includes(value) ||
              moment.utc(el?.updatedAt).startOf("day").format("YYYY/MM/DD").includes(value)
          ));
    }
    setNewList(filteredNames);
  };

  const toTitleCase = (str) => {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };

  const getDisplayGenres = async (genres) => {
    let genreNames = "";
    await genres.forEach((el, i) => {
      if (el.length > 0) {
        if (i > 0) {
          genreNames += ", ";
        }
        genreNames += el.genre.name + " / " + el.subgenre.name;
      }
    });
    return genreNames;
  };

  const orderedList = (list = [], roles = false) => {
    const count = list.length;
    if (Array.isArray(list)) {
      return list.map((el, index) => {
        if (count === index + 1 && count > 1) {
          return (
            " " +
            dictionary.and +
            " " +
            (roles ? dictionary.artistType[el] : el.name)
          );
        } else if (count - 2 === index && count > 1) {
          return roles ? dictionary.artistType[el] : el.name;
        } else if (count < 3) {
          return (roles ? dictionary.artistType[el] : el.name) + " ";
        } else {
          return (roles ? dictionary.artistType[el] : el.name) + ", ";
        }
      });
    }
  };

  const artistsPreview = (artistsData) => {
    const onlyMain = artistsData.filter((el) => el.role.includes("main"));
    const onlyFeat = artistsData.filter((el) => el.role.includes("feature"));
    const countFeat = onlyFeat.length;

    let setMains = orderedList(onlyMain);

    let setFeat = onlyFeat.map((el, index) => {
      if (countFeat === index + 1 && countFeat > 1) {
        return " " + dictionary.and + " " + el.name;
      } else if (countFeat < 3) {
        return dictionary.featuring + el.name + " ";
      } else if (index === 0) {
        return dictionary.featuring + el.name + ", ";
      } else {
        return el.name + ", ";
      }
    });
    setMains.push(setFeat);
    return setMains;
  };

  const getDisplayTracks = async (tracks) => {
    return await Bluebird.map(tracks, async (el, i) => {
      const artistName = await artistsPreview(el.artists);
      const genres = await getDisplayGenres(el.genres);
      const publishers = await getDisplayPublishers(el.producers);
      const contributors = await getDisplayContributors(
        el.producers,
        el.writers,
        el.participants
      );
      return {
        key: i,
        title: el.title,
        version: el.version || "--",
        isrc: el.isrc,
        artist: artistName,
        genre: genres,
        publishers: (
          <React.Fragment>
            {publishers ? (
              <ReleaseElement
                title={dictionary.publishers}
                value={publishers}
                className="tracks-rel-admin"
              />
            ) : null}
            {contributors ? (
              <ReleaseElement
                title={dictionary.contributors}
                value={contributors}
                className="tracks-rel-admin"
              />
            ) : null}
          </React.Fragment>
        ),
      };
    });
  };

  const getDisplayPublishers = async (producers) => {
    let publisherNames = "";
    await producers.forEach((el, i) => {
      if (el.role === "publisher") {
        if (i > 0) {
          publisherNames += ", ";
        }
        publisherNames += el.name;
      }
    });
    return publisherNames;
  };

  const getDisplayContributors = async (producers, writers, participants) => {
    let contributorsNames = "";
    if (producers) {
      await producers.forEach((el, i) => {
        if (el.role !== "publisher") {
          if (i > 0) {
            contributorsNames += ", ";
          }
          contributorsNames += el.name + " (" + el.role + ")";
        }
      });
    }
    if (writers) {
      await writers.forEach((el) => {
        contributorsNames += ", " + el.name + " (" + el.role + ")";
      });
    }
    if (participants) {
      await participants.forEach((el) => {
        contributorsNames += ", " + el.name;
      });
    }
    return contributorsNames;
  };

  const onSearch = (value) => {
    getSearch(value.target.value);
    searchInAdmin(value.target.value);
  };

  const handleCancel = () => {
    //showModalRelease(false);
    setReleaseData({ _id: null });
  };

  const sendInReview = async (rel = null) => {
    setErrMsg(null);
    setLoadingList(true);
    const updateStatus = await Admin.updateStatus(
      rel ? rel._id : releaseData._id
    );
    let titleRel = rel ? rel.title : releaseData.title;
    if (updateStatus.status === 200) {
      setLoadingList(false);
      if (updateStatus.body.error === "releaseCheckData") {
        setErrMsg(dictionary.releaseCheckDataAdmin);
      } else {
        if (updateStatus.body.error === "releaseInReview") {
          setErrMsg(dictionary.releaseInReview);
        } else {
          getAllReleases();
          getReleases();
          handleCancel();
          let succesRel = dictionary.releaseSendToReview.replace(
            "--name--",
            titleRel
          );
          notification.success({
            message: succesRel,
            duration: 6,
            placement: "bottomRight",
          });
        }
      }
    } else {
      setLoadingList(false);
      setErrMsg(dictionary.stripeError);
    }
  };

  return (
    <div className="tables">
      <div className="top-actions-srch">
        <div className="searchers">
          <Search
            name="searcher"
            value={search}
            mobile="responsive"
            onSearch={onSearch}
            className="searcher sec back"
            enterButton={<SearchIcon />}
            placeholder={dictionary.search}
          />
          {filtersBar()}
        </div>
        {permission &&
        permission.release &&
        permission.release.add &&
        window.location.pathname === '/release' ? (
          <Link to="/newRelease" className="new-release">
            <Button
              size="fullWidth"
              className="new-rel-btn"
              text={dictionary.createNewRel}
            />
          </Link>
        ) : null}
      </div>
      <div className="general-card-admin">
        <div className="table-admin inline-contend">
          <div className="header-tab-ad"></div>
          <Spin spinning={loadList} className="spinner">
            <Table
              rowKey="index"
              columns={columns}
              pagination={true}
              dataSource={dataSource}
              className="content-table-admin"
            />
          </Spin>
        </div>
        {error ? <p className="text-error relative-text">{error}</p> : null}
      </div>
    </div>
  );
};

export default Tables;
