import React, { useContext } from "react";
import { LanguageContext } from "../../context/Language";
import Modal from "../Modal";
import Button from "../Buttons/GeneralButton";

const ModalNotification = ({ id, visible, message, onClose }) => {
  const { dictionary } = useContext(LanguageContext);

  return (
    <Modal
      idModal={id || "modal-delete"}
      addclassName="deleteModal"
      handleCancel={onClose}
      title={null}
      isModalVisible={visible}
      body={<div className="forms">{message}</div>}
      footer={
        <div className="modalAdd-btns">
          <Button
            key="cancel"
            size="sm"
            className="back-btn"
            text={dictionary.close}
            onClick={onClose}
          />
        </div>
      }
    />
  );
};

export default ModalNotification;
