import React, { useContext, useState } from "react";
import Profile from "../../components/Settings/Profile";
import Payment from "../../components/Settings/Payment";
import Password from "../../components/Settings/Password";
import Payee from "../../components/Settings/Payee";
import MenuSettings from "../../components/Settings/MenuSettings";
import "../../components/Settings/set.css";
import { UserContext } from "../../context/User";
import Account from "../../components/Settings/Account";

const Settings = () => {
  const [section, settingSection] = useState(window.location.hash);
  const { alertPayee }= useContext(UserContext);
  const changeSection = (n) => {
    settingSection(n);
  };

  return (
    <React.Fragment>
      <div className="container-box dashboard">
        <div className="container-settings">
          <MenuSettings alert={alertPayee} section={section} changeSection={changeSection} />
          {section === "#membership" ? (
            <Payment />
          ) : section === "#password" ? (
            <Password />
          ) : section === "#payee" ? (
            <Payee />
          ) : section === "#account" ? (
            <Account />
          ) : (
            <Profile />
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default Settings;
