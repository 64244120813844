/**
 * Proposal for manage the state of the release detail page to avoid prop drilling
 * I think that we can migrate step by step, at this time i only add the basics
 * and the upc header management
 */

import React, { createContext, useState, useEffect, useContext } from "react";
import ReleaseCalls from "../../classes/release/release";
import { ArtistContext } from "../Artist";

export const ReleaseDetailContext = createContext(null);

export const ReleaseDetailProvider = ({ children, releaseId }) => {
  const { updateArtistsByLabelId } = useContext(ArtistContext);
  const [isLoading, setIsLoading] = useState(true);
  const [releaseData, setReleaseData] = useState({});
  const [tracks, setTracks] = useState([]);
  const [showModal, setShowModal] = useState({
    addUpcModal: false,
    addIsrcModal: false,
  });

  const loadRelease = (releaseId) => {
    if (!isLoading) setIsLoading(true);

    ReleaseCalls.getReleaseDetails(releaseId)
      .then((data) => {
        setReleaseData(data.release);
        setTracks(data.tracks);
        updateArtistsByLabelId(data.release.label_id);
      })
      .catch((error) => {
        console.log(error);
        setTracks([]);
        setReleaseData({});
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (releaseId) loadRelease(releaseId);
  }, [releaseId]);

  const toggleShowModal = (modal) => {
    if (!showModal.hasOwn(modal))
      throw new Error(`The modal ${modal} is not defined`);

    setShowModal({ ...showModal, [modal]: !showModal[modal] });
  };

  return (
    <ReleaseDetailContext.Provider
      value={{
        releaseId,
        isLoading,
        releaseData,
        refreshRelease: () => loadRelease(releaseId),
        tracks,
        toggleShowModal,
        // this shouldnt be exposed, but for now is ok while we migrate
        setTracks,
      }}
    >
      {children}
    </ReleaseDetailContext.Provider>
  );
};
