import React, { useContext } from "react";
import { LanguageContext } from "../../../../../context/Language";
import Modal from "../../../../Modal";
import Button from "../../../../Buttons/GeneralButton";

const TrackDelete = ({
  trackName,
  deleteTrack,
  handleCancel,
  deleteVisible,
}) => {
  const { dictionary } = useContext(LanguageContext);

  function deleteAction(e) {
    e.currentTarget.disabled = true;
    deleteTrack();
  }

  return (
    <Modal
      destroyOnClose
      maskClosable={false}
      idModal="delete"
      className="deleteModal"
      handleCancel={handleCancel}
      isModalVisible={deleteVisible}
      title={null}
      body={
        <div className="modal-title">
          {dictionary.deleteTrack} {`"` + trackName + `"`}?
        </div>
      }
      footer={
        <div className="modalAdd-btns">
          <Button
            key="cancel"
            size="sm"
            className="back-btn"
            text={dictionary.cancel}
            onClick={handleCancel}
          />
          <Button
            size="md"
            key="delete"
            className="footer-btn"
            onClick={deleteAction}
            text={dictionary.delete}
          />
        </div>
      }
    />
  );
};

export default TrackDelete;
