import React, { useContext, useState, useEffect } from "react";
import { Divider, Alert } from "antd";
import { Link } from "react-router-dom";
import { UserContext } from "../../../context/User";
import { LanguageContext } from "../../../context/Language";
import moment from "moment";
import Loader from "../../Loader";
import Button from "../../Buttons/GeneralButton";
import PaymentMethodSelector from "./PaymentMethodSelector";
import LabelCTA from "../../Register/Pricing/LabelCTA/LabelCTA";
import FeatureModal from "../../Register/Pricing/FeatureModal/FeatureModal";
import "../set.css";
import { planCategories, plans } from "../../Register/Pricing/plans-data";

const Payment = () => {
  const { dictionary } = useContext(LanguageContext);
  const { membership, type: userType } = useContext(UserContext);
  const [loader, setLoader] = useState(true); // Start with loading to prevent premature rendering
  const [showPaymentEditModal, setShowPaymentEditModal] = useState(false);
  const [showFeatureModal, setShowFeatureModal] = useState(false);
  const creditCard = "XXXX XXXX XXXX ";
  const [membershipData, setMembData] = useState({
    planPrice: "",
    plan: "",
    secondary: "",
    benefits: [],
    nextTierBenefits: [], // Ensure this is initialized as an empty array
    isAnnual: false
  });

  // Get plan display name
  const getPlanDisplayName = (planId) => {
    if (!planId) return 'Priam Core'; // Default if planId is null/undefined
    
    switch(planId) {
      case 'core': return 'Priam Core';
      case 'extra': return 'Priam Extra';
      case 'pro': return 'Priam Pro';
      case 'plus': return 'Priam Plus'; // For backward compatibility
      default: return `Priam ${planId.charAt(0).toUpperCase() + planId.slice(1)}`;
    }
  };
  
  // Get next tier plan - for artists, only suggest Core -> Extra
  const getNextTierPlan = (currentPlan) => {
    if (!currentPlan) return 'extra'; // Default if currentPlan is null/undefined
    
    // For artists (type 0), only suggest up to Extra
    if (userType === 0) {
      switch(currentPlan) {
        case 'core': return 'extra';
        case 'plus': return 'core'; // For backward compatibility
        default: return 'extra'; // Default to Extra if unknown plan
      }
    } else {
      // For labels or other user types
      switch(currentPlan) {
        case 'core': return 'extra';
        case 'extra': return 'pro';
        case 'plus': return 'core'; // For backward compatibility
        case 'pro': return 'pro'; // No higher tier
        default: return 'extra';
      }
    }
  };
  
  // Extract benefits for a specific plan
  const extractPlanBenefits = (planId) => {
    if (!planId) return []; // Return empty array if planId is null/undefined
    
    const benefits = [];
    
    // Default to core if plan not found
    const planKey = ['core', 'extra', 'pro'].includes(planId) ? planId : 'core';
    
    try {
      // Get plan information from plans data
      const planInfo = plans.find(p => p.id === planId);
      
      // Only add price for next tier plans (not current plan)
      const isCurrentPlan = membership && planId === membership.plan;
      
      if (!isCurrentPlan && planInfo?.price) {
        benefits.push(`Price: $${planInfo.price}/month`);
      }
      
      // Extract top benefits from plan categories
      if (planCategories && Array.isArray(planCategories)) {
        planCategories.forEach(category => {
          if (category && category.features && Array.isArray(category.features)) {
            category.features.forEach(feature => {
              // Skip any price features from the categories since we handle it above
              if (feature.name === 'Price') {
                return;
              }
              
              if (feature && feature[planKey] && feature[planKey] !== 'no' && feature[planKey] !== '✘' && feature[planKey] !== '-') {
                benefits.push(`${feature.name}: ${feature[planKey]}`);
              }
            });
          }
        });
      }
    } catch (error) {
      console.error('Error extracting plan benefits:', error);
    }
    
    // Limit to 8 benefits for display
    return benefits.slice(0, 8);
  };

  const handleContactClick = () => {
    // Redirect to contact page
    window.open('https://help.priamdigital.com/portal/en/newticket?departmentId=152968000000007061', '_blank');
  };

  useEffect(() => {
    try {
      
      if (membership) {
        // Get current plan ID (default to core if missing)
        const planId = membership.plan || 'core';
        const nextTierPlanId = getNextTierPlan(planId);
      
        
        // Get display names
        const planName = getPlanDisplayName(planId);
        const nextTierPlanName = getPlanDisplayName(nextTierPlanId);
        
        // Extract plan benefits
        const benefits = extractPlanBenefits(planId);
        const nextTierBenefits = extractPlanBenefits(nextTierPlanId);
        
        // Format price with currency (default to USD if not specified)
        const price = membership.price || 0;
        const currency = (membership.currency === 'Home Currency' || !membership.currency) ? 'USD' : membership.currency;
        const period = membership.period || 'Monthly';
        
        // Determine if plan is annual
        const isAnnual = period.toLowerCase().includes('year') || 
                          period.toLowerCase().includes('yearly') || 
                          period.toLowerCase().includes('annual') || 
                          period.toLowerCase() === 'yr' || 
                          period.toLowerCase() === 'yrs';
        
        // Simplified price formatting for annual plans
        let formattedPrice = `$${price} ${currency} / ${period}`;
        
        if (isAnnual && price > 0) {
          // Calculate monthly equivalent
          const monthlyPrice = price / 12;
          formattedPrice = `$${price} ${currency} / ${period} ($${monthlyPrice.toFixed(2)}/month)`;
        }
                
        setMembData({
          planPrice: formattedPrice,
          plan: planName,
          planId: planId,
          secondary: nextTierPlanName,
          secondaryId: nextTierPlanId,
          benefits: benefits || [],
          nextTierBenefits: nextTierBenefits || [],
          isAnnual: isAnnual
        });
      } else {
        // Set default values if membership is null
        setMembData({
          planPrice: "$0 USD / Monthly",
          plan: "Priam Core",
          planId: "core",
          secondary: "Priam Extra",
          secondaryId: "extra",
          benefits: extractPlanBenefits('core'),
          nextTierBenefits: extractPlanBenefits('extra'),
          isAnnual: false
        });
      }
    } catch (error) {
      console.error('Error in membership effect:', error);
      // Set fallback values in case of error
      setMembData({
        planPrice: "$0 USD / Monthly",
        plan: "Priam Core",
        planId: "core",
        secondary: "Priam Extra",
        secondaryId: "extra",
        benefits: [],
        nextTierBenefits: [],
        isAnnual: false
      });
    } finally {
      setLoader(false);
    }
    // eslint-disable-next-line
  }, [membership, userType]);

  // Render current plan benefits
  const renderPlanBenefits = () => {
    // Ensure benefits array exists
    if (!membershipData.benefits || !Array.isArray(membershipData.benefits)) {
      return <li>Basic benefits included</li>;
    }
    
    // If array is empty, return a default message
    if (membershipData.benefits.length === 0) {
      return <li>Basic benefits included</li>;
    }
    
    return membershipData.benefits.map((benefit, index) => (
      <li key={index}>{benefit}</li>
    ));
  };
  
  // Render next tier plan benefits
  const renderNextTierBenefits = () => {
    // Ensure nextTierBenefits array exists
    if (!membershipData.nextTierBenefits || !Array.isArray(membershipData.nextTierBenefits)) {
      return <li>Enhanced benefits included</li>;
    }
    
    // If array is empty, return a default message
    if (membershipData.nextTierBenefits.length === 0) {
      return <li>Enhanced benefits included</li>;
    }
    
    return membershipData.nextTierBenefits.map((benefit, index) => (
      <li key={index}>{benefit}</li>
    ));
  };

  const handleShowPaymentEditModal = (e) => {
    if (e) e.preventDefault();
    setShowPaymentEditModal(!showPaymentEditModal);
  };

  const drawCorePaymentDetail = () => {
    if (!membership || !membership.plan || !membership.payment_method) return null;

    const isCanceled = membership.subscription_status === "canceled";

    if (membership.scheduled_to_change_at)
      return (
        <Alert
          message={dictionary.subscriptionAboutToChange}
          description={dictionary.subscriptionAboutToChangeDetail
            .replace(
              "--date--",
              moment(membership.scheduled_to_change_at).format("D MMM, YYYY")
            )
            .replace("--plan--", getPlanDisplayName(getNextTierPlan(membership.plan)))}
          type="warning"
          showIcon
        />
      );

    return (
      <>
        <div className="data">
          {dictionary.paymentMethod}:&nbsp;
          <div className="card">
            {membership?.payment_method
              ? creditCard + membership?.payment_method?.last4
              : dictionary.none}
          </div>
          <Button
            text={dictionary.update}
            size="xs"
            disabled={isCanceled}
            onClick={handleShowPaymentEditModal}
            className="update"
          />
        </div>
        <div className="data">
          {isCanceled ? dictionary.expiredAt : dictionary.renewalDate}:&nbsp;
          <div className="card">
            {membership?.renewal_date
              ? moment(membership.renewal_date).format("D MMM, YYYY")
              : membership?.subscription_period?.end
              ? moment(membership?.subscription_period?.end).format("D MMM, YYYY")
              : dictionary.none}
          </div>
        </div>
        <div className="data">
          {dictionary.status}:&nbsp;
          <div className="card">
            {membership?.subscription_status
              ? dictionary.subscriptionStatuses?.[membership?.subscription_status] || membership?.subscription_status
              : "active"}
          </div>
        </div>
      </>
    );
  };

  const secondaryPlan = () => {
    return (
      <div className="card">
        {dictionary.priamPlans?.descriptionExtra ||  dictionary.upgradeTo.replace("--membershipData--", membershipData.secondary)}
        <ul>{renderNextTierBenefits()}</ul>
      </div>
    );
  };

  // If still loading, show loader
  if (loader) {
    return (
      <div className="content-set pay">
        <Loader />
      </div>
    );
  }

  // Determine if we should show the Custom Plan CTA
  // Show LabelCTA when: user is an artist with Extra plan or user wants to inquire about Pro plan
  const shouldShowLabelCTA = userType === "label" && (membershipData.planId === 'extra' || !['core', 'extra'].includes(membershipData.planId));

  return (
    <div className="content-set pay">
      <React.Fragment>
        <h2>
          {dictionary.currPlan?.replace("--plan--", membershipData.plan) || `Current Plan: ${membershipData.plan}`}
        </h2>
        <React.Fragment>
          <PaymentMethodSelector
            show={showPaymentEditModal}
            defaultPaymentId={membership?.payment_method?.id}
            close={handleShowPaymentEditModal}
          />
          <div className="data">
            {dictionary.price}:&nbsp;
            <div className="card">
              {membershipData.planPrice}
            </div>
          </div>
          {membership && drawCorePaymentDetail()}
          <div className="curr-benefits">
            <div>{dictionary.yourCurrentBenefits || "Your Current Benefits"}:</div>
            <ul>{renderPlanBenefits()}</ul>
          </div>
          <Divider />
          
          {/* Only show upgrade option if not at the highest available plan for user type */}
          {((userType === "artist" && membershipData.planId !== 'extra') || 
             (userType === "label" && membershipData.planId !== 'pro')) && (
            <>
              <div className="data bg">
                {dictionary.priamPlans?.switch || "Want to upgrade to"}
                <b>{" " + membershipData.secondary}</b>?
                <Link
                  to="https://help.priamdigital.com/portal/en/newticket?departmentId=152968000000007061"
                  target="_blank"
                >
                  <div className="contact">
                    {dictionary.priamPlans?.contactUs || "Contact us"}
                  </div>
                </Link>
              </div>
              <div className="data sec-plan">
                {dictionary.priamPlans?.whatIs || "What is"}
                <b>{" " + membershipData.secondary}</b>?
              </div>
              {secondaryPlan()}
            </>
          )}
          
          {/* Show LabelCTA for custom plan requests */}
          {shouldShowLabelCTA && (
            <div className="custom-plan-section">
              <LabelCTA 
                dictionary={dictionary}
                onContactClick={handleContactClick}
                customStyles={{
                  container: {
                    padding: '15px',
                    margin: '10px 0',
                    borderRadius: '8px',
                    background: 'var(--secondary-gradient, linear-gradient(135deg, #8e44ad 0%, #3498db 100%))',
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'
                  },
                  heading: {
                    fontSize: '16px',
                    marginBottom: '8px',
                    color: 'white'
                  },
                  text: {
                    fontSize: '14px',
                    lineHeight: '1.4',
                    color: 'rgba(255, 255, 255, 0.9)'
                  },
                  button: {
                    padding: '6px 12px',
                    fontSize: '14px',
                    height: 'auto',
                    background: 'white',
                    color: '#3498db',
                    border: 'none',
                    fontWeight: 'bold'
                  }
                }}
              />
            </div>
          )}
          
          {/* View all features link */}
          <div className="view-all-features">
            <button 
              className="view-all-link"
              onClick={() => setShowFeatureModal(true)}
            >
              {dictionary.pricingViewAllFeatures}
            </button>
          </div>
          
          {/* Feature Comparison Modal */}
          <FeatureModal
            visible={showFeatureModal}
            onClose={() => setShowFeatureModal(false)}
            plans={plans}
            selectedPlan={membershipData.planId}
            onSelect={() => {}} // No selection in Payment screen
            dictionary={dictionary}
            isAnnual={membershipData.isAnnual}
          />
        </React.Fragment>
      </React.Fragment>
    </div>
  );
};

export default Payment;
