/**
 * Google Analytics utility for tracking events
 */

// Enable debug mode to log events to console in development
let DEBUG_MODE = true;

/**
 * Enable or disable debug mode for analytics
 * @param {boolean} enable - Whether to enable debug mode
 */
export const setDebugMode = (enable) => {
  DEBUG_MODE = !!enable;
  
  if (isDevelopmentEnvironment()) {
    console.log(`Analytics debug mode ${DEBUG_MODE ? 'enabled' : 'disabled'}`);
  }
};

// Check if the current environment is development
const isDevelopmentEnvironment = () => {
  const hostname = window.location.hostname;
  return hostname === 'localhost' || 
         hostname === '127.0.0.1' || 
         hostname === 'dev.priamdigital.com' ||
         hostname.includes('local');
};

// Log event to console in development mode
const logEventToConsole = (eventName, eventParams = {}) => {
  if (DEBUG_MODE && isDevelopmentEnvironment()) {
    console.log(
      `%c GA Event (DEBUG): ${eventName}`, 
      'background: #f0f0f0; color: #333; padding: 2px 4px; border-radius: 2px; font-weight: bold;', 
      eventParams
    );
    return true;
  }
  return false;
};

// Check if gtag exists and is a function and we're not in development
const isGtagAvailable = () => {
  // Skip tracking if in development environment
  if (isDevelopmentEnvironment()) {
    return false;
  }
  
  return typeof window !== 'undefined' && typeof window.gtag === 'function';
};

/**
 * Track a page view in Google Analytics
 * @param {string} path - The page path
 * @param {string} title - The page title
 */
export const trackPageView = (path, title) => {
  const params = {
    page_path: path,
    page_title: title,
    page_location: window.location.href
  };
  
  // Log to console in development
  if (logEventToConsole('page_view', params)) return;
  
  if (!isGtagAvailable()) return;
  
  window.gtag('event', 'page_view', params);
};

/**
 * Track a user sign up event
 * @param {string} method - The signup method (email, social, etc.)
 */
export const trackSignUp = (method = 'email') => {
  const params = { method };
  
  // Log to console in development
  if (logEventToConsole('sign_up', params)) return;
  
  if (!isGtagAvailable()) return;
  
  window.gtag('event', 'sign_up', params);
};

/**
 * Track a user login event
 * @param {string} method - The login method (email, social, etc.)
 */
export const trackLogin = (method = 'email') => {
  const params = { method };
  
  // Log to console in development
  if (logEventToConsole('login', params)) return;
  
  if (!isGtagAvailable()) return;
  
  window.gtag('event', 'login', params);
};

/**
 * Track a new release creation event
 * @param {string} releaseId - The ID of the release
 * @param {string} releaseTitle - The title of the release
 */
export const trackNewRelease = (releaseId, releaseTitle) => {
  const params = {
    release_id: releaseId,
    release_title: releaseTitle
  };
  
  // Log to console in development
  if (logEventToConsole('create_release', params)) return;
  
  if (!isGtagAvailable()) return;
  
  window.gtag('event', 'create_release', params);
};

/**
 * Track a purchase event
 * @param {Object} purchaseData - Purchase data following GA4 ecommerce schema
 */
export const trackPurchase = (purchaseData) => {
  // Log to console in development
  if (logEventToConsole('purchase', purchaseData)) return;
  
  if (!isGtagAvailable()) return;
  
  window.gtag('event', 'purchase', {
    transaction_id: purchaseData.transactionId,
    value: purchaseData.value,
    currency: purchaseData.currency || 'USD',
    items: purchaseData.items || []
  });
};

/**
 * Track a custom event
 * @param {string} eventName - Name of the event
 * @param {Object} eventParams - Event parameters
 */
export const trackEvent = (eventName, eventParams = {}) => {
  // Log to console in development
  if (logEventToConsole(eventName, eventParams)) return;
  
  if (!isGtagAvailable()) return;
  
  window.gtag('event', eventName, eventParams);
};

/**
 * Set user properties for better segmentation
 * @param {string} userId - User ID
 * @param {Object} properties - User properties
 */
export const setUserProperties = (userId, properties = {}) => {
  const params = {
    user_id: userId,
    ...properties
  };
  
  // Log to console in development
  if (logEventToConsole('set_user_properties', params)) return;
  
  if (!isGtagAvailable()) return;
  
  window.gtag('set', 'user_properties', params);
};

export default {
  trackPageView,
  trackSignUp,
  trackLogin,
  trackNewRelease,
  trackPurchase,
  trackEvent,
  setUserProperties,
  setDebugMode
}; 