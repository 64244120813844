import React, {
  useContext,
  useState,
  useRef,
  useCallback,
  useMemo,
  useEffect,
} from "react";
import { LanguageContext } from "../../context/Language";
import { Button } from "antd";
import { FormProvider, useForm } from "react-hook-form";
import ContractCalls from "../../classes/contract/contract";
import Modal from "antd/lib/modal/Modal";
import Loader from "../../components/Loader";
import { useSearchParams, useNavigate } from "react-router-dom";
import CreateContractTabs from "./CreateContractTabs";
import ModalDelete from "../../components/ModalDelete/ModalDelete";
import ModalNotification from "../../components/ModalNotification";
import ModalConfirm from "../../components/ModalConfirm/ModalConfirm";

const CreateContract = () => {
  const [searchParams] = useSearchParams();
  const labelId = searchParams.get("labelId");
  const contractId = searchParams.get("c");
  const { dictionary } = useContext(LanguageContext);
  const [isSaving, setIsSaving] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [saveResponse, setSaveResponse] = useState(null);
  const [saveStatus, setSaveStatus] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openNotficationModal, setOpenNotficationModal] = useState(false);
  const [openNotficationSyncModal, setOpenNotficationSyncModal] =
    useState(false);
  const [openSyncModal, setOpenSyncModal] = useState(false);
  const [openDeleteOnSyncModal, setOpenDeleteOnSyncModal] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const [reload, setReload] = useState(false);
  const form = useRef(null);
  const navigate = useNavigate();
  const deletedNotification = useMemo(
    () =>
      deleted
        ? dictionary.deleteContractSuccess
        : dictionary.deleteContractError,
    [deleted]
  );
  const [syncMessageModal, setSyncMessageModal] = useState(dictionary.syncContractConfirm)

  const deletedAction = useCallback(
    () => (deleted ? navigate("/contracts") : setOpenNotficationModal(false)),
    [deleted]
  );
  const pageTitle = useMemo(
    () => (contractId ? dictionary.editContract : dictionary.createContract),
    [contractId]
  );

  const formMethods = useForm({
    loadState: "unloaded",
    defaultValues: {
      label: labelId,
      companyId: "",
    },
  });

  useEffect(() => {
    if (isDeleted) {
      setOpenDeleteOnSyncModal(true);
    }
  }, [isDeleted]);

  const onSubmit = async (data) => {
    setIsSaving(true);
    const contractSave = contractId
      ? await ContractCalls.patchContract(contractId, data)
      : await ContractCalls.saveContract(data);
    if (contractSave.contract) {
      setSaveStatus(true);
      setSaveResponse(dictionary.successSavedContract);
    } else {
      setSaveStatus(false);
      setSaveResponse(
        contractSave.errors
          ? contractSave.errors[0].message
          : dictionary.errorSavingContract
      );
    }
    setModalVisible(true);
    setIsSaving(false);
  };

  const save = () => {
    form.current.requestSubmit();
  };

  const deleteContract = async () => {
    setIsSaving(true);
    const deleted = await ContractCalls.deleteContract(contractId);
    if (!deleted.error) {
      setOpenNotficationModal(true);
      setDeleted(true);
    } else {
      setOpenNotficationModal(true);
      setDeleted(false);
    }
    setOpenDeleteModal(false);
    setOpenDeleteOnSyncModal(false);
    setIsSaving(false);
  };

  const syncContract = async () => {
    setIsSaving(true);
    const sync = await ContractCalls.syncContract(contractId);
    if (sync.deleted) {
      setOpenDeleteOnSyncModal(true);
    } else {
      setOpenNotficationSyncModal(true);
      setReload(!reload);
    }
    setOpenSyncModal(false);
    setIsSaving(false);
  };

  const loadModalInCharge = () => {
    setSyncMessageModal(dictionary.syncContractAlert)
    setOpenSyncModal(true)
  }

  const openSyncModalOnClick = () => {
    setOpenSyncModal(true)
    setSyncMessageModal(dictionary.syncContractConfirm)
  }

  return (
    <React.Fragment>
      {isSaving && <Loader />}
      <Modal
        className={"modal-box"}
        open={modalVisible}
        closable={true}
        onCancel={() => setModalVisible(false)}
        onOk={() => setModalVisible(false)}
        title={pageTitle}
      >
        <div>
          <h4>{saveStatus ? dictionary.success : dictionary.error}</h4>
          <p>{saveResponse}</p>
        </div>
      </Modal>
      <div className="container-admin contrcts">
        <FormProvider {...formMethods}>
          <form
            id="form-contract"
            ref={form}
            onSubmit={formMethods.handleSubmit(onSubmit)}
          >
            <div className="form-header">
              <h2>{pageTitle}</h2>
              <div>
                <Button
                  className="general-btn save"
                  type="primary"
                  onClick={() => save()}
                >
                  {dictionary.save}
                </Button>
                <Button
                  className="general-btn"
                  type="danger"
                  onClick={() => setOpenDeleteModal(true)}
                >
                  {dictionary.delete}
                </Button>
                <Button
                  className="general-btn warning"
                  type="warning"
                  onClick={() => openSyncModalOnClick()}
                >
                  {dictionary.sync}
                </Button>
              </div>
            </div>
            <CreateContractTabs reload={reload} setIsDeleted={setIsDeleted} setSync={loadModalInCharge} />
          </form>
        </FormProvider>
      </div>
      <ModalDelete
        visible={openDeleteModal}
        message={dictionary.deleteContractConfirm}
        onCancel={() => setOpenDeleteModal(false)}
        onDelete={deleteContract}
      />
      <ModalConfirm
        visible={openSyncModal}
        id={"syncModal"}
        message={syncMessageModal}
        onAction={syncContract}
        onCancel={() => setOpenSyncModal(false)}
      />
      <ModalConfirm
        visible={openDeleteOnSyncModal}
        id={"syncDeleteModal"}
        message={dictionary.syncDeleteContract}
        onAction={deleteContract}
        onCancel={() => setOpenDeleteOnSyncModal(false)}
      />
      <ModalNotification
        id={"deletedNotification"}
        visible={openNotficationModal}
        message={deletedNotification}
        onClose={deletedAction}
      />
      <ModalNotification
        id={"syncedNotification"}
        visible={openNotficationSyncModal}
        message={dictionary.syncedContracts}
        onClose={() => setOpenNotficationSyncModal(false)}
      />
    </React.Fragment>
  );
};

export default CreateContract;
