import React, { useContext, useEffect } from "react";
import Input from "../../../../Form/Input/GeneralInput";
import { useFieldArray, useFormContext, Controller } from "react-hook-form";
import { Button, Select } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import CreateSalesEscalation from "./Escalation";
import { AppContext } from "../../../../../context/App/AppProvider";
import { LanguageContext } from "../../../../../context/Language";

const CreateSalesTerms = ({ index, remove }) => {
  const formMethods = useFormContext();
  const {
    catTypeContractOptions,
    catGroupContractOptions,
    chanelContractOptions,
    priceCatContractOptions,
    sourceContractOptions,
    dealTypeSalesContractOptions,
    escalationEquivalentConfigurationOptions,
    countriesOptions,
    openedContract,
    setOpenedContract,
  } = useContext(AppContext);
  const { dictionary } = useContext(LanguageContext);
  const { control } = formMethods;

  const {
    fields: salesEscalationTerms,
    append: appendSalesEscalationTerms,
    remove: removeSalesEscalationTerms,
    replace: replaceSalesEscalationTerms,
  } = useFieldArray({
    name: `salesTerms-escalation_${index}`,
  });

  useEffect(() => {
    if (
      openedContract?.salesTerms &&
      openedContract?.salesTerms[index]?.escalation
    ) {
      replaceSalesEscalationTerms(
        openedContract[`salesTerms-escalation_${index}`]
      );
      delete openedContract[`salesTerms-escalation_${index}`];
      setOpenedContract({
        ...openedContract,
      });
    }
  }, [openedContract]);

  const addSalesEscalation = () => {
    appendSalesEscalationTerms(
      {
        type: "",
        rate: 0,
        multiplier: 1,
        reductionRate: 0,
        reserve: 0,
      },
      {
        shouldFocus: false,
      }
    );
  };

  const drawSalesEscalations = () => {
    return salesEscalationTerms.map((st, i) => {
      return (
        <CreateSalesEscalation
          key={st.id}
          data={st}
          index={i}
          parentIndex={index}
          remove={() => removeSalesEscalationTerms(i)}
        />
      );
    });
  };
  return (
    <div className="input-row-group">
      <div className="input-row">
        <div className={"relative-input"}>
          <Controller
            control={control}
            name={`salesTerms.${index}.catType`}
            rules={{
              required: "This field is required",
            }}
            render={({ field, fieldState }) => (
              <React.Fragment>
                <label className="allWidth">{dictionary.catType}</label>
                <Select
                  className={
                    "fullWidth selector-general" +
                    (fieldState.error ? " input-general-error" : "")
                  }
                  options={catTypeContractOptions}
                  {...field}
                />
                {fieldState.error && (
                  <div className="text-error">{fieldState.error.message}</div>
                )}
              </React.Fragment>
            )}
          />
        </div>
        <div className={"relative-input"}>
          <Controller
            control={control}
            name={`salesTerms.${index}.catalogueGroup`}
            rules={{
              required: "This field is required",
            }}
            render={({ field, fieldState }) => (
              <React.Fragment>
                <label className="allWidth">{dictionary.catGroup}</label>
                <Select
                  className={
                    "fullWidth selector-general" +
                    (fieldState.error ? " input-general-error" : "")
                  }
                  options={catGroupContractOptions}
                  {...field}
                />
                {fieldState.error && (
                  <div className="text-error">{fieldState.error.message}</div>
                )}
              </React.Fragment>
            )}
          />
        </div>
        <div className={"relative-input"}>
          <Controller
            control={control}
            name={`salesTerms.${index}.territory`}
            rules={{
              required: "This field is required",
            }}
            render={({ field, fieldState }) => (
              <React.Fragment>
                <label className="allWidth">{dictionary.territory}</label>
                <Select
                  className={
                    "fullWidth selector-general" +
                    (fieldState.error ? " input-general-error" : "")
                  }
                  options={countriesOptions}
                  {...field}
                />
                {fieldState.error && (
                  <div className="text-error">{fieldState.error.message}</div>
                )}
              </React.Fragment>
            )}
          />
        </div>
        <div className={"relative-input"}>
          <Controller
            control={control}
            name={`salesTerms.${index}.channel`}
            rules={{
              required: "This field is required",
            }}
            render={({ field, fieldState }) => (
              <React.Fragment>
                <label className="allWidth">{dictionary.channel}</label>
                <Select
                  className={
                    "fullWidth selector-general" +
                    (fieldState.error ? " input-general-error" : "")
                  }
                  options={chanelContractOptions}
                  {...field}
                />
                {fieldState.error && (
                  <div className="text-error">{fieldState.error.message}</div>
                )}
              </React.Fragment>
            )}
          />
        </div>
        <div className={"relative-input"}>
          <Controller
            control={control}
            name={`salesTerms.${index}.configuration`}
            rules={{
              required: "This field is required",
            }}
            render={({ field, fieldState }) => (
              <React.Fragment>
                <label className="allWidth">{dictionary.config}</label>
                <Select
                  className={
                    "fullWidth selector-general" +
                    (fieldState.error ? " input-general-error" : "")
                  }
                  options={escalationEquivalentConfigurationOptions}
                  {...field}
                />
                {fieldState.error && (
                  <div className="text-error">{fieldState.error.message}</div>
                )}
              </React.Fragment>
            )}
          />
        </div>
        <div className={"relative-input"}>
          <Controller
            control={control}
            name={`salesTerms.${index}.priceCategory`}
            rules={{
              required: "This field is required",
            }}
            render={({ field, fieldState }) => (
              <React.Fragment>
                <label className="allWidth">{dictionary.priceCat}</label>
                <Select
                  className={
                    "fullWidth selector-general" +
                    (fieldState.error ? " input-general-error" : "")
                  }
                  options={priceCatContractOptions}
                  {...field}
                />
                {fieldState.error && (
                  <div className="text-error">{fieldState.error.message}</div>
                )}
              </React.Fragment>
            )}
          />
        </div>
        <div className={"relative-input"}>
          <Controller
            control={control}
            name={`salesTerms.${index}.source`}
            rules={{
              required: "This field is required",
            }}
            render={({ field, fieldState }) => (
              <React.Fragment>
                <label className="allWidth">{dictionary.source}</label>
                <Select
                  className={
                    "fullWidth selector-general" +
                    (fieldState.error ? " input-general-error" : "")
                  }
                  options={sourceContractOptions}
                  {...field}
                />
                {fieldState.error && (
                  <div className="text-error">{fieldState.error.message}</div>
                )}
              </React.Fragment>
            )}
          />
        </div>
      </div>
      <div className="input-row">
        <div className={"relative-input"}>
          <Controller
            control={control}
            name={`salesTerms.${index}.type`}
            rules={{
              required: "This field is required",
            }}
            render={({ field, fieldState }) => (
              <React.Fragment>
                <label className="allWidth">{dictionary.dealType}</label>
                <Select
                  className={
                    "fullWidth selector-general" +
                    (fieldState.error ? " input-general-error" : "")
                  }
                  options={dealTypeSalesContractOptions}
                  {...field}
                />
                {fieldState.error && (
                  <div className="text-error">{fieldState.error.message}</div>
                )}
              </React.Fragment>
            )}
          />
        </div>
        <Controller
          control={control}
          name={`salesTerms.${index}.unitRate`}
          render={({ field, fieldState }) => (
            <Input
              className={"form-box-input"}
              label={dictionary.unitRate}
              placeholder={dictionary.unitRate}
              type="number"
              min={0}
              {...field}
              error={fieldState.error?.message}
            />
          )}
        />
        <Controller
          control={control}
          name={`salesTerms.${index}.rate`}
          rules={{
            required: "This field is required",
            min: "Field must be grather or equal than 0",
          }}
          render={({ field, fieldState }) => (
            <Input
              className={"form-box-input"}
              label={dictionary.rate}
              placeholder={dictionary.rate}
              type="number"
              min={0}
              {...field}
              error={fieldState.error?.message}
            />
          )}
        />
        <Controller
          control={control}
          name={`salesTerms.${index}.multiplier`}
          render={({ field, fieldState }) => (
            <Input
              className={"form-box-input"}
              label={dictionary.multiplier}
              placeholder={dictionary.multiplier}
              type="number"
              min={0}
              {...field}
              error={fieldState.error?.message}
            />
          )}
        />
        <Controller
          control={control}
          name={`salesTerms.${index}.reductionRate`}
          render={({ field, fieldState }) => (
            <Input
              className={"form-box-input"}
              label={dictionary.reductionRate}
              placeholder={dictionary.reductionRate}
              type="number"
              min={0}
              {...field}
              error={fieldState.error?.message}
            />
          )}
        />
        <Controller
          control={control}
          name={`salesTerms.${index}.reserve`}
          render={({ field, fieldState }) => (
            <Input
              className={"form-box-input"}
              label={dictionary.reserve}
              placeholder={dictionary.reserve}
              type="number"
              min={0}
              {...field}
              error={fieldState.error?.message}
            />
          )}
        />
        <Button
          className="remove remove-cross"
          type="danger"
          onClick={() => remove()}
          icon={<DeleteOutlined />}
        />
      </div>
      {drawSalesEscalations()}
      <div className="input-row">
        <Button
          type="primary"
          onClick={() => addSalesEscalation()}
          className="add-escalation"
        >
          {dictionary.addEscalation}
        </Button>
      </div>
    </div>
  );
};

export default CreateSalesTerms;
