import React, { useContext, useState, useEffect } from "react";
import { Row, Col, message } from "antd";
import { LanguageContext } from "../../../context/Language";
import Select from "../../Form/Select";
import Button from "../../Buttons/GeneralButton";

const Account = () => {
  const { dictionary, locale, setLanguage } = useContext(LanguageContext);

  const langOptions = [
    { _id: "en", name: dictionary.englishTitle },
    { _id: "es", name: dictionary.spanishTitle },
    { _id: "fr", name: dictionary.frenchTitle },
    { _id: "sq", name: dictionary.albanianTitle },
  ];

  const currentLanguage = langOptions.find((lang) => lang._id === locale) || langOptions[0];

  const [loading, setLoading] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(currentLanguage);

  useEffect(() => {
    setSelectedLanguage(currentLanguage);
  }, [locale, dictionary]);

  const selectChangeHandler = (value, option) => {
    setSelectedLanguage({ _id: value, name: option.label });
  };

  const success = () => {
    message.success({
      content: dictionary.processSuccess,
      style: {
        marginTop: "50px",
      },
    });
  };
  const save = async () => {
    if (selectedLanguage._id !== locale) {
      setLoading(true);
      try {
        await setLanguage(selectedLanguage._id);
        success();
      } catch (error) {
        console.error("saving language error", error);
        message.error({ content: dictionary.errorGeneral, style: { marginTop: "50px" } });
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div className="content-set">
      <h2>{dictionary.accountSettings}</h2>
        <Row>
          <Col span={24}>
            <h4 className="subtext">{dictionary.updateLangDescription}</h4>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col span={24}>
            <Select
              name="language"
              label={dictionary.changeLanguage}
              options={langOptions}
              onChange={selectChangeHandler}
              value={selectedLanguage._id}
              className={"selector-general"}
            />
          </Col>
        </Row>
      <div className="reg-btn">
        <Button
          disabled={loading || selectedLanguage._id === locale}
          size="md"
          onClick={save}
          text={dictionary.save}
        />
      </div>
    </div>
  );
};

export default Account;
