import React, { useContext, useMemo } from "react";
import { Row, Col, Input, Select } from "antd";
import { LanguageContext } from "../../../../../../context/Language";

const TrackCopyright = ({
  dataToUpdateDispatcher,
  dataToUpdate,
  isEditMode,
  trackData,
}) => {
  const { dictionary } = useContext(LanguageContext);
  const yearOptions = useMemo(() => {
    const years = [];
    for (let i = new Date().getFullYear(); i >= 1900; i--) {
      years.push({ value: i, label: i });
    }
    return years;
  }, []);

  return (
    <Row justify="space-between" className="general-info-tracks mt-3">
      <Col xs={24} lg={11} className="info-one">
        <h2>{dictionary.copyright}</h2>
        <Row justify="space-between" align="middle" className="mt-2 mr-4">
          <Col className="label" md={8} span={24}>
            {"(C) " + dictionary.info}
          </Col>
          <Col md={16} span={24} className="">
            <Input
              className="input-general"
              onChange={(e) => {
                dataToUpdateDispatcher({
                  type: "objectChange",
                  payload: {
                    name: "copyright",
                    original: trackData.copyright,
                    value: dataToUpdate.copyright
                      ? {
                          data: e.target.value,
                          year: dataToUpdate?.copyright?.year,
                        }
                      : {
                          data: e.target.value,
                          year: trackData?.copyright?.year,
                        },
                  },
                });
              }}
              defaultValue={
                dataToUpdate?.copyright?.data ?? trackData?.copyright?.data
              }
              value={
                dataToUpdate?.copyright?.data ?? trackData?.copyright?.data
              }
              placeholder="Rights holder international"
              disabled={!isEditMode}
            />
          </Col>
        </Row>
        <Row justify="space-between" align="middle" className="mt-2 mr-4">
          <Col className="label" md={8} span={24}>
            {"(C) " + dictionary.year}
          </Col>
          <Col md={16} span={24}>
            <Select
              className="selector-general"
              options={yearOptions}
              onChange={(e) => {
                dataToUpdateDispatcher({
                  type: "objectChange",
                  payload: {
                    name: "copyright",
                    original: trackData.copyright,
                    value: dataToUpdate.copyright
                      ? { data: dataToUpdate?.copyright?.data, year: e }
                      : { data: trackData?.copyright?.data, year: e },
                  },
                });
              }}
              defaultValue={
                dataToUpdate?.copyright?.year || trackData?.copyright?.year
              }
              value={
                dataToUpdate?.copyright?.year || trackData?.copyright?.year
              }
              disabled={!isEditMode}
            />
          </Col>
        </Row>
        <Row justify="space-between" align="middle" className="mt-2 mr-4">
          <Col className="label" md={8} span={24}>
            {dictionary.rightsHolder}
          </Col>
          <Col md={16} span={24}>
            <Input
              className="input-general"
              onChange={(e) => {
                dataToUpdateDispatcher({
                  type: "simpleChange",
                  payload: {
                    name: "rights_holder",
                    original: trackData.rights_holder,
                    value: e.target.value,
                  },
                });
              }}
              defaultValue={
                dataToUpdate?.rights_holder ?? trackData.rights_holder
              }
              value={dataToUpdate.rights_holder ?? trackData.rights_holder}
              placeholder="Example corp LLC"
              disabled={!isEditMode}
            />
          </Col>
        </Row>
      </Col>
      <Col xs={24} lg={11}  className="creactive-info-title">
        <h2>{dictionary.phonographic}</h2>
        <Row justify="space-between" align="middle" className="mt-2 mr-4">
          <Col className="label" md={8} span={24}>
            {"(P) " + dictionary.info}
          </Col>
          <Col md={16} span={24}>
            <Input
              className="input-general"
              onChange={(e) => {
                dataToUpdateDispatcher({
                  type: "objectChange",
                  payload: {
                    name: "phonographic",
                    original: trackData.phonographic,
                    value: dataToUpdate.phonographic
                      ? {
                          data: e.target.value,
                          year: dataToUpdate?.phonographic?.year,
                        }
                      : {
                          data: e.target.value,
                          year: trackData?.phonographic?.year,
                        },
                  },
                });
              }}
              defaultValue={
                dataToUpdate?.phonographic?.data ??
                trackData?.phonographic?.data
              }
              value={
                dataToUpdate?.phonographic?.data ??
                trackData?.phonographic?.data
              }
              placeholder="Rights holder phonographic"
              disabled={!isEditMode}
            />
          </Col>
        </Row>
        <Row justify="space-between" align="middle" className="mt-2 mr-4">
          <Col className="label" md={8} span={24}>
            {"(P) " + dictionary.year}
          </Col>
          <Col md={16} span={24}>
            <Select
              className="selector-general"
              options={yearOptions}
              onChange={(e) => {
                dataToUpdateDispatcher({
                  type: "objectChange",
                  payload: {
                    name: "phonographic",
                    original: trackData.phonographic,
                    value: dataToUpdate.phonographic
                      ? { data: dataToUpdate?.phonographic?.data, year: e }
                      : { data: trackData?.phonographic?.data, year: e },
                  },
                });
              }}
              defaultValue={
                dataToUpdate?.phonographic?.year ||
                trackData?.phonographic?.year
              }
              value={
                dataToUpdate?.phonographic?.year ||
                trackData?.phonographic?.year
              }
              disabled={!isEditMode}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default TrackCopyright;
