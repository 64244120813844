import React, { useContext, useEffect, useState } from "react";
import { Card, notification } from "antd";
import { Link } from "react-router-dom";
import { getResizedImage } from "../../../../util";
import { LanguageContext } from "../../../../context/Language";
import Bluebird from "bluebird";
import DeleteDraft from "./DeleteDraft";
import ReleaseList from "./ReleaseList";
import Button from "../../../Buttons/GeneralButton";
import Release from "../../../../classes/release/release.jsx";
import Loader from "../../../Loader/Loader";
import CustomCard from '../../../Common/CustomCard/CustomCard';
const ReleaseCard = ({
  releaseList,
  listName,
  released,
  plus,
  changeItem,
  getReleases,
  canDelete = false,
  section,
}) => {
  const { dictionary } = useContext(LanguageContext);
  const [releases, setReleasesList] = useState([]);
  const [deleteTitle, deleteRelTitle] = useState(false);
  const [deleteVisible, deleteRelVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setListReleases();
    // eslint-disable-next-line
  }, [releaseList]);

  const extractArtist = async (main, featured) => {
    if (!main?.length) {
      return "";
    }
    let artistName = main ? main[0]?.name : "";
    let featuredArtists = "";
    await featured.forEach(async (el, index) => {
      featuredArtists += el.name;
      if (index + 1 < featured.length) {
        featuredArtists += " & ";
      }
    });
    return featuredArtists.length
      ? artistName + " ft. " + featuredArtists
      : artistName;
  };

  const setListReleases = async () => {
    let cdArr = [];
    await Bluebird.map(releaseList, async (el, index) => {
      let artistName = await extractArtist(el.main_artist, el.featured_artist);
      let image = el.image_cover
        ? el.s3_url + "/" + el.image_cover.filename
        : null;
      let imageResized = null;
      if (image) {
        imageResized = getResizedImage({
          url: image,
          type: "release",
          resourceId: el._id,
        });
      } 
      cdArr.push(
        <ReleaseList
          key={index}
          img={image}
          section={section}
          idRelease={el._id}
          released={released}
          canDelete={canDelete}
          changeItem={changeItem}
          imgResized={imageResized}
          deleteRelease={deleteRelease}
          showDeleteDraft={showDeleteDraft}
          title={!el.title ? dictionary.noTitle : el.title}
          artist={artistName === "" ? dictionary.noArtist : artistName}
          rejections={el.rejections || []}
        />
      );
    }).then(() => {
      setReleasesList(cdArr);
    });
  };

  const deleteCancel = () => {
    deleteRelVisible(false);
  };

  const showDeleteDraft = (id, title) => {
    deleteRelVisible(id);
    deleteRelTitle(title);
  };

  const deleteRelease = (id) => {
    try {
      setLoading(true);
      Release.updateDraftStatus(id, false).then((res) => {
        if (res.error) {
          notification.error({
            message: dictionary.errorGeneral,
            duration: 3,
            placement: "topRight",
          });
        } else {
          notification.success({
            message: dictionary.delRelSuccess,
            duration: 3,
            placement: "topRight",
          });
        }
        getReleases();
      }).catch((e) => {
        console.log(e);
      }).finally(() => {
        setListReleases();
        deleteRelVisible(false);
        setLoading(false);
      });
    } catch (e) {
      console.log(e);
    }
  };

  const viewAllBtn = <Button text={dictionary.viewAll} className="view-all" />;

  return (
    <div className="general-card">
        {loading ? <Loader /> : null}
      <Card>
        <p className="list-name">{listName}</p>
        <Link onClick={() => changeItem("/release")} to={"/release#" + section}>
          {releases.length ? viewAllBtn : null}
        </Link>
        <div className="list-rel">
          <ul
            className={
              (!releases.length ? "empty-stylecard" : "") + " list-cd dash_home"
            }
          >
            {plus ? <li onClick={() => changeItem("/newRelease")}>
                <CustomCard
                isAddCard={true}
                addTo={'/newRelease'}
              /> 
            </li>: null}
            {!releases.length && (
              <li>
                <CustomCard
                  isEmptyCard={true}
                />
              </li>
            )}
            {releases}
          </ul>
        </div>
      </Card>
      {deleteVisible ? (
        <DeleteDraft
          deleteTitle={deleteTitle}
          handleCancel={deleteCancel}
          deleteRelease={deleteRelease}
          deleteVisible={deleteVisible}
        />
      ) : null}
    </div>
  );
};

export default ReleaseCard;
