import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Badge, Popconfirm, Empty } from 'antd'
import Avatar from 'react-avatar';
import PlusIconCard from '../PlusIconCard/PlusIconCard';
import RejectionTimeLine from '../../TimeLine/RejectionTimeLine';
import { LanguageContext } from "../../../context/Language";
import './style.css';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';


const CustomCard = ({
    // For Card
    imageResized,
    album,
    artist,
    idRelease,
    onImageError,
    onClick,

    // Type of Card
    isEmptyCard = false,
    isAddCard = false,

    // For Rejections
    rejections = 0,

    // For Plus Icon
    addCardOnClick,
    addTo,

    // Fade Background
    editCardOnClick,
    deleteCardOnClick,
    showFadeBackground = false,
    canDeleteOnFadeBackground = true,
    canUpdateOnFadeBackground = true,
    // For Link
    redirect = false,
    link,
}) => {
    const { dictionary } = useContext(LanguageContext);

    const renderBadge = () => {
        return <div className="custom-card-badge-container">
            {rejections.length > 0 ? 
                <Popconfirm
                    title={<RejectionTimeLine rejections={rejections} />}
                    showCancel={false}
                    icon={false}
                    okText={"ok"}
                    style={{height: '20px'}}
                >
                    <Badge count={rejections.length} className="custom-card-badge"></Badge>
                </Popconfirm> 
            : null} 
        </div>
    };

        
    const renderImage = () => {
        if (imageResized) {
            return <img
                src={imageResized}
                alt={album || 'card-image'}
                className="custom-card-image"
                id={"imgList" + idRelease}
                onError={(e) => onImageError(e)}
            />
        }
        return (
            <Avatar
            name={album || 'NA'}
            round="10px"
            size="151"
            className="custom-card-avatar"
            />
        );
    };
    
    const fadeBackground = () => {
        if (showFadeBackground) {
            return <div className="fade-background-icons">
                {canUpdateOnFadeBackground ? <EditOutlined className="fade-icon" onClick={editCardOnClick} /> : null}
                {canDeleteOnFadeBackground ? <DeleteOutlined className="fade-icon" onClick={deleteCardOnClick} /> : null}
            </div>
        }
    }

    const imageAndContent = () => {
        const imageConstant = <div className="custom-card-background-image-fadebg">
            <div className={"custom-card-fade-background" + (showFadeBackground ? ' show show-mobile' : '')}>
                {fadeBackground()}
            </div>
            <div className="custom-card-image-container">
                {renderImage()}
            </div>
        </div>
    if(!isAddCard && !isEmptyCard)
        if(redirect)
            return <Link to={link} className="custom-card-link">{imageConstant}</Link>
        else
            return imageConstant
    }
    const content = (
    <>
        {renderBadge()}
        {isEmptyCard ?
            (<Empty
                className="empty-rel"
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={
                <div className={"no-release-description"}>
                    {dictionary.noReleases}
                </div>
                }
            />)
        : null}
        {isAddCard ?
            (<PlusIconCard
                to={addTo}
                onClick={() => addCardOnClick()}
            />)
        : null}
        {imageAndContent()}
        <div className="custom-card-content">
            <p className="album">{album}</p>
            <p className="artist">{artist}</p>
        </div>
    </>
    );

    return (
    <div className="custom-card-wrapper">
        <div onClick={!showFadeBackground ? onClick : null} className="custom-card-container">
            {content}
        </div>
    </div>
    );
};

export default CustomCard; 