import React, { useState, useEffect } from 'react';
import { Modal } from 'antd';
import PlanTable from '../PlanTable/PlanTable'
import './feature-modal.css';

const FeatureModal = ({ 
  visible, 
  onClose, 
  plans, 
  selectedPlan, 
  onSelect, 
  dictionary, 
  isAnnual 
}) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <Modal
      title={dictionary.pricingAllFeatures}
      open={visible}
      onCancel={onClose}
      footer={null}
      width={isMobile ? "100%" : "80%"}
      className={`feature-modal ${isMobile ? 'feature-modal-mobile' : ''}`}
      centered
      destroyOnClose={false}
    >
      <div className="feature-table-wrapper" style={{ display: 'block', width: '100%' }}>
        <PlanTable
          plans={plans}
          selectedPlan={selectedPlan}
          onSelect={onSelect}
          dictionary={dictionary}
          hideSelectBtn={true}
          isAnnual={isAnnual}
        />
      </div>
    </Modal>
  );
};

export default FeatureModal; 