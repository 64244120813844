import React, { useContext } from "react";
import { Row, Col, DatePicker } from "antd";
import { LanguageContext } from "../../../../../context/Language";
import { UserContext } from "../../../../../context/User";
import Input from "../../../../Form/Input";
import Select from "../../../../Form/Select";
import InfoIcon from "../../../../Icon/InfoIcon/InfoIcon";
import moment from "moment";

const AdvanceInfo = ({ state, setInput, errors }) => {
  const { dictionary, languagesList } = useContext(LanguageContext);
  const { plan, type } = useContext(UserContext);

  const handleSelect = (e, name) => {
    setInput({
      ...state,
      [name]: e,
    });
  };

  const changeHandler = (e) => {
    setInput({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const changeHandlerYear = (e, dateString) => {
    setInput({
      ...state,
      recordYear: dateString,
    });
  };

  const onChangepreOrderDate = (date, dateString) => {
    setInput({
      ...state,
      preOrder: dateString,
    });
  };

  const disabledDate = (current) => {
    return (
      (current && current.isAfter(state.relDate, "day")) ||
      current.isBefore(moment(), "day")
    );
  };

  return (
    <div className="forms">
      <Row className="gutter-row">
        <Col xs={24} sm={24} md={12} lg={12} className="col1">
          <label className={"allWidth"}>
            {dictionary.PreOrderDate}
            <InfoIcon tooltipKey="preOrderDateInfo" placement="right" />
          </label>
          <DatePicker
            value={state.preOrder ? moment(state.preOrder, "YYYY-MM-DD") : null}
            onChange={onChangepreOrderDate}
            disabledDate={disabledDate}
            placeholder={dictionary.selectDate}
            className={errors.preOrderDateErr ? "inputErr " : ""}
            inputReadOnly={true}
          />
          {errors.preOrderDateErr ? (
            <div className="text-error">{errors.preOrderDateErr}</div>
          ) : null}
          <label className={"allWidth"}>
              {dictionary.recordingYear}
              <InfoIcon tooltipKey="recordingYearInfo" placement="right" />
            </label>
          <DatePicker
              picker="year"
              value={state.recordYear ? moment(state.recordYear, "YYYY-MM-DD") : null}
              onChange={changeHandlerYear}
              placeholder={dictionary.selectYear}
              className={errors.recordYearErr ? "inputErr " : ""}
              inputReadOnly={true}
            />
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} className="col2">
          <Select
            name="metadata"
            required={true}
            value={state.metadata}
            options={languagesList || []}
            error={errors.metadataErr}
            placeholder={dictionary.select}
            label={dictionary.metadataLanguage}
            labelExtra={
              <InfoIcon tooltipKey="metadataLanguageInfo" placement="right" />
            }
            onChange={(e) => handleSelect(e, "metadata")}
            className={
              (errors.metadataErr ? "countErr " : "") + " selector-general"
            }
          />
          {((type !== "admin" && plan?.features?.recording_location) || type==="admin") && (
            <Input
              name="recordLoc"
              value={state.recordLoc}
              onChange={changeHandler}
              label={dictionary.recordingLocation}
              labelExtra={
                <InfoIcon
                  tooltipKey="recordingLocationInfo"
                  placement="right"
                />
              }
            />
          )}
        </Col>
      </Row>
    </div>
  );
};

export default AdvanceInfo;
