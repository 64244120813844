import React from "react";
import { Button } from "antd";
import "./style.css";

const GeneralButton = ({
  styles = null,
  onClick = null,
  text = null,
  icon = null,
  size = null,
  className = null,
  disabled = false,
  type = null,
}) => {
  const buttonClass = type === 'pricing' ? 'pricing-btn' : 'general-btn';
  
  return (
    <Button
      className={(className || "") + " " + buttonClass + " " + (size ? size : " sm ")}
      onClick={onClick}
      style={styles}
      type="text"
      icon={icon}
      disabled={disabled}
    >
      {text}
    </Button>
  );
};

export default GeneralButton;
