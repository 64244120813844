import React, { useState, useContext, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { UserContext } from "../../context/User";
import { Layout, Menu, Button, Drawer, notification } from "antd";
import { LanguageContext } from "../../context/Language";
import { AppContext } from "../../context/App";
import { CaretLeftOutlined, CaretRightOutlined, LogoutOutlined } from "@ant-design/icons";
import SecureLS from "secure-ls";
import Items from "./Items/Items.jsx";
import AudioSaladApiTokenModal from "./AudioSaladApiTokenModal/";
import Logo from "../../logoPriamDogital.svg";
import LogoShort from "../../logo_short.svg";
import Cookies from "js-cookie";
const { Sider } = Layout;
import "./nav.css";
const ls = new SecureLS({ encodingType: "base64", isCompression: false });

const Navbar = ({ current, changeItem, open, setOpen, collapsed, setCollapsed }) => {

  const { dictionary } = useContext(LanguageContext);
  const { sessionAuth, setRegister, register, handleSession, type } =
    useContext(UserContext);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { isAudioSaladApiTokenEnable, validateAudiosaladAPIstatus } =
    useContext(AppContext);
  let home = useRef(null);

  useEffect(() => {
    if (!isAudioSaladApiTokenEnable) {
      notification.error({
        key: "audioSaladApiTokenError",
        message: dictionary.audioSaladApiTokenError,
        duration: 15,
        placement: "bottomRight",
        onClick: () => {
          setIsModalVisible(true);
        },
      });
    }
  }, [isAudioSaladApiTokenEnable]);

  const logout = () => {
    ls.removeAll();
    Cookies.remove("userInfo");
    setRegister(false);
    handleSession(false);
    home.current.click();
  };

  const logoutregister = () => {
    if (register) {
      return (
        <div
          className="logout-nav"
          onClick={() => {
            logout();
          }}
        >
          <LogoutOutlined style={{ marginRight: '6px' }} />
          {dictionary.backToStart}
        </div>
      );
    }
    return null;
  };

  const onCollapse = () => {
    setCollapsed(!collapsed);
    onClose();
  };

  const onClose = () => {
    setOpen(false);
  };

  const sideMenu = (
    <Button
      type="text"
      icon={
        collapsed ? (
          <CaretRightOutlined style={{ color: "#fff" }} />
        ) : (
          <CaretLeftOutlined style={{ color: "#fff" }} />
        )
      }
      onClick={onCollapse}
      className="collapse-btn"
    />
  );

  const termsAndConditions = () => {
    return (
      <div className={(type === "admin" ? "admin-bar " : "usr-bar ") + "priam-terms"}>
        <Link to="https://priamdigital.com/privacy-policy/" target="_blank">
          {dictionary.privacyPolicy}
        </Link>
        <span>&#8226;</span>
        <Link to="/terms" onClick={onClose}>
          {dictionary.TermsOfService}
        </Link>
        <span>&#8226;</span>
        <Link to="/distribution-agreement" onClick={onClose}>
          {dictionary.DistrAgreement}
        </Link>
        <span>&#8226;</span>
        <span> Priam Media Inc © {new Date().getFullYear()} | Priam Digital ®</span>
      </div>
    );
  };

  const sideNavbar = () => {
    return (
      <Sider
        className="sider-navbar"
        trigger={null}
        collapsible
        collapsed={collapsed}
      >
        <div className="overflow-fixer">
          <Link to="/" className="a-img">
            {collapsed ? (
              <img className="logoActiveShort" src={LogoShort} alt="Priam" />
            ) : (
              <img className="logoActive" src={Logo} alt="Priam" />
            )}
          </Link>
          <hr className="divisor-sider" />
          <Menu
            theme="dark"
            mode="inline"
            selectedKeys={[current]}
            defaultSelectedKeys={
              current !== "/newRelease" ? [current] : ["/release"]
            }
            items={Items(changeItem)}
          />
          {!collapsed ? termsAndConditions() : null}
        </div>
        {sideMenu}
      </Sider>
    );
  };

  const drawerResponsive = () => {
    return (
      <Drawer
        className="drawer-mobile"
        placement="left"
        closable={false}
        onClose={onClose}
        open={open}
        title={null}
      >
        <Link to="/" className="a-img">
          <img className="logoActive" src={Logo} alt="Priam" />
        </Link>
        <hr className="divisor-sider" />
        <Menu
          theme="dark"
          mode="inline"
          selectedKeys={
            current.includes("/newRelease")
              ? ["/release"]
              : [current]
          }
          defaultSelectedKeys={
            current.includes("/newRelease")
              ? ["/release"]
              : [current]
          }
          items={Items(changeItem)}
        />
        <Button
          type="text"
          icon={<CaretLeftOutlined style={{ color: "#fff" }} />}
          onClick={onClose}
          className="collapse-btn"
        />
        {termsAndConditions()}
      </Drawer>
    );
  };

  return (
    <React.Fragment>
      {!sessionAuth ? (
        <div className="nabvar-on-register">
          <Link to="/" ref={home}>
            <img src={Logo} alt="Priam" className="logo" />
          </Link>
          {logoutregister()}
        </div>
      ) : (
        <React.Fragment>
          <AudioSaladApiTokenModal
            isModalVisible={isModalVisible}
            onCancel={() => setIsModalVisible(false)}
            onConfirm={() => {
              setIsModalVisible(false);
              setTimeout(() => {
                validateAudiosaladAPIstatus();
              }, 300);
            }}
          />
          {sideNavbar()}
          {drawerResponsive()}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default Navbar;
