import React, { useContext, useState, useEffect } from "react";
import { LanguageContext } from "../../context/Language";
import User from "../../classes/user/user.jsx";
import Tables from "../../components/Catalog/Tables";
import Release from "../../classes/release/release";
import "../../components/Catalog/catalog.css";

const DistributionQueue = () => {

  const { dictionary } = useContext(LanguageContext);
  const [search, getSearch] = useState(null);
  const [loadList, setLoading] = useState(false);
  const [listRelAdmin, setReleaseAdmin] = useState([]);
  const [allStatus, filterStatus] = useState([]);
  const [releases, setReleases] = useState({
    draft: [],
    rejected: [],
    delivered: [],
    readyToSent: [],
    inReview: [],
    published: [],
    pendingDistribution: [],
  });

  useEffect(() => {
    getReleases();
    getStatus();
    // eslint-disable-next-line
  }, []);

  const columns = [
    {
      title: dictionary.cover,
      dataIndex: "details",
      className: "id-category",
    },
    {
      title: dictionary.title,
      dataIndex: "title",
      className: "title-category album",
    },
    {
      title: dictionary.requestedBy,
      dataIndex: "requestedBy",
      className: "title-category",
    },
    {
      title: dictionary.releaseDate,
      dataIndex: "releaseDate",
      className: "title-category",
    },
    {
      title: dictionary.status,
      dataIndex: "estatus",
      className: "title-category",
    },
    {
      title: dictionary.upcCodes,
      dataIndex: "upc",
      className: "title-category",
    },
    {
      title: dictionary.createdAt,
      dataIndex: "createdAt",
      className: "title-category",
    },
    {
      title: dictionary.actions,
      dataIndex: "actions",
      className: "title-category",
    },
    {
      title: dictionary.priority,
      dataIndex: "priority",
      className: "title-category",
    },
    {
      title: dictionary.storesRequested,
      dataIndex: "storesRequested",
      className: "title-category",
    },
    {
      title: dictionary.details,
      dataIndex: "detail",
      className: "title-category",
    },
  ];

  const getReleases = async () => {
    setLoading(true);
    const voidReleases = {
      draft: [],
      rejected: [],
      delivered: [],
      readyToSent: [],
      inReview: [],
      pendingDistribution: [],
      published: [],
    };
    try {
      const resp = await User.getReleases();
      const releasesObject = resp.body.releases || voidReleases;
      setReleases(releasesObject);
      const sections = Object.keys(releases);
      const all = [];
      getAllReleases(releasesObject);
      sections.forEach((el) => {
        releasesObject[el].forEach((element) => {
          element.step = el;
          all.push(element);
        });
      });
      setReleaseAdmin(all);
      setLoading(false);
    } catch (e) {
      setReleases(voidReleases);
      setLoading(false);
    }
  };

  const getAllReleases = (rels = releases) => {
    const sections = Object.keys(rels);
    const all = [];
    sections.forEach((el) => {
      rels[el].forEach((element) => {
        if (element.title !== null) {
          all.push(element);
        }
      });
    });
    return all;
  };

  const getStatus = () => {
    const listStatus = [];
    Release.audiosaladStatus().then((res) => {
      let allstatus = (res.body.status || []);
      let keys = Object.keys(allstatus);
      let i = 0;
      for (let type of keys) {
        if (type === "inReview" || type === "pendingDistribution")
          listStatus.push({ _id: keys[i], name: dictionary[keys[i]] });
        i++;
      }
      filterStatus(listStatus);
    });
  };

  return (
    <React.Fragment>
      <div className="container-admin">
        <Tables
          error={false}
          typeTable={2}
          remove={false}
          released={true}
          distro={true}
          search={search}
          getSearch={getSearch}
          canUpdate={false}
          loadList={loadList}
          isRelease={true}
          showModal={false}
          subjects={listRelAdmin}
          list={listRelAdmin || []}
          setNewList={setReleaseAdmin}
          canDelete={false}
          title={dictionary.distributionReq}
          getReleases={getReleases}
          getAllReleases={getAllReleases}
          columns={columns}
          allStatus={allStatus}
          setLoadingList={setLoading}
          isAdminRels={true}
          section={"distributionQueue"}
        />
      </div>
    </React.Fragment>
  );
};

export default DistributionQueue;
