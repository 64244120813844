import React, { useState, useEffect, useMemo, useContext } from "react";
import User from "../../classes/user/user.jsx";
import Counts from "../../components/Dashboard/Counts";
import Releases from "../../components/Dashboard/Releases";
import { UserContext } from "../../context/User";
import "./dash.css";

const Dashboard = ({ changeItem }) => {
  const { type } = useContext(UserContext);
  const [totalLabels, setTotalLabels] = useState(0);
  const [totalArtists, setTotalArtists] = useState(0);
  const [totalReleases, setTotalReleases] = useState(0);
  const [updateReleaseLoading, setUpdateReleaseLoading] = useState(false);
  const [loading, setLoading] = useState({
    release: true,
    artist: true,
    label: true,
  });
  const [releases, setReleases] = useState({
    draft: [],
    rejected: [],
    delivered: [],
    readyToSent: [],
    inReview: [],
    published: [],
    pendingDistribution: [],
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const loadingCopy = { ...loading };
        const [totalLabelsResp, totalArtistsResp, releasesResp] =
          await Promise.all([
            User.getTotalLabels(),
            User.getTotalArtists(),
            User.getReleases(),
          ]);
        setTotalLabels(totalLabelsResp.body.total || 0);
        setTotalArtists(totalArtistsResp.body.total || 0);
        const releasesObject = releasesResp.body.releases || {
          draft: [],
          rejected: [],
          delivered: [],
          readyToSent: [],
          inReview: [],
          published: [],
          pendingDistribution: [],
          rejected_team: [],
        };
        setReleases(releasesObject);
        let total = 0;
        for (let key in releasesObject) {
          total += releasesObject[key].length;
        }
        setTotalReleases(total);

        loadingCopy.label = false;
        loadingCopy.artist = false;
        loadingCopy.release = false;
        setLoading(loadingCopy);
      } catch (error) {
        setTotalLabels(0);
        setTotalArtists(0);
        setTotalReleases(0);
        console.error("Error fetching data:", error);
      } finally {
        setLoading({
          label: false,
          artist: false,
          release: false,
        });
      }
    };

    fetchData();
  }, []);

  const getAllReleases = async (loading2 = []) => {
    try {
      const resp = await User.getReleases();
      const releasesObject = resp.body.releases || {
        draft: [],
        rejected: [],
        delivered: [],
        readyToSent: [],
        inReview: [],
        published: [],
        pendingDistribution: [],
        rejected_team: [],
      };
      setReleases(releasesObject);
      let total = 0;
      for (let key in releasesObject) {
        total += releasesObject[key].length;
      }
      setTotalReleases(total);
    } catch (e) {
      setReleases({
        draft: [],
        rejected: [],
        delivered: [],
        readyToSent: [],
        inReview: [],
        published: [],
        pendingDistribution: [],
        rejected_team: [],
      });
      setTotalReleases(0);
    } finally {
      loading2.release = false;
      setUpdateReleaseLoading(true);
      setLoading(loading2);
    }
  };

  const loadingUpdates = useMemo(() => {
    return (
      <Counts
        loading={loading}
        changeItem={changeItem}
        totalLabels={totalLabels}
        totalArtists={totalArtists}
        totalReleases={totalReleases}
        totalPublished={releases.published.length}
      />
    );
  }, [loading, updateReleaseLoading]);

  return (
    <React.Fragment>
      <div className="container-box dashboard">
        {loadingUpdates}
        <Releases
          changeItem={changeItem}
          releases={releases}
          getReleases={getAllReleases}
          type={type}
        />
        {/*<Tops/>*/}
      </div>
    </React.Fragment>
  );
};

export default Dashboard;
