import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Card } from "antd";
import { LanguageContext } from "../../../context/Language";
import { UserContext } from "../../../context/User";
import { getResizedImage } from "../../../util";
import "./style.css";
import CustomCard from '../../Common/CustomCard/CustomCard';
const List = ({
  type,
  list,
  title,
  remove,
  showModal,
  error,
  plus,
  redirect,
  route,
  canAdd,
  canUpdate,
  canDelete,
  isColab,
  isLabel = false,
}) => {
  const { dictionary } = useContext(LanguageContext);
  const { plan } = useContext(UserContext);

  const drawList = () => {
    return list.map((el, index) => {
      let image = null;
      if (el.image || el.image_cover) {
        const url = el.image_cover
          ? el.image_cover.s3_url + "/" + el.image_cover.filename
          : el.image;
        const type = el.image_cover ? "release" : "artist";
        image = getResizedImage({
          url,
          type,
          size: "sm",
          resourceId: el._id,
        });
      }

      let title = !el.name
        ? el.title
          ? el.title
          : dictionary.noTitle
        : el.name;

      let headArtist = el.user ? true : false;
      return (
        <li key={index}>
          <CustomCard
            imageResized={image}
            img={image}
            album={title}
            showFadeBackground={canDelete || canUpdate}
            editCardOnClick={() => showModal(el, headArtist, type, isColab)}
            deleteCardOnClick={() => remove(index, el, isColab)}
            canDeleteOnFadeBackground={canDelete && !headArtist}
            canUpdateOnFadeBackground={canUpdate}
          />
          {redirect ? <Link ref={redirect} to={route + el._id}></Link> : null}
        </li>
      );
    });
  };
  const addBtn = () => {
    const plusComponent = plus === true ? <CustomCard isAddCard={true} addCardOnClick={() => showModal()}/> : null;
    const limit = (plan?.features?.additional_label_names) || 0;
    const sublabelsNum = limit !== 0 ? limit + 1 : limit;
    const canAddUpdated = list.length < sublabelsNum && canAdd; 

    if ((canAddUpdated && isLabel) || !isLabel) {
      return (
        <li>
          {plusComponent}
        </li>
      );
    }
  }

  return (
    <div className="general-card">
      <Card>
        <p className="list-name">{title}</p>
        <Row>
          <Col xs={24} sm={24} md={24} lg={24}>
            <div className="list-rel list-artist">
              <ul className="list-cd inline-contend">
                {addBtn()}
                {!list.length ? (
                  <li>
                    <CustomCard
                      isEmptyCard={true}
                    />
                  </li>
                ) : null}
                {drawList()}
              </ul>
              {error ? (
                <p className="text-error relative-text">{error}</p>
              ) : null}
            </div>
          </Col>
        </Row>
        {redirect ? <Link ref={redirect} to={route}></Link> : null}
      </Card>
    </div>
  );
};

export default List;
