import React, { useContext, useState, useRef, useEffect } from "react";
import { Dropdown, Upload } from "antd";
import { EllipsisOutlined, LoadingOutlined } from "@ant-design/icons";
import { LanguageContext } from "../../../../../context/Language";
import { UploadServiceContext } from "../../../../../context/Services";
import { beforeUploadImage } from "../../../../../hooks/files/files";
import AlbumIcon from "../../../../Icon/AlbumIcon/AlbumIcon.svg";
import ReplaceIcon from "../../../../Icon/ReplaceIcon";
import DownloadIcon from "../../../../Icon/DownloadIcon";
import { getResizedImage } from "../../../../../util";

const CoverHeader = (props) => {
  const { dispatch, isEditMode, releaseId } = props;
  const { dictionary } = useContext(LanguageContext);
  const { uploadStatus } = useContext(UploadServiceContext);

  const imgCover = useRef();
  const coverCol = useRef();

  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);
  const [urlOriginal, setUrlOriginal] = useState(null);

  // If a cover change throught the upload service,
  // update the image with the resized one
  useEffect(() => {
    if (
      uploadStatus &&
      uploadStatus.type === "cover" &&
      uploadStatus.releaseId === releaseId
    ) {
      if (!loading && uploadStatus.progress < 100) setLoading(true);

      if (uploadStatus.progress === 100 && uploadStatus.asset) {
        setLoading(false);
        setImageSource(uploadStatus.asset, uploadStatus.s3_url);
        // This is to force the refresh, the s3 cache don't update the image properly
        const url = URL.createObjectURL(
          uploadStatus.file.originFileObj || uploadStatus.file
        );
        setImageUrl(url);
      }
    }
  }, [uploadStatus]);

  // This take any change on the props and update the image with the resized one
  useEffect(() => {
    if (props.asset.length > 0) setImageSource(props.asset[0], props.s3_url);
  }, [props.asset.length, props.s3_url]);

  const setImageSource = (asset, s3_url) => {
    const checksum = asset.checksum;
    const originalFilneName = asset.filename;
    const originalUrl = s3_url + "/" + originalFilneName + "?v=" + checksum;
    const minUrl = getResizedImage({
      url: originalUrl,
      type: "release",
      resourceId: releaseId,
    });

    setImageUrl(minUrl);
    setUrlOriginal(originalUrl);
  };

  const onImageLoadError = () => {
    setLoading(true);
    imgCover.current.src = urlOriginal;
    let image = document.createElement("img");
    image.src = urlOriginal;
    image.addEventListener(
      "error",
      () => {
        var isLoaded = image.complete && image.naturalHeight !== 0;
        if (!isLoaded) {
          coverCol?.current.classList.add("broke");
          imgCover.current.classList.add("broken");
          imgCover.current.src = AlbumIcon;
        }
      },
      false
    );
    setLoading(false);
    return;
  };

  const loadingButton = (
    <div className="edit-release-cover">
      <LoadingOutlined />
    </div>
  );

  const handleCoverSelector = async ({ file }) => {
    const url = URL.createObjectURL(file.originFileObj || file);
    if (url) {
      setImageUrl(url);
      dispatch({
        type: "cover",
        payload: {
          name: "cover",
          original: props.s3_url,
          value: file.originFileObj || file,
        },
      });
    }
    return false;
  };

  const handleDownloadClick = () => {
    if (imageUrl) {
      const dwnlink = document.createElement("a");
      dwnlink.href = urlOriginal;
      dwnlink.target = "_blank";
      dwnlink.download = true;
      dwnlink.click();
    }
  };

  const coverImageDropDownMenuOptions = [
    {
      key: "1",
      label: (
        <div onClick={handleDownloadClick}>
          <DownloadIcon />
          {dictionary.download}
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <div>
          <Upload
            customRequest={() => {}} // Disable default upload behavior
            name="avatar"
            showUploadList={false}
            onChange={handleCoverSelector}
            accept={"image/*"}
            beforeUpload={(file) => beforeUploadImage(file, dictionary)}
            maxCount={1}
            listType="text"
            className={" avatar-uploader"}
          >
            <ReplaceIcon />
            {dictionary.replace}
          </Upload>
        </div>
      ),
    },
  ];

  return (
    <div className="img-c" ref={coverCol}>
      {!loading && imageUrl ? (
        <React.Fragment>
          <img
            src={imageUrl}
            alt="cover"
            className="cover"
            ref={imgCover}
            onError={onImageLoadError}
          />
          {isEditMode ? (
            <Dropdown
              menu={{ items: coverImageDropDownMenuOptions }}
              placement="bottomLeft"
              overlayClassName="cover-edit"
            >
              <EllipsisOutlined className="menu-cover" />
            </Dropdown>
          ) : null}
        </React.Fragment>
      ) : !loading && !imageUrl ? (
        <img src={AlbumIcon} className="broken" />
      ) : (
        loadingButton
      )}
    </div>
  );
};

export default CoverHeader;
