import React from "react";
import moment from "moment";
import { CalendarOutlined } from "@ant-design/icons";
import { DatePicker } from "antd";
import "../form.css";

const dateFormat = "YYYY-MM-DD";

const DateInput = ({
  placeholder,
  label = null,
  value = undefined,
  picker = null,
  error = false,
  required = false,
  onChange = null,
  className = null,
  disabledDate = null,
  suffixText = null,
  suffixIcon = <CalendarOutlined />,
  disabled = false,
  labelExtra = null,
}) => {
  return (
    <div className="relative-input">
      {label ? (
        <label className={"allWidth"}>
          {label}
          <span>{required ? "*" : ""}</span>
          {labelExtra ? labelExtra : null}
        </label>
      ) : null}
      <DatePicker
        inputReadOnly={true}
        suffixIcon={suffixIcon}
        picker={picker}
        onChange={onChange}
        className={className}
        placeholder={placeholder}
        defaultValue={value ? moment(value, dateFormat) : null}
        value={value ? moment(value, dateFormat) : null}
        disabledDate={disabledDate}
        disabled={disabled}
      />
      {suffixText ? <div className="suffix-text">{suffixText}</div> : null}
      {error ? <div className="text-error">{error}</div> : null}
    </div>
  );
};

export default DateInput;
