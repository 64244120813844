import React, { useContext, useState } from "react";
import { Table, Tabs } from "antd";
import { LanguageContext } from "../../context/Language";

const Pricing = () => {
  const { dictionary } = useContext(LanguageContext);
  const [releasePrice] = useState([
    {
      wholesale: "$1.40",
      retail: "$1.99",
      tierName: "Digital 45",
      itunes: "14",
    },
    { wholesale: "$2.10", retail: "$2.99", tierName: "Mini EP", itunes: "30" },
    { wholesale: "$2.80", retail: "$3.99", tierName: "EP", itunes: "5" },
    {
      wholesale: "$3.50",
      retail: "$4.99",
      tierName: "Mini Album One",
      itunes: "31",
    },
    {
      wholesale: "$3.50",
      retail: "$4.99",
      tierName: "Mini Album Two",
      itunes: "42",
    },
    {
      wholesale: "$4.20",
      retail: "$5.99",
      tierName: "Budget One",
      itunes: "1",
    },
    {
      wholesale: "$4.90",
      retail: "$6.99",
      tierName: "Budget Two",
      itunes: "32",
    },
    { wholesale: "$5.60", retail: "$7.99", tierName: "Back", itunes: "2" },
    { wholesale: "$6.30", retail: "$8.99", tierName: "Mid", itunes: "33" },
    { wholesale: "$7.00", retail: "$9.99", tierName: "Mid/Front", itunes: "3" },
    {
      wholesale: "$7.70",
      retail: "$10.99",
      tierName: "Front One",
      itunes: "34",
    },
    {
      wholesale: "$7.70",
      retail: "$10.99",
      tierName: "Front Two",
      itunes: "40",
    },
    {
      wholesale: "$8.40",
      retail: "$11.99",
      tierName: "Front Plus",
      itunes: "4",
    },
    {
      wholesale: "$9.10",
      retail: "$12.99",
      tierName: "Deluxe One ",
      itunes: "50",
    },
    {
      wholesale: "$9.80",
      retail: "$13.99",
      tierName: "Deluxe Two",
      itunes: "51",
    },
    {
      wholesale: "$10.50",
      retail: "$14.99",
      tierName: "Deluxe Three",
      itunes: "52",
    },
    {
      wholesale: "$11.20",
      retail: "$15.99",
      tierName: "Deluxe Four",
      itunes: "53",
    },
  ]);

  const [trackPrice] = useState([
    { wholesale: "$0.49", retail: "$0.69", tierName: "Back", itunes: "98" },
    { wholesale: "$0.70", retail: "$0.99", tierName: "Mid", itunes: "99" },
    { wholesale: "$0.91", retail: "$1.29", tierName: "Front", itunes: "100" },
  ]);

  const [options] = useState([
    {
      title: dictionary.wholesale,
      dataIndex: "wholesale",
      key: "wholesale",
    },
    {
      title: dictionary.retail,
      dataIndex: "retail",
      key: "retail",
    },
    {
      title: dictionary.tierName,
      dataIndex: "tierName",
      key: "tierName",
    },
    {
      title: "Itunes",
      dataIndex: "itunes",
      key: "itunes",
    },
  ]);

  const drawTrackPrice = () => {
    return trackPrice.map((el, i) => {
      return {
        key: i,
        wholesale: el.wholesale,
        retail: el.retail,
        tierName: el.tierName,
        itunes: el.itunes,
      };
    });
  };

  const drawReleasePrice = () => {
    return releasePrice.map((el, i) => {
      return {
        key: i,
        wholesale: el.wholesale,
        retail: el.retail,
        tierName: el.tierName,
        itunes: el.itunes,
      };
    });
  };

  const items = [
    {
      key: "1",
      label: <h2>{dictionary.releasePriceTiers}</h2>,
      children: (
        <Table
          dataSource={drawReleasePrice()}
          columns={options}
          className="tracks-table-admin prices"
          pagination={false}
        />
      ),
    },
    {
      key: "2",
      label: <h2>{dictionary.trackPriceTiers}</h2>,
      children: (
        <Table
          dataSource={drawTrackPrice()}
          columns={options}
          pagination={false}
          className="tracks-table-admin prices"
        />
      ),
    },
  ];

  return (
    <React.Fragment>
      <div className="container-box thanks-box tiers">
        <Tabs
          defaultActiveKey="1"
          centered
          items={items}
          className="tabs-top"
        />
      </div>
    </React.Fragment>
  );
};

export default Pricing;
