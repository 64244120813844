import React, {useEffect, useContext} from 'react';
import { Layout } from 'antd';
import './footer.css';
import { LanguageContext } from '../../context/Language';
import { Link } from 'react-router-dom';
const { Footer } = Layout;

const FooterComp = () => {

  const { dictionary } = useContext(LanguageContext);

  useEffect(() => {
    adjustFooterForIOS();
  },[]);

  const isIOS = () => {
    return /iPhone|iPad|iPod/.test(navigator.userAgent) && !window.MSStream;
  }

  const adjustFooterForIOS = () => {
    if (isIOS()) {
      const footer = document.querySelector('footer');
      if (footer) {
        footer.style.bottom = '78px'; 
      }
    }
  }

  return (
    <Footer className="footer">
      <div className="footer-content">
        <div className="footer-links">
          <a 
            href="https://priamdigital.com/privacy-policy/"
            target="_blank"
            rel="noopener noreferrer"
          >
            {dictionary.privacyPolicy}
          </a>
          <Link 
            to="/terms"
            target="_blank"
          >
            {dictionary.TermsOfService}
          </Link>
          <Link 
            to="/distribution-agreement"
            target="_blank"
          >
            {dictionary.DistrAgreement}
          </Link>
          <span>© {new Date().getFullYear()} - Priam Media Inc | Priam Digital ®</span>
        </div>
      </div>
    </Footer>
  );
};

export default FooterComp;
