import { useState, useContext } from "react";
import { LanguageContext } from "../../context/Language";
import validator from "validator";

const isValid = (stateData, rules, dictionary, location) => {
  let errorMessages = {};
  const stateKeys = Object.keys(stateData);

  for (const key of stateKeys) {
    if (!rules[key]) {
      errorMessages = {
        ...addError({
          errorMessages,
          key,
          message: `${key} is not declared in rules`,
        }),
      };
      continue;
    }

    const isOptional = rules[key]?.required === false || rules[key]?.optional === true;
    const type = rules[key]?.type;
    const value = stateData[key];

    if (isOptional && (value === "" || value === undefined || value === null)) {
      continue;
    }

    switch (type) {
      case "string": {
        const stringErrors = validateString({ value, rules: rules[key], dictionary, location});
        if (stringErrors.length > 0 && (!isOptional || value !== "")) {
          errorMessages = {
            ...addError({
              errorMessages,
              key,
              message: stringErrors,
            }),
          };
        }
        break;
      }

      case "number":
        errorMessages = {
          ...addError({
            errorMessages,
            key,
            message: validateNumber({ value, rules: rules[key], dictionary}),
          }),
        };
        break;

      case "array":
        errorMessages = {
          ...addError({
            errorMessages,
            key,
            message: validateArray({ value, rules: rules[key], key, dictionary}),
          }),
        };
        break;

      default:
        if (type !== "omit")
          console.error(`Type ${type} is not supported, ${key} key in rules`);
        break;
    }
  }

  return errorMessages;
};

/**
 * update the errorMessages object with proper push or set
 * @param {object} errorMessages
 * @param {string | string[]} key
 * @param {message} message
 * @returns {object} - errorMessages object
 */
const addError = ({ errorMessages, key, message }) => {
  if (message.length === 0) return errorMessages;

  if (Object.hasOwnProperty.call(errorMessages, key))
    errorMessages[key] = errorMessages[key].concat(message);
  else errorMessages[key] = Array.isArray(message) ? message : [message];
  return errorMessages;
};

/**
 * @param {string} value - evaluated value
 * @param {object} rules - rules to validate against { min, max, length}
 * @param {string} key - evaluated key
 * @returns {array} - array of error messages
 */
const validateString = ({ value, rules, dictionary, location }) => {
  const errors = [];
  const {
    min,
    max,
    isURL,
    select,
    length, 
    isAlpha, 
    isEmail,
    validEmpty, 
    numericStr,
    isAlphanumeric,
  } = rules;

  if (validEmpty && value === "")
    return errors;
  else if(value === 0 && select)
    errors.push(`${dictionary.selectErr}`);
  else if (numericStr && !validator.isNumeric(value, "any"))
    errors.push(`${dictionary.onlyNumbers}`);
  else if (value.length === 0)
    errors.push(`${dictionary.emptyField}`);
  else if (length && value.length !== length)
    errors.push(`${dictionary.shouldBe} ${length} ${dictionary.chars}`);
  else if (min && value.length < min)
    errors.push(`${dictionary.shouldBeAtLeast} ${min} ${dictionary.chars}`);
  else if (max && value.length > max)
    errors.push(`${dictionary.shouldBeAtMost} ${max} ${dictionary.chars}`);
  else if (isEmail && !value.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/))
    errors.push(`${dictionary.isNotValidEmailAddress}`);
  else if (isAlpha && !validator.isAlpha(value, ["en-US"], { ignore: "-s" }))
    errors.push(`${dictionary.onlyLetters}`);
  else if (isAlphanumeric && !validator.isAlphanumeric(value, ["en-US"], { ignore: ".,-s'&" }))
    errors.push(`${dictionary.onlyLettersAndChars}`);
  else if (isURL && !validator.isURL(value, { protocols: ["http", "https"]}))
    errors.push(`${dictionary.websiteErr}`);

  return errors;
};

/**
 *
 * @param {*} param0
 * @returns {array} - array of error messages
 */
const validateNumber = ({ value, rules, dictionary }) => {
  const { min, max, validEmpty } = rules;
  const errors = [];
  // if (!required && (value === "" || value === 0)) return errors;
  if (validEmpty && value === "") return errors;
  if (typeof value === "string") value = Number(value.replace(/,/g, ""));
  if (min && value < min) errors.push(`${dictionary.shouldBeAtLeast} ${min} ${dictionary.numbers}`);
  if (max && value > max) errors.push(`${dictionary.shouldBeAtMost} ${max} ${dictionary.numbers}`);
  return errors;
};

const validateArray = ({ value, rules, key, dictionary }) => {

  const errors = [];
  const { item_rules, min, max } = rules;

  // if (!required && value.length === 0) return errors;

  if (min && value.length < min)
    return [`${dictionary.shouldContainAtLeast} ${min} ${dictionary.items}`];

  if (max && value.length > max)
    return [`${dictionary.shouldContainAtMost} ${max} ${dictionary.items}`];

  let i = 0;
  for (const item_value of value) {
    const itemKey = key + "[" + i + "]";
    const itemErr = isValid(
      { [itemKey]: item_value },
      { [itemKey]: item_rules }
    );
    const iek = Object.keys(itemErr);
    if (iek.length) errors.push(`${itemErr[itemKey].join("")}`);

    i++;
  }

  return errors;
};

/**
 *
 * @param {object} rules
 * @example
 * #First create a object that contains the rules for each key in the object
 * const rules = {
 *  [key]: {
 *   type: string | array | number | omit | object
 *   required: bool,
 *   min: number,
 *   max: number,
 *   isEmail: bool,
 *   length: number
 *   item: object
 *  },
 *  ...rules
 * };
 * #Call the hook and pass the rules object
 * const { valid, validationErrorMessages, validate } = useStateValidator(rules);
 * #Call the validate function with the object (fn or effect) that you want to validate
 * const save = () => {
 * validate(componentState);
 * const errors = validate(componentState);
 * };
 */
// Maybe we can pass the onError function to the hook
const useStateValidator = ({ rules }) => {
  const { dictionary, locale } = useContext(LanguageContext);
  const [validationErrorMessages, setValidationErrorMessages] = useState({});

  const getValidLocale = (loc) => {
    if (loc.startsWith("es")) return "es-ES";
    if (loc.startsWith("fr")) return "fr-FR";
    if (loc.startsWith("sq") || loc.startsWith('alb')) return "sq-AL";
    return "en-US";
  };

  const location = getValidLocale(locale);

  // In the exported function only pass the data object an use the intilizaed rules
  const validate = (d) => {
    const validationErrors = isValid(d, rules, dictionary, location);
    setValidationErrorMessages(validationErrors);
    return validationErrors;
  };

  return {
    valid: Object.keys(validationErrorMessages).length === 0,
    validationErrorMessages,
    validate,
  };
};

export default useStateValidator;
