import { useContext, useEffect, useState } from "react";
import { ReleaseDetailContext } from "../../context/Release";

/**
 * We should move the reducer logic to this hook
 * and the derived state
 * @returns {Object} The context of the release detail
 */
export const useReleaseDetail = () => {
  const context = useContext(ReleaseDetailContext);

  if (!context) {
    throw new Error("useRelease must be used within a ReleaseProvider");
  }

  const [releaseData, setReleaseData] = useState({});

  useEffect(() => {
    if (context.releaseData) setReleaseData(context.releaseData);
  }, [context.releaseData]);

  /**
   * In theory we should hanlde the derived state here
   * like this use effect that lives in 2 components
   * at this time i dont have the time to do it
   * so i will leave it here as a proposal
   * 
    useEffect(() => {
        if (Object.keys(trackData).length === 0) return;
    
        // We need to reconcile the data from the server with the data from the user
        // The item.origin is very important, cuz its used in the reducer with originalContributors
        const result = [
          ...(dataToUpdate?.artists || trackData?.artists || []).map((item) => {
            item.origin = "artists";
            return item;
          }),
          ...(dataToUpdate?.writers || trackData?.writers || []).map((item) => {
            item.origin = "writers";
            return item;
          }),
          ...(dataToUpdate?.producers || trackData?.producers || []).map((item) => {
            item.origin = "producers";
            return item;
          }),
        ];
    
        if (result.length === 0) return;
    
        // This was changed to objects cuz we need to use the contributorId as key
        // from the original data, so the contributorRole is the server structure
        // {contributorId: [contributorRole, contributorRole, ...]]}
        const artistList = {};
        const writerList = {};
        const additionalList = {};
    
        // For meet the requirements we need to restucture the data,
        // cuz the roles are diferent between the server and the presentation view,
        // (change the server data implies several changes in both sides)
        // you can see the ArtistContext.js file for more details about the roles
        for (const contributor of result) {
          let added = false;
    
          if (rolesByType["artist"]?.includes(contributor.role)) {
            if (Object.hasOwnProperty.call(artistList, contributor.artist_id)) {
              artistList[contributor.artist_id].push(contributor);
            } else {
              artistList[contributor.artist_id] = [contributor];
            }
            added = true;
          }
    
          if (rolesByType["writer"]?.includes(contributor.role)) {
            if (Object.hasOwnProperty.call(writerList, contributor.artist_id)) {
              writerList[contributor.artist_id].push(contributor);
            } else {
              writerList[contributor.artist_id] = [contributor];
            }
            added = true;
          }
    
          if (rolesByType["participant"]?.includes(contributor.role)) {
            if (Object.hasOwnProperty.call(additionalList, contributor.artist_id)) {
              additionalList[contributor.artist_id].push(contributor);
            } else {
              additionalList[contributor.artist_id] = [contributor];
            }
            added = true;
          }
    
          if (!added) {
            if (Object.hasOwnProperty.call(additionalList, contributor.artist_id))
              additionalList[contributor.artist_id].push(contributor);
            else additionalList[contributor.artist_id] = [contributor];
          }
        }
        setArtists(artistList);
        setWriters(writerList);
        setAdditional(additionalList);
        setOriginalContributors({
          artists: trackData.artists,
          writers: trackData.writers,
          producers: trackData.producers,
        });
      }, [
        JSON.stringify(trackData.artists),
        JSON.stringify(trackData.writers),
        JSON.stringify(trackData.producers),
        JSON.stringify(dataToUpdate.artists),
        JSON.stringify(dataToUpdate.writers),
        JSON.stringify(dataToUpdate.producers),
    ]);
  */

  const mutateReleaseData = (newData) => {
    if (!newData || Object.keys(newData).length === 0) return;

    setReleaseData((prevState) => ({
      ...prevState,
      ...newData,
    }));
  };

  return {
    ...context,
    releaseData,
    mutateReleaseData /* reducer, derivedState */,
  };
};
