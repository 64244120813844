import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { UserContext } from "../../../context/User";
import { LanguageContext } from "../../../context/Language";
import UpcIcon from "../../Icon/UpcIcon";
import DspIcon from "../../Icon/DspIcon";
import HomeIcon from "../../Icon/HomeIcon";
import WorldIcon from "../../Icon/WorldIcon";
import ReviewIcon from "../../Icon/ReviewIcon";
import ArtistsIcon from "../../Icon/ArtistsIcon";
import ReleaseIcon from "../../Icon/ReleaseIcon";
import DistribIcon from "../../Icon/DistribIcon";
import ContractIcon from "../../Icon/ContractIcon";
import RoyaltyIcon from "../../Icon/RoyaltyIcon";
import UsersIcon from "../../Icon/UsersIcon";
import SettingsIcon from "../../Icon/SettingsIcon";
import SupportIcon from "../../Icon/SupportIcon";

const Items = (changeItem) => {
  const { dictionary } = useContext(LanguageContext);
  const { permission, type } = useContext(UserContext);

  const items = [
    {
      label: <Link to="/">{dictionary.home}</Link>,
      key: "/",
      onClick: changeItem,
      icon: <HomeIcon/>
    },
  ];
  if (permission && permission.artist && permission.artist.see) {
    items.push({
      label: <Link to="/artist">{dictionary.artists}</Link>,
      key: "/artist",
      onClick: changeItem,
      icon: <ArtistsIcon/>
    });
  }
  if (type !== "artist") {
    if (permission && permission.label && permission.label.see) {
      items.push({
        label: <Link to="/label">{dictionary.labels}</Link>,
        key: "/label",
        onClick: changeItem,
        icon: <WorldIcon/>
      });
    }
  }
  if(type === "admin"){
    items.push({
      label: <Link to="/user">{dictionary.users}</Link>,
      key: "/user",
      onClick: changeItem,
      icon: <UsersIcon/>
    });
  }
  if (permission && permission.release && permission.release.see) {
    items.push({
      label: (
        <Link to="/release">{dictionary.releases}</Link>
      ),
      key: "/release",
      onClick: changeItem,
      icon: <ReleaseIcon/>
    });
  }
  if (type === "admin") {
    items.push({
      label: <Link to="/review">{dictionary.review}</Link>,
      key: "/review",
      onClick: changeItem,
      icon: <ReviewIcon/>
    });
  }
  if (type === "admin") {
    items.push({
      label: <Link to="/distributionQueue">{dictionary.distributionReq}</Link>,
      key: "/distributionQueue",
      onClick: changeItem,
      icon: <DistribIcon/>
    });
  }
  if (permission && permission.upc && permission.upc.see) {
    items.push({
      label: <Link to="/upc">UPC & ISRC</Link>,
      key: "/upc",
      onClick: changeItem,
      icon: <UpcIcon/>
    });
  }
  if(type === "admin"){
    items.push({
      label: <Link to="/dsp">DSP</Link>,
      key: "/dsp",
      onClick: changeItem,
      icon: <DspIcon/>
    });
  }
  if(type === "admin"){
    items.push({
      label: <Link to="/contracts">{dictionary.contracts}</Link>,
      key: "/contracts",
      onClick: changeItem,
      icon: <ContractIcon/>
    });
  }
  if(type !== "admin"){
    items.push({
      label: <Link to="/royalties">{dictionary.royalties}</Link>,
      key: "/royalties",
      onClick: changeItem,
      icon: <RoyaltyIcon/>
    });
  }
  if(type !== "admin"){
    items.push({
      label: <Link to="/settings">{dictionary.settings}</Link>,
      key: "/settings",
      onClick: changeItem,
      icon: <SettingsIcon color={"#ffffffa6"}/>
    });
  }
  items.push({
    label: <Link to="/support  ">{dictionary.support}</Link>,
    key: "/support",
    onClick: changeItem,
    icon: <SupportIcon />
  });
  return (
    items
  );
};

export default Items;
