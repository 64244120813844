import React, { useContext, useEffect, useState, useMemo } from "react";
import { Upload, Table, Skeleton, Popconfirm } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { LanguageContext } from "../../../../context/Language";
import { useReleaseDetail } from "../../../../hooks/release/useReleaseDetail";
import DeleteIcon from "../../../../components/Icon/DeleteIcon";
import Summary from "./Summary";
import TrackCrudModal from "../Modals/TrackCrudModal";
import AudioPlayer from "../../../AudioPlayer";
import "./tracks.css";

const Tracks = ({
  isEditMode,
  dispatch,
  dataToUpdate = [],
  setShowAddUpcIsrcModal,
}) => {
  const { releaseData, isLoading, tracks, setTracks } = useReleaseDetail();
  const { dictionary } = useContext(LanguageContext);
  const [dataSource, setTableData] = useState([]);
  const [summaryTrack, setSummaryTrack] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalTrackData, setModalTrackData] = useState({});
  const [updateTrack, setUpdateTrack] = useState(false);

  const columns = [
    {
      title: dictionary.title,
      dataIndex: "title",
      className: "title-category",
    },
    {
      title: dictionary.titleVersion,
      dataIndex: "titleVersion",
      className: "title-category",
    },
    {
      title: dictionary.isrc,
      dataIndex: "isrc",
      className: "title-category",
    },
    {
      title: dictionary.actions,
      dataIndex: "actions",
      className: "title-category",
    },
  ];

  // We need to reconcile the data from the server with the data from the user
  const trackDataChangedMerged = useMemo(() => {
    if (dataToUpdate.length === 0) return tracks;

    let result = [...tracks];
    // Separate objects based on '_id' property
    // @see TrackCrudModal@handleModalSave

    for (const trackUpdated of dataToUpdate) {
      if (trackUpdated._id.includes("new")) result.push(trackUpdated);
      else if (trackUpdated._id.includes("delete"))
        result = result.filter(
          (t) => t._id !== trackUpdated._id.replace("delete", "")
        );
      else {
        const originalTrackIndex = result.findIndex(
          (t) => t._id === trackUpdated._id
        );
        // this condition should never be false
        if (originalTrackIndex !== -1) {
          result[originalTrackIndex] = {
            ...result[originalTrackIndex],
            ...trackUpdated,
          };
        }
      }
    }

    return result;
  }, [dataToUpdate, tracks]);

  useEffect(() => {
    if (trackDataChangedMerged.length !== 0) {
      createAndSetTableData();
      setSummaryTrack(trackDataChangedMerged[0] || []);
    }
  }, [trackDataChangedMerged]);

  const createAndSetTableData = () => {
    let listArray = [];
    if (trackDataChangedMerged.length !== 0) {
      trackDataChangedMerged.map((track, index) => {
        let audioplayer = null;
        // If the track is new or the audio has changed
        if (track?.newFiles) {
          audioplayer = (
            <AudioPlayer
              showAsIconButton
              dataFromFile={track.newFiles.fileList[0]}
            />
          );
        } else {
          const dataFromAudioURI = {
            url: "",
            filename: "",
            format: "",
          };
          if (track?.asset?.length) {
            dataFromAudioURI.url = track?.s3_url || "";
            dataFromAudioURI.filename = track?.asset[0]?.filename || "";
            dataFromAudioURI.format = track?.asset[0]?.format || "";
          }
          audioplayer = (
            <AudioPlayer
              releaseId={track.releaseId}
              showAsIconButton
              dataFromURI={dataFromAudioURI}
            />
          );
        }

        return listArray.push({
          index: index,
          key: index,
          title: track?.name || track?.title || dictionary.noTitle,
          titleVersion: track?.version || dictionary.noVersion,
          isrc: track.isrc || dictionary.noIsrc,
          info: track,
          actions: (
            <div key={track._id} className="actions-tracks">
              {audioplayer}
              <EditOutlined
                className="icon-eye-track"
                onClick={() => openCurrentTrack(track)}
              />
              {isEditMode ? (
                <Popconfirm
                  placement="top"
                  title={dictionary.deleteTrackQueue}
                  onConfirm={() =>
                    dispatch({
                      type: "tracks",
                      payload: {
                        name: "tracks",
                        original: modalTrackData,
                        value: { ...track, _id: `delete${track._id}` },
                      },
                    })
                  }
                  okText={dictionary.yes}
                  cancelText={dictionary.no}
                >
                  <DeleteIcon
                    className="icon-trash-track btn-click"
                    color="#F63E3E"
                  />
                </Popconfirm>
              ) : null}
            </div>
          ),
        });
      });
    }
    setTableData(listArray);
  };

  const handleSaveModal = (trackData) => {
    dispatch({
      type: "tracks",
      payload: {
        name: "tracks",
        original: modalTrackData,
        value: trackData,
      },
    });

    setModalTrackData({});
    setShowModal(false);
  };

  const handleCancelModal = () => {
    setModalTrackData({});
    setShowModal(false);
  };

  const openCurrentTrack = (current) => {
    setModalTrackData(current);
    setShowModal(true);
  };

  const triggerUpdateAudioUpload = (e) => {
    const newModalData = Object.prototype.hasOwnProperty.call(
      modalTrackData,
      "_id"
    )
      ? { ...modalTrackData, newFiles: e }
      : { newFiles: e };
    setUpdateTrack(true);
    setModalTrackData(newModalData);
    setShowModal(true);
  };

  const uploadButton = () => {
    return isEditMode ? (
      <div>
        {/* Original behavior is start the upload, 
          when a file is selected,
          with customRequest remove that */}
        <Upload
          customRequest={() => {}}
          name="avatar"
          accept="audio/x-wav, audio/wav, audio/x-flac, audio/flac"
          showUploadList={false}
          onChange={triggerUpdateAudioUpload}
          listType="picture-card"
          className="track-uploader"
          maxCount={1}
        >
          <div className="sideBtn-drop">
            <div className="text-drop">{dictionary.dragOrClick}</div>
          </div>
        </Upload>
        <div className="desc-drop">*{dictionary.audioDesc}</div>
      </div>
    ) : null;
  };

  return (
    <div className="tracks-box">
      <div className="title">
        <h1>{dictionary.tracks}</h1>
      </div>
      <div className="tracker-container">
        <div className="list-tracks">
          {uploadButton()}
          {isLoading ? (
            <Skeleton active paragraph={{ width: [], rows: 6 }} />
          ) : (
            <Table
              rowKey="index"
              columns={columns}
              dataSource={dataSource}
              pagination={false}
              className="content-table-tracks tracks-table"
              onRow={(record) => {
                return {
                  onClick: () => setSummaryTrack(record.info),
                };
              }}
            />
          )}
        </div>
        <Summary
          dataTrack={summaryTrack}
          editMode={isEditMode}
          openCurrentTrack={(track) => openCurrentTrack(track)}
        />
      </div>
      <TrackCrudModal
        releaseData={releaseData}
        tracks={trackDataChangedMerged}
        setTracks={setTracks}
        numberTracks={trackDataChangedMerged.length}
        trackData={modalTrackData}
        labelId={releaseData.label_id}
        handleSave={handleSaveModal}
        handleCancel={handleCancelModal}
        isEditMode={isEditMode}
        modalVisible={showModal}
        triggerUpdateAudioUpload={triggerUpdateAudioUpload}
        priceTier={tracks[0]?.priceTier || { type: "generic", name: "Front" }}
        setShowAddUpcIsrcModal={setShowAddUpcIsrcModal}
        updateTrack={updateTrack}
        setUpdateTrack={setUpdateTrack}
      />
    </div>
  );
};

export default Tracks;
