import React, { useContext } from "react";
import { Row, Col } from "antd";
import { LanguageContext } from "../../../../../context/Language";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import ArtistIcon from "../../../../Icon/ArtistIcon";

const ArtistList = ({ type, error, title, remove, showModal, frontList, orderedList, info }) => {

  const { dictionary } = useContext(LanguageContext);

  const drawList = () => {
    return frontList.map((el, index) => {
      return (
        <li key={index} className="card-data new-artist">
          <ArtistIcon className="artist-icon" />
          <div className="del-participant">
            <DeleteOutlined
              onClick={() => remove(type, index, el.name, el.artist_id)}
            />
          </div>
          <div className="name-added">{el.name}</div>
          <div className="show-roles">
            <div className="role-disp">{orderedList(el.role, true)}</div>
            <EditOutlined
              className="edit-roles"
              onClick={() => showModal(el, type)}
            />
          </div>
        </li>
      );
    });
  };

  return (
    <div>
      <div className="title-add-flex">
        <h2 className="titl-section">
          {title}
          {info ? info : null}
        </h2>
        <button
          className="new-artist-add clicked btnsm"
          onClick={() => showModal(null, type)}
        >
          <b>+ </b>
          {dictionary.add} {dictionary.category[type]}
        </button>
      </div>
      <Row>
        <Col xs={24} sm={24} md={24} lg={24}>
          <div>
            {error ? <p className="text-error relative-text">{error}</p> : null}
            <ul className="participant-cd">
              {frontList.length !== 0 ? drawList() : null}
            </ul>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ArtistList;
