import ReleaseCalls from "../calls/release/release.jsx";
import uploadS3Calls from "../calls/amazon/uploadS3.jsx";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import * as analytics from '../../util/analytics';

class Release {
  releaseType = async () => {
    return await ReleaseCalls.releaseType();
  };

  audiosaladStatus = async () => {
    return await ReleaseCalls.audiosaladStatus();
  };

  getRoles = async () => {
    const roles = await ReleaseCalls.getRoles();
    return roles.body.roles || null;
  };

  getFrontRoles = async () => {
    const roles = await ReleaseCalls.getFrontRoles();
    return roles.body.roles || [];
  };

  getReleaseDetails = async (releaseId) => {
    const data = await ReleaseCalls.getReleaseDetails(releaseId);
    if (data.status === 200) {
      return data.body;
    }
    return { error: true, status: data.status };
  };

  getReleaseResume = async (releaseId) => {
    const data = await ReleaseCalls.getReleaseResume(releaseId);
    if (data.status === 200) {
      return data.body;
    }
    return { error: true, status: data.status };
  };

  uploadCover = async (file, release) => {
    const re = /(?:\.([^.]+))?$/;
    const ext = re.exec(file.name)[1];
    const uploadS3 = new uploadS3Calls(release, "cover." + ext);
    const url = await uploadS3.createSignedUrl(file);
    return await uploadS3.uploadFile(url, file);
  };

  createRelease = async (release) => {
    const releaseData = {
      format: release.format,
      label: release.label,
      language: release.lang,
      isNew: release.isNew,
      relName: release.relName,
      relDate: release.relDate,
      permission: [],
      parental: release.parental,
      advanceInfo: {
        metadataLanguage: release.metadata,
      },
      subgenres: release.subgenres,
    };

    if (release._id) {
      releaseData["relId"] = release._id;
    }

    if (release.preOrder && moment(release.preOrder))
      releaseData.permission.push({
        type: "preorder",
        enabled: true,
        country: ["WW"],
        start: release.preOrder,
      });

    if (release.upc) releaseData.upc = release.upc;

    if (release.version) releaseData.version = release.version;

    if (release.orgRelDate) releaseData.advanceInfo.orgRelDate = release.orgRelDate;

    if (release.recordLoc) releaseData.advanceInfo.recordLoc = release.recordLoc;

    if (release.recordYear) releaseData.advanceInfo.recordYear = release.recordYear;

    const saved = await ReleaseCalls.createRelease(releaseData);
    if (saved.status === 200 && saved.body.release) {
      // Track release creation event
      analytics.trackNewRelease(
        saved.body.release, 
        releaseData.relName
      );
      
      // Track additional release details
      analytics.trackEvent('release_created', {
        release_format: releaseData.format,
        is_new: releaseData.isNew,
        has_preorder: !!release.preOrder,
        language: releaseData.language
      });
      
      return saved.body.release;
    } else if (saved.status === 200) {
      return saved.body;
    }
    return { error: true, status: saved.status };
  };

  setInfoRelease = async (release) => {
    const info = {
      relId: release._id,
      s3Url: process.env.REACT_APP_STORAGE + release._id,
      asset: release.asset,
    };

    const saved = await ReleaseCalls.setInfoRelease(info);
    if (saved.status === 200 && saved.body.release) {
      return saved.body.release;
    } else if (saved.status === 200) {
      return saved.body;
    }

    return { error: true, status: saved.status };
  };

  setRightsRelease = async (release) => {
    const info = {
      relId: release._id,
      holder: release.holder,
      infoC: release.infoC,
      infoP: release.infoP,
      yearC: release.yearC,
      yearP: release.yearP,
    };
    const saved = await ReleaseCalls.setRightsRelease(info);
    if (saved.status === 200 && saved.body.release) {
      return saved.body.release;
    } else if (saved.status === 200) {
      return saved.body;
    }
    return { error: true, status: saved.status };
  };

  setParticipantsRelease = async (release, participants) => {
    const info = {
      relId: release._id,
      artists: participants.artist,
      producers: participants.producer,
      writers: participants.writer,
      participants: participants.participant,
    };
    const saved = await ReleaseCalls.setParticipantsRelease(info);
    if (saved.status === 200 && saved.body.release) {
      return saved.body.release;
    } else if (saved.status === 200) {
      return saved.body;
    }
    return { error: true, status: saved.status };
  };

  setTracksRelease = async (release, tracks) => {
    const info = {
      relId: release,
      track: tracks,
    };
    const saved = await ReleaseCalls.setTracksRelease(info);
    if (saved.status === 200) {
      return saved.body;
    }
    return { error: true, status: saved.status };
  };

  removeTracksRelease = async (release, track) => {
    const body = {
      release: release,
    };
    const saved = await ReleaseCalls.removeTracksRelease(track, body);
    if (saved.status === 200) {
      return saved.body.deleted;
    }
    return { error: true, status: saved.status };
  };

  setReleaseTrackOrder = async (release, order) => {
    const body = {
      relId: release,
      order: order,
    };
    const saved = await ReleaseCalls.setReleaseTrackOrder(body);
    if (saved.status === 200) {
      return saved.body.ordered;
    }
    return { error: true, status: saved.status };
  };

  setReleaseTerritories = async (release, territories) => {
    const body = {
      relId: release,
      territories: territories,
    };
    const saved = await ReleaseCalls.setReleaseTerritories(body);
    if (saved.status === 200 && saved.body.release) {
      return saved.body.release;
    } else if (saved.status === 200) {
      return saved.body;
    }
    return { error: true, status: saved.status };
  };

  setReleaseDelivery = async (release) => {
    const body = {
      relId: release._id,
      delivery: {
        release_tier: release.albumPrice,
        dsp_delivery: release.distros,
        track_tier: release.trackPrice,
      },
    };
    const saved = await ReleaseCalls.setReleaseDelivery(body);
    if (saved.status === 200 && saved.body.release) {
      return saved.body.release;
    } else if (saved.status === 200) {
      return saved.body;
    }
    return { error: true, status: saved.status };
  };

  /**
   * Uploads the track to S3
   * @param {File} file
   * @param {String} folder - release folder name
   * @param {Object} filesOptions - custom options for the file {index, fileList, totalFilesPercentage, setPercentage}
   * @param {Number} filesOptions.index - track index
   * @param {Object} filesOptions.filesList - list of total files
   * @param {Number} filesOptions.totalFilesPercentage - total percentage of all files
   * @param {Function} filesOptions.setPercentage - function to set the percentage of the total files uploaded on the state
   */
  uploadTrack = async (file, folder, filesOptions = {}) => {
    const {
      index, // track index
      filesList,
    } = filesOptions;

    const re = /(?:\.([^.]+))?$/;
    const ext = re.exec(file.name)[1];
    const fileName = uuidv4() + "." + ext;
    const uploadS3 = new uploadS3Calls(folder, fileName);
    const url = await uploadS3.createSignedUrl(file);

    // using custom option "onProgress" to set the percentage of the total files uploaded
    const uploaded = await uploadS3.uploadFile(url, file, (progress) => {
      // set the percentage of the current file uploading
      const percentage = Math.round((progress.loaded / progress.total) * 100);
      if (percentage === 100) {
        filesList[index].fileUploaded = true;
      }
      filesList[index].percentageUploaded = percentage;
    });

    if (uploaded) {
      const uploadedTracksS3Url =
        process.env.REACT_APP_S3_BUCKET_CDN_URL ||
        process.env.REACT_APP_STORAGE;
      return {
        index,
        id: filesList[index].id || "",
        fileS3Url: uploadedTracksS3Url + folder,
        filename: fileName,
        type: "audio", // for this moment only audio
        fileUploaded: true,
        format: ext,
        checksum: uploaded.asset?.checksum,
      };
    }
    return {
      index,
      id: filesList[index].id || "",
      filename: fileName,
      type: "audio", // for this moment only audio
      fileUploaded: false,
      format: ext,
    };
  };

  /**
   * Delete S3 file from the bucket
   * @param {String} folder - release folder name
   * @param {String} filename - file name
   * @returns {Promise}
   */
  deleteFile = async (folder, filename) => {
    const deleteS3 = new uploadS3Calls(folder, filename);
    return await deleteS3.deleteFile();
  };

  pricingTier = async () => {
    return await ReleaseCalls.pricingTier();
  };

  trackPricingTier = async () => {
    return await ReleaseCalls.trackPricingTier();
  };

  updateAudiosaladStatus = async (id, adStatus) => {
    const body = {
      relId: id,
      adStatus: adStatus,
    };
    const saved = await ReleaseCalls.updateAudiosaladStatus(body);
    if (saved.status === 200) {
      return saved.body;
    }
    return { error: true, status: saved.status };
  };

  canRollback = async (release_id) => {
    const canRollBack = await ReleaseCalls.canRollback(release_id);
    if (canRollBack.status === 200) {
      return canRollBack.body;
    }
    return { error: true, status: canRollBack.status };
  };

  requestRollback = async (release_id) => {
    const canRollBack = await ReleaseCalls.requestRollback(release_id);
    if (canRollBack.status === 200) {
      return canRollBack.body;
    }
    return { error: true, status: canRollBack.status };
  };

  getDSPstatus = async (release_id) => {
    const dspStatus = await ReleaseCalls.getDSPstatus(release_id);
    if (dspStatus.status === 200) {
      return dspStatus.body;
    }
    return { error: true, status: dspStatus.status };
  };

  updateDraftStatus = async (id, draftStatus) => {
    const body = {
      relId: id,
      draftStatus: draftStatus,
    };
    const saved = await ReleaseCalls.updateDraftStatus(body);
    if (saved.status === 200) {
      return saved.body;
    }
    return { error: true, status: saved.status };
  };
}

export default new Release();
