import React from 'react';
import CheckIcon from '../../../Icon/CheckIcon';
import { planCategories } from '../plans-data';
import './plan-table.css';

const PlanTable = ({ 
  plans, 
  selectedPlan, 
  dictionary,
}) => {
  const renderValue = (value) => {
    if (value === 'yes' || value === '✔') return <CheckIcon className="check-icon" />;
    if (value === 'no' || value === '✘') return <span className="cross-icon">✘</span>;
    if (value === '-' || value === '' || value === undefined) return <span className="dash-icon">—</span>;
    return <span className="feature-value">{value}</span>;
  };
  
  const renderPriceWithPeriod = (plan) => (
    <div className="price">
      <span className="currency">$</span>
      <span className="amount">{plan.price}</span>
      <span className="period">/{dictionary[plan.period]}</span>
    </div>
  );

  return (
    <div className="plan-table-container">
      <table className="plan-table">
        <thead>
          <tr>
            <th className="feature-column">{dictionary.features}</th>
            {plans.map(plan => (
              <th 
                key={plan.id}
                className={`plan-column-simple ${selectedPlan === plan.id ? 'selected-plan' : ''} ${plan.id === 'extra' ? 'popular-plan' : ''}`}
              >
                <h3 className="plan-name">{plan.name}</h3>
                <div className="plan-price">
                  {renderPriceWithPeriod(plan)}
                </div>
                <div className="plan-description">
                  {dictionary.plan[plan.id].description}
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {planCategories.map((category, categoryIndex) => (
            <React.Fragment key={categoryIndex}>
              <tr className="category-row">
                <td colSpan={plans.length + 1} className="category-name">
                  {dictionary.planCategories[category.name]}
                </td>
              </tr>
              {category.features.map((feature, featureIndex) => (
                feature.name !== 'Price' && (
                  <tr key={`${categoryIndex}-${featureIndex}`} className="feature-row">
                    <td className="feature-name">
                      {dictionary.planCategories.features[feature.name]}
                    </td>
                    {plans.map(plan => (
                      <td 
                        key={plan.id}
                        className={`feature-check ${selectedPlan === plan.id ? 'selected-plan' : ''}`}
                      >
                        {renderValue(feature[plan.id])}
                      </td>
                    ))}
                  </tr>
                )
              ))}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PlanTable; 