import React, { useContext, useState, useEffect } from "react";
import { Row } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { ArtistContext } from "../../../../context/Artist";
import { LanguageContext } from "../../../../context/Language";
import Loader from "../../../Loader";
import Button from "../../../Buttons/GeneralButton";
import Release from "../../../../classes/release/release";
import ParticipantsForm from "../ParticipantsForm";

const Participants = ({
  state,
  setInput,
  imageUrl,
  backSection,
  nextSection,
  changeSection,
  setValid,
  validation,
  validateStep,
  releaseId,
}) => {
  const { dictionary } = useContext(LanguageContext);
  const { roles } = useContext(ArtistContext);

  const [errors, setError] = useState({
    artist: false,
    producer: false,
  });
  const [allParticipants] = useState({
    artist: [],
    writer: [],
    producer: [],
  });
  const [generalError, setGeneralError] = useState(null);
  const [loader, setLoader] = useState(false);
  const [planError, setPlanError] = useState(null);

  useEffect(() => {
    validateStep(next);
    // eslint-disable-next-line
  }, [state.artists.artist, state.artists.producer]);

  const orderParticipants = () => {
    let keys = Object.keys(state.artists);
    let all = [];
    let onlyArtist = [];
    let onlyProd = [];
    let onlyWrit = [];

    keys.forEach((el) => {
      state.artists[el].forEach((element) => {
        all.push(element);
      });
    });

    all.forEach(async (el) => {
      for (let artistRole in roles.artist) {
        if (artistRole === el.role) {
          onlyArtist.push(el);
        }
      }
      for (let prodRole in roles.producer) {
        if (prodRole === el.role) {
          onlyProd.push(el);
        }
      }
      for (let writRole in roles.writer) {
        if (writRole === el.role) {
          onlyWrit.push(el);
        }
      }
    });
    let newdata = {
      artist: onlyArtist,
      producer: onlyProd,
      writer: onlyWrit,
    };
    return newdata;
  };

  const validate = () => {
    let data = null;
    let error = false;
    let errors = {
      artist: false,
      producer: false,
    };
    setError({
      ...errors,
      errors,
    });

    let allParticipants = orderParticipants();
    const hasMain = allParticipants.artist.find((el) => el.role === "main");
    if (!hasMain) {
      errors.artist = dictionary.atLeastOneMainError;
      error = true;
    }

    const hasSongWriter = allParticipants.writer.find(
      (el) => el.role === "composer" || el.role === "song_writer"
    );

    if (!hasSongWriter) {
      errors.writer = dictionary.atLeastOneWriterError;
      error = true;
    }
    if (!error && !planError) {
      data = allParticipants;
    } else {
      setError({
        ...errors,
        errors,
      });
    }

    return data;
  };

  const back = () => {
    changeSection(2);
    backSection(3);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    return () => {
      // Limpiar cualquier tarea asincrónica pendiente o suscripciones aquí
    };
  }, []);

  const next = async (n = null) => {
    const data = validate();
    setGeneralError(null);
    if (data) {
      setLoader(true);
      const saved = await Release.setParticipantsRelease(state, data);
      if (!saved.error) {
        setValid({
          ...validation,
          step3: true,
        });
        if (n) {
          changeSection(n);
          nextSection(n);
        }
        window.scrollTo(0, 0);
      } else {
        setGeneralError(dictionary[saved.error] || dictionary.releaseCheckData);
      }
      setLoader(false);
    }
  };

  const saveArtists = async (artists, frontView) => {
    setError({
      artist: false,
      producer: false,
    });
    setInput({
      ...state,
      artists: artists,
      artistsFront: frontView,
    });
  };

  return (
    <div>
      <div className="forms">
        <Row className="gutter-row">
          <ParticipantsForm
            showCover={true}
            planError={planError}
            setPlanError={setPlanError}
            label={state.label}
            artistsAll={state.artists}
            imageRelease={imageUrl}
            nameRelease={state.relName}
            validationErrors={errors}
            saveArtists={saveArtists}
            allParticipants={allParticipants}
            artistsFrontAll={state.artistsFront}
            releaseId={releaseId}
          />
          {generalError ? (
            <div className="error-field">{generalError}</div>
          ) : null}
        </Row>
        {loader ? <Loader /> : null}
      </div>
      <div className="align-right reg-btn">
        <Button
          size="md"
          onClick={back}
          className="back-btn"
          text={dictionary.back}
          icon={<ArrowLeftOutlined />}
        />
        <Button size="md" 
          onClick={() => next(4)}
          text={dictionary.next}
          disabled={planError}
        />
      </div>
    </div>
  );
};

export default Participants;
