import React, { useContext } from "react";
import { UserOutlined } from "@ant-design/icons";
import { UserContext } from "../../../../context/User";
import { getResizedImage } from "../../../../util";

const Avatar = ({ otherClass = "" }) => {
  const { avatar, userId } = useContext(UserContext);

  if (avatar) {
    const getResized = getResizedImage({
      url: avatar,
      size: "xs",
      type: "user",
      resourceId: userId,
    });

    return (
      <div
        alt="user"
        className={"avatar-img " + otherClass}
        style={{
          backgroundImage: "url(" + getResized + "), url(" + avatar + ")",
        }}
      ></div>
    );
  }

  return <UserOutlined className="avatar-default" />;
};

export default Avatar;
